import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
const config = require("./app_config.json")

// Firebase 
export const firebase_app = firebase.initializeApp({

    // apiKey: "AIzaSyA8TF6Bkr5UaPBCQTwBj-6rcU3Ih_XDImg",
    // authDomain:"icetran-vendas.firebaseapp.com",
    // databaseURL: "https://icetran-vendas-default-rtdb.firebaseio.com/",
    // projectId: "icetran-vendas",
    // storageBucket: "icetran-vendas.appspot.com",
    // messagingSenderId: "446316106604",
    // appId: "1:446316106604:web:7a75c925728594c8389949"
    apiKey: "AIzaSyCsirpP1pvmZ639cABujdkWOrbLZQPepnk",
    authDomain: "icetran-vendas-bdcba.firebaseapp.com",
    projectId: "icetran-vendas-bdcba",
    storageBucket: "icetran-vendas-bdcba.appspot.com",
    messagingSenderId: "516541138527",
    appId: "1:516541138527:web:87a0f60a36b537b976bdea"
});

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db =  firebase.firestore();

// Auth0
export const auth0 = ({
    domain : config.auth0.domain,
    clientId : config.auth0.clientID,
    redirectUri : config.auth0.redirectUri
})

// Jwt
export const Jwt_token = localStorage.getItem('token');



