import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Edit } from 'react-feather';
import { UpdateProfile, EmailAddress, CancelUpdateProfile, Name, EditParceiro, CNPJ, UF, Faturamento1, Pais, Estado, Cidade, Bairro, CepCheckout, Rua, Numero, Complemento } from '../../constant';
import { useLocation } from 'react-router';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { setIn } from 'formik';
import { toInteger } from 'lodash';
import { mask, unMask } from 'remask';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import fusData from '../../utils/fus';
import seven from '../../assets/images/user/7.jpg';
import convertToBase64 from '../../services/convertToBase64';

import { StatesPerGroupBR } from '../../utils/utilTools';

import { requestAuthConfig } from '../../utils/utilTools';
import ButtonWarningPartner from '../icetran/ButtonWarningPartner';

const PartnerEdit = (props) => {
    const location = useLocation();

    const [name, setName] = useState('');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const [partnerId, setPartnerId] = useState('')
    const [partnerReference, setPartnerReference] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [invoicement, setInvoicement] = useState('');
    const [invoicementType, setInvoicementType] = useState('');
    const [street, setStreet] = useState('');
    const [complement, setComplement] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [fu, setFu] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [iuguKey, setIuguKey] = useState('');
    const [redeKey, setRedeKey] = useState('');
    const [valueUF, setValueUF] = useState('');
    const [partnerEditingHimself, setPartnerEditingHimself] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [fuList, setFuList] =  useState([]);

    const [imageURL, setImageURL] = useState('');
    const [base64, setBase64] = useState('');
    const [imagePath, setImagePath] = useState('');

    const [activeAccount, setActiveAccount] = useState(false)
    const [fus, setFus] = useState('');


    const addOrRemoveFu = (fu) => {
        const tempFus = [...fus];
        if (tempFus.includes(fu)) {
          tempFus.splice(tempFus.indexOf(fu), 1);
        }
        else {
          tempFus.push(fu);
        }
        setFus(tempFus);
        console.log(tempFus);
      }

    const cepPatterns = ['99999-999']
    const setCepValue = (cepValue) => {
        setZipCode(maskGeneric(cepValue, cepPatterns))
    }
    const maskGeneric = (maskValue, patterns) => {
        maskValue = unMask(maskValue)
        return mask(maskValue, patterns)
    }

    useEffect(() => {

        if (unMask(zipCode.toString()).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(zipCode.toString())}/json/`)
                .then(res => {
                    setStreet(res.data.logradouro);
                    setDistrict(res.data.bairro)
                    setCity(res.data.localidade)
                    setFu(res.data.uf)
                    setCountry('Brasil')
                })
                .catch(err => console.log(err))
        }
        else {
            setStreet('')
            setCity('')
            setDistrict('')
            setFu('')
            setCountry('')
            setNumber('')
            setComplement('')

        }
    }, [zipCode])

    async function getPartner(id) {
        axios.get(`${LOCAL_BACKEND_URL}/partner/${id}`, {
            headers: { 'Authorization': `bearer ${localStorage.getItem('token')}`}
        })
            .then(res => {
                console.log('res, partner edit', res)
                setImageURL(res.data.Partner.imageURL)
                setImagePath(res.data.Partner.imagePath)
                setValueUF(res.data.Partner.valueUF)
            })
            .catch(e => console.log(e.response.data))
    }

    useEffect(() => {
        setName(location.state.partnerInfo.name);
        setEmail(location.state.partnerInfo.email);
        setDocument(location.state.partnerInfo.document);
        // Address info
        setStreet(location.state.partnerInfo.adress.street);
        setComplement(location.state.partnerInfo.adress.complement);
        setNumber(location.state.partnerInfo.adress.number);
        setDistrict(location.state.partnerInfo.adress.district);
        setCity(location.state.partnerInfo.adress.city);
        setFu(location.state.partnerInfo.adress.fu);
        setCountry(location.state.partnerInfo.adress.country);
        setZipCode(location.state.partnerInfo.adress.zipCode);

        setPartnerId(location.state.partnerInfo.partnerId);
        setPhoneNumber(location.state.partnerInfo.phoneNumber);
        setPartnerReference(location.state.partnerInfo.partnerReference)
        setInvoicement(location.state.partnerInfo.invoicement);
        setInvoicementType(location.state.partnerInfo.invoicementType);
        setValueUF(location.state.partnerInfo.valueUF)
        setFus(location.state.partnerInfo.fusList);
        getPartner(location.state.partnerInfo.partnerId)

    }, []);

    const submitPartnerHandler = event => {
        if (location.state.partnerInfo.partnerId === localStorage.getItem('userId')) {
            setPartnerEditingHimself(true);
        }
        else {
            submitHandler(event);
        }
    }

    async function convert(e) {
        const base64 = await convertToBase64(e)
        setBase64(base64)
    }

    const submitHandler = event => {
        /* event.preventDefault(); */
        axios.patch(`${LOCAL_BACKEND_URL}/partner/${partnerId}`, {
            'name': name,
            'document': document,
            'email': email,
            'phoneNumber': phoneNumber,
            'zipCode': toInteger(unMask(zipCode.toString())),
            'street': street,
            'number': toInteger(number),
            'complement': complement,
            'district': district,
            'city': city,
            'fu': fu,
            'country': country,
            'invoicement': parseInt(invoicement),
            'invoicementType': parseInt(invoicementType),
            'partnerReference': parseInt(partnerReference),
            "passwordConfirmation": passwordConfirmation,
            'iuguKey': iuguKey,
            'redeKey': redeKey,
            'blocked': activeAccount,
            'valueUF': valueUF,
            'fusList': fus,
            'imagePath': imagePath,
            'imageURL': imageURL,
            'photoURL': base64
        }, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                props.history.push(`${process.env.PUBLIC_URL}/parceiros/estados`);
            })
            .catch(err => console.log(err.response.data));
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();
    return (
        <>
            <Breadcrumb parent="Parceiros" title="Editar Parceiro" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditParceiro}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                        <img
                                            className="img-70 rounded-circle"
                                            alt=""
                                            src={imageURL}
                                            onError={() => setImageURL(seven)}
                                        />
                                        <input
                                            className="form-control w-75"
                                            type="file"
                                            onChange={(e) => convert(e)}
                                        />
                                    </div>
                                    <div className="row">
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={name}
                                                    name="partner"
                                                    onChange={e => setName(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O nome do Parceiro é obrigatório.",
                                                            minLength: 2
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="partner" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    type="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O email do Parceiro é obrigatório.",
                                                            minLength: 4
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{CNPJ}</label>
                                                <input
                                                    className="form-control"
                                                    name="cnpj"
                                                    type="text"
                                                    value={document}
                                                    onChange={e => setDocument(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CNPJ é obrigatório.",
                                                            minLength: 6
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cnpj" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Liberar Conta</label>
                                                <select
                                                    className="form-control"
                                                    onChange={e => setActiveAccount(e.target.value)}
                                    
                                                >
                                                    <option value={false}>Ativa</option>
                                                    <option value={true}>Inativa</option>
                                                </select>
                                                <ErrorMessage errors={errors} name="cnpj" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{CepCheckout}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="cep"
                                                    value={zipCode}
                                                    onChange={(e) => setCepValue(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CEP é obrigatório.",
                                                            minLength: 8
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cep" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Rua}</label>
                                                <input
                                                    value={street}
                                                    onChange={(e) => setStreet(e.target.value)}
                                                    className="form-control"
                                                    type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">{Numero}</label>
                                                <input
                                                    className="form-control"
                                                    value={number}
                                                    name="numero"
                                                    onChange={(e) => setNumber(e.target.value)}
                                                    type="text"
                                                    ref={
                                                        register({
                                                            required: "O número é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="numero" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Complemento}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="complemento"
                                                    value={complement}
                                                    onChange={(e) => setComplement(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O complemento é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    } />
                                                <ErrorMessage errors={errors} name="complemento" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">{Bairro}</label>
                                                <input
                                                    value={district}
                                                    className="form-control"
                                                    onChange={(e) => setDistrict(e.target.value)}
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Cidade}</label>
                                                <input
                                                    value={city}
                                                    className="form-control"
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{Estado}</label>
                                                <select
                                                    className="form-control"
                                                    value={fu}
                                                    onChange={(e) => setFu(e.target.value)}
                                                >
                                                    <option value={null}>Selecione o Estado</option>
                                                    {
                                                        fusData.map((fu) => (
                                                            <option value={fu.initials}>
                                                                {fu.fu}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Pais}</label>
                                                <input
                                                    value={country}
                                                    className="form-control"
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Estado de Faturamento</label>
                                                <select
                                                    className='form-control'
                                                    onChange={(e) => setValueUF(e.target.value)}
                                                >
                                                    {
                                                        fusData.map((fu) => (
                                                            <option value={fu.initials}>
                                                                {
                                                                    fu.fu
                                                                }
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                        <FormGroup check inlist onChange={(e) => addOrRemoveFu(e.target.value)}>
                                                <h4 className="row" for="url">Estados</h4>
                                                <div className="row">
                                                    <div className="col-3">
                                                        {
                                                            StatesPerGroupBR.UF[0].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input
                                                                        type="checkbox"
                                                                        checked={
                                                                            location.state.partnerInfo.fusList.includes(stateGroup.sigla)
                                                                            ||
                                                                            fus.includes(stateGroup.sigla)
                                                                        }
                                                                        value={ stateGroup.sigla }
                                                                        onChange={(e) => setFuList([...fuList, e.target.value])}
                                                                    />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-3">
                                                    {
                                                            StatesPerGroupBR.UF[1].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input
                                                                        type="checkbox"
                                                                        checked={
                                                                            location.state.partnerInfo.fusList.includes(stateGroup.sigla)
                                                                            ||
                                                                            fus.includes(stateGroup.sigla)
                                                                        }
                                                                        value={ stateGroup.sigla }
                                                                    />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div className="col-3">
                                                    {
                                                            StatesPerGroupBR.UF[2].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input
                                                                        type="checkbox"
                                                                        checked={
                                                                            location.state.partnerInfo.fusList.includes(stateGroup.sigla)
                                                                            ||
                                                                            fus.includes(stateGroup.sigla)
                                                                        }
                                                                        value={ stateGroup.sigla }
                                                                    />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {/* </Input> */}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Faturamento1}</label>
                                                <select
                                                    onChange={e => setInvoicement(e.target.value)}
                                                    className="form-control"
                                                    value={invoicement}

                                                >
                                                    <option value={null}>Selecione o período</option>
                                                    <option value={0}>Semanal</option>
                                                    <option value={1}>Quinzenal</option>
                                                    <option value={2}>Mensal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Faturamento1}</label>
                                                <select
                                                    onChange={e => setInvoicementType(e.target.value)}
                                                    className="form-control"
                                                    type="text"
                                                    value={invoicementType}>
                                                    <option value={null}>Selecione o Tipo</option>
                                                    <option value={0}>Avulsa</option>
                                                    <option value={1}>Escalonada</option>
                                                    <option value={2}>Assinatura</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Chave Iugu
                                                </label>
                                                <input type='text' className='form-control' onChange={(e) => setIuguKey(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Chave Rede
                                                </label>
                                                <input type='text' className='form-control' onChange={(e) => setRedeKey(e.target.value)} />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => props.history.push(`${process.env.PUBLIC_URL}/parceiros/estados`)}>{CancelUpdateProfile}</button>
                                    <button
                                        onClick={handleSubmit}
                                        type="submit"
                                        className="btn btn-primary ml-3"
                                    >{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {

                partnerEditingHimself ?
                    <ButtonWarningPartner
                        updateHandler={submitPartnerHandler}
                        toggleState={partnerEditingHimself}
                        toggle={setPartnerEditingHimself}
                        password={passwordConfirmation}
                        setPassword={setPasswordConfirmation}
                        
                    />
                :
                <></>
            }
        </>
    );
};

export default PartnerEdit;