export const allCoursesData = [
    {
        'id': 1,
        'order': 0,
        'reference': 27,
        'image': 'https://picsum.photos/200/300',
        'title': 'Curso de Remarcação de Chassi',
        'status': 'Inativo',
    },
    {
        'id': 2,
        'order': 0,
        'reference': 59,
        'image': 'https://picsum.photos/150/250',
        'title': 'TAXISTA CAMPOS DOS GOYTACAZES - Rj',
        'status': 'Ativo',
    },
    {
        'id': 3,
        'order': 0,
        'reference': 98,
        'image': 'https://picsum.photos/100/200',
        'title': 'CURSO DE VISTORIA CAUTELAR',
        'status': 'Ativo',
    },
    {
        'id': 4,
        'order': 1,
        'reference': 51,
        'image': 'https://picsum.photos/40/100',
        'title': 'TAXISTA (EGEM)',
        'status': 'Ativo',
    },
    {
        'id': 5,
        'order': 2,
        'reference': 97,
        'image': 'https://picsum.photos/40/100',
        'title': 'MONITOR DE TRANSPORTE DE ESCOLARES (EGEM)',
        'status': 'Ativo',
    },
    {
        'id': 6,
        'order': 2,
        'reference': 46,
        'image': 'https://picsum.photos/40/100',
        'title': 'RECICLAGEM PARA CONDUTORES INFRATORES - SE',
        'status': 'Ativo',
    },
    {
        'id': 7,
        'order': 2,
        'reference': 93,
        'image': 'https://picsum.photos/40/100',
        'title': 'RECICLAGEM PARA CONDUTORES INFRATORES - MS',
        'status': 'Ativo',
    },
    {
        'id': 8,
        'order': 2,
        'reference': 14,
        'image': 'https://picsum.photos/40/100',
        'title': 'PREVENTIVO DE RECICLAGEM - MS',
        'status': 'Ativo',
    },
    {
        'id': 9,
        'order': 3,
        'reference': 20,
        'image': 'https://picsum.photos/40/100',
        'title': 'RECICLAGEM PARA CONDUTORES INFRATORES - AL',
        'status': 'Inativo',
    },
    {
        'id': 10,
        'order': 4,
        'reference': 57,
        'image': 'https://picsum.photos/40/100',
        'title': 'TAXISTA',
        'status': 'Inativo',
    },
]