import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Detaills } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import Breadcrumb from '../../common/breadcrumb';
import fus from '../../../utils/fus';
import SaleDetail from './saleDetail';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DownloadToExcel from '../../buttons/downloadToExcel';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { fetchSales } from '../../../api';
import diferenceInDays from '../../../services/diferenceInDays';

export default function SalesReport() {
    const [filterActive,setFilterActive] = useState(false);
    const [salesReportModal, setSalesReportModal] = useState(false);
    const [saleDatailed ,setSaleDatailed] = useState({});
    const [data, setData] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [partners, setPartners] = useState([]);
    const [courses, setCourses] = useState([]);
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [exportData, setExportData] = useState([]);

    //Filters
    const [fusSelected, setFusSelected] = useState('');
    const [coursesSelected, setCoursesSelected] = useState('');
    const [partnersSelected, setPartnersSelected] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientCPF, setClientCPF] = useState(''); 
    const [salesFiltered, setSalesFiltered] = useState([]);

    // Request Log
    const [requestLogIsOpen, setRequestLogIsOpen] = useState(false);
    const [registrationEmail, setRegisstrationEmail] = useState('');
    const [registrationDistrict, setRegistrationDistrict] = useState('');
    const [registrationPhone, setRegistrationPhone] = useState('');
    const [registrationCEP, setRegistrationCEP] = useState('');
    const [registrationCity, setRegistrationCity] = useState('');
    const [registrationBirthDay, setRegistrationBirthDay] = useState('');
    const [registrationDoc, setRegistrationDoc] = useState('');
    const [registrationAdress, setRegistrationAdress] = useState('');
    const [registrationFu, setRegistrationFu] = useState('');
    const [registrationPaymentType, setRegistrationPaymentType] = useState(0);
    const [registrationInstallments, setRegistrationInstallments] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [registrationFinantialType, setRegistrationFinantialType] = useState('');
    const [registrationInstallmentValue, setRegistrationInstallmentValue] = useState('');
    const [registrationFullValue, setRegistrationFullValue] = useState('');
    const [registrationExpirationOfTheFirst, setRegistrationExpirationOfTheFirst] = useState('');
    const [registrationExpirationOfTheLast, setRegistrationExpirationOfTheLast] = useState('');
    const [registrationClerkId, setRegistrationClerkId] = useState('');
    const [registrationCfcId, setregistrationCfcId] = useState('');
    const [registrationCourseIdsString, setRegistrationCourseIdsString] = useState('');
    const [registrationOfferId, setRegistrationOfferId] = useState('');
    const [registrationClassId, setRegistrationClassId] = useState('');
    const [registrationName, setRegistrationName] = useState('');
    const [registrationAdressNumber, setregistrationAdressNumber] = useState(0);
    const [registrationCountry, setRegistrationCountry] = useState('');
    const [registrationRG, setRegistrationRG] = useState('');
    const [registrationSaleDate, setRegistrationSaleDate] = useState('');
    const [registrationContractValue, setRegistrationContractValue] = useState('');

    // Pagination
    const [paginationCountArray, setPaginationCountArray] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPagination, setCurrentPagination] = useState(0);
    const [totalNumberOfSales, setTotalNumberOfSales] = useState(0);
    const [currentPaginationLength, setCurrentPaginationLength] = useState(0);
    const [responseData, setResponseData] = useState([]);
    const [saleAndId, setSaleAndId] = useState([]);
    const [salesToFilter, setSalesToFilter] = useState([]);

    const isAdmin = localStorage.getItem('isAdmin');
        useEffect(() => {
            async function getData() {
                await fetchSales('/sales')
                    .then(res => {
                        setSalesToFilter(res.data.sales)
                    })
                    .catch(err => console.log(err))
            }
            getData();
            axios.get(`${LOCAL_BACKEND_URL}/sales?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
                headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                setResponseData([...response.data.sales]);
                setNumberOfPages(response.data.info.numPages);

            })
            .catch((err) => {
                console.log(err)
            });
            getPartners();
            getCourses();
        }, []);

        useEffect(() => {
            if(!responseData) return;
            if(responseData.length > 0 && numberOfPages > 0) {
                let carrierArray = [];
                let saleAndIdCarrier = {};
                responseData.forEach((sale) => {
                    carrierArray.push(sale);
                    saleAndIdCarrier[sale.name] = sale.id;
                });
                setData(carrierArray);
                setCurrentPaginationLength(carrierArray.length);
                setSaleAndId({ ...saleAndIdCarrier });
                const tempCountArray = [];
                for (let i = 1; i <= numberOfPages; i++) {
                    tempCountArray.push(i);
                }
                setPaginationCountArray(tempCountArray);
            }
        }, [responseData, numberOfPages])

        async function getPartners() {
            await axios.get(`${LOCAL_BACKEND_URL}/partners`,{
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                setPartners(res.data.partners)
            })
            .catch(err => console.log(err.response.data))
        }

        async function getCourses() {
            await axios
            .get(`${LOCAL_BACKEND_URL}/courses`, {
                headers: { Authorization: `bearer ${localStorage.getItem('token')}` },
            })
            .then((res) => setCourses(res.data.courses))
            .catch((err) => console.log(err.response.data));
        }

        const dataList = data.map(
            (sale) =>
                <div className="card-body" key={sale.saleId}>
                    <div className="new-users">
                        <div className="media">
                            <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/2.png')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{sale.clientName}</h6>
                                <p className='mt-2'>
                                    <span>Parceiro: {sale.partnerName}</span><br />
                                    <span>Curso: {sale.courseTitle}</span><br />
                                    <span>ID da Venda: {sale.saleId}</span><br />
                                    <span>{"R$"} {Number(sale.value / 100).toFixed(2)}</span><br />
                                    <span>Data: {new Date(sale.date._seconds * 1000).toLocaleDateString('pt-BR')}</span>
                                </p>
                            </div>
                            <span className="pull-right">
                                <button className="btn btn-pill btn-outline-light" onClick={() => showDetails(sale)} >
                                    {Detaills}
                                </button>
                            </span>
                        </div>
                    </div>
                    <hr className="paddingLeft-5"></hr>
                </div >
        );

        const dataListFiltered = salesFiltered.map(
            (sale, index) => {
                if(!sale) return;
                return    <div className="card-body" key={index}>
                        <div className="new-users">
                            <div className="media">
                                <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/2.png')} alt="" />
                                <div className="media-body">
                                    <h6 className="mb-0 f-w-700">{sale.clientName}</h6>
                                    <p className='mt-2'>
                                        <span>Parceiro: {sale.partnerName}</span><br />
                                        <span>Curso: {sale.courseTitle}</span><br />
                                        <span>ID da Venda: {sale.saleId}</span><br />
                                        <span>{"R$"} {Number(sale.value / 100).toFixed(2)}</span><br />
                                        <span>Data: {new Date(sale.date._seconds * 1000).toLocaleDateString('pt-BR')}</span>
                                    </p>
                                </div>
                                <span className="pull-right">
                                    <button className="btn btn-pill btn-outline-light" onClick={() => showDetails(sale)} >
                                        {Detaills}
                                    </button>
                                </span>
                            </div>
                        </div>
                        <hr className="paddingLeft-5"></hr>
                    </div >
            }
        )

        function showDetails(sale) {
            if(sale.requestLog) {
                setRegistrationClassId(sale.requestLog.matriculas.idturma)
                setRegistrationOfferId(sale.requestLog.matriculas.idoferta);
                setRegistrationCourseIdsString(sale.requestLog.matriculas.idcurso);
                setregistrationCfcId(sale.requestLog.matriculas.idcfc);
                setRegistrationClerkId(sale.requestLog.matriculas.idatendente);
                setRegistrationExpirationOfTheLast(sale.requestLog.matriculas.financeiro.vencimento_ultima);
                setRegistrationExpirationOfTheFirst(sale.requestLog.matriculas.financeiro.vencimento_primeira);
                setRegistrationFullValue(sale.requestLog.matriculas.financeiro.valor_total);
                setRegistrationInstallmentValue(sale.requestLog.matriculas.financeiro.valor_parcela);
                setRegistrationFinantialType(sale.requestLog.matriculas.financeiro.tipo);
                setRegistrationStatus(sale.requestLog.matriculas.financeiro.situacao);
                setRegistrationInstallments(sale.requestLog.matriculas.financeiro.parcelas);
                setRegistrationPaymentType(sale.requestLog.matriculas.financeiro.forma_pagamento);
                setRegistrationContractValue(sale.requestLog.matriculas.valor_contrato);
                setRegistrationFu(sale.requestLog.matriculas.estado);
                setRegistrationAdress(sale.requestLog.matriculas.endereco);
                setRegistrationDoc(sale.requestLog.matriculas.documentoaluno);
                setRegistrationBirthDay(sale.requestLog.matriculas.data_nasc);
                setRegistrationCity(sale.requestLog.matriculas.cidade);
                setRegistrationCEP(sale.requestLog.matriculas.cep);
                setRegisstrationEmail(sale.requestLog.matriculas.email);
                setRegistrationDistrict(sale.requestLog.matriculas.bairro);
                setRegistrationPhone(sale.requestLog.matriculas.celular);
                setRegistrationRG(sale.requestLog.matriculas.rg);
                const date = new Date(sale.date._seconds * 1000).toLocaleString('pt-BR');
                setRegistrationSaleDate(date)
                setSaleDatailed(sale);
                setSalesReportModal(!salesReportModal);
            } else {
                setRegistrationClassId(' ');
                setRegistrationOfferId(' ');
                setRegistrationCourseIdsString(' ');
                setregistrationCfcId(' ');
                setRegistrationClerkId(' ');
                setRegistrationExpirationOfTheLast(' ');
                setRegistrationExpirationOfTheFirst(' ');
                setRegistrationFullValue(' ');
                setRegistrationInstallmentValue(' ');
                setRegistrationFinantialType(' ');
                setRegistrationStatus(' ');
                setRegistrationInstallments(' ');
                setRegistrationPaymentType(' ');
                setRegistrationContractValue(' ');
                setRegistrationFu(' ');
                setRegistrationAdress(' ');
                setRegistrationDoc(' ');
                setRegistrationBirthDay(' ');
                setRegistrationCity(' ');
                setRegistrationCEP(' ');
                setRegisstrationEmail(' ');
                setRegistrationDistrict(' ');
                setRegistrationPhone(' ');
                setRegistrationRG(' ');
                const date = new Date(sale.date._seconds * 1000).toLocaleString('pt-BR');
                setRegistrationSaleDate(date)
                setSaleDatailed(sale);
                setSalesReportModal(!salesReportModal);
            }
        }

        const changeTableData = async (dataIndex) => {
            setCurrentPagination(parseInt(dataIndex) - 1);
            await axios.get(`${LOCAL_BACKEND_URL}/sales?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                }
              })
              .then(response => {
                    setResponseData(response.data.sales);
                    setNumberOfPages(response.data.info.numPages);
                })
                .catch(err => console.log(err));
        }

        const filterCourses = () => {
            setSalesFiltered([]);
            const date = diferenceInDays(dateStart, dateEnd);
            const filterObj = {
                fuFilter: fusSelected,
                courseFilter: coursesSelected,  
                partnerFilter: partnersSelected,
                clientNameFilter: clientName,
                clientCPFFilter: clientCPF,
                dateFilter: date
            }

            let filt = []
            console.log(filterObj)
            salesToFilter.forEach(item => {
                    if(filterObj.fuFilter != '') {
                        if(item.fu != filterObj.fuFilter) return;
                    }
                    if(filterObj.courseFilter != '') {
                        if(item.courseTitle != filterObj.courseFilter) return;
                    }
                    if(filterObj.partnerFilter != '') {
                        if(item.partnerName != filterObj.partnerFilter) return;
                    }
                    if(filterObj.clientNameFilter != '') {
                        if(!item.clientName) return;
                        if(!item.clientName.includes(filterObj.clientNameFilter)) return;
                    }
                    if(filterObj.clientCPFFilter != '') {
                        if(item.clientCPF != filterObj.clientCPFFilter) return;
                    }
                    if(filterObj.dateFilter.length > 0) {
                        filterObj.dateFilter.forEach(date => {
                            const dateN = new Date(item.date._seconds * 1000).toLocaleString('pt-BR');
                            if(date != dateN.split(' ')[0]) return;
                            filt.push(item);
                        });
                    } else {
                        filt.push(item);
                    }
                }
            );
                
            setSalesFiltered(filt);
        };

        function cleanFilters() {
            setSalesFiltered([]);
            setFusSelected('');
            setPartnersSelected('');
            setCoursesSelected('');
            setClientCPF('');
            setClientName('');
            setDateStart('');
            setDateEnd('');
        }

        
        
    return (
        <div className='container-fluid'>
            <Breadcrumb parent={'Relatórios'} title='Relatórios de vendas' />
             <div className='w-75 m-auto bg-white rounded'>
                 <div className='p-3 d-flex w-100'>
                    {<div className='mt-1 w-75'>
                        <div className='ml-5'>
                            <div className='row'>
                                <div className='col'>
                                    <select 
                                        className='p-2 mb-1 form-control' 
                                        onChange={(e) => {
                                            setFusSelected(e.target.value)
                                            setFilterActive(true)
                                        }}>
                                        <option 
                                            selected 
                                            
                                            value={''}
                                        >
                                            Estado
                                        </option>
                                        {
                                            fus.map(fu => (
                                                <option 
                                                    value={fu.initials} 
                                                    key={fu.id}
                                                >
                                                    {fu.initials}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {<div className='col'>
                                    <select 
                                        className='p-2 mb-1 form-control' 
                                        onChange={(e) => {
                                            setPartnersSelected(e.target.value)
                                        }}
                                    >
                                        <option selected value={''}>Parceiro</option>
                                        {
                                            localStorage.getItem('isAdmin')
                                            ?
                                            partners.map(partner => (
                                                <option key={partner.partnerId} value={partner.name}>{partner.name}</option>
                                            ))
                                            :
                                            <option value={localStorage.getItem('userName')}>{localStorage.getItem('userName')}</option>
                                        }
                                    </select>
                                </div>}
                                <div className='col'>
                                    <select className='p-2 form-control' onChange={(e) => setCoursesSelected(e.target.value)}>
                                        <option selected value={''} >Curso</option>
                                        {
                                            courses.map(course => (
                                                <option key={course.reference} value={course.title}>{course.title}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                
                                <div className='col'>
                                        <input 
                                            type='text' 
                                            className='form-control' 
                                            placeholder='CPF' 
                                            value={clientCPF} 
                                            onChange={(e) => setClientCPF(e.target.value)}></input>
                                </div>
                                <div className='col'>
                                    <input 
                                        type='text' 
                                        className='form-control' 
                                        placeholder='Nome do Cliente' 
                                        value={clientName}
                                        onChange={(e) => setClientName(e.target.value)}>
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 ml-5 row d-flex'>
                            <div className='mr-4 mb-2'>
                                De <input type='date' className='form-control' onChange={(e) => setDateStart(e.target.value)}/>
                            </div>
                            <div>
                                Até <input type='date' className='form-control' onChange={(e) => setDateEnd(e.target.value)}/>
                            </div>
                        </div>
                    </div>}
                    <div className='w-50 ml-2' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div className='btn btn-primary py-2 px-3 w-75' onClick={() => filterCourses()}>Filtrar</div>
                        <div className='btn btn btn-dark mt-2 py-2 px-3 w-75' onClick={() => cleanFilters()}>Limpar Filtros</div>
                        <div className='w-75'>
                            {
                                salesFiltered.length === 0 
                                ?
                                <DownloadToExcel data={data} buttonText={'Baixar Dados'} />
                                :
                                <DownloadToExcel data={salesFiltered} buttonText={'Baixar Dados Filtrados'} />
                            }
                            {/*
                                <DownloadToExcel 
                                    data={data}
                                    
                                />
                            }
                            {
                                <DownloadToExcel
                                    data={salesFiltered}
                                    
                                />
                            */}
                        </div>
                    </div>
                 </div>
                <>
                    {salesFiltered.length > 0 ? dataListFiltered : dataList}
                </>
                
                    
                
                <SaleDetail 
                    salesReportModal={salesReportModal}
                    saleDatailed={saleDatailed}
                    registrationRG={registrationRG}
                    registrationSaleDate={registrationSaleDate}
                    registrationStatus={registrationStatus}
                    registrationInstallments={registrationInstallments}
                    registrationPaymentType={registrationPaymentType}
                    registrationPhone={registrationPhone}
                    registrationEmail={registrationEmail}
                    registrationDoc={registrationDoc}
                    registrationContractValue={registrationContractValue}
                    registrationExpirationOfTheLast={registrationExpirationOfTheLast}
                    registrationExpirationOfTheFirst={registrationExpirationOfTheFirst}
                    registrationFullValue={registrationFullValue}
                    registrationInstallmentValue={registrationInstallmentValue}
                    registrationFinantialType={registrationFinantialType}
                    registrationBirthDay={registrationBirthDay}
                    registrationCEP={registrationCEP}
                    registrationFu={registrationFu}
                    setSalesReportModal={setSalesReportModal}
                    isAdmin={isAdmin}
                />
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                                    <div className="mt-5">
                                        {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfSales}` : ''}
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                                            {
                                                currentPagination === 0 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink first href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    first
                                                    onClick={() => changeTableData((currentPagination + 1) - 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                            {
                                                paginationCountArray.map(item =>
                                                <PaginationItem>
                                                    <PaginationLink onClick={() => changeTableData(item)}>
                                                    {item}
                                                    </PaginationLink>
                                                </PaginationItem>
                                                )
                                            }
                                            {
                                                currentPagination === numberOfPages - 1 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink last href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    last
                                                    onClick={() => changeTableData((currentPagination + 1) + 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                        </Pagination>
                                    </nav>
                                </div>
            </div>
        </div>
    )
}