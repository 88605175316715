import React, { useState, useEffect } from 'react';
import { EditSale, Edit, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, NewPassword, AccessLevel, Salvar, EditCategoria } from "../../constant";
import Breadcrumb from "../common/breadcrumb";
import { Reference, Image, CustomTitle, Status, EditCourse } from "../../constant";
import seven from '../../assets/images/user/7.jpg';
import { useLocation } from 'react-router';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { FormGroup, Input, Label } from 'reactstrap';
import { Redirect } from 'react-router';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';


const EditCategory = ({ history }) => {

    const location = useLocation();

    const [url, setUrl] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        console.log(location.categoryId)
        axios.get(`${LOCAL_BACKEND_URL}/category/${location.categoryId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                setName(res.data.category.name);
                setUrl(res.data.category.url);
            })
            .catch(err => console.log(err));
    }, []);


    const submitHandler = event => {
        /* event.preventDefault(); */
        axios.patch(`${LOCAL_BACKEND_URL}/category/${location.categoryId}`, {
            'name': name,
            'url': url,

        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {

                history.push(`${process.env.PUBLIC_URL}/base/categorias`);
            })
            .catch(err => console.log(err.response.data));
    };





    /*     const [courseToBeEditted, setCourseToBeEditted] = useState({});
    
    
        useEffect(() => {
            const tempCourse = { ...location.state.courseInfo };
            setTitle(tempCourse.title);
            setReference(tempCourse.reference);
            setStatus(tempCourse.status)
            setCourseToBeEditted({ ...tempCourse })
        }, []);
    
        const updateCourseHandler = () => {
            const data = JSON.stringify({
                "title": title,
                "reference": reference,
    
    
            })
        } */

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <>
            <Breadcrumb parent="Categorias" title="Editar Categorias" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditCategoria}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input
                                                    name="name"
                                                    className="form-control"
                                                    type="text"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)} ref={
                                                        register({
                                                            required: "O nome da categoria é obrigatório",
                                                            minLength: 3
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="name" />
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-6">
                                            <FormGroup>
                                                <Label for="url">URL</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="url"
                                                    id="url"
                                                    value={url}
                                                    onChange={e => setUrl(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "A url da categoria do curso é obrigatório",
                                                            minLength: 3
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="url" />
                                            </FormGroup>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Status}</label>
                                                <input className="form-control"
                                                    type="text"
                                                    value={status}
                                                    onChange={e => setStatus(e.target.value)} />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button
                                        className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/categorias`)}>{CancelUpdateProfile}</button>
                                    <button
                                        className="btn btn-primary ml-3"
                                        onClick={handleSubmit}
                                        type="submit">{Salvar}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCategory;