import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { firebase_app, auth0 } from './data/config';
import { configureFakeBackend, authHeader, handleResponse } from "./services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";
import { Auth0Provider } from '@auth0/auth0-react'

//Clients
import ClientEdit from './components/clients/clientsEdit';

//Sells
import Vendas from './components/base/lists/vendas'
import EditSales from './components/edit/editSales';


// Import custom Components 
import Default from './components/dashboard/defaultCompo/default';
import Ecommerce from './components/dashboard/ecommerce';
import University from './components/dashboard/university';
import Crypto from './components/dashboard/crypto/crypto-component';
import ServerComponent from './components/dashboard/server/server-component';
import Project from './components/dashboard/project/project';

// widgets
import General from './components/widgets/general';
import Chart from './components/widgets/chart';

// banners

import Banner from './components/banner/banner';

// Base
import Statecolor from './components/base/statecolor';
import Typography from './components/base/typography';
import Avatar from './components/base/avatar';
import HelperClass from './components/base/helperclass';
import Grid from './components/base/grid';
import TagsandPills from './components/base/tagsandpills';
import ProgressBar from './components/base/progressBar';
import ModalComponent from './components/base/modalComponent';
import AlertComponent from './components/base/Alert/Alert';
import PopoverComponent from './components/base/Popover/Popover';
import TooltipsComponent from './components/base/tooltipsComponent';
import Spinner from './components/base/spinner';
import DropdownComponent from './components/base/DropDown/Dropdown';
import TabBootstrap from './components/base/Tabs/TabBootstrap';
import TabLine from './components/base/Tabs/TabLine';
import Accordion from './components/base/Accordian/Accordian';
import Navs from './components/base/Nav/Navs';
import List from './components/base/lists/list';
import ClientsList from './components/base/lists/clients';
import Levels from './components/base/lists/levels';
import AllCourses from './components/base/lists/allCourses';
import Categorias from './components/base/lists/categorias';
import Termos from './components/base/lists/termos';
import Contratos from './components/base/lists/contratos';
import PassosCursos from './components/base/lists/passosCurso';
import Shadow from './components/base/shadow';


// Advance
import Scrollable from './components/advance/scrollable';
import Toastr from './components/advance/toastr';
import RatingComponent from './components/advance/ratingComponent';
import DropzoneComponent from './components/advance/dropzone';
import TourComponent from './components/advance/tourComponent';
import SweetAlert from './components/advance/sweetAlert';
import SlickSlider from './components/advance/slickSlider';
import CarouselComponent from './components/advance/Carousels/carouselComponent';
import Ribbon from './components/advance/ribbon';
import Pagination from './components/advance/pagination';
import Steps from './components/advance/steps';
import UIBreadCrumb from './components/advance/uibreadcrumb';
import RangeSlider from './components/advance/rangeSlider';
import ImageCropper from './components/advance/imageCropper';
import StickyNotes from './components/advance/stickyNotes';
import DragNDropComp from './components/advance/drag-n-drop/dragNDropComp';
import UploadImage from './components/advance/uploadImage';

// icons 
import FlagIcons from './components/icons/flagIcons';
import FontAwsomeIcon from './components/icons/fontAwsomeIcon';
import IcoIcons from './components/icons/icoIcons';
import ThemifyIcons from './components/icons/themifyIcons';
import FeatherIcons from './components/icons/featherIcons';
import WeatherIcons from './components/icons/weatherIcons';

// buttons
import DefaultBtn from './components/buttons/default-btn';
import FlatBtn from './components/buttons/flatBtn';
import EdgeBtn from './components/buttons/edgeBtn';
import RaisedBtn from './components/buttons/raisedBtn';
import GroupBtn from './components/buttons/groupBtn';


// forms 
import FormValidation from './components/forms/form-control/form-validation';
import BaseInput from './components/forms/form-control/baseInput';
import RadioCheckbox from './components/forms/form-control/radio-checkbox';
import InputGroupComp from './components/forms/form-control/inputGroup';
import MegaOptions from './components/forms/form-control/megaOptions';

import DatepickerComponent from './components/forms/form-widgets/datepickerComponent';
import TimePickerWrapper from './components/forms/form-widgets/timepickerComponent/timepicker';
import TypeaheadComp from './components/forms/form-widgets/typeaheadComponent/typeahead';


import FormDefault from './components/forms/form-layout/formDefault';
import FormWizard from './components/forms/form-layout/wizard/form-wizard';

// Formulário de cadastro
import CadastrarForm from './components/forms/form-cadastro/cadastrarForm';

// Formulário de cadastro de categoria
import CadastrarCategoria from './components/forms/cadastrarCategoria';

// Search test
import BuscarCurso from './components/search/buscarCurso';

// Novo termo
import NovoTermo from './components/base/newTerm';

// Formulário de cadastro de contrato
import CadastrarContrato from './components/forms/form-cadastrar-contrato/cadastrarContrato';

// Novo passo de curso
import PassoCurso from './components/forms/cadastrarPassoCurso';

// Página de CFCs
import EstadosCFC from './components/base/lists/estadosCFC';

// Formulário de cadastro de CFCs
import CfcCreate from './components/cfcs/cfcCreate';

// Página de edição de cursos
import EditCourses from './components/edit/editCourses';

// Página de edição de categorias
import EditCategory from './components/edit/editCategory';

// Página de edição de termos
import EditTermo from './components/edit/editTerm';

// Página de edição de contratos
import EditContract from './components/edit/editContract';

// Página de edição de passos de cursos
import EditCourseStep from './components/edit/editCourseStep';

// Página de edição de nível de usuário
import EditLevel from './components/edit/editLevel';

// Página de edição de usuários cfc
import EditCfc from './components/edit/editCfc';

// cards
import BasicCards from './components/cards/basicCards';
import CreativeCards from './components/cards/creativeCards';
import TabCard from './components/cards/tabCard';
import DraggingCards from './components/cards/draggingCards';

// editors
import Editor1 from './components/editor/ckEditor';
import Editor2 from './components/editor/mdeEditor';
import Editor3 from './components/editor/aceCodeEditor';

// users
import UserProfile from './components/users/userProfile';
import UserEdit from './components/users/userEdit';
import ProfileEdit from './components/users/ProfileEdit';

import UserCreate from './components/users/userCreate';

import UserCards from './components/users/user-cards';

// levels
import LevelsCreate from './components/levels/levelCreate';

// plans
import Plans from './components/base/lists/planos';
import CreatePlan from './components/forms/createPlan';
import EditPlan from './components/edit/editPlan';

// pages 
import Login from './pages/login';
import LoginWithBgImg from './pages/loginWithBgImg';
import LoginWithVideo from './pages/loginWithVideo';
import Signup from './pages/signup';
import SignupWithImg from './pages/signupWithImg';
import SignupWithVideo from './pages/signupWithVideo';
import UnlockUser from './pages/unlockUser';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import ComingSoon from './pages/comingsoon';
import ComingSoonImg from './pages/comingsoonImg';
import ComingSoonVideo from './pages/comingsoonVideo';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';

// Import Applications Components

import todoFirebase from './components/applications/todo-firebase-app/todo-firebase';
import Signin from './auth/signin';
import PartnerSignin from './auth/partnerSignin';
//config data
import configDB from './data/customizer/config'

import Callback from './auth/callback'
import ClientsCreate from './components/users/clientsCreate';
import NewSellCreate from './components/users/sellCreate'
import { New } from './constant';
import SellEdit from './components/clients/sellEdit';
import CoursesEdit from './components/users/coursesEdit';
import Checkout from './components/checkout/Checkout';
import CheckoutCarrinhoTeste from './components/checkout/CheckoutTeste';
import Boleto2 from './components/checkout/Boleto2';
import NovosCFCs from './components/base/lists/solicitacaoCFC';
import RelacionamentoSms from './components/base/lists/sms';
import RelacionamentoEmail from './components/base/lists/email';
import CreateSms from './components/base/lists/smsCreate';
import CreateEmail from './components/base/lists/emailCreate';
import CreateCfc from './components/base/lists/createCfc';
import CreateNewCourse from './components/forms/form-cadastro/NovoCurso';
import EditSms from './components/edit/editSms';
import EditEmail from './components/edit/editEmail';
import EditPartners from './components/edit/editPartners';
import CategoryEdit from './components/users/categoriasEdit';
import PartnerEdit from './components/users/partnersEdit';
import ClientsCreateTeste from './components/base/lists/clientsCreateTeste';
import EditClients from './components/clients/editClients';
import TestePagination from './components/base/lists/testePagination';
import LastSells from './components/base/lists/lastSales';
import DestaqueEstado from './components/base/lists/destaqueEstados';
import EventChartsChange from './components/dashboard/defaultCompo/eventChartschange';
import teste from './components/base/lists/teste';
import Faturas from './components/base/lists/faturas';
import DataTeams from './components/base/lists/teams';
import NovosTimes from './components/users/novoTime';
import EditTeam from './components/edit/editTeam';
import EditStatus from './components/edit/EditStatusFaturas';
import PartnerFatura from './components/base/lists/verFaturas';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './recaptchaStyle.css';
import CreateCfcSolicitation from './components/base/lists/createCfcSolicitation';
import RecoveryPasswordSolicitation from './components/users/recoveryPasswordSolicitation';
import ChangePasswordWithToken from './components/users/changePasswordWithToken';
import CreditPackages from './components/base/lists/creditPackages';
import NewCredits from './components/forms/form-cadastro/newCredits';
import PendentesDeAprovacao from './components/base/lists/pendentesDeAprovacao';
import PendentesDeContrato from './components/base/lists/pendentesDeContrato';
import CreateCredit from './components/base/lists/createCredit';
import EditCreditPackage from './components/edit/editCreditPackage';

import BuyPackages from './components/buyPackages'
import CreditsPerPackage from './components/base/lists/creditsPerPackage';
import ChangePlans from './components/changePlan';
import Coupons from './components/base/lists/coupons';
import CreateCoupon from './components/base/lists/createCoupon';
import ContractSigningPage from './components/partners/contractSigningPage';
import axios from 'axios';

import { LOCAL_BACKEND_URL } from './data/urls';
import EditCoupon from './components/edit/editCoupon';
import AllowedFUs from './components/base/lists/allowedFUs';
import WaitListPanel from './components/waitListPanel';
import SalesReport from './components/base/salesReport';
import CostumerOwing from './components/clients/costumerOwing';
// setup fake backend
configureFakeBackend();


const Root = () => {

    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated, setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('token');
    const [partnerWithNoContract, setPartnerWithNoContract] = useState(false);
    const [isSigned, setIsSigned] = useState(false)

    console.log('isSigned', isSigned)

    useEffect(() => {

        const requestOptions = { method: 'GET', headers: authHeader() };
        fetch('/users', requestOptions).then(handleResponse)
        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        firebase_app.auth().onAuthStateChanged(setCurrentUser);
        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }

    }, [abortController]);

    // useEffect(() => {
    //     if (localStorage.getItem('invoicement')) {
    //         axios.get(`${LOCAL_BACKEND_URL}/partner/${localStorage.getItem('userId')}`, {
    //             headers: {
    //               'Authorization': `bearer ${localStorage.getItem('token')}`
    //               }
    //           })
    //             .then(res => {
    //                 if (res.data.Partner.contractIsSigned) {
    //                     <Redirect to={`${LOCAL_BACKEND_URL}/assinar-contrato`} />
    //                 }
    //             })
    //             .catch(err => console.log(err));
    //     }
    // });

    const publicRoutes = [
        { path: '/adm/login', component:Signin },
        { path: '/login', component:PartnerSignin },
        { path: '/solicitacaoparceiro', component:CreateCfcSolicitation },
        { path: '/recuperarsenha', component:RecoveryPasswordSolicitation },
        { path: '/alterarsenha', component:ChangePasswordWithToken },
        { path: '/checkout/carrinho', component: CheckoutCarrinhoTeste }
    ];

    const privateRoutes = [
        { path: '/dashboard', component:Default },
        { path: '/dashboard/ecommerce', component:Ecommerce },
        { path: '/dashboard/university', component:University },
        { path: '/dashboard/crypto', component:Crypto },
        { path: '/dashboard/server', component:ServerComponent },
        { path: '/dashboard/project', component:Project },

        // Widgets Menus
        { path: '/widgets/general', component:General },
        { path: '/widgets/chart', component:Chart },

        // Base
        { path: '/base/statecolor', component:Statecolor },
        { path: '/base/typography', component:Typography },
        { path: '/base/avatar', component:Avatar },
        { path: '/base/grid', component:Grid },
        { path: '/base/helperclass', component:HelperClass },
        { path: '/base/tagsandpills', component:TagsandPills },
        { path: '/base/progressBar', component:ProgressBar },
        { path: '/base/modalComponent', component:ModalComponent },
        { path: '/base/alert', component:AlertComponent },
        { path: '/base/popoverComponent', component:PopoverComponent },
        { path: '/base/tooltipsComponent', component:TooltipsComponent },
        { path: '/base/spinner', component:Spinner },
        { path: '/base/dropdownComponent', component:DropdownComponent },
        { path: '/base/tabs/tab-bootstrap', component:TabBootstrap },
        { path: '/base/tabs/tab-line', component:TabLine },
        { path: '/base/accordion', component:Accordion },
        { path: '/base/navs', component:Navs },
        { path: '/base/shadow', component:Shadow },
        { path: '/base/lista', component:List },
        { path: '/base/lista-clientes', component:ClientsList },
        { path: '/clients/clientsEdit', component:EditClients },
        { path: '/base/levels', component:Levels },
        { path: '/base/cursos', component:AllCourses },
        { path: '/base/categorias', component:Categorias },
        { path: '/base/termos', component:Termos },
        { path: '/base/contratos', component:Contratos },
        { path: '/base/passo_curso', component:PassosCursos },
        { path: '/base/relatorio', component:SalesReport },

        // Advance
        { path: '/advance/scrollable', component:Scrollable },
        { path: '/advance/bootstrap-notify', component:Toastr },
        { path: '/advance/ratingComponent', component:RatingComponent },
        { path: '/advance/dropzone', component:DropzoneComponent },
        { path: '/advance/tourComponent', component:TourComponent },
        { path: '/advance/sweetAlert', component:SweetAlert },
        { path: '/advance/slick-slider', component:SlickSlider },
        { path: '/advance/carouselComponent', component:CarouselComponent },
        { path: '/advance/ribbon', component:Ribbon },
        { path: '/advance/pagination', component:Pagination },
        { path: '/advance/steps', component:Steps },
        { path: '/advance/uibreadcrumb', component:UIBreadCrumb },
        { path: '/advance/rangeSlider', component:RangeSlider },
        { path: '/advance/imageCropper', component:ImageCropper },
        { path: '/advance/stickyNotes', component:StickyNotes },
        { path: '/advance/dragNDropComp', component:DragNDropComp },
        { path: '/advance/uploadImage', component:UploadImage },

        // Icons
        { path: '/icons/flagIcons', component:FlagIcons },
        { path: '/icons/fontAwsomeIcon', component:FontAwsomeIcon },
        { path: '/icons/icoIcons', component:IcoIcons },
        { path: '/icons/themifyIcons', component:ThemifyIcons },
        { path: '/icons/featherIcons', component:FeatherIcons },
        { path: '/icons/weatherIcons', component:WeatherIcons },

        // Buttons
        { path: '/buttons/default-btn', component:DefaultBtn },
        { path: '/buttons/flatBtn', component:FlatBtn },
        { path: '/buttons/edgeBtn', component:EdgeBtn },
        { path: '/buttons/raisedBtn', component:RaisedBtn },
        { path: '/buttons/groupBtn', component:GroupBtn },

        // Forms
        { path: '/forms-control/form-validation', component:FormValidation },
        { path: '/forms-control/baseInput', component:BaseInput },
        { path: '/forms-control/radio-checkbox', component:RadioCheckbox },
        { path: '/forms-control/inputGroup', component:InputGroupComp },
        { path: '/forms-control/megaOptions', component:MegaOptions },
        
        { path: '/form-layout/formDefault', component:FormDefault },
        { path: '/form-layout/FormWizard', component:FormWizard },

        { path: '/form-widget/datepickerComponent', component:DatepickerComponent },
        { path: '/form-widget/timepicker', component:TimePickerWrapper },
        { path: '/form-widget/typeahead', component:TypeaheadComp },

        // Cadastrar Form
        { path: '/courses/criar-curso', component:CreateNewCourse },

        // Cadastrar Categoria Form
        { path: '/cadastrar/categoria-curso', component:CadastrarCategoria },

        // Search Test
        { path: '/search/buscar-curso', component:BuscarCurso },

        // Times
        { path: '/base/times', component:DataTeams },
        { path: '/cadastrar/times', component:NovosTimes },
        
        // Novo Termo
        { path: '/create/new-term', component: NovoTermo },

        // Formulário de Cadastro de Contrato
        { path: '/cadastrar/contrato', component: CadastrarContrato },

        // Formulário de Cadastro de Novo Curso
        { path: '/cadastrar/passo-curso', component:PassoCurso },

        // Página de usuários CFC
        { path: '/parceiros/estados', component: EstadosCFC },

        // Página para Testes
        { path: '/teste/teste', component: teste },
        { path: '/teste/estado', component:DestaqueEstado },
        { path: '/banners', component: Banner },

        // Página de Relacionamentos Emails e SMS
        { path: '/relacionamentos/sms', component: RelacionamentoSms },
        { path: '/sms/criar', component: CreateSms },
        { path: '/relacionamentos/email', component:RelacionamentoEmail },
        { path: '/email/criar', component: CreateEmail },

        // Página de Criação de Usuários CFC
        { path: '/cfc/create', component: CfcCreate },
        { path: '/criar/parceiro', component: CreateCfc },

        // Página de Edição de vendas
        { path: '/edit/vendas', component: EditSales },

        // Página de Edição de Cursos
        { path: '/edit/cursos', component:EditCourses },

        // Página de Edição de Categorias 
        { path: '/edit/passo-categoria', component: EditCategory },

        // Página de Edição de Termos 
        { path: '/edit/termo', component: EditTermo },

        // Página de Edição de Contratos 
        { path: '/edit/contrato', component: EditContract },

        //Página de Edição de Passos de Cursos
        { path: '/edit/passo-curso', component: EditCourseStep },

        // Página de Edição de Níveis de Usuário
        { path: '/edit/nivel-usuario', component: EditLevel },

        // Página de edição de usuários CFC 
        { path: '/edit/cfc', component: PartnerEdit },

        // Página de edição de Times
        { path: '/edit/time', component: EditTeam },

        // Página de edição de Invoices
        { path: '/edit/fatura', component: EditStatus },

        // Ver venda de Fatura
        { path: '/fatura/vendas', component: PartnerFatura },

        // Página de Edição de SMS
        { path: '/edit/edit-sms', component: EditSms },
        { path: '/edit/edit-email', component: EditEmail },

        // Cards
        { path: '/cards/basicCards', component:BasicCards },
        { path: '/cards/creativeCards', component:CreativeCards },
        { path: '/cards/tabCard', component:TabCard },
        { path: '/cards/draggingCards', component:DraggingCards },

        // Editor
        { path: '/editor/ckEditor', component: Editor1 },
        { path: '/editor/mdeEditor', component: Editor2 },
        { path: '/editor/acecodeEditor', component:Editor3 },

        // Users
        { path: '/users/userProfile', component: UserProfile },
        { path: '/users/userEdit', component:UserEdit },
        { path: '/users/coursesEdit', component:CoursesEdit },
        { path: '/users/profileEdit', component:ProfileEdit },
        { path: '/users/userCreate', component:UserCreate },

        // Clients
        { path: '/users/novo-cliente', component:ClientsCreateTeste },

        // Faturas
        { path: '/financeiro/faturas', component: Faturas },

        // Sell
        { path: '/base/vendas', component: Vendas },
        { path: '/vendas/novaVenda', component: NewSellCreate },
        { path: '/users/userCards', component: UserCards },

        // Levels
        { path: '/levels/levelsCreate', component:LevelsCreate },

        // Plans
        { path: '/base/planos', component: Plans },
        { path: '/create/plan', component: CreatePlan },
        { path: '/edit/plan', component: EditPlan },

        // Pacotes
        { path: '/pacotes/creditos', component: CreditPackages },
        { path: '/parceiros/solicitacao/aprovacao', component: PendentesDeAprovacao },
        { path: '/parceiros/solicitacao/contrato', component: PendentesDeContrato },
        { path: '/creditos/criar', component: CreateCredit },
        { path: '/edit/credit', component: EditCreditPackage },
        { path: '/creditos/por-pacote', component: CreditsPerPackage },

        // Compra de Pacotes
        { path: '/base/cupons', component: Coupons },
        { path: '/criar/cupom', component: CreateCoupon },
        { path: '/editar/cupom', component: EditCoupon },

        // Lista de espera de Cursos Presenciais
        { path: '/lista-de-espera', component: WaitListPanel },

        // Altera plano
        { path: '/alterar-plano', component: ChangePlans },

        // Estados Permitidos
        { path: '/estadosautorizados', component: AllowedFUs },

        // To-Do Firebase
        { path: '/todo-app/todo-firebase', component: todoFirebase },

        // Cadastrar Créditos
        { path: '/creditos/adicionar', component:NewCredits }
    ];

    return (
        <div className="App">
            <GoogleReCaptchaProvider
                reCaptchaKey='6LddXVcdAAAAAAUgW43T6sgn8fZmbkFnAy4fvHlA'
            >
                <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
                    <Provider store={store}>
                        <BrowserRouter basename={`/`}>
                            <Switch>
                                {publicRoutes.map(eachRoute => (
                                    <Route path={process.env.PUBLIC_URL + eachRoute.path} component={eachRoute.component} />
                                ))}

                                {/* <Route path={`${process.env.PUBLIC_URL}/adm/login`} component={Signin} />
                                <Route path={`${process.env.PUBLIC_URL}/login`} component={PartnerSignin} />
                                <Route path={`${process.env.PUBLIC_URL}/solicitacaoparceiro`} component={CreateCfcSolicitation} />
                                <Route path={`${process.env.PUBLIC_URL}/recuperarsenha`} component={RecoveryPasswordSolicitation} />
                                <Route path={`${process.env.PUBLIC_URL}/alterarsenha`} component={ChangePasswordWithToken} /> */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} component={LoginWithBgImg} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} component={LoginWithVideo} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} component={SignupWithImg} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} component={SignupWithVideo} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} component={ComingSoonVideo} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
                                <Route path={`${process.env.PUBLIC_URL}/checkout/carrinho2`} component={Checkout} />
                                */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/checkout/carrinho/`} component={CheckoutCarrinhoTeste} /> */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/checkout/boletoTeste`} component={Boleto2} /> */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} /> */}

                                {jwt_token ?

                                    <App partnerStatus={partnerWithNoContract} setPartnerStatus={setPartnerWithNoContract}>
                                        {/* dashboard menu */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/`} />)
                                        }} />

                                        {privateRoutes.map(eachRoute => (
                                            <Route path={process.env.PUBLIC_URL + eachRoute.path} component={eachRoute.component} />
                                        ))}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Default} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/ecommerce`} component={Ecommerce} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/university`} component={University} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/crypto`} component={Crypto} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/server`} component={ServerComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/project`} component={Project} /> */}

                                        {/* Widgets Menu */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/widgets/general`} component={General} />
                                        <Route path={`${process.env.PUBLIC_URL}/widgets/chart`} component={Chart} /> */}

                                        {/* base */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/base/statecolor`} component={Statecolor} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/typography`} component={Typography} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/avatar`} component={Avatar} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/grid`} component={Grid} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/helperclass`} component={HelperClass} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/tagsandpills`} component={TagsandPills} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/progressBar`} component={ProgressBar} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/modalComponent`} component={ModalComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/alert`} component={AlertComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/popoverComponent`} component={PopoverComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/tooltipsComponent`} component={TooltipsComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/spinner`} component={Spinner} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/dropdownComponent`} component={DropdownComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/tabs/tab-bootstrap`} component={TabBootstrap} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/tabs/tab-line`} component={TabLine} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/accordion`} component={Accordion} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/navs`} component={Navs} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/shadow`} component={Shadow} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/lista`} component={List} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/lista-clientes`} component={ClientsList} />
                                        <Route path={`${process.env.PUBLIC_URL}/clients/clientsEdit`} component={EditClients} />
                                        <Route path={`${process.env.PUBLIC_URL}/editar/parceiro`} component={PartnerEdit} />
                                        <Route path={`${process.env.PUBLIC_URL}/clients/sellEdit`} component={SellEdit} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/levels`} component={Levels} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/cursos`} component={AllCourses} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/categorias`} component={Categorias} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/termos`} component={Termos} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/contratos`} component={Contratos} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/passo_curso`} component={PassosCursos} />
                                        <Route path={`${process.env.PUBLIC_URL}/base/relatorio`} component={SalesReport} /> */}

                                        {/* Advance */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/advance/scrollable`} component={Scrollable} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/bootstrap-notify`} component={Toastr} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/ratingComponent`} component={RatingComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/dropzone`} component={DropzoneComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/tourComponent`} component={TourComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/sweetAlert`} component={SweetAlert} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/slick-slider`} component={SlickSlider} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/carouselComponent`} component={CarouselComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/ribbon`} component={Ribbon} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/pagination`} component={Pagination} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/steps`} component={Steps} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/uibreadcrumb`} component={UIBreadCrumb} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/rangeSlider`} component={RangeSlider} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/imageCropper`} component={ImageCropper} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/stickyNotes`} component={StickyNotes} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/dragNDropComp`} component={DragNDropComp} />
                                        <Route path={`${process.env.PUBLIC_URL}/advance/uploadImage`} component={UploadImage} /> */}

                                        {/* icons */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/icons/flagIcons`} component={FlagIcons} />
                                        <Route path={`${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`} component={FontAwsomeIcon} />
                                        <Route path={`${process.env.PUBLIC_URL}/icons/icoIcons`} component={IcoIcons} />
                                        <Route path={`${process.env.PUBLIC_URL}/icons/themifyIcons`} component={ThemifyIcons} />
                                        <Route path={`${process.env.PUBLIC_URL}/icons/featherIcons`} component={FeatherIcons} />
                                        <Route path={`${process.env.PUBLIC_URL}/icons/weatherIcons`} component={WeatherIcons} /> */}

                                        {/* buttons */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/buttons/default-btn`} component={DefaultBtn} />
                                        <Route path={`${process.env.PUBLIC_URL}/buttons/flatBtn`} component={FlatBtn} />
                                        <Route path={`${process.env.PUBLIC_URL}/buttons/edgeBtn`} component={EdgeBtn} />
                                        <Route path={`${process.env.PUBLIC_URL}/buttons/raisedBtn`} component={RaisedBtn} />
                                        <Route path={`${process.env.PUBLIC_URL}/buttons/groupBtn`} component={GroupBtn} /> */}

                                        {/* Forms */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/forms-controls/form-validation`} component={FormValidation} />
                                        <Route path={`${process.env.PUBLIC_URL}/forms-controls/baseInput`} component={BaseInput} />
                                        <Route path={`${process.env.PUBLIC_URL}/forms-controls/radio-checkbox`} component={RadioCheckbox} />
                                        <Route path={`${process.env.PUBLIC_URL}/forms-controls/inputGroup`} component={InputGroupComp} />
                                        <Route path={`${process.env.PUBLIC_URL}/forms-controls/megaOptions`} component={MegaOptions} /> */}

                                        {/* <Route path={`${process.env.PUBLIC_URL}/form-layout/formDefault`} component={FormDefault} />
                                        <Route path={`${process.env.PUBLIC_URL}/form-layout/FormWizard`} component={FormWizard} /> */}

                                        {/* <Route path={`${process.env.PUBLIC_URL}/form-widget/datepickerComponent`} component={DatepickerComponent} />
                                        <Route path={`${process.env.PUBLIC_URL}/form-widget/timepicker`} component={TimePickerWrapper} />
                                        <Route path={`${process.env.PUBLIC_URL}/form-widget/typeahead`} component={TypeaheadComp} /> */}

                                        {/* Cadastrar form */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/courses/criar-curso`} component={CreateNewCourse} /> */}

                                        {/* Cadastrar categoria form */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/cadastrar/categoria-curso`} component={CadastrarCategoria} /> */}

                                        {/* Search test */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/search/buscar-curso`} component={BuscarCurso} /> */}

                                        {/*Times */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/base/times`} component={DataTeams} />
                                        <Route path={`${process.env.PUBLIC_URL}/cadastrar/times`} component={NovosTimes} /> */}


                                        {/* Novo termo */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/create/new-term`} component={NovoTermo} /> */}

                                        {/* Formulário de cadastro de contrato */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/cadastrar/contrato`} component={CadastrarContrato} /> */}

                                        {/* Formulário de cadastro de novo curso */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/cadastrar/passo-curso`} component={PassoCurso} /> */}

                                        {/* Página de usuários CFC */}

                                        {/* <Route path={`${process.env.PUBLIC_URL}/parceiros/estados`} component={EstadosCFC} /> */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/parceiros/solicitacao`} component={NovosCFCs} /> */}

                                        {/* Pagina para testes */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/teste/teste`} component={teste} />
                                        <Route path={`${process.env.PUBLIC_URL}/teste/estado`} component={DestaqueEstado} />
                                        <Route path={`${process.env.PUBLIC_URL}/banners`} component={Banner} /> */}


                                        {/* Página de Relacionamentos email e SMS */}

                                        {/* <Route path={`${process.env.PUBLIC_URL}/relacionamentos/sms`} component={RelacionamentoSms} />
                                        <Route path={`${process.env.PUBLIC_URL}/sms/criar`} component={CreateSms} />
                                        <Route path={`${process.env.PUBLIC_URL}/relacionamentos/email`} component={RelacionamentoEmail} />
                                        <Route path={`${process.env.PUBLIC_URL}/email/criar`} component={CreateEmail} /> */}


                                        {/* Página de criação de usuários CFC */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/cfc/create`} component={CfcCreate} />
                                        <Route path={`${process.env.PUBLIC_URL}/criar/parceiro`} component={CreateCfc} /> */}

                                        {/* Página de edição de vendas */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/vendas`} component={EditSales} /> */}

                                        {/* Página de edição de cursos */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/cursos`} component={EditCourses} /> */}

                                        {/* Página de edição de categorias */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/passo-categoria`} component={EditCategory} /> */}

                                        {/* Página de edição de termos */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/termo`} component={EditTermo} /> */}

                                        {/* Página de edição de contratos */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/contrato`} component={EditContract} /> */}

                                        {/* Página de edição de passos de cursos */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/passo-curso`} component={EditCourseStep} /> */}

                                        {/* Página de edição de níveis de usuário */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/nivel-usuario`} component={EditLevel} /> */}

                                        {/* Página de edição de usuários cfc */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/cfc`} component={PartnerEdit} /> */}

                                        {/* Página de edição de Times */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/time`} component={EditTeam} /> */}

                                        {/* Página de edição de Invoices */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/fatura`} component={EditStatus} /> */}

                                        {/* Ver venda de fatura */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/fatura/vendas`} component={PartnerFatura} /> */}

                                        {/* Página de edição de sms */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/edit/edit-sms`} component={EditSms} />
                                        <Route path={`${process.env.PUBLIC_URL}/edit/edit-email`} component={EditEmail} /> */}

                                        {/* cards */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/cards/basicCards`} component={BasicCards} />
                                        <Route path={`${process.env.PUBLIC_URL}/cards/creativeCards`} component={CreativeCards} />
                                        <Route path={`${process.env.PUBLIC_URL}/cards/tabCard`} component={TabCard} />
                                        <Route path={`${process.env.PUBLIC_URL}/cards/draggingCards`} component={DraggingCards} /> */}

                                        {/* Editor */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/editor/ckEditor`} component={Editor1} />
                                        <Route path={`${process.env.PUBLIC_URL}/editor/mdeEditor`} component={Editor2} />
                                        <Route path={`${process.env.PUBLIC_URL}/editor/acecodeEditor`} component={Editor3} /> */}

                                        {/* Users */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/users/userProfile`} component={UserProfile} />
                                        <Route path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />
                                        <Route path={`${process.env.PUBLIC_URL}/users/coursesEdit`} component={CoursesEdit} />
                                        <Route path={`${process.env.PUBLIC_URL}/users/profileEdit`} component={ProfileEdit} />
                                        <Route path={`${process.env.PUBLIC_URL}/users/userCreate`} component={UserCreate} /> */}

                                        {/* Clients */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/users/novo-cliente`} component={ClientsCreateTeste} /> */}

                                        {/* Faturas */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/financeiro/faturas`} component={Faturas} /> */}

                                        {/* Sell */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/base/vendas`} component={Vendas} />
                                        <Route path={`${process.env.PUBLIC_URL}/vendas/novaVenda`} component={NewSellCreate} /> */}

                                        {/* <Route path={`${process.env.PUBLIC_URL}/users/userCards`} component={UserCards} /> */}

                                        {/* Levels */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/levels/levelsCreate`} component={LevelsCreate} /> */}

                                        {/* Plans */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/base/planos`} component={Plans} />
                                        <Route path={`${process.env.PUBLIC_URL}/create/plan`} component={CreatePlan} />
                                        <Route path={`${process.env.PUBLIC_URL}/edit/plan`} component={EditPlan} />

                                        <Route path={`${process.env.PUBLIC_URL}/pacotes/creditos`} component={CreditPackages} />
                                        <Route path={`${process.env.PUBLIC_URL}/parceiros/solicitacao/aprovacao`} component={PendentesDeAprovacao} />
                                        <Route path={`${process.env.PUBLIC_URL}/parceiros/solicitacao/contrato`} component={PendentesDeContrato} /> */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/creditos/criar`} component={CreateCredit} />
                                        <Route path={`${process.env.PUBLIC_URL}/edit/credit`} component={EditCreditPackage} />

                                        <Route path={`${process.env.PUBLIC_URL}/creditos/por-pacote`} component={CreditsPerPackage} /> */}

                                        {/* Teste Checkout */}

                                        {/* Compra de pacotes */}
                                        {!localStorage.getItem('isAdmin') && (
                                            <Route path={`${process.env.PUBLIC_URL}/compra-pacotes`} component={BuyPackages} />
                                        )}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/base/cupons`} component={Coupons} />
                                        <Route path={`${process.env.PUBLIC_URL}/criar/cupom`} component={CreateCoupon} />
                                        <Route path={`${process.env.PUBLIC_URL}/editar/cupom`} component={EditCoupon} /> */}

                                        {/* Lista de espera de cursos presenciais */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/lista-de-espera`} component={WaitListPanel} /> */}

                                        {/* Altera plano */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/alterar-plano`} component={ChangePlans} /> */}

                                        {/* Estados permitidos */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/estadosautorizados`} component={AllowedFUs} /> */}
                                        
                                        {/* To-Do-Firebase */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/todo-app/todo-firebase`} component={todoFirebase} /> */}
                                        {
                                            localStorage.getItem('isAdmin') === 'true' ?
                                                <></>
                                            :
                                                <Route path={`${process.env.PUBLIC_URL}/assinar-contrato`} component={ContractSigningPage} />
                                        }
                                        <Route path={`${process.env.PUBLIC_URL}/debito-parceiro`} component={CostumerOwing} />
                                    </App>
                                    :
                                    <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                                }
                            </Switch>
                        </BrowserRouter>
                    </Provider>
                </Auth0Provider>
            </GoogleReCaptchaProvider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();