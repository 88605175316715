import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { New } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import { categoriasData } from '../../../data/categoriasData';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import axios from 'axios';
import ButtonDeleteCategory from '../../icetran/ButtonDeleteCategory';
import { db } from '../../../data/config';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';


const Categorias = ({ history }) => {

  const [responseData, setResponseData] = useState([]);
  const [categoryAndId, setCategoryAndId] = useState([]);
  const [data, setData] = useState();

  const [refresh, setRefresh] = useState();

  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfCategories, setTotalNumberOfCategories] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/categories?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((res) => {
        setResponseData([...res.data.categories]);
        setNumberOfPages(res.data.info.numPages);

      })
      .catch((err) => { console.log(err) });

      axios.get(`${LOCAL_BACKEND_URL}/allCategories`, {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('token')}`
        }
      })
        .then(res => {
          setTotalNumberOfCategories(res.data.categories.length);
        })
        .catch(err => console.log(err.message));

  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      let categoryAndIdCarrier = {};
      responseData.forEach(category => {
        carrierArray.push(category);
        categoryAndIdCarrier[category.name] = category.categoryId;
      })
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);
      setCategoryAndId({ ...categoryAndIdCarrier });

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);

    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/categories?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.categories);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }


  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      center: true,
      grow: 3,
      cell: row => <div style={{ overflow: 'inherit' }}>{row.name}</div>,
    },
    {
      name: 'URL',
      selector: 'url',
      sortable: true,
      center: true,
      grow: 3,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}><a href={row.url} target='_blank'>{row.url}</a></div>,
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "70%" }}><li className="list-group-item-action">
        <button
          style={{ marginRight: 8 }}
          onClick={() => history.push(
            {
              pathname: `${process.env.PUBLIC_URL}/edit/passo-categoria`,
              categoryId: categoryAndId[row.name],
              categoryInfo: getCategoryData(responseData, categoryAndId[row.name])
            }
          )} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
        <li className="list-group-item-action">
          <ButtonDeleteCategory
            refreshPage={setRefresh}
            getCategoryFunction={getCategoryData}
            category={responseData}
            categoryId={categoryAndId[row.name]}
          /></li>
      </div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  useEffect(() => {
    const fetchCategoryData = async () => {
      const data = await db.collection("category").get()
    };
  }, [])
  // }, []);

  function searchCategories(rows) {
    return rows && rows.length ? rows.filter(row =>
      row.name.toLowerCase().indexOf(searchTerm) > -1) : rows
  }

  console.log(data)

  return (
    <div>
      <Breadcrumb parent="Produtos" title="Categorias" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Categorias dos Produtos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/cadastrar/categoria-curso`)} type="button">{New}</button>
                </div>
              </div>
              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchCategories(data)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfCategories}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                            <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                            />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                            <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                            />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categorias;

const getCategoryData = (category, idToBeFound) => {
  let returnValue = 'Categoria not found';
  category.forEach(category => {
    if (category.categoryId === idToBeFound) {
      returnValue = { ...category };
    }
  });
  return returnValue;
}