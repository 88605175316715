import React, {useEffect, useState } from 'react';
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../data/urls";
import Modal from 'react-modal';
import Breadcrumb from '../common/breadcrumb';
import convertToBase64 from '../../services/convertToBase64';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
}

Modal.setAppElement('#root')



const Banner = () => {

    const fus = [
        {
            fu: 'Acre',
            initials: 'AC'
        },
        {
            fu: 'Alagoas',
            initials: 'AL'
        },
        {
            fu: 'Amapá',
            initials: 'AP'
        },
        {
            fu: 'Amazonas',
            initials: 'AM'
        },
        {
            fu: 'Bahia',
            initials: 'BA'
        },
        {
            fu: 'Ceará',
          initials: 'CE'
        },
        {
            fu: 'Espirito Santo',
            initials: 'ES'
        },
        {
            fu: 'Goiás',
            initials: 'GO'
        },
        {
            fu: 'Maranhão',
            initials: 'MA'
        },
        {
            fu: 'Mato Grosso',
            initials: 'MT'
        },
        {
          fu: 'Mato Grosso do Sul',
          initials: 'MS'
        },
        {
            fu: 'Minas Gerais',
            initials: 'MG'
        },
        {
            fu: 'Pará',
          initials: 'PA'
        },
        {
            fu: 'Paraíba',
          initials: 'PB'
        },
        {
          fu: 'Paraná',
          initials: 'PR'
        },
        {
            fu: 'Pernambuco',
            initials: 'PE'
        },
        {
          fu: 'Piauí',
          initials: 'PI'
        },
        {
            fu: 'Rio de Janeiro',
            initials: 'RJ'
        },
        {
            fu: 'Rio Grande do Norte',
            initials: 'RN'
        },
        {
            fu: 'Rio Grande do Sul',
            initials: 'RS'
        },
        {
            fu: 'Rondônia',
            initials: 'RO'
        },
        {
          fu: 'Roraima',
          initials: 'RR'
        },
        {
            fu: 'Santa Catarina',
            initials: 'SC'
        },
        {
          fu: 'São Paulo',
          initials: 'SP'
        },
        {
          fu: 'Sergipe',
          initials: 'SE'
        },
        {
            fu: 'Tocantins',
            initials: 'TO'
        },
        {
            fu: 'Distrito Federal',
          initials: 'DF'
        }
    ]
    
    const [fusSelected, setFusSelected] = useState([]);
    const [editFusSelected, setEditFusSelected] = useState([]);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [updateModal, setUpdateOpen] = useState(false);

    const [ReponseData, setResponseData] = useState([]);

    const [bannerCreateStartDate, setBannerCreateStartDate] = useState(Date);
    const [bannerCreateEndDate, setBannerCreateEndDate] = useState(Date);
    const [bannerCreateName, setBannerCreateName] = useState('');
    const [bannerCreateUrl, setBannerCreateUrl] = useState('');
    const [bannerCreateBase64, setBannerCreateBase64] = useState('');

    const [bannerUpdateStartDate, setBannerUpdateStartDate] = useState(Date);
    const [bannerUpdateEndDate, setBannerUpdateEndDate] = useState(Date);
    const [bannerUpdateName, setBannerUpdateName] = useState('');
    const [bannerUpdateUrl, setBannerUpdateUrl] = useState('');
    const [bannerUpdateBase64, setBannerUpdateBase64] = useState('');
    const [bannerUpdateId, setBannerUpdateid] = useState('');
    const [bannerUpdateImagePath, setBannerUpdateImagePath] = useState(false);
    const [bannerUpdateImageURL, setBannerUpdateImageURL] = useState(false);

    const [bannerCreateWithImg, setBannerCreateWithImg] = useState(false);
    const [bannerUpdateWithImg, setBannerUpdateWithImg] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    
    async function getNewBanner() {
        await axios.get(`${LOCAL_BACKEND_URL}/banners`, {
            headers: {
              'Authorization': `bearer ${localStorage.getItem('token')}`
            }
          })
                    .then((response) => {
                        setResponseData(response.data.banners)
                    })
                    .catch((err) => {
                        console.log(err)
                    });
    }
    async function deleteBanner(d) {

        await axios.delete(`${LOCAL_BACKEND_URL}/banner/${d}`, {
                headers: {'Authorization': `bearer ${localStorage.getItem('token')}`}
            })
                .then(res => {
                    toast.success('Banner Deletado!')
                    })
                .catch(err => console.log(err))

                getNewBanner()
    
    }
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    function handleFus(fu) {
        setFusSelected([...fusSelected,fu])
    }
    function editHandleFus(fu){
        setEditFusSelected([...editFusSelected,fu])
    }
    async function openUpdateModal(d) {

        setBannerUpdateid(d.bannerId);

        try {
            await axios.get(`${LOCAL_BACKEND_URL}/banner/${d.bannerId}`, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                }
              })
                        .then((response) => {
                            setBannerUpdateName(response.data.banner.name);
                            setBannerUpdateUrl(response.data.banner.url);
                            setEditFusSelected(response.data.banner.fu);
                            setBannerUpdateImagePath(response.data.banner.imagePath);
                            setBannerUpdateImageURL(response.data.banner.imageURL);
                            console.log(response.data.banner)
                        })
                        .catch((err) => {
                            console.log(err)
                        });
        
        } catch(err) {
            console.log(err);
        }

        getNewBanner()
        
        setUpdateOpen(true);
    }
    function closeCloseModal() {
        setUpdateOpen(false);
    }
    async function createBanner() {

        await axios.post(`${LOCAL_BACKEND_URL}/banner`, {
            name: bannerCreateName,
            url: bannerCreateUrl,
            fu: fusSelected,
            startDate: bannerCreateStartDate.replace(bannerCreateStartDate.slice(-5, -3), `${Number(bannerCreateStartDate.slice(-5, -3)) +3}`),
            finishDate: bannerCreateEndDate.replace(bannerCreateEndDate.slice(-5, -3), `${Number(bannerCreateEndDate.slice(-5, -3)) +3}`),
            image: bannerCreateBase64,

        }, {
            headers: {'Authorization': `bearer ${localStorage.getItem('token')}`}
        })
            .then(res => {
                toast.success('Banner Criado!')
                closeModal()
                getNewBanner()
                })
            .catch(err => console.log(err))   
        
    }
    async function updateBanner() {

        bannerUpdateWithImg 
        ? 
        axios.patch(`${LOCAL_BACKEND_URL}/banner/${bannerUpdateId}`, {
            name: bannerUpdateName,
            url: bannerUpdateUrl,
            fu: editFusSelected,
            startDate: bannerUpdateStartDate.replace(bannerCreateStartDate.slice(-5, -3), `${Number(bannerCreateStartDate.slice(-5, -3)) +3}`),
            finishDate: bannerUpdateEndDate.replace(bannerCreateEndDate.slice(-5, -3), `${Number(bannerCreateEndDate.slice(-5, -3)) +3}`),
            image: bannerUpdateBase64,

        }, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                }
              })
                        .then((response) => {
                            toast.success('Banner Atualizado!')
                            closeCloseModal()
                        })
                        .catch((err) => {
                            console.log(err)
                        })
        :
         axios.patch(`${LOCAL_BACKEND_URL}/banner/${bannerUpdateId}`,{
            name: bannerUpdateName,
            url: bannerUpdateUrl,
            fu: editFusSelected,
            startDate: bannerUpdateStartDate.replace(bannerCreateStartDate.slice(-5, -3), `${Number(bannerCreateStartDate.slice(-5, -3)) +3}`),
            finishDate: bannerUpdateEndDate.replace(bannerCreateEndDate.slice(-5, -3), `${Number(bannerCreateEndDate.slice(-5, -3)) +3}`),
            imagePath: bannerUpdateImagePath,
            imageURL: bannerUpdateImageURL,

        }, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                }
              })
                        .then((response) => {
                            toast.success('Banner Atualizado!')
                            closeCloseModal()
                        })
                        .catch((err) => {
                            console.log(err)
                        });

        
                        getNewBanner()
    }
    async function convert(e) {
        const base64 = await convertToBase64(e);
        setBannerCreateWithImg(true);
        setBannerCreateBase64(base64);
    }
    async function updateConvert(e){
        const base64 = await convertToBase64(e);
        setBannerUpdateWithImg(true);
        setBannerUpdateBase64(base64);
    }
    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/banners`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then((response) => {
                setResponseData(response.data.banners)
            })
            .catch((err) => {
                console.log(err)
            });

    }, [])
    const columns = [
        {
            name: 'ID',
            selector: 'bannerId',
            sortable: true,
            center: true,
        }, 
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            center: true,
        }, 
        {
            name: 'Data de criação',
            selector: 'createdAt',
            cell: (row) => <div>{new Date(row._seconds * 1000).toLocaleDateString('pt-br')}</div>,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: '',
            cell: (row) => <div>
                    <button type="button" className="btn btn-outline-success btn-sm" onClick={() => openUpdateModal(row)}><i className="fa fa-pencil"></i></button>
                    <button type="button" className="btn btn-outline-danger btn-sm ml-3" onClick={() => deleteBanner(row.bannerId)}><i className="fa fa-trash"></i></button>
                </div>,
            sortable: true,
            center: true,
        },
        /*{
            name: '',
            selector: '',
            cell: (row) => <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteBanner(row.bannerId)}><i className="fa fa-trash"></i></button>,
            sortable: true,
            center: true,
        },*/

    ]
    return(
        <>
            <Breadcrumb parent="dashboard" title="Banners"/>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-sm-12'>
                        <div className='card p-5'>
                            <div className='card-headers'>
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h1>Banners</h1>
                                    <button className="btn btn-success" onClick={openModal}>Criar</button>
                                </div>
                            </div>
                            <div className="ml-5 mt-3 mb-3 col-sm-4">
                                <div className="form-group row">
                                <div class="search_wrap search_wrap_3">
                                    <div class="search_box">
                                    <input type="text" className="form-control" value={searchTerm} placeHolder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <DataTable
                                columns={columns}
                                data={ReponseData}
                                striped={true}
                                center={true}
                                noDataComponent="Não possui nenhum dado."
                                // contextActions={contextActions}
                                />
                            </div>
                            {/*<table className="table table-bordered table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Data de Criação</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ReponseData.map((d) => {
                
                                        return (
                                            <tr key={d.bannerId} className="table-light">
                                                <th scope="row">{d.bannerId}</th>
                                                <td>{d.name}</td>
                                                <td>{new Date(d.createdAt._seconds * 1000).toLocaleDateString('pt-br')}</td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm" onClick={() => openUpdateModal(d)}><i className="fa fa-pencil"></i></button>
                                                    <span>   </span>
                                                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteBanner(d.bannerId)}><i className="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                </table>*/}
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>

            <Modal 
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Banner Modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Novo Banner</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>
                                    Nome do banner:
                                    <input type="text" className="form-control" placeholder="Nome do Banner" onChange={(e) => setBannerCreateName(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Adicionar Imagem:
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="customFile" onChange={(e) => convert(e)} />
                                        <label className="custom-file-label" htmlFor="customFile">Arquivo</label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>
                                Url da Imagem:
                                <input type="text" className="form-control" placeholder="URL" onChange={(e) => setBannerCreateUrl(e.target.value)} />
                            </label>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label for="noticeImage" className="mt-3">
                                    Inicio: <input type="datetime-local" name="noticeImage" className="form-control" onChange={(e) => setBannerCreateStartDate(e.target.value.replaceAll('-', '/').replace('T', ' '))}/>
                                </label>
                            </div>
                            <div className="col">
                                <label for="noticeImage" className="mt-3">
                                    Fim: <input type="datetime-local" name="noticeImage" className="form-control" onChange={(e) => setBannerCreateEndDate(e.target.value.replaceAll('-', '/').replace('T', ' '))} />
                                </label>
                            </div>
                        </div>

                        <div className="form-group">    
                            <label>Estados:
                                <select onChange={(e) => handleFus(e.target.value)} className="form-control">
                                    <option selected disabled>Estados</option>
                                    {fus.map(fu => (
                                        <option key={fu.id} value={fu.initials}>{fu.initials}</option>
                                    ))}
                                </select>
                            </label>
                            <div className="m-auto w-75">
                                {fusSelected.map((fu, index) => (
                                    <div className="mt-3 d-flex justify-content-between" key={fu}>{fu}<button className="btn btn-danger" onClick={() => setFusSelected(fusSelected.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                ))}
                            </div>
                        </div>
                    </form>
                

                </div>


                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={createBanner}>Criar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={closeModal}>Fechar</button>
                </div>

            </Modal>

            <Modal 
                isOpen={updateModal}
                onRequestClose={closeCloseModal}
                style={customStyles}
                contentLabel="Edit Modal"
            >   
                <div className="modal-header">
                    <h5 className="modal-title">Atualizar Banner</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeCloseModal}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Nome do Banner:
                                    <input type="text" className="form-control" value={bannerUpdateName} onChange={(e) => setBannerUpdateName(e.target.value)}>
                                    </input>
                                </label>
                            </div>
                            <div className="form-group col">
                                <label>Adicionar Imagem:
                                    <div className="custom-file">
                                        <label className="custom-file-label" htmlFor="customFile">Arquivo</label>
                                        <input type="file" className="custom-file-input" id="customFile" onChange={(e) => updateConvert(e)} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label> URL da Imagem:
                                <input type="text" className="form-control w-100" placeholder="URL" id="updateUrl" value={bannerUpdateUrl} onChange={(e) => setBannerUpdateUrl(e.target.value)}>
                                </input>
                            </label>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label for="noticeImage" className="mt-3">
                                    Inicio: <input type="datetime-local" name="noticeImage" className="form-control" onChange={(e) => setBannerUpdateStartDate(e.target.value.replaceAll('-', '/').replace('T', ' '))}/>
                                </label>
                            </div>
                            <div className="col">
                                <label for="noticeImage" className="mt-3">
                                    Fim: <input type="datetime-local" name="noticeImage" className="form-control" onChange={(e) => setBannerUpdateEndDate(e.target.value.replaceAll('-', '/').replace('T', ' '))} />
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>
                                Estados:
                                <select onChange={(e) => editHandleFus(e.target.value)} className="form-control">
                                    <option selected disabled>Estados</option>
                                    {fus.map(fu => (
                                        <option key={fu.id} value={fu.initials}>{fu.initials}</option>
                                    ))}
                                </select>
                            </label>
                            <div className="m-auto w-75">
                                {editFusSelected.map((fu, index) => (
                                    <div className="mt-3 d-flex justify-content-between" key={fu}>{fu}<button className="btn btn-danger" onClick={() => setEditFusSelected(editFusSelected.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                ))}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => updateBanner()}>Update</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={closeCloseModal}>Fechar</button>
                </div>

            </Modal>
        </>
    )
}


export default Banner;