import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { CancelUpdateProfile, Create, Salvar, TestEndpoint } from '../../constant';
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LOCAL_BACKEND_URL } from '../../data/urls';

import { requestAuthConfig } from '../../utils/utilTools';



const CadastrarCategoria = ({ history }) => {

  const [inputs, setInput] = useState({});

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const submitHandler = event => {
    /* event.preventDefault(); */
    axios.post(`${LOCAL_BACKEND_URL}/category`, {
      'name': name.trim(),
      'url': url,
      'categoryId': categoryId

    }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
      .then(res => {
        console.log(res);
        console.log(res.data);
        history.push(`${process.env.PUBLIC_URL}/base/categorias`);
      })
      .catch(err => console.log(err));
  }
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = event => submitHandler();
  return (
    <div>
      <Breadcrumb parent="Cursos" title="Cursos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cadastrar categoria</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Label for="name">Nome</Label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Nome"
                      onChange={e => setName(e.target.value)}
                      ref={
                        register({
                          required: "O nome da categoria é obrigatório",
                          minLength: 3
                        })
                      }
                    />
                    <ErrorMessage errors={errors} name="name" />

                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <input
                      type="text"
                      className="form-control"
                      name="url"
                      id="url"
                      placeholder="URL"
                      onChange={e => setUrl(e.target.value)}
                      ref={
                        register({
                          required: "A url da categoria do curso é obrigatório",
                          minLength: 3
                        })
                      }
                    />
                    <ErrorMessage errors={errors} name="url" />
                  </FormGroup>  
                  <div className="card-footer text-right">
                    <button
                      className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/categorias`)}>{CancelUpdateProfile}</button>
                    <button
                      className="btn btn-primary ml-3"
                      onClick={handleSubmit}
                      type="submit">{Create}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default CadastrarCategoria;