import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import man  from '../../assets/images/dashboard/user.png';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { requestAuthConfig } from '../../utils/utilTools';

const ButtonWarningUser = (props) => {
    const [modal, setModal] = useState(true);

    const toggle = () => {
        props.toggle(!props.toggleState);
    }

    const [refresh, setRefresh] = useState();

    const [userData, setUserData] = useState({});

    return (
        <>
            <Modal isOpen={props.toggleState} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmar ação</ModalHeader>
                <ModalBody>
                    <h5>Você tem certeza que deseja alterar seus próprios dados?</h5>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">Confirme sua senha: </label>
                            <input
                                className="form-control"
                                type="password"
                                value={props.password}
                                onChange={e => props.setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary" onClick={toggle}>Cancelar</Button>
                    <Button type="button" color="danger" onClick={props.updateHandler}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ButtonWarningUser;