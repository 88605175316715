import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import man from '../../../assets/images/dashboard/user.png';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonViewCfc from '../../icetran/buttonViewCfc';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';

const PendentesDeContrato = ({ history }) => {

  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState([]);

  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfPendentPartners, setTotalNumberOfPendentPartners] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/contractpendentpartners?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
        }
    })
        .then((res) => {
            setResponseData([...res.data.partners]);
            setNumberOfPages(res.data.info.numPages);
        })
        .catch((err) => { console.log(err) });

        axios.get(`${LOCAL_BACKEND_URL}/allContractPendentPartners`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then(res => {
            setTotalNumberOfPendentPartners(res.data.partners.length);
          })
          .catch(err => console.log(err.message));

  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      responseData.forEach(partner => {
        carrierArray.push(partner);
      });
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);

    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/contractpendentpartners?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.partners);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const columns = [
    {
      name: 'Parceiro',
      selector: 'username',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'flex-start', alignItems: "center", }}><img style={{ marginLeft: "4px", borderRadius: "50%", width: "50px", height: "50px", marginRight: "10px" }} alt="man" src={row.photoURL ? row.photoURL : man} />{row.name}</div>,
      center: true,
    },
    {
      name: 'Estado (UF)',
      selector: 'state',
      sortable: true,
      center: true,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.adress.fu}</div>
    },
    {
      name: 'CNPJ',
      selector: 'id',
      sortable: true,
      center: true,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.document}</div>
    },
    {
      name: 'Ultima sessão',
      selector: 'last_session',
      sortable: true,
      center: true,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.lastSession ? new Date(row.lastSession._seconds * 1000).toLocaleString('pt-BR') : "Dado indisponível"}</div>
    },
    {
      name: 'Criado em',
      selector: 'created_at',
      sortable: true,
      center: true,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.createdAt ? new Date(row.createdAt._seconds * 1000).toLocaleString('pt-BR') : "Data indisponível"}</div>
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%" }}><li className="list-group-item-action">
        <ButtonViewCfc
        style={{ marginRight: 4 }}
        onClick={() => history.push(`${process.env.PUBLIC_URL}/users/userEdit`)} 
        user={row}
        className="btn btn-primary pr-4 pl-4">
        <i className="icon icon-pencil"></i>
        </ButtonViewCfc></li>
      </div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  function search(rows) {
    return rows.filter(row => 
      row.name.toLowerCase().indexOf(searchTerm) > -1 ||
      row.adress.fu.toLowerCase().indexOf(searchTerm) > -1 ||
      row.document.indexOf(searchTerm) > -1)
  }

  // }, []);
  return (
    <div>
      <Breadcrumb parent="Parceiros" title="Parceiros pendentes" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                {/* <div className="row justify-content-between ml-3 mr-5">
                  <h5>Parceiros pendentes</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/cfc/create`)} type="button">{New}</button>
                </div> */}
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <div className="form-group row">
                    <div className="search_wrap search_wrap_3">
                      <div className="search_box">
                        <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={search(data)}
                  striped={true}
                  center={true}
                  noDataComponent="Não há parceiros pendentes no momento"
                // contextActions={contextActions}
                />


                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfPendentPartners}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                            <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                            />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                            <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                            />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendentesDeContrato;
