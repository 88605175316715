import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import man  from '../../assets/images/dashboard/user.png';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import fus from '../../utils/fus';

export default function ButtonViewCfc(props) {
    const [modal, setModal] = useState();
    const [plans, setPlans] = useState([]);
    const [planSelected, setPlanSelected] = useState('');
    const [invoicement, setInvoicement] = useState(null);
    const [invoicementType, setInvoicementType] = useState(null);
    const [valueUF, setValueUF] = useState('');

    useEffect(() => {
        getPlans();
    }, [])

    async function getPlans() {
        await axios
          .get(`${LOCAL_BACKEND_URL}/plans`, {
            headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          })
          .then((res) => setPlans(res.data.plans))
          .catch((err) => console.log("Errro", err));
      }
    const toggle = () => {
        setModal(!modal)
        console.log(props.user)
    }
    console.log('props.user', props.user)
    const approvePartnerHandler = () => {
        axios.patch(`${LOCAL_BACKEND_URL}/acceptpartner/${props.user.partnerId}`, {
            invoicement: parseInt(invoicement),
            invoicementType: parseInt(invoicementType),
            valueUF,
            plan: invoicementType === 2 ? planSelected : null
        }, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                console.log(res)
                toggle()
                window.location.reload();
            })
            .catch((err) => { console.log(err.response.data) })
    };

    const reprovePartnerHandler = () => {
        axios.patch(`${LOCAL_BACKEND_URL}/reprovepartner/${props.user.partnerId}`, undefined, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                console.log(res)
                toggle()
                window.location.reload();
            })
            .catch((err) => { console.log(err.response.data) })
    };
    console.log('invoicementType', invoicementType)
    return (
        <>
            <button onClick={toggle} className="btn btn-sm btn-secondary">
                <span>Visualizar</span>
            </button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Visualização</ModalHeader>
                <ModalBody>
                    <h5>Este são os dados do parceiro pendente.</h5>
                    <div className="mt-4 mb-3 mw-200 d-flex justify-content-start align-items-center">
                        <img className="img-70 rounded-circle mr-3" alt="man" src={props.user.photoURL ? props.user.photoURL : man} />
                        <h5>{props.user.name}</h5>
                    </div>
                    <ul>
                        <li className="h6">Usuário: <span>{props.user.name}</span></li>
                        <li className="mt-1 h6">Estado(UF): <span>{props.user.adress.fu}</span></li>
                        <li className="mt-1 h6">CNPJ: <span>{props.user.document}</span></li>

                        <li className="mt-1 h6">Ultimo Sessão: <span>{props.user.lastSession ? new Date(props.user.lastSession._seconds * 1000).toLocaleString('pt-BR') : "Dado indisponível"}</span></li>
                        <li className="mt-1 h6">Criado em: <span>{props.user.createdAt ? new Date(props.user.createdAt._seconds * 1000).toLocaleString('pt-BR') : "Data indisponível"}</span></li>
                    </ul>
                    <div className='row'>
                        <div className='col-6'>
                            <span>Faturamento</span>
                            <select onChange={(e) => setInvoicement(e.target.value)} className='form-control mb-2'>                                
                                <option value={0}>Semanal</option>
                                <option value={1}>Quinzenal</option>
                                <option value={2}>Mensal</option>
                            </select>
                        </div>
                        <div className='col-6'>
                            <span>Tipo de Faturamento</span>
                            <select onChange={(e) => setInvoicementType(e.target.value)} className='form-control'>                                
                                <option value={0}>Avulsa</option>
                                <option value={1}>Escalonada</option>
                                <option value={2}>Assinatura</option>
                            </select>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <span>Estado de Faturamento</span>
                        <select className='form-control' onChange={(e) => setValueUF(e.target.value)}>
                            {
                                fus.map(fu => (
                                    <option value={fu.initials} key={fu.initials}>{fu.fu}</option>
                                ))
                            }
                        </select>
                    </div>

                    {
                        invoicementType === '2'  && (
                            <select onChange={(e) => setPlanSelected(e.target.value)} className='form-control mt-3'>
                                {
                                    plans.map((plan) => (
                                        <option value={plan.planId}>{plan.name}</option>
                                    ))
                                }
                            </select>
                        ) 
                    }
                    <p className="mt-4">
                        <span style={{ color: "red", fontWeight: "bold", }}>Atenção</span> essa ação não poderá ser revertida.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={approvePartnerHandler}>Aprovar</Button>
                    <Button color="danger" onClick={reprovePartnerHandler}>Reprovar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}