import React, { useEffect, useState } from 'react';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';
import Cards from 'react-credit-cards';
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout_From_Firebase } from '../common/header-component/userMenu';
import downloadContent from '../../services/downLoadContent';

const ContractSigningPage = ({ history }) => {
  const location = useLocation();
  const [signedContract, setSignedContract] = useState(false);
  const [contractData, setContractData]         = useState({});
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [paymentOnTicket, setPaymentOnTicket] = useState(false);
  const [paymentOnCard, setPaymentOnCard] = useState(false);
  const [partnerId, setPartnerId] = useState('');
  const [bankSlip, setBankSlip] = useState('');
  const [modalFirstCredits, setModalFirstCredits] = useState(false);
    // Card info
    const [cardCvc, setCardCvc]= useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardFocused, setCardFocused] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    // Boleto info
    const [cep, setCep] = useState("");
    const [rua, setRua] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [estado, setEstado] = useState("");
    const [complemento, setComplemento] = useState('');
    const [numero, setNumero] = useState('');

    const [installments, setInstallments] = useState(Number);
    const [showFirstPayment, setShowFirstPayment] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [showTicketButton, setShowTicketButton] = useState(false);
  
  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/partnercontracts`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
        }
    })
      .then(res => setContractData(res.data.contract[0]))
      .catch(err => console.log(err.response.data));
    }, []);
    
    console.log('localStorage.getItem(FirstCredits)', localStorage.getItem('FirstCredits'), typeof localStorage.getItem('FirstCredits'))
    const  Logout_From_Auth0 = () =>  {
      localStorage.removeItem("auth0_profile")
      localStorage.setItem("authenticated",false)
      history.push(`${process.env.PUBLIC_URL}/login`)
      logout()
    }

    // auth0 profile
    const {logout} = useAuth0()
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))
   
    const { executeRecaptcha } = useGoogleReCaptcha();
    const submitHandler = event => {
        try {
            const newToken = executeRecaptcha();
            newToken.then(res => {
                axios.post(`${LOCAL_BACKEND_URL}/signcontract`, { 
                    contractId: contractData.contractId,
                    contractIsSigned: true,
                    token: res 
                }, {
                  headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(res => {
                    setModalFirstCredits(true);
                    setTimeout(() => {
                        toast.success('Contrato assinado com sucesso.');
                    }, 200);
                  //  localStorage.clear();
                    setSignedContract(true)
                    if(localStorage.getItem('invoicementType') !== 'Assinatura') {
                      localStorage.clear();
                      history.push(`${process.env.PUBLIC_URL}/login`)
                    };
                    localStorage.setItem('isSigned', 'true');
                    setIsSigned(true);

                    if(authenticated) {
                      Logout_From_Auth0();
                    } else {
                      Logout_From_Firebase(history);
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.error);
                });
            });
        }
        catch (err) {
            setTimeout(() => {
                toast.error("Oppss... Parece que você é um robô.");
            }, 200);
        }
    };

    async function handleBuy()
    {
      try {
        await axios.post(`${LOCAL_BACKEND_URL}/firstcreditpayment`, {
          paymentMethod: 2,
          cardHolderName: cardName,
          cardNumber: Number(cardNumber),
          expirationMonth: Number(cardExpiry.slice(0, 2)),
          expirationYear: Number(cardExpiry.slice(2, 4)),
          securityCode: cardCvc,
          installments: Number(installments)
        }, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
          if(res.status === 200) toast.success('Créditos adicionados, faça login novamente!');
          history.push(`${process.env.PUBLIC_URL}/login`);
          localStorage.clear();
        })
        .catch(e => console.log(e.response.data))
      }catch(err) {
        console.log(err)
      }
    }

    async function getTicket()
    {
      try {
        axios.post(`${LOCAL_BACKEND_URL}/firstcreditpayment`, {
          partnerId: localStorage.getItem('token'),
          paymentMethod: 3,
        }, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
        .then(res => {
          console.log(res);
          setBankSlip(res.data.bankSlip);
          setShowTicketButton(true);
        })
        .catch(err => console.log(err))

      }catch(err) {
        console.log(err)
      }
    }

    
    const DownloadContract = () => <div onClick={() => downloadContent(contractData.body)} className="btn btn-info mt-3 w-25">Baixar Contrato</div>
    return (
        <div>
          <GoogleReCaptcha onVerify={t => console.log({ t })} />
          <div className="page-wrapper">
              <div className="container-fluid p-0">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="auth-innerright">
                              <div className="authentication-box" style={{ width: '60%' }}>
                                  <div className="text-center"><img src={''} alt="" /></div>
                                  <div className="card mt-4">
                                      <div className="card-body">
                                          {!signedContract && localStorage.getItem('isSigned') === 'false' && (
                                            <form className="card">
                                              <div className="text-center mb-5">
                                                  <h4>Assinatura de contrato</h4>
                                              </div>
                                                <div>
                                                  <h4 style={{textAlign: 'center', marginBottom: '2vh'}}>{contractData.title}</h4>
                                                  <p>{contractData.body}</p>
                                                </div>
                                                <DownloadContract />
                                                <div className="form-check mt-5 mb-5">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                    checked={!submitIsDisabled}
                                                    onChange={() => setSubmitIsDisabled(submitIsDisabled => !submitIsDisabled)}
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Li e aceito os termos e condições
                                                  </label>
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button
                                                      className="btn btn-primary btn-block"
                                                      type="button"
                                                      disabled={submitIsDisabled}
                                                      onClick={submitHandler}
                                                    >
                                                      {"Assinar"}
                                                    </button>
                                                </div>
                                            </form>
                                          )}
                                          
                                          {localStorage.getItem('isSigned') === 'true' && (
                                          <div>
                                            <form className='card w-100'>
                                                  <div className='d-flex justify-content-center align-items-center mb-5'>
                                                    <span className='h3 font-weight-bold'>Adicionar Primeiros Créditos</span>
                                                  </div>
                                                  <div className='w-100 mt-3'>
                                                    <div className='d-flex justify-content-around w-50 m-auto mt-5'>
                                                      <div 
                                                        className='btn btn-info text-uppercase btn-lg' 
                                                        onClick={() =>{
                                                          setPaymentOnTicket(!paymentOnTicket);
                                                          getTicket();
                                                          setPaymentOnCard(false);  
                                                        }}>Boleto</div>
                                                      <div 
                                                        className='btn btn-info text-uppercase btn-lg'
                                                        onClick={() => {
                                                          setPaymentOnCard(!paymentOnCard)
                                                          setPaymentOnTicket(false)
                                                        }}  
                                                      >Cartão</div>
                                                    </div>
                                                    {paymentOnCard && <div className='row mt-5' style={{display: 'flex'}}>
                                                      <div className=''>
                                                        <Cards 
                                                          cvc={cardCvc}
                                                          expiry={cardExpiry}
                                                          focused={cardFocused}
                                                          name={cardName}
                                                          number={cardNumber}
                                                          placeholders={{ name: 'Seu Nome Aqui' }}
                                                        />
                                                      </div>
                                                      <div className='mt-2 flex-grow-1 p-1'>
                                                        <div className='d-flex w-100'>
                                                          <div className='mr-2 ml-2 w-100'>
                                                            <input 
                                                              placeholder='Digite seu nome' 
                                                              value={cardName}
                                                              type='text' 
                                                              className='form-control'
                                                              onChange={(e) => setCardName(e.target.value)}
                                                            />
                                                          </div>
                                                          <div className=''>
                                                            <input 
                                                              placeholder='Validade do cartão' 
                                                              value={cardExpiry}
                                                              type='text' 
                                                              className='form-control'
                                                              onChange={(e) => setCardExpiry(e.target.value)}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className='d-flex mt-3'>
                                                          <div className='mr-2 mr-2 ml-2'>
                                                            <input 
                                                              placeholder='Digite o CVC' 
                                                              value={cardCvc} 
                                                              type='text'
                                                              className='form-control'
                                                              onChange={(e) => setCardCvc(e.target.value)}
                                                            />
                                                          </div>
                                                          <div className='w-100'>
                                                            <input 
                                                              placeholder='Digite o número do cartão' 
                                                              value={cardNumber} 
                                                              type='text'
                                                              className='form-control w-100'
                                                              onChange={(e) => setCardNumber(e.target.value)}
                                                            />
                                                          </div>
                                                          
                                                        </div>
                                                        <div className='w-50'>
                                                          <div className='mr-2 mr-2 ml-2 mt-3'>
                                                            <select className='form-control' onChange={(e) => setInstallments(e.target.value)}>
                                                              <option selected>Parcelas</option>
                                                              <option value={1}>1</option>
                                                              <option value={2}>2</option>
                                                              <option value={3}>3</option>
                                                              <option value={4}>4</option>
                                                              <option value={5}>5</option>
                                                              <option value={6}>6</option>
                                                            </select>                                               
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>}
                                                    {
                                                      paymentOnTicket && 
                                                      <div className='text-center mt-5'>
                                                        {!showTicketButton ? <div>Aguarde, estamos gerando seu boleto.</div> :
                                                        <div> 
                                                          <p className='text-center'>Boleto gerado com sucesso!</p>
                                                          <div className="btn btn-success text-uppercase btn-lg py-2 px-3 h4" >
                                                            <a href={bankSlip} alt="Boleto" onClick={() => localStorage.setItem('generatedTicket', 'true')}>
                                                              Ir Para o Boleto
                                                            </a>
                                                          </div>
                                                        </div>} 
                                                      </div>
                                                    }
                                                  </div>
                                                  { paymentOnCard && <div className='text-center mt-2'>
                                                    <div className='btn btn-primary' onClick={handleBuy}>
                                                        Finalizar Compra
                                                    </div>
                                                  </div>}
                                            </form>
                                          </div>
                                        )}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ToastContainer />
              </div>
          </div>
        </div>
    );
};
export default ContractSigningPage;