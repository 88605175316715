import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { Edit } from 'react-feather';
import { EditUser, UpdateProfile, OracleId, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, Bio, RetypePassword, NewPassword, AccessLevel, Team } from '../../constant';
import { useLocation } from 'react-router';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { testImage } from './userCreate';
import { defaultPatch } from '../../utils/requestTemplates';
import { Redirect } from 'react-router';
// Libs para máscara de número de telefone
import { mask, unMask } from 'remask';

import { requestAuthConfig } from '../../utils/utilTools';

import ButtonWarningUser from '../icetran/ButtonWarningUser';
import convertToBase64 from '../../services/convertToBase64';

const UserEdit = (props) => {

    const location = useLocation();

    const [teams, setTeams] = useState([]);
    const [teamsAndIds, setTeamsAndIds] = useState({});
    const [teamsArray, setTeamsArray] = useState([]);

    const [withImage, setWithImage] = useState(false);
    const [photoURL, setPhotoURL] = useState(seven);
    const [imageURL, setImageURL] = useState(null);
    const [imagePath, setImagePath] = useState(null);

    const [pageHasLoaded, setPageHasLoaded] = useState(false);

    useEffect(() => {
        setImageURL(location.state.userInfo.imageURL);
        setImagePath(location.state.userInfo.imagePath);
        console.log('testetetetetetete', location.state.userInfo.imageURL)

        axios.get(`${LOCAL_BACKEND_URL}/teams`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                setTeams([...response.data.teams]);
            })
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        if (teams.length > 0) {
            const tempTeams = {};
            const tempTeamsList = [];
            teams.forEach(team => {
                tempTeams[team.name] = team.teamId;
                tempTeamsList.push(team.name);
            });
            setTeamsAndIds(tempTeams);
            setTeamsArray(tempTeamsList);
            setPageHasLoaded(true);
        }
    }, [teams]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    // Lógica para o número de telefone e sua máscara.
    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['+99 (99) 9999-9999', '+99 (99) 99999-9999'];
    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    const [user, setUser] = useState([]);

    const [originalAccessLevel, setOriginalAccessLevel] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [bio, setBio] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [team, setTeam] = useState('');
    const [oracleId, setOracleId] = useState('');

    const [userToBeEditted, setUserToBeEditted] = useState({});

    const [userEditingHimself, setUserEditingHimself] = useState(false);

    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    // Função genérica de geração de máscara.
    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }

    useEffect(() => {
        if (pageHasLoaded) {
            const tempUser = { ...location.state.userInfo };
            setName(tempUser.displayName);
            setEmail(tempUser.email);
            setBio(tempUser.bio);
            phoneMask(tempUser.phoneNumber);
            setOriginalAccessLevel(tempUser.role);
            setOracleId(tempUser.idOraculo);
            setTeam(getKeyByValue(teamsAndIds, tempUser.teamId));
            setUserToBeEditted({ ...tempUser });
            console.log('tempUser', tempUser)
        }
    }, [pageHasLoaded]);

    const submitUserHandler = (e) => {
        if (location.state.userInfo.uid === localStorage.getItem('userId')) {
            setUserEditingHimself(true);
        }
        else {
            updateUserHandler(e);
        }
    }

    async function convert(e) {
        const base64 = await convertToBase64(e)
        setPhotoURL(base64)
        setWithImage(true)
    }

    const updateUserHandler = (e) => {
        e.preventDefault();

        const data = {
            "role": originalAccessLevel,
            "idOraculo": userToBeEditted.idOraculo,
            "email": email,
            "password": newPassword === '' ? undefined : newPassword,
            "password2": newPassword === '' ? undefined : confirmNewPassword,
            "phoneNumber": phoneNumber,
            "displayName": name,
            "photoURL": photoURL,
//            "imageURL": imageURL,
//            "imagePath": imagePath,
            "disabled": false,
            "teamId": teamsAndIds[team],
            "passwordConfirmation": passwordConfirmation
        };

        const dataWithoutImage = {
            "role": originalAccessLevel,
            "idOraculo": userToBeEditted.idOraculo,
            "email": email,
            "password": newPassword === '' ? undefined : newPassword,
            "password2": newPassword === '' ? undefined : confirmNewPassword,
            "phoneNumber": phoneNumber,
            "displayName": name,
//            "photoURL": photoURL,
            "imageURL": imageURL,
            "imagePath": imagePath,
            "disabled": false,
            "teamId": teamsAndIds[team],
            "passwordConfirmation": passwordConfirmation
        }

        const config = {
            method: 'patch',
            url: `${LOCAL_BACKEND_URL}/user/${location.state.userId}`,
            ...requestAuthConfig,
            data: !withImage ? dataWithoutImage : data
        };

        axios(config)
            .then(res => {
                console.log('res', res)
                props.history.push(`${process.env.PUBLIC_URL}/base/lista`);
            })
            .catch(err => {
                console.log(err.message);
            });
    }
    
    return (
        <>
            <Breadcrumb parent="Usuários" title="Editar Usuário" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditUser}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                            <img
                                                className="img-70 rounded-circle"
                                                alt=""
                                                src={imageURL}
                                            />
                                            <input
                                                className="form-control w-75"
                                                type="file"
                                                onChange={(e) => convert(e)}
                                            />
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}*</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="userName"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    value={email}
                                                    name="email"
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{NewPassword}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    value={newPassword}
                                                    onChange={e => setNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{RetypePassword}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    value={confirmNewPassword}
                                                    onChange={e => setConfirmNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Bio}</label>
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    value={bio}
                                                    name="bio"
                                                    onChange={(e) => setBio(e.target.value)}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{PhoneNumber}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={e => phoneMask(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{AccessLevel}</label>
                                                <select
                                                    className="form-control digits"
                                                    id="access_level"
                                                    value={originalAccessLevel}
                                                    onChange={e => setOriginalAccessLevel(e.target.value)}
                                                >
                                                    <option select="">{"Escolha um nível de acesso..."}</option>
                                                    <option>{"Atendente"}</option>
                                                    <option>{"Administrador"}</option>
                                                    <option>{"Parceiro"}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Team}</label>
                                                <select
                                                    className="form-control digits"
                                                    id="team"
                                                    value={team}
                                                    onChange={(e) => setTeam(e.target.value)}
                                                >
                                                    <option select="">{"Escolha um time..."}</option>
                                                    {
                                                        teamsArray.map(team => {
                                                            return <option>{team}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{OracleId}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={oracleId}
                                                    onChange={(e) => setOracleId(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button type="button" className="btn btn-danger" onClick={() => props.history.push(`${process.env.PUBLIC_URL}/base/lista`)}>{CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" type="button" onClick={submitUserHandler}>{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                userEditingHimself ?
                    <ButtonWarningUser
                        updateHandler={updateUserHandler}
                        toggleState={userEditingHimself}
                        toggle={setUserEditingHimself}
                        password={passwordConfirmation}
                        setPassword={setPasswordConfirmation}
                        
                    />
                : 
                    <></>
            }
        </>
    );
};

export default UserEdit;

export function getKeyByValue(object, value) {
    const result = Object.keys(object).find(key => object[key] === value);
    console.log(result);
    return result;
}