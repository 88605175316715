import axios from 'axios';
import { values } from 'lodash-es';
import react, { useEffect, useState } from 'react'
import { render } from 'react-dom';
import { useLocation } from 'react-router';
import { Ciclano, Detaills, Fulano, Joaozinho, Ronaldo, LastestSells, Name, Salvar, CancelUpdateProfile, Voltar } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

const PartnerFatura = ({ history }) => {

    const location = useLocation();

    // Number of sales registered as the current partner's
    const [salesCount, setSalesCount] = useState(0);
    const [sales, setSales] = useState([]);
    
    useEffect(() => console.log(sales));

    useEffect(() => {

        setSalesCount(location.invoice.sales.length);


        location.invoice.sales.forEach(saleId => {
            let tempSales = [];
            axios.get(`${LOCAL_BACKEND_URL}/sale/${saleId}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    tempSales.push(res.data.sale[0]);
                    setSales(tempSales);
                })
                .catch(err => console.log(err.message));
        });

    }, []);

    //Clientlist map só com o nome
    //outromap

    //datalist = sale valor e título do curso
    const dataList = (data) => data.map(
        (sale) =>
            <div className="card-body">
                <div className="new-users">
                    <div className="media">
                        <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/2.png')} alt="" />
                        <div className="media-body">
                            <h6 className="mb-0 f-w-700">{sale.partnerName}</h6>
                            <div className='d-flex'>
                                <div className='mr-5'>
                                    <p className='mt-2'>Aluno: {sale.clientName}</p>
                                    <p>Curso: {sale.courseTitle}</p>
                                    <p>{"R$"} {sale.value}</p>
                                </div>
                                <div className='mt-2 ml-3'>
                                    <p>Estado: {sale.fu}</p>
                                    <p>Parcelas: {sale.installmentsNumber}x de R$ {sale.installmentsValue}</p>
                                </div>
                            </div>
                        </div><span className="pull-right">
                            <button className="btn btn-pill btn-outline-light"  >{Detaills}</button></span>
                    </div>
                </div>
                <hr className="paddingLeft-5"></hr>
            </div >
    )

    return (

        <div className="col-xl-12" >
            <div className="card height-equal">
                <div className="card-header card-header-border">
                    <div className="row">
                        <div className="col-sm-6">
                            <h5>Vendas do Parceiro</h5>
                        </div>
                    </div>
                </div>
                <>
                    { sales.length >= salesCount ? dataList(sales) : '' }
                </>
                <div className="card-footer text-right">
                    <button className="alert alert-primary p-2" onClick={() => history.push(`${process.env.PUBLIC_URL}/financeiro/faturas`)}>{Voltar}</button>
                </div>
            </div>
        </div>
    )
}

export default PartnerFatura;