import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const SaleDetail = ({
  salesReportModal,
  saleDatailed,
  registrationRG,
  registrationSaleDate,
  registrationStatus,
  registrationInstallments,
  registrationPaymentType,
  registrationPhone,
  registrationEmail,
  registrationDoc,
  registrationContractValue,
  registrationExpirationOfTheLast,
  registrationExpirationOfTheFirst,
  registrationFullValue,
  registrationInstallmentValue,
  registrationFinantialType,
  registrationBirthDay,
  registrationCEP,
  registrationFu,
  registrationDistrict,
  registrationAdress,
  setSalesReportModal,
  isAdmin
}) => {
  const [requestLogIsOpen, setRequestLogIsOpen] = useState(false);

  const requestLogStyle = {
        color: 'black',
        height: `${requestLogIsOpen ? '100%' : '200px'}`,
        backgroundColor: '#d3d3d3',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative'
    }
    const showMoreButtonStyle = {
        position: 'absolute', 
        bottom: 0,
        boxSizing: 'border-box'
    }
  return (
    <Modal
      isOpen={salesReportModal}
      //className='d-flex align-items-center justify-content-center h-100 w-100'
      centered={true}
      size="lg"
    >
      <div className="w-100">
        <div>
          <ModalHeader>Detalhes da Venda</ModalHeader>
          <ModalBody>
            <div className="w-100">
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Nome do Clinte :
                </span>
                <div>{saleDatailed.clientName}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  RG do Clinte :
                </span>
                <div>{registrationRG}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  ID do Clinte :
                </span>
                <div>{saleDatailed.clientId}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Data de Conclusão :
                </span>
                <div>{saleDatailed.conclusionDate}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <div
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Curso :
                </div>
                <div>{saleDatailed.courseTitle}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Valor:
                </span>
                <div>R$ {Number(saleDatailed.value / 100).toFixed(2)}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span style={{ fontSize: 17, color: "black", width: "350px" }}>
                  Documento :
                </span>
                <div>{registrationDoc}</div>
              </div>
              <div className="mt-4 d-flex">
                <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                  E-mail :
                </span>
                <div>{registrationEmail}</div>
              </div>
              <div className="mt-4 d-flex">
                <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                  Celular :
                </span>
                <div>{registrationPhone}</div>
              </div>
              {isAdmin && (
                      <div className="mt-4">
                        <div
                          style={{ height: "100%" }}
                        >
                          <div className="mt-1 d-flex">
                          <span style={{ color: "black",fontSize: 17, width: "350px" }}>
                              Financeiro :
                            </span>
                          </div>
                          <div className="mt-1 d-flex">
                            <span style={{ color: "black",fontSize: 17, width: "350px" }}>
                              Forma de Pagamento :
                            </span>
                            <div className="ml-2">
                              {registrationPaymentType}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{color: "black",fontSize: 17, width: "350px" }}>Parcelas :</span>
                            <div className="ml-2">
                              {registrationInstallments}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>Situação :</span>
                            <div className="ml-2">{registrationStatus}</div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>Tipo :</span>
                            <div className="ml-2">
                              {registrationFinantialType}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>
                              Valor da Parcela :
                            </span>
                            <div className="ml-2">
                              R$ {registrationInstallmentValue}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>
                              Valor Total :
                            </span>
                            <div className="ml-2">
                              R$ {registrationFullValue}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>
                              Vencimento da Primeira Parcela :
                            </span>
                            <div className="ml-2">
                              {registrationExpirationOfTheFirst}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>
                              Vencimento da Ultima Parcela :
                            </span>
                            <div className="ml-2">
                              {registrationExpirationOfTheLast}
                            </div>
                          </div>
                          <div className="mt-3 d-flex">
                            <span style={{ color: "black",fontSize: 17,width: "350px" }}>
                              Valor do Contrato :
                            </span>
                            <div className="ml-2">
                              R$ {registrationContractValue}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
              <div className="mt-4 d-flex">
                      <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                        Rua :
                      </span>
                      <div>{registrationAdress}</div>
                    </div>
                    <div className="mt-4 d-flex">
                      <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                        Bairro :
                      </span>
                      <div>{registrationDistrict}</div>
                    </div>
                    <div className="mt-4 d-flex">
                      <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                        Estado :
                      </span>
                      <div>{registrationFu}</div>
                    </div>
                    <div className="mt-4 d-flex">
                      <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                        CEP :
                      </span>
                      <div>{registrationCEP}</div>
                    </div>
                    <div className="mt-4 d-flex">
                      <span style={{ fontSize: 17,color: "black", width: "350px" }}>
                        Data de Nascimento :
                      </span>
                      <div>
                        {`${registrationBirthDay.slice(
                          8,
                          10
                        )}/${registrationBirthDay.slice(
                          5,
                          7
                        )}/${registrationBirthDay.slice(0, 4)}`}
                      </div>
                    </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Data:
                </span>
                <div>{registrationSaleDate}</div>
              </div>
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Nome do Parceiro:
                </span>
                <div>{saleDatailed.partnerName}</div>
              </div>
              {isAdmin && (
                <>
                  <div className="w-100 mt-4 d-flex">
                    <span
                      className=""
                      style={{
                        color: "black",
                        fontSize: 17,
                        width: "350px",
                      }}
                    >
                      ID do Parceiro:
                    </span>
                    <div>{saleDatailed.partnerId}</div>
                  </div>
                  <div className="w-100 mt-4 d-flex">
                    <span
                      className=""
                      style={{
                        color: "black",
                        fontSize: 17,
                        width: "350px",
                      }}
                    >
                      ID do Atendente:
                    </span>
                    <div>{saleDatailed.idAtendente}</div>
                  </div>
                  <div className="w-100 mt-4 d-flex">
                    <span
                      className=""
                      style={{
                        color: "black",
                        fontSize: 17,
                        width: "350px",
                      }}
                    >
                      ID da Oferta:
                    </span>
                    <div>{saleDatailed.idOferta}</div>
                  </div>
                  <div className="w-100 mt-4 d-flex">
                    <span
                      className=""
                      style={{
                        color: "black",
                        fontSize: 17,
                        width: "350px",
                      }}
                    >
                      ID da Turma:
                    </span>
                    <div>{saleDatailed.idTurma}</div>
                  </div>
                </>
              )}
              <div className="w-100 mt-4 d-flex">
                <span
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Estado de Integração:
                </span>
                <div>{saleDatailed.integrationUf}</div>
              </div>

              <div className="w-100 mt-4 d-flex">
                <div
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Response Log :
                </div>
                <div>{saleDatailed.responseLog}</div>
              </div>
              <div className="w-100 mt-4">
                <div
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                    width: "350px",
                  }}
                >
                  Integração :
                </div>
                <div className="user-select-none h-100">
                  {saleDatailed.cofeciIntegration ? (
                    <div className="btn btn-success mt-2">Integrado</div>
                  ) : (
                    <div
                      className="btn btn-danger mt-2"
                      style={{ cursor: "default" }}
                    >
                      Falha na Integração
                    </div>
                  )}
                </div>
                
              </div>
              
              <div className="w-100 mt-4">
                <div
                  className=""
                  style={{
                    color: "black",
                    fontSize: 17,
                  }}
                >
                  Request Log :
                </div>
                <div style={requestLogStyle}> 
                  {JSON.stringify(saleDatailed)}
                  <div className="w-100" style={showMoreButtonStyle}>
                    <div
                      className="btn btn-success w-100"
                      onClick={() => setRequestLogIsOpen(!requestLogIsOpen)}
                    >
                      {requestLogIsOpen ? "Exibir Menos" : "Exibir Mais"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-center">
            <div className="text-center">
              <div
                className="btn btn-danger"
                onClick={() => {
                  setRequestLogIsOpen(false);
                  setSalesReportModal(!salesReportModal);
                }}
              >
                Fechar
              </div>
            </div>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
};


export default SaleDetail;