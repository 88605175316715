import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb, } from 'reactstrap';
import axios from 'axios';
import { Create, Option, TestEndpoint, Textarea } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

const CreateCredit = ({ history }) => {

    const [name, setName] = useState('');
    const [credits, setCredits] = useState('');
    const [value, setValue] = useState('');

    const submitHandler = event => {
        event.preventDefault();

        axios.post(`${LOCAL_BACKEND_URL}/credit`, {
            'name': name,
            'credits': parseInt(credits),
            'value': parseFloat(value)
        }, {
                headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/pacotes/creditos`);
            })
            .catch(err => console.log(err.response.data));
    }

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Cadastrar pacote de crédito</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form">
                                    <FormGroup>
                                        <Label for="name">Nome</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nome do pacote"
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Créditos</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            value={credits}
                                            placeholder="Quantidade de créditos"
                                            onChange={e => setCredits(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Valor</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            value={value}
                                            placeholder="Valor"
                                            onChange={e => setValue(e.target.value)}
                                        />
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={submitHandler}
                                    >
                                        {Create}
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CreateCredit;