import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { DashboardCourses, Escolar, Infratores, Purchase, Reciclagem, SellingCourses, SellingState, Shipping, Taxista, TodasVendas, TotalSell } from "../../../constant";
import { LOCAL_BACKEND_URL } from "../../../data/urls";

import { requestAuthConfig } from '../../../utils/utilTools';

const DestaqueEstado = () => {

    const [ac, setAc] = useState(0);
    const [al, setAl] = useState(0);
    const [ap, setAp] = useState(0);
    const [am, setAm] = useState(0);
    const [ba, setBa] = useState(0);
    const [ce, setCe] = useState(0);
    const [es, setEs] = useState(0);
    const [go, setGo] = useState(0);
    const [ma, setMa] = useState(0);
    const [mt, setMt] = useState(0);
    const [ms, setMs] = useState(0);
    const [mg, setMg] = useState(0);
    const [pa, setPa] = useState(0);
    const [pb, setPb] = useState(0);
    const [pr, setPr] = useState(0);
    const [pi, setPi] = useState(0);
    const [rj, setRj] = useState(0);
    const [rs, setRs] = useState(0);
    const [rn, setRn] = useState(0);
    const [ro, setRo] = useState(0);
    const [rr, setRr] = useState(0);
    const [ufSc, setUfSc] = useState(0)
    const [sp, setSp] = useState(0);
    const [se, setSe] = useState(0);
    const [to, setTo] = useState(0);
    const [df, setDf] = useState(0);


    const [responseData, setResponseData] = useState([])



    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/fusales`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then((response) => {
                setResponseData(response.data.sales)

            })
            .catch((err) => {
                console.log(err)
            });

    }, [])

    useEffect(() => {
        console.log(ufSc);
        console.log(responseData)
    }, [ufSc]);

    const googleData = responseData.map((d) => [d.fu, d.salesNumber]);


    googleData.unshift(["Estado","Venda"])

    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body">
                    <h5><strong>{SellingState}</strong></h5><span className="d-block fonts-dashboard">{TodasVendas}</span>
                    <Chart
                        height={'350px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={
                            googleData
                        }
                        options={{
                            title: 'Vendas Por Estado',
                            sliceVisibilityThreshold: 0.1, // 20%
                            slices: [
                                {
                                    color: "#32CD32"
                                },
                                {
                                    color: "#00FF7F"
                                },
                                {
                                    color: "#98FB98"
                                },
                                {
                                    color: "#90EE90"
                                },
                                {
                                    color: "#00FA9A"
                                }
                            ],
                            chartArea: {
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: "70%"
                            }
                        }}
                        rootProps={{ 'data-testid': '7' }}
                    />



                    <div className="row">
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">SP</h6>

                                <h5 className="mb-0 f-18">+R$ {sp}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">MG</h6>
                                <h5 className="mb-0 f-18">+R$ {mg}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">RJ</h6>
                                <h5 className="mb-0 f-18">+R$ {rj}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">SC</h6>
                                <h5 className="mb-0 f-18">+R$ {ufSc}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">RS</h6>
                                <h5 className="mb-0 f-18">+R$ {rs}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">PR</h6>
                                <h5 className="mb-0 f-18">+R$ {pr}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">ES</h6>
                                <h5 className="mb-0 f-18">+R$ {es}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">MT</h6>
                                <h5 className="mb-0 f-18">+R$ {mt}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0">
                                <h6 className="mb-0 f-w-700">DF</h6>
                                <h5 className="mb-0 f-18">+R$ {df}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DestaqueEstado;