import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { Create, Option, TestEndpoint } from '../../../constant';
import { toInteger } from 'lodash';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

const CreateEmail = ({ history }) => {

    const [type, setType] = useState(0);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [state, setState] = useState('');
    const [isActive, setIsActive] = useState(0);

    const submitHandler = event => {
        /* event.preventDefault(); */

        axios.post(`${LOCAL_BACKEND_URL}/email`, {
            'name': name,
            'body': message,
            'status': toInteger(isActive),
            'type': toInteger(type),

        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                console.log(res);
                console.log(res.data);
                history.push(`${process.env.PUBLIC_URL}/relacionamentos/email`);
            })
            .catch(err => console.log(err.response.data));
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Cadastrar Mensagem de Email</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <Label for="name">Nome</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nome da Mensagem"
                                            onChange={e => setName(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O campo nome é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Corpo da Mensagem</Label>
                                        <input
                                            className="form-control"
                                            type="textarea"
                                            name="message"
                                            rows="4"
                                            id="message"
                                            placeholder="Nome da Mensagem"
                                            onChange={e => setMessage(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O corpo da mensagem é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="message" />
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="form-group">
                                            <Label for="type">Tipo</Label>
                                            <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>

                                                <option value={null}>Escolha o tipo</option>
                                                <option value={1}> Venda criada</option>
                                                <option value={2}>Venda negada</option>
                                                <option value={3}>Venda concluída</option>
                                                <option value={4}>Venda concluída(parceiro)</option>
                                                <option value={5}>Link gerado</option>

                                            </select>
                                        </div>
                                        <FormGroup>
                                            <div className="form-group">
                                                <Label for="status">Status</Label>
                                                <select className="form-control" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                    onChange={e => setType(e.target.value)}
                                                    <option value={null}>Escolha o Status</option>
                                                    <option value={1}> Ativo</option>
                                                    <option value={2}>Inativo</option>
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        {Create}
                                    </Button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default CreateEmail;