import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';

export default function LevelCreate() {

    return (
      <div>
        <Breadcrumb parent="Níveis" title="Novo Nível" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                      <div className="row justify-content-between ml-3 mr-5">
                        <h5>Novo Nível</h5>
                      </div>
                  </div>
                  <div className="datatable-react mr-5 ml-5 form-group">
                    <Form>
                      <FormGroup>
                          <Label for="reference">Nome do nível</Label>
                          <Input type="text" name="reference" id="reference" placeholder="Nível" />
                      </FormGroup>
                      <Button color="primary">Criar</Button>
                    </Form>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
};
