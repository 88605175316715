export const courseSteps = [
    {
        'id': 1,
        'course': 'CURSO PREVENTIVO DE RECICLAGEM(TESTE)',
    },
    {
        'id': 2,
        'course': 'TAXISTA (SALINAS, FORTUNA e MATEUS LEME /MG)',
    },
    {
        'id': 3,
        'course': 'RECICLAGEM PARA CONDUTORES INFRATORES EAD',
    },
    {
        'id': 4,
        'course': 'CONDUTORES DE VEÍCULOS DE TRANSPORTE DE ESCOLARES',
    },
    {
        'id': 5,
        'course': 'CONDUTORES DE VEÍCULOS DE TRANSPORTE DE CARGA INDIVISÍVEL',
    },
    {
        'id': 6,
        'course': 'CONDUTORES DE VEÍCULOS DE TRANSPORTE DE PRODUTOS PERIGOSOS',
    },
    {
        'id': 7,
        'course': 'CONDUTORES DE VEÍCULOS DE EMERGÊNCIA',
    }
]