import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Redirect } from 'react-router';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { User } from 'react-feather';
import { CreateUser, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, Password, AccessLevel, OracleId, Bio, Team, ErrorInUserCreation } from '../../constant'

import { createUser } from '../../services/user.service';
import axios from 'axios';
import { BACKEND_API, LOCAL_BACKEND_URL } from '../../data/urls';
import { Jwt_token } from '../../data/config';
import { mask, unMask } from 'remask';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';
import convertToBase64 from '../../services/convertToBase64';

const isImageURL = require('valid-image-url');

export default function UserCreate({ history }) {

    const [bearer, setBearer] = useState(localStorage.token);

    // Controla o estado atual de login.
    const [hasUserBeenCreated, setHasUserBeenCreated] = useState(false);

    /*
        Variáveis de estado para cada campo do formulário de cadastro de usuário.
        O campo de URL da foto usada no avatar do perfil faz uso de uma função separada para validação dinâmica. Essa função
        foi tirada do seguinte link: https://stackoverflow.com/questions/9714525/javascript-image-url-verify.
        O restante das variáveis de estado funcionam da mesma maneira, com a exceção da correspondente ao número de telefone,
        que utiliza uma máscara de formatação dinâmica.
    */
    const [photoURL, setPhotoURL] = useState(seven);
    const isTheImageURLValid = (url, result) => {
        if (result === 'success') {
            setPhotoURL(url);
        }
        else {
            setPhotoURL(seven);
        }
    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState('');
    const [bio, setBio] = useState('');
    const [accessLevel, setAccessLevel] = useState('');
    const [base64, setBase64] = useState('');

    // Variáveis de estado e função que gera a máscara do número de telefone.
    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['(99) 9999-9999', '(99) 99999-9999'];
    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    // Variáveis de estado para time e id de oráculo.
    const [team, setTeam] = useState('');
    const [oracleId, setOracleId] = useState('');

    // Função genérica de geração de máscara.
    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }

    /*
        Objetos de estado que armazena os times cadastrados.
        teams armazena uma lista idêntica à lista de times retornada no corpo da resposta à requisição.
        teamsAndIds é um objeto onde cada chave é um nome de time, e seus respectivos valores são o id do time correspondente à chave.
        teamsList armazena os nomes de cada time, e, em seguida, popula o select de times no formulário de cadastro de usuário.
    */
    const [teams, setTeams] = useState([]);
    const [teamsAndIds, setTeamsAndIds] = useState({});
    const [teamsList, setTeamsList] = useState([]);

    const [errorMessageIsVisibile, setErrorMessageIsVisible] = useState('none');

    useEffect(() => {

        axios.get(`${LOCAL_BACKEND_URL}/teams`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                setTeams([...response.data.teams]);
            })
            .catch(error => console.log(error));

    }, []);

    useEffect(() => {
        if (teams.length > 0) {
            let tempTeams = {};
            let tempTeamsList = [];
            teams.forEach(team => {
                tempTeams[team.name] = team.teamId;
                tempTeamsList.push(team.name);
            });
            setTeamsAndIds({ ...tempTeams });
            setTeamsList([...tempTeamsList]);
        }
    }, [teams]);

    const submitHandler = (e) => {
        /* e.preventDefault(); */
        const data = {
            "email": email,
            "displayName": name,
            "bio": bio,
            "idOraculo": oracleId,
            "photoURL": base64,
            "role": accessLevel,
            "phoneNumber": `+55${unMask(phoneNumber)}`,
            "password": password,
            "password2": passwordConfirmed,
            "teamId": teamsAndIds[team]
        };
        console.log(data)
        const config = {
            method: 'post',
            url: `${LOCAL_BACKEND_URL}/user`,
            ...requestAuthConfig,
            data: data
        };

        axios(config)
            .then((response) => {
                history.push(`${process.env.PUBLIC_URL}/base/lista`);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    async function convert(e) {
        const base64 = await convertToBase64(e);
        setBase64(base64);
    }

    // if (hasUserBeenCreated) {
    //     history.push(`${process.env.PUBLIC_URL}/base/lista`)
    // }
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler(event);
    return (
        <>
            <Breadcrumb parent="Usuários" title="Novo Usuário" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><User /> {CreateUser}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div
                                            className="w-100 mb-5"
                                            style={{
                                                color: 'red',
                                                display: errorMessageIsVisibile
                                            }}
                                        >
                                            <span>
                                                {ErrorInUserCreation}
                                            </span>
                                        </div> */}
                                        <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                            <img
                                                className="img-70 rounded-circle"
                                                alt=""
                                                src={photoURL}
                                                onError={() => setPhotoURL(seven)}
                                            />
                                            <input
                                                className="form-control w-75"
                                                type="file"
                                                placeholder="Insira a URL do avatar"
                                                onChange={(e) => convert(e)}
                                            />
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}*</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="userName"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O nome do usuário é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="userName" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}*</label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O email é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Password}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "A senha é obrigatória.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="password" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{RetypePassword}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="passwordConfirm"
                                                    value={passwordConfirmed}
                                                    onChange={(e) => setPasswordConfirmed(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "A confirmação de senha não bate.",
                                                            value: { password }
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="passwordConfirm" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{Bio}</label>
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    name="bio"
                                                    value={bio}
                                                    onChange={(e) => setBio(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "A bio é obrigatória.",
                                                            minLength: 5
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="bio" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{PhoneNumber}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => phoneMask(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O número de telefone é obrigatório.",
                                                            minLength: 10
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="phoneNumber" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{AccessLevel}*</label>
                                                <select
                                                    className="form-control digits"
                                                    id="access_level"

                                                    value={accessLevel}
                                                    onChange={(e) => setAccessLevel(e.target.value)}
                                                >
                                                    <option select="">{"Escolha um nível de acesso..."}</option>
                                                    <option>{"Atendente"}</option>
                                                    <option>{"Administrador"}</option>
                                                    <option>{"Parceiro"}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Team}</label>
                                                <select
                                                    className="form-control digits"
                                                    id="access_level"
                                                    value={team}
                                                    onChange={(e) => setTeam(e.target.value)}
                                                >
                                                    <option select="">{"Escolha um time..."}</option>
                                                    {
                                                        teamsList.map(team => {
                                                            return <option>{team}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{OracleId}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={oracleId}
                                                    onChange={(e) => setOracleId(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/lista`)}>{CancelUpdateProfile}</button>
                                    <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export function testImage(url, callback, timeout) {
    timeout = timeout || 5000;
    var timedOut = false, timer;
    var img = new Image();
    img.onerror = img.onabort = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "error");
        }
    };
    img.onload = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "success");
        }
    };
    img.src = url;
    timer = setTimeout(function () {
        timedOut = true;
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        callback(url, "timeout");
    }, timeout);
}