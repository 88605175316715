import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Edit } from 'react-feather';
import { UpdateProfile, EmailAddress, CancelUpdateProfile, Name, EditParceiro, CNPJ, UF, Faturamento1, Pais, Estado, Cidade, Bairro, CepCheckout, Rua, Numero, Complemento, CPF, ClienteEdit } from '../../constant';
import { useLocation } from 'react-router';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { setIn } from 'formik';
import { toInteger } from 'lodash';
import { mask, unMask } from 'remask';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

import { requestAuthConfig } from '../../utils/utilTools';

const EditClients = ({ history }) => {

    const location = useLocation();

    const [name, setName] = useState('');
    const [adress, setAdress] = useState('');
    const [clientCpf, setClientCpf] = useState('');
    const [email, setEmail] = useState('');
    const [clientId, setClientId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idCard, setIdCard] = useState('')
    const [bornDate, setBornDate] = useState('')
    const [gender, setGender] = useState('')
    const [createdAt, setCreatedAt] = useState('')




    const [country, setCountry] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');

    const cepPatterns = ['99999-999']
    const setCepValue = (cepValue) => {
        setCep(maskGeneric(cepValue, cepPatterns))
    }
    const maskGeneric = (maskValue, patterns) => {
        maskValue = unMask(maskValue)
        return mask(maskValue, patterns)
    }
    useEffect(() => {

        if (unMask(cep).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(cep)}/json/`)
                .then(res => {
                    setRua(res.data.logradouro);
                    setBairro(res.data.bairro)
                    setCidade(res.data.localidade)
                    setEstado(res.data.uf)
                    setCountry('Brasil')
                })
                .catch(err => console.log(err))
        }
        else {
            setRua('')
            setCidade('')
            setBairro('')
            setEstado('')
            setCountry('')
        }
    }, [cep])



    useEffect(() => {
        console.log(location.clientInfo);
        setName(location.clientInfo.name);
        setEmail(location.clientInfo.email);
        setClientCpf(location.clientInfo.clientCpf);
        setAdress(location.clientInfo.adress);
        setClientId(location.clientInfo.clientId);
        setPhoneNumber(location.clientInfo.phoneNumber);
        setIdCard(location.clientInfo.idCard)
        setCreatedAt(location.clientInfo.createdAt)
        setBornDate(location.clientInfo.bornDate)
        setCep(location.clientInfo.adress.zipCode)
        setRua(location.clientInfo.adress.street)
        setBairro(location.clientInfo.adress.district)
        setNumero(location.clientInfo.adress.number)
        setComplemento(location.clientInfo.adress.complement)
        setCidade(location.clientInfo.adress.city)
        setEstado(location.clientInfo.adress.fu)
        setCountry(location.clientInfo.adress.country)
        setGender(location.clientInfo.gender)


    }, []);

    const submitHandler = event => {
        /* event.preventDefault(); */

        axios.patch(`${LOCAL_BACKEND_URL}/client/${location.clientId}`, {
            'name': name,
            'email': email,
            'clientCpf': clientCpf,
            'partnerId': clientId,
            'phoneNumber': phoneNumber,
            'gender': gender,
            'idCard': idCard,
            'bornDate': bornDate,
            'createdAt': createdAt,
            'zipCode': unMask(cep),
            'street': rua,
            'number': toInteger(numero),
            'complement': `${complemento}`,
            'district': `${bairro}`,
            'city': `${cidade}`,
            'fu': estado,
            'country': `${country}`,

        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/base/lista-clientes`);
            })
            .catch(err => {
                console.log(err.response.data);
            });
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <>
            <Breadcrumb parent="Clientes" title="Editar Cliente" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {ClienteEdit}</h4>
                                    {/* {erro && <h6>(Por Favor Preencha Todos os Campos)</h6>} */}

                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O nome do Cliente é obrigatória.",
                                                            minLength: 2
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="name" />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    value={email}
                                                    name="email"
                                                    onChange={e => setEmail(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O email é obrigatório.",
                                                            minLength: 3
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{CPF}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="cnpj"
                                                    value={clientCpf}
                                                    onChange={e => setClientCpf(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CPF é obrigatório.",
                                                            minLength: 10
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cnpj" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{CepCheckout}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={cep}
                                                    name="cep"
                                                    onChange={(e) => setCepValue(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O número do CEP é obrigatório.",
                                                            minLength: 8
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cep" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Rua}</label>
                                                <input
                                                    value={rua}

                                                    className="form-control"
                                                    type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">{Numero}</label>
                                                <input
                                                    className="form-control"
                                                    value={numero}
                                                    name="numero"
                                                    onChange={(e) => setNumero(e.target.value)}
                                                    type="text"
                                                    ref={
                                                        register({
                                                            required: "O número é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="numero" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Complemento}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={complemento}
                                                    name="complemento"
                                                    onChange={(e) => setComplemento(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O complemento é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="complemento" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">{Bairro}</label>
                                                <input value={bairro}
                                                    className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Cidade}</label>
                                                <input value={cidade} className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{Estado}</label>
                                                <select className="form-control" value={estado}>
                                                    <option value={null}>Selecione o Estado</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                    <option value="DF">Distrito Federal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Pais}</label>
                                                <input
                                                    value={country}
                                                    className="form-control"
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Gênero</label>
                                                <select
                                                    value={gender}
                                                    onChange={e => setGender(e.target.value)}
                                                    className="form-control"

                                                >
                                                    <option value={null}>Selecione o sexo</option>
                                                    <option value={'m'}>Masculino</option>
                                                    <option value={'f'}>Feminino</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>


                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/lista-clientes`)}>{CancelUpdateProfile}</button>
                                    <button
                                        onClick={handleSubmit} className="btn btn-primary ml-3" type="submit"
                                    >{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditClients;