import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { CancelUpdateProfile, Create, TestEndpoint, UpdateProfile } from '../../constant';
import { LOCAL_BACKEND_URL } from '../../data/urls';

import { useLocation } from 'react-router';

// Imports for the value mask
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

import { formatReais } from '../../utils/utilTools';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';

const EditPlan = ({ history }) => {

  const location = useLocation();

  const [name, setName] = useState('');
  const [fus, setFus] = useState([]);
  const [amount, setAmount] = useState('');
  const [credits, setCredits] = useState('');

  const [statesChecked, setStatesChecked] = useState({});

  const changeStatesChecked = (state) => {
    const tempStatesChecked = {...statesChecked};
    tempStatesChecked.state = true;
    setStatesChecked(tempStatesChecked);
  }

  const addOrRemoveFu = (fu) => {
    const tempFus = [...fus];
    if (tempFus.includes(fu)) {
      tempFus.splice(tempFus.indexOf(fu), 1);
    }
    else {
      tempFus.push(fu);
    }
    setFus(tempFus);
    console.log(tempFus);
  }
  // Mask for sale amount
  const saleMask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 13,
    allowNegative: false,
    allowLeadingZeroes: false
  });

  useEffect(() => {
    console.log(location.planInfo.fus);
    setName(location.planInfo.name);
    setStatesChecked({
      'SP': location.planInfo.fus.includes('SP') ? true : false,
      'SC': location.planInfo.fus.includes('SC') ? true : false,
      'MG': location.planInfo.fus.includes('MG') ? true : false,
      'RS': location.planInfo.fus.includes('RS') ? true : false,
      'MT': location.planInfo.fus.includes('MT') ? true : false,
      'MS': location.planInfo.fus.includes('MS') ? true : false,
      'PR': location.planInfo.fus.includes('PR') ? true : false,
      'AC': location.planInfo.fus.includes('AC') ? true : false,
      'AL': location.planInfo.fus.includes('AL') ? true : false,
      'AP': location.planInfo.fus.includes('AP') ? true : false,
      'AM': location.planInfo.fus.includes('AM') ? true : false,
      'BA': location.planInfo.fus.includes('BA') ? true : false,
      'CE': location.planInfo.fus.includes('CE') ? true : false,
      'ES': location.planInfo.fus.includes('ES') ? true : false,
      'GO': location.planInfo.fus.includes('GO') ? true : false,
      'MA': location.planInfo.fus.includes('MA') ? true : false,
      'PA': location.planInfo.fus.includes('PA') ? true : false,
      'PB': location.planInfo.fus.includes('PB') ? true : false,
      'PE': location.planInfo.fus.includes('PE') ? true : false,
      'PI': location.planInfo.fus.includes('PI') ? true : false,
      'RJ': location.planInfo.fus.includes('RJ') ? true : false,
      'RR': location.planInfo.fus.includes('RR') ? true : false,
      'SE': location.planInfo.fus.includes('SE') ? true : false,
      'TO': location.planInfo.fus.includes('TO') ? true : false,
      'DF': location.planInfo.fus.includes('DF') ? true : false,
      'RO': location.planInfo.fus.includes('RO') ? true : false,
      'RN': location.planInfo.fus.includes('RN') ? true : false
    });
    setFus(location.planInfo.fus);
    setAmount(formatReais(location.planInfo.value));
    setCredits(location.planInfo.credits);
  }, []);

  const submitHandler = event => {
    /* event.preventDefault(); */
    const submitData = {
      'name': name,
      'fus': fus,
      'value': parseAmount(amount),
      'credits': credits
    }

    axios.patch(`${LOCAL_BACKEND_URL}/plan/${location.planId}`, submitData, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
      .then(res => {
        history.push(`${process.env.PUBLIC_URL}/base/planos`);
      })
      .catch(err => console.log(err.message));

  }

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = event => submitHandler();
  return (
    <div>
      <Breadcrumb parent="Planos" title="Planos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cadastrar plano</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6 ml-3">
                      <FormGroup>
                        <Label for="name">Nome</Label>
                        <input
                          value={name}
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nome"
                          onChange={e => setName(e.target.value)}
                          ref={
                            register({
                              required: "O nome do Plano é obrigatório.",
                              minLength: 2
                            })
                          }
                        />
                        <ErrorMessage errors={errors} name="name" />

                      </FormGroup>
                    </div>


                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="value">Valor</Label>
                      <MaskedInput
                        className="form-control"
                        name="value"
                        value={amount}
                        mask={saleMask}
                        onChange={e => setAmount(e.target.value)}
                        ref={
                          register({
                            required: "O valor do plano é obrigatório.",
                            minLength: 2
                          })
                        }
                      />
                      <ErrorMessage errors={errors} name="value" />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="credits">Créditos</Label>
                      <input
                        value={credits}
                        type="text"
                        name="credits"
                        className="form-control"
                        placeholder="Digite os créditos"
                        onChange={e => setCredits(e.target.value)}
                        ref={
                          register({
                            required: "A quantidade de créditos é obrigatória.",
                            minLength: 2
                          })
                        }
                      />
                      <ErrorMessage errors={errors} name="credits" />

                    </FormGroup>
                  </div>
                  <FormGroup
                    value={fus}
                    check inlist onChange={(e) => addOrRemoveFu(e.target.value)}>
                    <h4 className="row" for="fu">Estados</h4>
                    <div className="row">
                      <div className="col-3">
                        <li>
                          <input className="form-check-input" type="checkbox" value="SP" defaultChecked={statesChecked['SP']} />
                          <Label for="SP">São Paulo</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="SC" defaultChecked={statesChecked['SC']} />
                          <Label for="SC">Santa Catarina</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="MG" defaultChecked={statesChecked['MG']} />
                          <Label for="MG">Minas Gerais</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="RS" defaultChecked={statesChecked['RS']} />
                          <Label for="RS">Rio Grande do Sul</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="MT" defaultChecked={statesChecked['MT']} />
                          <Label for="MT">Mato Grosso</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="MS" defaultChecked={statesChecked['MS']} />
                          <Label for="MS">Mato Grosso do Sul</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="PR" defaultChecked={statesChecked['PR']} />
                          <Label for="PR">Paraná</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="AC" defaultChecked={statesChecked['AC']} />
                          <Label for="AC">Acre</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="AL" defaultChecked={statesChecked['AL']} />
                          <Label for="AL">Alagoas</Label>
                        </li>
                      </div>
                      <div className="col-3">
                        <li>
                          <input className="form-check-input" type="checkbox" value="AP" defaultChecked={statesChecked['AP']} />
                          <Label for="AP">Amapá</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="AM" defaultChecked={statesChecked['AM']} />
                          <Label for="AM">Amazonas</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="BA" defaultChecked={statesChecked['BA']} />
                          <Label for="BA">Bahia</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="CE" defaultChecked={statesChecked['CE']} />
                          <Label for="CE">Ceará</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="ES" defaultChecked={statesChecked['ES']} />
                          <Label for="ES">Espírito Santo</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="GO" defaultChecked={statesChecked['GO']} />
                          <Label for="GO">Goiás</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="MA" defaultChecked={statesChecked['MA']} />
                          <Label for="MA">Maranhão</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="PA" defaultChecked={statesChecked['PA']} />
                          <Label for="PA">Pará</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="PB" defaultChecked={statesChecked['PB']} />
                          <Label for="PA">Paraíba</Label>
                        </li>
                      </div>

                      <div className="col-3">
                        <li>
                          <input className="form-check-input" type="checkbox" value="PE" defaultChecked={statesChecked['PE']} />
                          <Label for="PE">Pernambuco</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="PI" defaultChecked={statesChecked['PI']} />
                          <Label for="PI">Piauí</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="RJ" defaultChecked={statesChecked['RJ']} />
                          <Label for="RJ">Rio de Janeiro</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="RR" defaultChecked={statesChecked['RR']} />
                          <Label for="RR">Roraima</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="SE" defaultChecked={statesChecked['SE']} />
                          <Label for="SE">Sergipe</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="TO" defaultChecked={statesChecked['TO']} />
                          <Label for="TO">Tocantins</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="DF" defaultChecked={statesChecked['DF']} />
                          <Label for="DF">Distrito Federal</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="RO" defaultChecked={statesChecked['RO']} />
                          <Label for="DF">Rondônia</Label>
                        </li>
                        <li>
                          <input className="form-check-input" type="checkbox" value="RN" defaultChecked={statesChecked['RN']} />
                          <Label for="DF">Rio Grande do Norte</Label>
                        </li>
                      </div>
                    </div>
                    {/* </Input> */}
                  </FormGroup>
                  <div className="card-footer text-right">
                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/planos`)}>{CancelUpdateProfile}</button>
                    <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );

}

export default EditPlan;

const parseAmount = (amount) => {
  return amount
    .replace(/^\D+/g, '')
    .split('.')
    .join('')
    .replace(',', '.');
}