import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { CancelUpdateProfile, Create, Option, Salvar, TestEndpoint } from '../../constant';
import { useLocation } from 'react-router';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';

const EditSms = ({ history }) => {

    const location = useLocation();

    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(0);
    const [type, setType] = useState(0)
    const [smsModelId, setSmsModelId] = useState('');

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/sms/${location.state.smsId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setName(res.data.smsMOdel.name);
                setMessage(res.data.smsMOdel.body);
                setType(res.data.smsMOdel.type);
                setStatus(res.data.smsMOdel.status);
                setSmsModelId(res.data.smsMOdel.smsModelId)
            })
            .catch(err => console.log(err));
    }, []);

    const submitHandler = event => {
/*         event.preventDefault();
 */        console.log(type, '', status);
        axios.patch(`${LOCAL_BACKEND_URL}/sms/${location.state.smsId}`, {
            'name': name,
            'body': message,
            'type': parseInt(type),
            'status': parseInt(status),
            'smsModelId': smsModelId
        }, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/relacionamentos/sms`);
            })
            .catch(err => {
                console.log(smsModelId)
                console.log(err.response.data);
            })
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Editar SMS</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <Label for="name">Nome</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nome da Mensagem"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O campo nome é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Corpo da Mensagem</Label>
                                        <Input
                                            className="form-control"
                                            type="textarea"
                                            name="message"
                                            rows="4"
                                            id="message"
                                            placeholder="Nome da Mensagem"
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O corpo da mensagem é obrigatório.",

                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="message" />
                                    </FormGroup>
                                    <div className="form-group">
                                        <Label for="type">Tipo</Label>
                                        <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                                            onChange={e => setType(e.target.value)}
                                            <option value={null}>Escolha o tipo</option>
                                            <option value={1}> Venda criada</option>
                                            <option value={2}>Venda negada</option>
                                            <option value={3}>Venda concluída</option>
                                            <option value={4}>Venda concluída(parceiro)</option>
                                            <option value={5}>Link gerado</option>

                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <Label for="status">Status</Label>
                                        <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            onChange={e => setStatus(e.target.value)}
                                            <option value={null}>Escolha o Status</option>
                                            <option value={1}> Ativo</option>
                                            <option value={2}>Inativo</option>
                                        </select>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/relacionamentos/sms`)}>{CancelUpdateProfile}</button>
                                        <button className="btn btn-primary ml-3" onClick={handleSubmit} type="submit">{Salvar}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditSms;