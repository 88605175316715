import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import DataTable from 'react-data-table-component';
import '../../../components/base/lists/filter.css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import ButtonDeletePlan from '../../icetran/ButtonDeletePlan';

import { formatReais } from '../../../utils/utilTools';
import { indexOf } from 'lodash-es';

import { requestAuthConfig } from '../../../utils/utilTools';

const Plans = ({ history }) => {

  const [plans, setPlans] = useState([]);


  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/plans`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        console.log(res)
        setPlans(res.data.plans);
      })
      .catch(err => console.log(err.message));
  }, [])

  const columns = [

    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{/* <img style={{ marginLeft: "4px", borderRadius: "50%", width: "50px", height: "50px", marginRight: "10px" }} alt="man" src={row.photoURL} /> */}{row.name}</div>,
      center: true,
    },
    {
      name: 'Estados',
      selector: 'fus',
      sortable: true,
      cell: row =>
        <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>
          {
            parseStates(row.fus)
          }
        </div>,
      center: true,
    },
    {
      name: 'Valor',
      selector: 'value',
      sortable: true,
      cell: row => <div>{/*formatReais(row.value)*/}R$ {Number(row.value).toFixed(2)}</div>,
      center: true
    },
    {
      name: 'Créditos',
      selector: 'credits',
      sortable: true,
      center: true,
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "50%" }}>
        <li className="list-group-item-action">
          <button
            style={{ marginRight: 8 }}
            onClick={() => history.push(
              {
                pathname: `${process.env.PUBLIC_URL}/edit/plan`,
                planId: row.planId,
                planInfo: { ...row }
              }
            )}
            className="btn btn-sm btn-warning">
            <i className="icon icon-pencil"></i>
          </button>
        </li>
        <li className="list-group-item-action">
          <ButtonDeletePlan
            plan={{ ...row }}
            planId={row.planId}
          />
        </li>
      </div>,
      center: true,
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');

  function searchPlans(rows) {
    return rows.filter(row =>
      row.name.toLowerCase().indexOf(searchTerm) > -1 ||
      row.value.toString().indexOf(searchTerm) > -1
    )
  }



  return (
    <div>
      <Breadcrumb parent="Produtos" title="Planos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Planos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/create/plan`)} type="button">Novo</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <div className="form-group row">
                    <div className="search_wrap search_wrap_3">
                      <div className="search_box">
                        <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchPlans(plans)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />


                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {/* {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfUsers}` : ''} */}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      <PaginationItem disabled>
                        <PaginationLink first href="#javascript" />
                      </PaginationItem>
                      {/* {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      } */}
                      <PaginationItem>
                        <PaginationLink last href="#javascript" />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;

export const parseStates = (states) => {
  let responseString = '';
  for (var i = 0; i < states.length - 1; i++) {
    responseString = i < states.length - 2 ? `${responseString}${states[i]}, ` : `${responseString}${states[i]} e `;
  }
  responseString += states[i];
  return responseString;
}