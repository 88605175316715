// dashboard
export const TotalVisits = "Total Visits"
export const TotalSells = "Total De Vendas Do Mês"
export const TicketMedio = "Ticket Médio"
export const Taxista = "Taxista"
export const Escolar = "Monitor De Transporte Escolar (EGEM)"
export const Reciclagem = "Reciclagem A/B"
export const Infratores = "Reciclagem Infrator"
export const TotalSale = "Total Sale"
export const TotalCoursesSell = "Total De Vendas Do Mês"
export const TotalMoneySell = "Projeção Do Mês"

export const CompleteAllSale = "Vendas Concluídas"
export const PendentAllSale = "Vendas Pendentes"
export const CompleteSale = "Vendas Concluídas"
export const PendentSale = "Vendas Pendentes"
export const CancelSales = "Vendas Canceladas"
export const DenySales = "Vendas Negadas"
export const TotalValue = "Total Value"
export const ProductionValuation = "Production Valuation"
export const Last12months = "Vendas Dos Últimos 12 Meses"
export const TotalEarning = "Total Earning"
export const TotalMoney = "Total De Faturamento"
export const TotalTax = "Total Tax"
export const TotalCourses = "Número De Cursos Vendidos"
export const New = "Novo"
export const NewSale = "New Sale"
export const NewMessage = "New Message"
export const NewReport = "New Report"
export const NewVisits = "New Visits"
export const TotalProfit = "Total Profit"
export const CfcProfit = "Vendas CFC"
export const SellCfc = "Vendas CFC"
export const AllCustomIncome = "All Custom Income"
export const AllCfc = "Todas vendas de CFC's"
export const TodasVendas = "Todas vendas"
export const DestaqueVendas = "Destaque de Vendas"
export const TotalInvestment = "Total Investment"
export const AllSells = "Vendas Totais"
export const TotalReview = "Total Review"
export const MediumTicket = "Ticket Médio"
export const CustomerReview = "Customer Review"
export const Change = "Change"
export const Feedback = "Feedback"
export const TotalFeedback = "Total Feedback"
export const Online = "Online"
export const MarshiKisteen = "Marshi Kisteen"
export const Dashboard = "Dashboard"
export const Ui = "Ui"
export const Xi = "Xi"
export const Message = "Message"
export const Portfolio = "Portfolio"
export const Users = "Usuários"
export const Clients = "Clientes"
export const NameFull = "Nome Completo*"
export const EmailCheckout = "E-mail*"
export const CpfCheckout = "CPF*"
export const CepCheckout = "CEP*"
export const Rua = "Rua*"
export const Pais = "País*"
export const PlanoNome = "Plano"
export const Faturamento1 = "Faturamento"
export const Numero = "Número*"
export const Complemento = "Complemento*"
export const Bairro = "Bairro*"
export const Cidade = "Cidade*"
export const Estado = "Estado*"
export const TelCheckout = "Telefone*"
export const BirthCheckout = "Data de Nascimento*"
export const RgCheckout = "RG*"
export const CnhCheckout = "CNH*"
export const Renach = "RENACH/Nº DO PROCESSO*"
export const CategoryCheckout = "Categoria*"
export const Sells = "Vendas"
export const Faturamento = "Faturas"
export const NewUser = "New User"
export const NewSell = "Novas Vendas"
export const LastestSells = "Últimas Vendas"
export const CreateUser = "Criar Usuário"
export const SellCreate = "Cadastrando Nova Venda"
export const CourseEdit = "Editar Curso"
export const CategoriaEdit = "Editar Categoria"
export const ClienteEdit = "Editando Cliente"
export const EditUser = "Editar Usuário"
export const EditParceiro = "Editar Parceiro"
export const CreateClients = "Novo Cliente"
export const CheckoutSell = "Checkout"
export const Carrinho = "Carrinho"
export const ClientCPFNotFoundCheckout = "Você ainda não é nosso aluno, continue a sua compra."
export const ClientsCPFFound = "CPF Cadastrado"
export const PayCheckout = "Formas de Pagamento"
export const Pix = "Pix"
export const BoletoB = "Boleto Bancário/Pix"
export const CreditCardOption = "Cartão de Crédito"
export const InfoSell = "Informações para compras"
export const Month = "Mês"
export const Today = "Hoje"
export const NickStone = "Nick Stone"
export const MilanoEsco = "Milano Esco"
export const Joaozinho = "João Vitor"
export const Ronaldo = "Ronaldo"
export const WiltorNoice = "Wiltor Noice"
export const AnnaStrong = "Anna Strong"
export const Fulano = "Fulano"
export const Ciclano = "Ciclano"
export const RecentNotification = "Recent Notification"
export const All = "All"
export const DashboardCourses = "Cursos"
export const DashboardCFC = "CFC's"
export const Opcao = "Opções"
export const Order = "Order"
export const Download = "Download"
export const Trash = "Trash"
export const ByKan = "By Kan"
export const ByTailer = "By Tailer"
export const ByKaint = "By Kaint"
export const ByCall = "By call"
export const ByWaiter = "By Waiter"
export const ByComman = "By Comman"
export const Calculation = "Calculation"
export const TotalIncome = "Total Income"
export const TotalLoss = "Total Loss"
export const Conversations = "Conversations"
export const View = "View"
export const Media = "Media"
export const Search = "Search"
export const SellingUpdate = "Selling Update"
export const SellingCourses = "Vendas por curso"
export const SellingState = "Vendas por Estado"
export const SellingCoursesCFC = "Destaque de Vendas de Cursos por CFC"
export const DestaqueVendasCursos = "Destaque de Vendas dos Cursos"
export const Shipping = "Shipping"
export const Mes = "Mês"
export const TresMeses = "3-meses"
export const SeisMeses = "6-meses"
export const Estados = "Estados"

//CFC'S
export const CFC1 = "Vargem Grande - Rio de Janeiro/RJ"
export const CFC2 = "Bom Jesus - Curitiba/PR"
export const CFC3 = "Praça XV - Florianópolis/SC"
export const CFC4 = "Oswlado Cruz - São Paulo/SP"
export const CFC5 = "Getúlio Vargas - Belo Horizonte/MG"
export const CFC6 = "SP"
export const CFC7 = "SP"
export const CFC8 = "SP"
export const Cfc = "CFC"
export const EstadoGrafico = "Estado"
export const Curso = "Curso"


export const Purchase = "Purchase"
export const TotalSell = "Total Sell"
export const Follow = "Follow"
export const Detaills = "Detalhes"
export const Activitys = "Activitys"
export const TotalWebVisitor = "Total Web Visitor"
export const TotalSaleProduct = "Total Sale Product"
export const CompanyLoss = "Company Loss"
export const BestSellers = "Best Sellers"
export const Name = "Nome"
export const ErrorInCfcCreation = "Erro na criação verifique seus dados"
export const curso01 = "ATUALIZAÇÃO PARA CONDUTORES DE VEÍCULOS DE TRANSPORTE COLETIVO DE PASSAGEIROS ATUALIZAÇÃO PARA CONDUTORES DE VEÍCULO DE TRANSPORTE COLETIVO DE PASSAGEIROS - MG"
export const NumId = "ID"
export const Sale = "Sale"
export const Stock = "Stock"
export const Categories = "Categories"
export const AlanaSlacker = "Alana Slacker"
export const PaymentStatus = "Payment Status"
export const WorkProcess = "Work Process"
export const SaleCompleted = "Sale Completed"
export const PaymentDone = "Payment Done"
export const ProfileStatus = "Profile Status"
export const BasicInformation = "Basic Information"
export const LegalDocument = "Legal Document"
export const Interest = "Interest"
export const ProductInfo = "Product Info"
export const BillingDetails = "Billing Details"
export const Logs = "Logs"
export const ShoppingCarts = "Shopping Cart"
export const Computer = "Computer"
export const Headphone = "Headphone"
export const Furniture = "Furniture"
export const Shoes = "shoes"
export const Active = "Active"
export const Disable = "Disable"
export const Paused = "Paused"
export const OnWay = "On Way"
export const Review = "Review"
export const UniversityEarning = "University Earning"
export const Monthly = "Monthly"
export const Average = "Average"
export const Maximum = "Maximum"
export const Yearly = "yearly"
export const Minimum = "Minimum"
export const TotalAdmission = "Total Admission"
export const TotalUniversityVisit = "Total University Visit"
export const HtmlCourse = "Html Course"
export const PHPCourse = "PHP Course"
export const MathProfessors = "Math Professors"
export const Lusonketer = "luson keter"
export const Elanhormas = "Elan hormas"
export const BioProfessors = "Bio Professors"
export const Director = "Director"
export const EranaSiddy = "Erana siddy"
export const Tomkerrly = "Tom kerrly"
export const DemoContent = "Demo Content"
export const QuizCompition = "Quiz Compition"
export const SingingCompition = "Singing Compition"
export const DiwaliCelebration = "Diwali Celebration"
export const RankerRatio = "Ranker Ratio"
export const Student = "Student"
export const Notifications = "Notification"
export const Statistics = "Statistics"
export const OurTopperList = "Our Topper List"
export const OssimKeter = "Ossim Keter"
export const PhoneNumber = "Telefone"
export const Rank = "Rank"
export const TotalMarks = "Total marks"
export const VenterLoren = "Venter Loren"
export const FranLoain = "Fran Loain"
export const LoftenHoren = "Loften Horen"
export const TotalStudent = "Total Student"
export const AdmissionRatio = "Admission Ratio"
export const BTC = "BTC"
export const Bitcoin = "Bitcoin"
export const EditClient = "Editar Cliente"
export const EditSell = "Editar Venda"
export const NewId = "Novo ID"
export const Cliente = "Cliente"
export const DASH = "DASH"
export const Tranding = "Tranding"
export const ETH = "ETH"
export const Ethereum = "Ethereum"
export const MarketValue = "Market Value"
export const Buy = "Buy"
export const Parchase = "Parchase"
export const Amount = "Amount"
export const Link1 = "Link 1"
export const Link2 = "Link 2"
export const Link3 = "Link 3"
export const Units = "Units"
export const Bid = "Bid"
export const Limit = "Limit"
export const Total = "Total"
export const BuyNow = "Buy Now!"
export const Sell = "Sell"
export const SellNow = "Sell Now!"
export const ActiveOrder = "Active Order"
export const SalesStatistics = "Sales Statistics"
export const BitcoinSale = "Bitcoin Sale"
export const EthereumSale = "Ethereum Sale"
export const Balance = "Balance"
export const Chat = "Chat"
export const SEND = "SEND"
export const Invest = "Invest"
export const Ripple = "Ripple"
export const DatatablesMarket = "Datatables Market"
export const User = "User"
export const Email = "Email"
export const Blog = "Blog"
export const Ecommerce = "Ecommerce"
export const Storage = "Storage"
export const Latency = "Latency"
export const Bandwidth = "Bandwidth"
export const ClusterLoad = "Cluster Load"
export const LiveCPUUsage = "Live CPU Usage"
export const MemoryUsage = "Memory Usage"
export const ProcessExplorer = "Process Explorer"
export const IOActivity = "I/O Activity"
export const Brandley = "Brandley"
export const Cara = "Cara"
export const Airi = "Airi"
export const Cedric = "Cedric"
export const Homes = "Home"
export const BudgetSummary = "Budget Summary"
export const TeamMembers = "Team Members"
export const DueTasks = "Due Tasks"
export const Completed = "Completed"
export const Features = "Features"
export const Proposals = "Proposals"
export const Implemented = "Implemented"
export const Issues = "Issues"
export const Open = "Open"
export const ClosedToday = "Closed today"
export const Overdue = "Overdue"
export const Tasks = "Tasks"
export const TaskSolved = "Task Solved"
export const TaskDistribution = "Task Distribution"
export const Schedule = "Schedule"
export const GroupMeeting = "Group Meeting"
export const Project = "Project"
export const Requirements = "Requirements"
export const Discussion = "Discussion"
export const Planning = "Planning"
export const PublicBetaRelease = "Public Beta Release"
export const ClientsTiming = "Clients Timing"
export const Lunch = "Lunch"
export const GithubIsuues = "Github Isuues"
export const Created = "Created"
export const Fixed = "Fixed"
export const ReOpened = "Re-opened"
export const Closed = "Closed"
export const WontFix = "Wont'fix"
export const NeedTest = "Need's test"
export const ClosedIssues = "Closed Issues"
export const CurrentProgress = "Current Progress"
export const WebApplication = "Web application"
export const DesignAndDevelopment = "Design & development"
export const LoginModule = "Login module"
export const Development = "Development"
export const ModuleTesting = "Module testing"
export const Testing = "Testing"
export const BudgetDistribution = "Budget Distribution"
export const Spent = "Spent"
export const WeeklySpent = "Weekly spent"
export const TotalSpent = "Total spent"
export const Remaining = "Remaining"
export const TotalBudget = "Total Budget"
export const JerryPatterson = "Jerry Patterson"
export const DesignManager = "Design Manager"
export const Integer = "Integer"
export const RosaMatthews = "Rosa Matthews"
export const DirectorOfSales = "Director of Sales"
export const Ipsum = "Ipsum"
export const AlvaroAguirre = "Alvaro Aguirre"
export const OfficeAssistant = "Office Assistant"
export const Praesent = "Praesent"
export const ProgrammerAnalyst = "Programmer Analyst"
export const AnneSnyder = "Anne Snyder"
export const SocialWorker = "Social Worker"
export const Donec = "Donec"
export const SystemsEngineer = "Systems Engineer"
export const Etiam = "Etiam"
export const Save = "Save"
export const LevelsHeader = "Níveis"


// widgets
export const Daily = "Daily"
export const Week = "Week"
export const Year = "Year"
export const India = "India"
export const March = "March"
export const Newyork = "Newyork"
export const Peris = "Peris"
export const Cancel = "Cancel"
export const NewProduct = "New Product"
export const Location = "Location"
export const RiskFactor = "Risk Factor"
export const BestSeller = "Best Seller"
export const Earnings = "Earnings"
export const Products = "Products"
export const Messages = "Messages"
export const Settings = "Settings"
export const Pending = "Pending"
export const Done = "Done"
export const kolkata_India = "kolkata, India"
export const India_Surat = "India, Surat"
export const RecentActivity = "Recent Activity"
export const Profit = "Profit"
export const Loss = "Loss"
export const MarkJecno = "Mark Jecno"
export const Manager = "Manager"
export const Follower = "Follower"
export const Following = "Following"
export const TotalPost = "Total Post"
export const NewOrder = "New Order"
export const Facebook = "Facebook"
export const Twitter = "Twitter"
export const Linkedin = "Linkedin"
export const GooglePlus = "Google +"
export const Github = "Github"
export const ContactUs = "Contact Us"
export const Contact = "Contact"
export const YourName = "Your Name"
export const SEND_IT = "SEND IT"
export const Post = "Post"
export const ProductsCart = "Products Cart"
export const Employee_Status = "Employee Status"
export const Like = "Like"

export const Projects = "Projects"
export const MarketingExpenses = "Marketing Expenses"
export const TotalSales = "Total sales"
export const TotalPurchase = "Total purchase"
export const SkillStatus = "Skill Status"
export const OrderStatus = "Order Status"

export const LiveProducts = "Live Products"
export const Turnover = "Turnover"
export const MonthlySales = "Monthly Sales"

export const Uses = "Uses"
export const BrowserUses = "Browser Uses"
export const Finance = "Finance"
export const Affiliate = "Affiliate"
export const Marketing = "Marketing"
export const Advertise = "Advertise"
export const Investor = "Investor"
export const Money = "Money"
export const Earning = "Earning"
export const Complete = "Complete"
export const Design = "Design"
export const Market = "Market"
export const Converse = "Converse"
export const WebsiteVisiter = "WebsiteVisiter"

// Base 
export const DefaultColor = "Default Color"
export const Color = "Color"
export const Primary = "Primary"
export const Secondary = "Secondary"
export const Success = "Success"
export const Info = "Info"
export const Warning = "Warning"
export const Information = "Information"
export const Danger = "Danger"
export const Pink = "Pink"
export const Grey = "Grey"
export const Dark = "Dark"
export const Light = "Light"

export const Headings = "Headings"
export const SubHeading = "Sub Heading"
export const Heading1 = "Heading 1"
export const Heading2 = "Heading 2"
export const Heading3 = "Heading 3"
export const Heading4 = "Heading 4"
export const Heading5 = "Heading 5"
export const Heading6 = "Heading 6"
export const VeryThin100 = "Very Thin (100)"
export const Thin300 = "Thin (300)"
export const Normal400 = "Normal (400)"
export const LightNormal600 = "light Normal (600)"
export const Bold700 = "Bold (700)"
export const ExtraBold900 = "Extra Bold (900)"
export const DisplayHeadings = "Display headings"
export const TextColor = "Text Color"
export const ListingTypography = "Listing Typography"
export const UnorderList = "Unorder list"
export const OrderList = "Order list"
export const FontawesomeList = "Fontawesome list"
export const ICOIconList = "ICO Icon list"
export const ThemfyList = "Themfy list"
export const Blockquotes = "Blockquotes"
export const SourceTitle = "Source Title"
export const AlignmentsCenter = "Alignments Center"
export const AlignmentRight = "Alignment Right"
export const Lead = "Lead"


export const Sizing = "Sizing"
export const StatusIndicator = "Status Indicator"
export const Initials = "Initials"
export const Shape = "Shape"
export const Ratio = "Ratio"
export const Groups = "Groups"

export const Padding = "Padding"
export const Margins = "Margins"
export const PaddingLeft = "Padding Left"
export const PaddingRight = "Padding Right"
export const PaddingTop = "Padding Top"
export const PaddingBottom = "Padding Bottom"
export const MarginLeft = "Margin Left"
export const MarginRight = "Margin Right"
export const MarginTop = "Margin Top"
export const MarginBottom = "Margin Bottom"
export const VerticalAlign = "Vertical Align"
export const ImageSizes = "Image Sizes"
export const BadgeColor = "Badge Color"
export const BackgroundColor = "Background Color"
export const ButtonColor = "Button Color"
export const BorderRadius = "Border Radius"
export const FontWeight = "Font Weight"
export const FontStyle = "Font Style"
export const Float = "Float"
export const Overflow = "Overflow"
export const FontSize = "Font Size"
export const AllBordersColor = "All Borders Color"
export const BorderWidth = "Border Width"
export const Position = "Position"
export const ButtonOutline = "Button Outline"

export const GridOptions = "Grid Options"
export const ExtraSmall = "Extra small"
export const Large = "Large"
export const Small = "Small"
export const Medium = "Medium"
export const ExtraLarge = "Extra large"
export const GridBehavior = "Grid behavior"
export const GutterWidth = "Gutter width"
export const Nestable = "Nestable"
export const Offsets = "Offsets"
export const ColumnOrdering = "Column ordering"
export const GridColumn = "Grid Column"
export const VerticalAlignment = "Vertical alignment"
export const HorizontalAlignment = "Horizontal alignment"
export const NestingColumn = "Nesting Column"

export const ContextualVariations = "Contextual variations"
export const TagsWithNumber = "Tags with number"
export const PillsWithNumber = "Pills with number"
export const TagsWithIcon = "Tags With icon"
export const PillsWithIcon = "Pills with Icon"
export const BadgesExample = "Badges Example"
export const Alert = "Alert"
export const PlayingNow = "Playing Now"
export const UpdateAvailable = "Update available"

export const BasicProgressBars = "Basic Progress Bars"
export const SmallProgressBars = "Small Progress Bars"
export const LargeProgressBars = "Large Progress Bars"
export const CustomHeightProgressBars = "Custom Height Progress Bars"
export const ProgressBarsStates = "Progress Bars states"
export const MultipleBarsStacked = "Multiple bars / Stacked"
export const ProgressBarsStriped = "Progress Bars Striped"
export const ProgressBarsAnimated = "Progress Bars animated"
export const StripesAndAnimations = "Stripes and Animations"
export const Stripes = "Stripes"
export const AnimatedStripes = "Animated Stripes"
export const Plain = "Plain"
export const MaxValue = "Max value"
export const Add = "Add"

export const BasicModal = "Basic Modal"
export const Simple = "Simple"
export const ModalTitle = "Modal title"
export const SaveChanges = "Save changes"
export const ScrollingLongContent = "Scrolling long content"
export const VerticallyCentered = "Vertically centered"
export const TooltipsAndPopovers = "Tooltips and popovers"
export const UsingTheGrid = "Using the grid"
export const VaryingModalContent = "Varying modal content"
export const SizesModal = "Sizes modal"
export const LargeModal = "Large modal"
export const SmallModal = "Small modal"
export const DoSomething = "DoSomething"
export const Recipient = "Recipient"
export const VerticallyModal = "Vertically Modal"

export const AlertWithIcon = "Alert With Icon"
export const LightAlerts = "Light Alerts"
export const LinkColor = "Link color"
export const Dismissing = "Dismissing"
export const OutlineAlerts = "Outline Alerts"
export const OutlineDarkAlerts = "Outline Dark Alerts"
export const AlertWithIconOutline = "Alert With Icon outline"
export const AlertWithIconInverse = "Alert With Icon inverse"
export const AdditionalContent = "Additional content"
export const TextAsAction = "Text as action"
export const Dismiss = "dismiss"

export const BasicExamples = "Basic Examples"
export const Direction = "Direction"
export const HTMLContent = "HTML Content"
export const OffsetToTooltip = "Offset to Tooltip"

export const WithTitleAlert = "With Title alert"
export const SuccessAlert = "Success alert"
export const InfoAlert = "Info alert"
export const WarningAlert = "Info alert"
export const Basic = "Basic"

export const BasicTooltip = "Basic Tooltip"
export const Directions = "Directions"
export const Tooltip = "Tooltip"
export const Offset = "Offset"
export const HoverMe = "Hover Me"
export const TooltipTitle = "Tooltip title"
export const HTMLElements = "HTML elements"
export const TooltipOnTop = "Tooltip on top"
export const TooltipOnRight = "Tooltip on right"
export const TooltipOnLeft = "Tooltip on left"
export const TooltipOnBottom = "Tooltip on bottom"

export const LoaderStyle = "Loader Style"
export const DefaultLoader = "Default Loader"
export const DottedStyle = "Dotted style"
export const DashedStyle = "Basic"
export const DoubleStyle = "Double style"
export const GrooveStyle = "Groove style"
export const RidgeStyle = "Ridge style"
export const InsetStyle = "Inset style"
export const OutsetStyle = "Outset style"
export const LoaderColor = "Loader Color"
export const LoaderMultiColor = "Loader Multi Color"
export const SolidStyle = "Solid style"
export const Default = "Default"

export const BasicDropdown = "Basic Dropdown"
export const DropdownButton = "Dropdown Button"
export const Action = "Action"
export const AnotherAction = "Another Action"
export const AnotherLink = "Another Link"
export const SomethingElseHere = "Something Else Here"
export const BasicColorDropdown = "Basic Color Dropdown"
export const DropdownSplitButton = "Dropdown Split Button"
export const RoundedDropdown = "Rounded Dropdown"
export const DropdownWithDivider = "Dropdown With Divider"
export const DropdownWithHeader = "Dropdown With Header"
export const DropdownWithDisable = "Dropdown With Disable"
export const DropdownWithDropUp = "Dropdown With DropUp"
export const Normal = "Normal"
export const Disabled = "Disabled"
export const DropdownHeader = "Dropdown Header"

export const BasicAccordion = "Basic Accordion"
export const CollapsibleGroupItem = "Collapsible Group Item #"
export const AllCloseAccordion = "All Close Accordion"
export const ColorAccordion = "Color Accordion"
export const AccordionWithIconOnTitle = "Accordion With Icon on Title"
export const AccordionOpenCloseIcon = "Accordion open close icon"

export const BasicTabs = "Basic Tabs"
export const TabsBottomLeftAlign = "Tabs bottom left Align"
export const TabsWithIcon = "Tabs With Icon"
export const TabsRightAlign = "Tabs Right Align"
export const TabsVertical = "Tabs Vertical"
export const PillTabs = "Pill Tabs"
export const PillTabsWithIcon = "Pill Tabs With Icon"
export const PillDarkColor = "Pill Dark color"
export const PrimaryColor = "Primary Color"
export const SecondaryColor = "Secondary Color"
export const SuccessColor = "Success Color"
export const InfoColor = "Info Color"
export const WarningColor = "Warning Color"
export const DangerColor = "Danger Color"
export const Profile = "Profile"
export const SeparatedLink = "Separated Link"

export const SimpleMaterialTab = "Simple Material style tab"
export const MaterialBottomTab = "Material style bottom tab"
export const MaterialLeftTab = "Material style left tab"
export const MaterialRightTab = "Material style right tab"
export const MaterialColorTab = "Material color tab"
export const Inbox = "Inbox"


export const DefaultNav = "Default Nav"
export const Base = "Base"
export const Forms = "Forms"
export const Tables = "Tables"
export const DropdownExample = "Dropdown example"

export const ItemBullets = "Item Bullets"
export const SectionAndSeparator = "Section and Separator"
export const Layouts = "Layout"
export const Sidebars = "Sidebar"
export const Charts = "Charts"
export const Maps = "Maps"
export const Editors = "Editors"
export const TreeView = "TreeView"
export const Rating = "Rating"
export const Scrollable = "Scrollable"
export const Advance = "Advance"
export const Timeline = "Timeline"
export const Cards = "Cards"
export const PageLayout = "Page Layout"
export const Footers = "Footers"
export const LogOut = "Log Out"
export const ActiveAndDisabledLinks = "Active and Disabled Links"
export const InlineNav = "Inline Nav"
export const SubNav = "Sub Nav"
export const StaticSubNav = "Static Sub Nav"
export const ToggleSubNav = "Toggle Sub Nav"
export const Panding = "Panding"
export const Replied = "Replied"
export const General = "General"
export const Widgets = "Widgets"

export const Examples = "Examples"
export const LargerShadow = "Larger shadow"
export const RegularShadow = "Regular shadow"
export const SmallShadow = "Small shadow"
export const NoShadow = "No shadow"

export const DefaultList = "Default List"
export const LinksAndButtons = "Links and buttons"
export const FlushStyle = "Flush Style"
export const ContextualClasses = "Contextual classes"
export const WithBadges = "With badges"
export const CustomContent = "Custom content"
export const JavaScriptBehavior = "JavaScript behavior"
export const WithIcon = "With Icon"
export const WithImages = "With Images"
export const WithImageRounded = "With Image Rounded"

// Advance
export const BOD = "BOD"
export const Designer = "designer"
export const Comment = "Comment"
export const JOHANDIO = "JOHAN DIO"
export const VerticalScrollbar = "Vertical Scrollbar"
export const ClickAndDragScrollbar = "Click and Drag Scrollbar"
export const SmoothScrollbar = "Smooth Scrollbar"
export const NoScrollbar = "No Scrollbar"

export const CustomDirections = "Custom  Directions"
export const CustomAutoclose = "Custom Autoclose"

export const StarRating = "Star Rating"
export const HeartRating = "Heart Rating"
export const ThumbsUpDownRating = "Thumbs Up/Down Rating"
export const PillRating = "Pill Rating"

export const SingleFileUpload = "Single File Upload"
export const MultiImageUpload = "Multi only Image Upload"
export const MultipleImageVideoAudioUpload = "Multiple Image Video Audio Upload"
export const LimitationFileUpload = "Limitation File Upload"
export const CustomFileUpload = "Custom File Upload"

export const AdvancedState = "Advanced State"
export const AlertState = "Alert State"

export const ResponsiveExample = "Responsive Example"
export const CenterExample = "Center Example"
export const LazyLoadExample = "LazyLoad Example"
export const MergeExample = "Merge Example"

export const Carousel = "Carousel"
export const UncontrolledCarousel = "Uncontrolled Carousel"
export const CarouselClassname = "Carousel Using a Tag And Classname"

export const SuccessNotification = "Success Notification"
export const InfoNotification = "Info Notification"
export const WarningNotification = "Warning Notification"
export const DangerNotification = "Danger Notification"
export const TopCenter = "Top Center"
export const BottomCenter = "Bottom Center"
export const BottomLeft = "Bottom Left"
export const RightBottom = "Right Bottom"
export const AutoCloseIn15000Sec = "AutoClose in 15000 Sec"
export const AutoCloseIn5000Sec = "AutoClose in 5000 Sec"
export const AutoCloseIn1000Sec = "AutoClose in 1000 Sec"
export const AutoCloseIn500Sec = "AutoClose in 500 Sec"

export const ColoredBreadcrumb = "Colored breadcrumb"
export const Ribbon = "Ribbon"

export const Pagi_Nations = "Pagination"
export const PaginationWithIcons = "Pagination with icons"
export const PaginationAlignment = "Pagination alignment"
export const PaginationActiveDisabled = "Pagination with active and disabled"
export const PaginationColor = "Pagination Color"
export const PaginationSizing = "Pagination sizing"
export const Previous = "Previous"
export const Next = "Next"

export const Default_Step = "Default Step"
export const Step_With_Icon = "Step with icon"
export const Step_States = "Step States"
export const Step_Sizing = "Step Sizing"
export const Vertical_Step = "Vertical Step"
export const Default_Pearls_Steps = "Default Pearls Steps"
export const Pearls_Steps_Icon = "Pearls Steps with icon"
export const Pearls_Step_Sizing = "Pearls Step Sizing"
export const Pearls_Step_States = "Pearls Step States"
export const AccountInfo = "Account Info"
export const BillingInfo = "Billing Info"
export const Confirmation = "Confirmation"
export const Shopping = "Shopping"
export const Billing = "Billing"
export const Getting = "Getting"

export const DefaultBootstrapBreadcrumb = "Default bootstrap breadcrumb"
export const NavBreadcrumb = "Nav breadcrumb"
export const Library = "Library"
export const Data = "Data"
export const Bootstrap = "Bootstrap"

export const BasicSlider = "Basic Slider"
export const NegativeValues = "Negative Values"
export const FormatedLabel = "Formated Label"
export const DraggableRange = "Draggable Range"

export const ImageCropper = "Image Cropper"
export const StickyNote = "Sticky Note"
export const AddNewNote = "Add New Note"

export const ClickAndDrag = "Click And Drag"
export const SpillDragNDrop = "Spill Drag N Drop"
export const CopyDragNDrop = "Copy Drag N Drop"

export const MultipleImageUpload = "Multiple Image Upload"
export const SelectSingleImageUpload = "Select Single Image Upload"

export const BasicCard = "Basic Card"
export const FlatCard = "Flat Card"
export const WithoutShadowCard = "Without shadow Card"
export const IconInHeading = "Icon in Heading"
export const CardSubTitle = "Card sub Title"
export const CardWithFooter = "Card With Footer"
export const Card_Footer = "Card Footer"
export const PrimaryColorCard = "Primary Color Card"
export const SecondaryColorCard = "Secondary Color Card"
export const SuccessColorCard = "Success Color Card"
export const PrimaryColorHeader = "Primary Color Header"
export const PrimaryColorBody = "Primary Color Body"
export const PrimaryColorFooter = "Primary Color Footer"

export const BorderLeft = "Border left"
export const BorderRight = "Border right"
export const BorderTop = "Border top"
export const BorderBottom = "Border bottom"
export const BorderColorState = "Border color state"
export const AbsoluteStyle = "Absolute Style"
export const ColorState = "Color state"

export const SimpleTab = "Simple Tab"
export const ColorTab = "Color Tab"
export const ColorOption = "Color Option"
export const MaterialTabWithColor = "Material Tab With Color"
export const MaterialTabWithIcon = "Material Tab With Icon "

export const DragBothTitleCard = "Drag Both Title and card"
export const DragOnlyCard = "Drag only card"
export const DragOnlyContent = " Drag only Content"

export const TimelinePrimaryColor = "Timeline Primary color"
export const TimelineSecondaryColor = "Timeline secondary color"
export const TimelineSuccessColor = "Timeline Success color"
export const TimelineInfoColor = "Timeline Info color"
export const TimelineWarningColor = "Timeline Warning color"
export const TimelineDangerColor = "Timeline Danger color"

export const FlagIcons = "Flag Icons"
export const Currency = "Currency"
export const Icons = "Icons"
export const WebApp = "Web App"
export const Spinner = "Spinner"
export const FormControl = "Form Control"
export const TextEditor = "Text Editor"
export const Directional = "Directional"

export const VideoPlayer = "Video Player"
export const BrandPlayer = "Brand Player"
export const MedicalPlayer = "Medical Player"

export const Abstract = "Abstract"
export const Animal = "Animal"
export const Construction = "Construction"
export const Device = "Device"
export const Education = "Education"
export const Emotion = "Emotion"
export const FileType = "File Type"
export const Food = "Food"
export const KidsToys = "Kids & Toys"
export const Law = "Law"
export const Mathematical = "Mathematical"
export const Medical = "Medical"
export const MobileUI = "Mobile UI"
export const Multimedia = "Multimedia"
export const Payment = "Payment"
export const Person = "Person"
export const Social = "Social"
export const Sport = "Sport"
export const Transport = "Transport"
export const Travel = "Travel"
export const Weather = "Weather"
export const Brand = "Brand"
export const Business = "Business"

export const ArrowsDirection = "Arrows & Direction"
export const Control = "Control"

export const Feather = "Feather"
export const WhetherIconsWithAnimations = "Whether Icons With Animations"

export const SimpleLineIcons = "Simple-Line Icons"
export const TypiconsIcons = "typicons Icons"
export const Pe7Icons = "pe7 Icons"
export const IonicIcons = "ionic Icons"

export const DefaultButtons = "Default buttons"
export const BootstrapStateButtons = "Bootstrap state buttons"
export const PrimaryButton = "Primary Button"
export const SecondaryButton = "Secondary Button"
export const SuccessButton = "Success Button"
export const InfoButton = "Info Button"
export const WarningButton = "Warning Button"
export const DangerButton = "Danger Button"
export const LightButton = "Light Button"
export const LargeButtons = "Large buttons"
export const SmallButtons = "Small buttons"
export const ExtraSmallButtons = "Extra Small buttons"
export const ActiveButtons = "Active Buttons"
export const DisabledButtons = "Disabled buttons"
export const CustomStateButtons = "Custom state buttons"
export const OutlineButtons = "outline buttons"
export const BoldBorderOutlineButtons = "bold Border outline buttons"
export const OutlineLargeButtons = "outline Large buttons"
export const OutlineSmallButtons = "outline small buttons"
export const OutlineExtraSmallButtons = "Outline extra small buttons"
export const DisabledOutlineButtons = "Disabled outline buttons"
export const GradienButtons = "Gradien buttons"

export const BasicButtonGroup = "Basic button group"
export const EdgesButtonGroup = "Edges button group"
export const FlatButtonGroup = "Flat button group"
export const LargeButtonGroup = "large Button group"
export const LargeEdgesButtonGroup = "large Edges Button group"
export const OutlineCustomButtonGroup = "Outline Custom button group"
export const OutlineButtonGroup = "Outline button group"
export const OutlineEdgesButton = "Outline Edges button"
export const OutlineFlatButton = "Outline flat button"
export const RadioButtonGroup = "Radio button group"
export const CheckBoxButtonGroup = "Check Box button group"
export const Nesting = "Nesting"
export const Vertical = "Vertical"
export const Left = "Left"
export const Middle = "Middle"
export const Right = "Right"
export const Header = "Header"

export const BasicAreaChart = "Basic Area Chart"
export const AreaSpalineChart = "Area Spaline Chart"
export const BarChart = "Bar chart"
export const ColumnChart = "Column Chart"
export const BubbleChart = "3d Bubble Chart"
export const SteplineChart = "Stepline Chart"
export const PieChart = "Pie Chart"
export const DonutChart = "Donut Chart"
export const MixedChart = "Mixed Chart"
export const CandlestickChart = "Candlestick Chart"
export const RadarChart = "Radar Chart"
export const RadialBarChart = "Radial Bar Chart"

export const RotatingPieChart = "Rotating A Pie Chart"
export const SliceVisibilityThreshold = "Slice Visibility Threshold"
export const StackingAreaChart = "Stacking Area Chart"
export const AreaChart = "Area Chart"
export const BasicBarChart = "Basic bar Chart"
export const MaterialDesign = "Material Design"
export const ComboChart = "ComboChart"
export const BarChart2 = "bar-chart2"
export const LineChart = "Line Chart"

export const LineChart2 = "Line Chart-2 Chart"
export const PolarChart = "Polar Chart"
export const DoughnutChart = "Doughnut Chart"

export const AdvancedSMILAnimations = "Advanced SMIL Animations"
export const SVGPathAnimation = "SVG Path animation"
export const BipolarChatAreaOnly = "BI-POLAR LINE CHART WITH AREA ONLY"
export const BipolarLine = "Bi-polar Line chart with area only"
export const LineChartWithArea = "Line chart with area"
export const BipolarBarChart = "Bi-polar bar chart"
export const StackedBarChart = "Stacked bar chart"
export const HorizontalBarChart = "Horizontal bar chart"
export const ExtremeResponsiveConfiguration = "Extreme responsive configuration"
export const SimpleLineChart = "Simple line chart"
export const HolesInData = "Holes in data"
export const FilledHolesInData = "Filled holes in data"

export const AngleOffset = "Angle Offset"
export const DisableDisplayInput = "Disable Display Input"
export const CursorMode = "Cursor Mode"
export const DisplayPreviousValue = "Display Previous Value"
export const AngleOffsetArc = "Angle Offset & Arc"


// Gallery
export const IMAGE_GALLERY = "IMAGE GALLERY"
export const HoverEffect = "Hover Effect"
export const MasonryGalleryWithDescription = "Masonry Gallery With Description"
export const MasonryGallery = "Masonry Gallery"
export const PortfolioTitle = "Portfolio Title"

// Forms
export const CustomStyles = "Custom styles"
export const BrowserDefaults = "Browser defaults"
export const SupportedElements = "Supported elements"
export const Tooltips = "Tooltips"
export const Zip = "Zip"
export const State = "State"
export const SubmitForm = "Submit form"

export const BasicFormControl = "Basic form control"
export const ExampleSelect = "Example select"
export const ExampleMultipleSelect = "Example multiple select"
export const ExampleTextarea = "Example textarea"
export const BasicHTMLInputControl = "Basic HTML input control"
export const SimpleInput = "Simple Input"
export const Placeholder = "Placeholder"
export const Number = "Número"
export const Telephone = "Telephone"
export const URL = "URL"
export const DateAndTime = "Date and time"
export const ColorPicker = "Color picker"
export const MaximumLength = "Maximum Length"
export const StaticText = "Static Text"
export const EdgesInputStyle = "Edges input style"
export const FlatInputStyle = "Flat input style"
export const RaiseInputStyle = "Raise input style"
export const SolidInputStyle = "Solid input style"
export const InputSizing = "Input sizing"
export const SmallSelect = "Small select"
export const LargeSelect = "Large select"
export const DefaultSelect = "Default select"
export const DefaultInput = "Default input"

export const CustomControls = "Custom controls"
export const UploadFile = "Upload File"
export const CustomSelect = "Custom select"
export const LargeInput = "Large Input"
export const SmallInput = "Small Input"
export const Textarea = "Textarea"

export const CustomRadio = "Custom Radio"
export const Option = "Option"
export const Checked = "Checked"
export const RadioStates = "Radio states"
export const InlineCheckbox = "Inline checkbox"
export const CustomCheckbox = "Custom checkbox"
export const CheckboxStates = "Checkbox states"
export const SuccessState = "Success state"
export const BrandState = "Brand state"
export const PrimaryState = "primary state"
export const AnimatedRadioButtons = "Animated radio buttons"
export const AnimatedCheckboxButtons = "Animated checkbox buttons"
export const SquareCheckbox = "Square checkbox"
export const SolidCheckbox = "Solid checkbox"

export const BasicInputGroups = "Basic Input groups"
export const LeftAddon = "Left Addon"
export const RightAddon = "Right Addon"
export const JointAddon = "Joint Addon"
export const LeftRightAddon = "Left & Right Addon"
export const SquareStyle = "Square style"
export const RaiseStyle = "Raise style"
export const FlatStyle = "Flat style"

export const DefaultStyle = "Default Style"
export const Fast = "Fast"
export const NoBorder = "No Border"
export const SolidBorderStyle = "Solid Border Style"
export const OfferStyleBorder = "Offer style Border"
export const InlineStyle = "Inline Style"
export const Local = "Local"
export const VerticalStyle = "Vertical Style"
export const DeliveryOption = "Delivery Option"
export const BuyingOption = "Buying Option"
export const XYZSeller = "XYZ Seller"
export const ABCSeller = "ABC Seller"
export const HorizontalStyle = "Horizontal Style"

export const DatePickers = "Date Picker"
export const SelectDateWithTime = "Select Date with Time"
export const SelectTimeOnly = "Select Time Only"
export const CustomDateFormat = "Custom date format"
export const TodayButton = "Today button"
export const DisableDaysOfWeek = "Disable days of week"
export const SpecificDateRange = "Specific date range"
export const MinDate = "Min date"
export const MaxDate = "Max date"
export const DateRange = "Date Range"
export const DisableDatepicker = "Disable Datepicker"
export const InlineVersion = "Inline version"

export const TimePickerDarkTheme = "Time Picker with Dark Theme"
export const SetClassicTheme = "Set Classic Theme"
export const SetMultipleTimepicker = "Set Multiple Timepicker"

export const MultipleSelections = "Checked"
export const CustomSelections = "Custom Selections"
export const Remote = "Remote"

export const DefaultFormLayout = "Default Form Layout"
export const HorizontalFormLayout = "Horizontal Form Layout"
export const MegaForm = "Mega form"
export const AccountInformation = "Account Information"
export const CompanyInformation = "Company Information"
export const BillingInformation = "Billing Information"
export const InlineForm = "Inline Form"
export const InlineFormWithLabel = "Inline Form with Label"
export const InlineFormWithoutLabel = "Inline Form without Label"

export const Login = "Login"
export const LOGIN = "LOGIN"
export const ContactNumber = "Contact Number"
export const Radios = "Radios"
export const Checkboxes = "Checkboxes"

export const FormWizardWithIcon = "Form Wizard With Icon"
export const ConfirmPassword = "Confirm Password"
export const DD = "DD"
export const MM = "MM"
export const YY = "YY"
export const Date = "Date"
export const Time = "Time"

//  Tables
export const BasicTable = "Basic Table"
export const InverseTable = "Inverse Table"
export const InverseTablePrimaryBackground = "Inverse Table with Primary background"
export const HoverableRows = "Hoverable rows"
export const TextBackgroundUtilities = "Text or background utilities"
export const TableHeadOptions = "Table head options"
export const StripedRow = "Striped Row"
export const StripedRowInverseTable = "Striped Row with Inverse Table"
export const Caption = "Caption"
export const ResponsiveTables = "Responsive Tables"
export const BreckpointSpecific = "Breckpoint Specific"

export const ExtraLargeTables = "Extra Large Tables"
export const LargeTable = "Large Table"
export const DefaultTable = "Default Table"
export const SmallTable = "Small table"
export const ExtraSmallTable = "Extra Small table"
export const HorizontalBorders = "Horizontal Borders"
export const VerticalBorders = "Vertical Borders"
export const BothBordeds = "Both Bordeds"
export const BorderlessTable = "Borderless Table"
export const DefaultTableBorder = "Default Table Border"
export const SolidBorder = "Solid Border"
export const DoubleBorder = "Double Border"
export const DottedBorder = "Dotted Border"
export const DashedBorder = "Dashed Border"
export const BorderBottomColor = "Border Bottom Color"
export const DefultStyling = "Defult Styling"
export const TableFooterStyling = "Table Footer Styling"
export const TableHeadOptionsPrimaryBackground = "Table head options With Primary Background"
export const CustomTableColor = "Custom Table Color"
export const CustomTableColorHoverStripped = "Custom Table Color with Hover and Stripped"

// Blog
export const JolioMark = "Jolio Mark"
export const PostEdit = "Post Edit"
export const Title = "Title"
export const Type = "Type"
export const Text = "Text"
export const Image = "Image"
export const Audio = "Audio"
export const Video = "Video"
export const Category = "Categoria"
export const Content = "Content"
export const Discard = "Discard"

// Job search 
export const PersonalDetails = "Personal Details"
export const FullName = "Full Name"
export const Password = "Senha"
export const UF = "Unidade Federal"
export const Preco = "Preço"
export const Status = "Status"
export const Integracao = "Integração"
export const RepeatPassword = "Repeat Password"
export const BirthDate = "Birth Date"
export const Nascimento = "Data de Nascimento"
export const CollegeName = "College Name"
export const Submit = "Submit"
export const Period = "Period"
export const DegreeLevel = "Degree Level"
export const Specialization = "Specialization"
export const YourExperience = "Your Experience"
export const YourEducation = "Your Education"
export const UploadCoverLetter = "Upload Cover Letter"
export const UploadYourCV = "Upload Your CV"
export const UploadRecommendations = "Upload Recommendations"
export const UploadYourFiles = "Upload Your Files"

export const JobDescription = "Job Description"
export const Qualifications = "Qualifications"
export const AgencyExperience = "Period"
export const Perks = "Perks"
export const SimilarJobs = "Similar jobs"
export const SeniorUXDesigner = "Senior UX designer"
export const Share = "Share"
export const Filters = "Filters"
export const FindJobs = "Find jobs"
export const NewYork = "New York"
export const SanFrancisco = "San Francisco"
export const Washington = "Washington"
export const Seattle = "Seattle"
export const Chicago = "Chicago"
export const AllLocations = "All Locations"
export const AllJobTitle = "All Job Title"
export const JobTitle = "Job Title"
export const Industry = "Industry"
export const AllIndustries = "All Industries"
export const SpecificSkills = "Specific skills"
export const AllSkills = "All Skills"

export const FindCourse = "Find Course"
export const Accounting = "Accounting"
export const Management = "Management"
export const Duration = "Duration"
export const AllCourses = "All Courses"
export const PaidCourses = "Paid Courses"
export const FreeCourses = "Free Courses"
export const Registration = "Registration"
export const Progress = "Progress"
export const Filter = "Filter"
export const UIDesign = "UI Design"
export const UXDesign = "UX Design"
export const InterfaceDesign = "Interface Design"
export const UserExperience = "User Experience"
export const FrontendDevelopment = "Frontend Development"
export const BackendDevelopment = "Backend Development"
export const UpcomingCourses = "Upcoming Courses"
export const UXDevelopment = "UX Development"
export const BusinessAnalyst = "Business Analyst"
export const WebDevelopment = "Web Development"
export const CourseBy = "Course By"
export const JavaLanguage = "Java Language"
export const CompanyName = "Company Name"

// Ecommerce
export const ShowingProducts = "Showing Products 1 - 24 Of 200 Results"
export const Quantity = "Quantity"
export const NotFoundData = "Sorry, We didn't find any results matching this search"
export const ProductDetails = "Product Details"
export const AddToCart = "Add to Cart"
export const ContinueShopping = "continue shopping"
export const ViewDetails = "View Details"
export const OutOfStock = "Out Of Stock"
export const ProductSizeArray = ["M", "L", "Xl"]
export const ProductReview = "(250 review)"
export const Availability = "Availability"
export const Febric = "Febric"
export const Details = "Details"

export const CreditCard = "Credit card"
export const DebitCard = "Debit card"
export const ExpirationDate = "Expiration Date"
export const COD = "COD"
export const EMI = "EMI"
export const NetBanking = "Net Banking"

export const SelectMonth = ["Select Month", "Jan", "Fab", "March", "April"]
export const SelectYear = ["Select Year", "2015", "2016", "2017", "2018", "2021"]
export const BankName = ["Bank Name", "SBI", "ICICI", "KOTAK", "BOB"]
export const SelectCard = ["Select Card", "2", "3", "4", "5"]
export const SelectDuration = ["Select Duration", "2015-2016", "2016-2017", "2017-2018", "2018-2021"]

export const OrdersHistory = "Orders History"
export const OrderHistoryTable = ["Prdouct", "Prdouct name", "Size", "Color", "Article number", "Units", "Price"]
export const ShippedOrders = "Shipped Orders"
export const CancelledOrders = "Cancelled Orders"

export const SimplePricingCard = "Simple Pricing Card"
export const PricingTableWithBorder = "Pricing Table With Border"
export const ColorHighlight = "Color Highlight"
export const PricingTableWithRibbons = "Pricing Table With Ribbons"
export const Standard = "Standard"
export const Premium = "Premium"
export const Extra = "Extra"
export const Pricings = "Pricing"
export const Popular = "Popular"
export const Subscribe = "Subscribe"
export const LorumIpsum = "Lorum Ipsum"

export const StandardArray = [
    { content1: "50GB", content2: "Disk Space" },
    { content1: "50", content2: "Email Accounts" },
    { content1: "50GB", content2: "Bandwidth" },
    { content1: "10", content2: "Subdomains" },
    { content1: "15", content2: "Domains" },
]

export const Business_Premium_Array = [
    { content1: "60GB", content2: "Disk Space" },
    { content1: "60", content2: "Email Accounts" },
    { content1: "60GB", content2: "Bandwidth" },
    { content1: "15", content2: "Subdomains" },
    { content1: "20", content2: "Domains" },
]

export const CartTitle = "Cart"
export const CartTableHeader = ["Prdouct", "Prdouct Name", "Price", "Quantity", "Action", "Total"]

export const NewOrders = "New Orders"
export const TotalPrice = "Total Price"
export const CheckOut = "check out"
export const CartEmpty = "Your Cart is Empty"
export const ExploreShortlistItems = "Explore more shortlist some items."


export const WishlistTitle = "Wishlist"
export const WishlistTableHeader = ["Prdouct", "Prdouct Name", "Price", "Availability", "Action"]

// Checkout
export const FirstName = "First Name"
export const LastName = "Last Name"
export const Phone = "Phone"
export const EmailAddress = "Email"

export const Cupom = "Cupom"
export const SellDate = "Data"
export const Country = "País"

export const CountryMenu = ["India", "South Africa", "United State", "Australia"]

export const Address = "Endereço"
export const TownCity = "Town/City"
export const StateCountry = "State/Country"

export const PostalCode = "Postal Code"
export const CheckMeOut = "Check me out"
export const PlaceOrder = "Place Order"

export const Product = "Product"
export const Subtotal = "Subtotal"

export const Option1 = "Option 1"
export const Option2 = "Option 2"
export const CheckPayments = "Check Payments"
export const CashOnDelivery = "Cash On Delivery"
export const PayPal = "PayPal"

// Filter 
export const Colors = "Colors"
export const NewProducts = "New Products"
export const FancyShirt = "Fancy Shirt"
export const FancyShirtPrice = "$100.00"

// Invoice 
export const Endless = "Endless"
export const HelloEndless = "hello@endless.in"
export const InvoiceHash = "Invoice #"
export const IssuedMay = "Issued: May"
export const PaymentDue = "Payment Due: June"
export const JohanDeo = "Johan Deo"
export const JohanDeoMailId = "JohanDeo@gmail.com"
export const ProjectDescription = "Project Description"
export const ProductName = "Product Name"
export const Sub_total = "Sub-total"
export const ThankBusiness = "Thank you for your business!"
export const ThankBusinessDesc = "Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices."
export const Price = "Price"

// Chats
export const CALL = "CALL"
export const STATUS = "STATUS"
export const PROFILE = "PROFILE"
export const EricaHughes = "Erica Hughes"
export const VincentPorter = "Vincent Porter"
export const KoriThomas = "Kori Thomas"
export const AinChavez = "Ain Chavez"
export const GingerJohnston = "Ginger Johnston"
export const PrasanthAnand = "Prasanth Anand"
export const HileriJecno = "Hileri Jecno"

// Email 
export const MARKJENCO = "MARK JENCO"
export const MARKJENCOEMAIL = "Markjecno@gmail.com"
export const NEWMAIL = "NEW MAIL"
export const AllMail = "All mail"
export const Sent = "Sent"
export const Draft = "Draft"
export const Send = "SEND"
export const IMPORTANT = "IMPORTANT"
export const Starred = "Starred"
export const UNREAD = "UNREAD"
export const Spam = "Spam"
export const OUTBOX = "OUTBOX"
export const UPDATE = "UPDATE"
export const ALERT = "ALERT"
export const NOTES = "NOTES"
export const NoMailFound = "No mail found"
export const SendMessage = "Send Message"
export const To = "To"
export const ATTACHMENTS = "ATTACHMENTS"
export const DownloadAll = "Download All"
export const Reply = "Reply"
export const ReplyAll = "Reply All"
export const Forward = "Forward"
export const More = "More"
export const Subject = "Subject"

// Clients
export const Identification = "Número de ID"
export const CPF = "Digite o CPF"
export const Imagem = "Imagem do Curso"

export const Course = "Curso"
export const Courses = "Cursos"
export const Ordem = "Ordem"
export const Referencia = "Referência"
export const Titulo = "Título"


// File-manager
export const API = "API"
export const Recent = "Recent"
export const Recovery = "Recovery"
export const Deleteds = "Deleteds"
export const PricingPlan = "Pricing plan"
export const TrialVersion = "Trial Version"
export const FREE = "FREE"
export const Free = "Free"
export const Selected = "Selected"
export const AddNew = "Add New"
export const Upload = "Upload"
export const AllFiles = "All Files"
export const RecentlyOpenedFiles = "Recently opened files"
export const Folders = "Folders"
export const EndlessAdmin = "Endless admin"
export const Files = "Files"


// Board
export const CustomBoards = "Custom Board"
export const DefaultDemo = "Default Demo"

// Bookmark
export const NewBookmark = "New Bookmark"
export const AddBookmark = "Add Bookmark"
export const EditBookmark = "Edit Bookmark"
export const MyBookmark = " My bookmark"
export const WebUrl = "Web Url"
export const Description = "Description"
export const Group = "Group"
export const Collection = "Collection"
export const MyBookmarks = "My Bookmarks"
export const Views = "Views"
export const CreatedByMe = "Created by me"
export const Favourites = "Favourites"
export const SharedWithMe = "Shared with me"
export const Tags = "Tags"
export const Newsletter = "Newsletter"
export const Holidays = "Holidays"
export const Important = "Important"
export const Orgenization = "Orgenization"
export const NoBookmarksFound = "No Bookmarks Found."

// Task 
export const Notification = "notification"
export const AddTask = "Add Task"
export const NewTask = "New Task"
export const TaskTitle = "Task Title"
export const TodayTasks = "Today's Tasks"
export const DelayedTasks = "Delayed Tasks"
export const UpcomingTasks = "Upcoming Tasks"
export const ThisWeekTask = "This week tasks"
export const AssignedToMe = "Assigned to me"
export const MyTasks = "My tasks"
export const ThisMonthTasks = "This month tasks"
export const NoTaskDueToday = "No task due today."
export const NoTasksFound = "No tasks found."
export const Print = "Print"

// Social app
export const Timline = "Timline"
export const About = "About"
export const GeneralManager = "general manager"
export const Friends = "Friends"
export const Photos = "Photos"

export const MoreCommnets = "More Commnets"
export const ELANA = "ELANA"
export const JasonBorne = "Jason Borne"
export const AlexendraDhadio = "Alexendra Dhadio"
export const OliviaJon = "Olivia Jon"
export const IssaBell = "Issa Bell"
export const MyProfile = "Perfil"
export const AboutMe = "Sobre"
export const SocialNetworks = "Social Networks"
export const ProfileIntro = "Profile Intro"
export const LatestPhotos = "Latest Photos"
export const FavouriteMusicBands = "Favourite Music Bands"
export const Dribbble = "Dribbble"
export const BuckyBarnes = "Bucky Barnes"
export const SarahLoren = "Sarah Loren"
export const ComerenDiaz = "Comeren Diaz"
export const AndewJon = "Andew Jon"
export const JohnyWaston = "Johny Waston"
export const JohnyWilliam = "Johny William"
export const AnnaMull = "Anna Mull"
export const KarleneLex = "Karlene Lex"
export const VellaChism = "Vella Chism"
export const WaiSchalk = "Wai Schalk"
export const DionCast = "Dion Cast"
export const AddFriend = "Add Friend"
export const Hobbies = "Hobbies"
export const MyPage = "My Page"
export const Likes = "Likes"
export const MutualFriends = "Mutual Friends"
export const ActivityFeed = "Activity Feed"
export const ActivityLog = "Activity Log"

// Contact
export const ContactHistory = "Contact History"
export const ContactCreated = "Contact Created"
export const NewContacts = "New Contacts"
export const AddContacts = " Add Contact"
export const Age = "Age"
export const Mobile = "Mobile"
export const MobileNo = "Mobile No"
export const Personal = "Personal"
export const AddCategory = "Add Category"
export const FollowUp = "Follow up"
export const Ideas = "Ideas"
export const History = "History"
export const NoDataFound = "No Data Found"
export const Gender = "Gender"
export const Male = "Male"
export const Female = "Female"
export const PrintViews = "Print previe"
export const Delete = "Delete"
export const Edit = "Edit"

// Project 
export const ProjectTitle = "Project Title"
export const ClientName = "Client name"
export const ProjectRate = "Project Rate"
export const ProgressLevel = "Progress Level"
export const ProjectStatus = "Project Status"
export const ProjectSize = "Project Size"
export const Big = "Big"
export const StartingDate = "Starting date"
export const EndingDate = "Ending date"
export const EnterSomeDetails = "Enter some Details"
export const UploadProjectFile = "Upload project file"
export const CreateNewProject = "Create New Project"
export const Resolved = "Resolved"
export const Doing = "Doing"

export const EndlessAdminDesign = "Endless admin Design"
export const ProjectExplaination = "Project explaination"
export const TaskList = "Task list"
export const OtherDetails = "Other details"
export const DesignWork = "Design work"
export const DevlopingWork = "Devloping work"
export const ProjectFiles = "Project Files"

// Todo 
export const ToDo = "To-Do"
export const AddNewTask = "Add new task"
export const Close = "Close"

// Users
export const MarekjecnoMailId = "Marekjecno@yahoo.com"
export const Bio = "Sobre"
export const City = "Cidade"
export const Website = "Website"
export const EditProfile = "Editar Perfil"
export const Company = "Company"
export const Username = "Username"
export const UsersCountryMenu = ["--Select--", "Germany", "Canada", "Usa", "Aus"]
export const UpdateProfile = "Salvar"
export const OracleId = "ID Oráculo"
export const AccessLevel = "Nível de Acesso"
export const CancelUpdateProfile = "Cancelar"
export const Voltar = "Voltar"
export const EndSell = "Finalizar Compra"
export const ButtonWhats = "Whatsapp"
export const UsersTableTitle = "Add projects And Upload"
export const UsersTableHeader = ["Project Name", "Date", "Status", "Price", ""]
export const Update = "Update"

//Contratos
export const Contrato1T = "LI E CONCORDO COM OS TERMOS DO CONTRATO DO CURSO ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE COLETIVO DE PASSAGEIROS"
export const Contrato1 = " Li e Concordo com os Termos -"
export const ContratoHiperLink = " de Prestação de Serviços Educacionais - EAD e fornecimento de Material Pedagógico"
export const Contrato1final = "do curso ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE COLETIVO DE PASSAGEIROS."
export const Contrato2T = `REQUISITOS PARA MATRÍCULA ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE COLETIVO DE PASSAGEIROS`
export const Contrato2 = ` Confirmo cumprir todos os requisitos abaixo, no ato da matrícula:
   - Ser maior de 21 anos; - Estar habilitado, no mínimo, na categoria D; 
   - Não estar cumprindo pena de suspensão do direito de dirigir, cassação da carteira nacional de habilitação - CNH, pena decorrente de crime de trânsito, bem como não estar impedido judicialmente de exercer seus direitos; 
   - Possuir certificado de conclusão de curso de capacitação.`

// Calendar
export const BasicCalendars = "Basic Calendar"
export const Dragging_Event = "DRAGGING EVENT"

// Maps
export const BasicDemo = "Basic Demo"
export const CurrentLocation = "Current Location"
export const MarkerMap = "Marker Map"
export const Polygons = "POLYGONS"
export const Polylines = "Polyline"

export const GeoJsonMapDemo = "GeoJsonMap Demo"
export const AnimateMap = "Animate Map"
export const AnimatePanning = "Animate panning"
export const SetMarkerWithPopup = "Set Marker with Popup"

// Editors
export const CKEditorExample = "CK Editor Example"
export const JavascriptMode = "Javascript Mode"
export const HtmlMode = "Html Mode"
export const CssMode = "css Mode"
export const PhpMode = "php Mode"
export const MDEEditorExample = "MDE Editor Example"

// Faq anf Knowledgebase
export const Articles = "Articles"
export const Knowledgebase = "Knowledgebase"
export const Support = "Support"
export const IntellectualProperty = "Intellectual Property"
export const SellingAndBuying = "Selling And Buying"
export const UserAccounts = "User Accounts"
export const SearchArticles = "Search articles"
export const Navigation = "Navigation"
export const LatestUpdates = "Latest Updates"

export const DavidLinner = "David Linner"
export const UserChristopher = "User Christopher"
export const AskQuestion = "Ask Question"
export const Tutorials = "Tutorials"
export const HelpCenter = "Help center"
export const VideoTutorials = "Video Tutorials"

export const AskOurCommunity = "Ask our community"
export const VictoriaWilson = "Victoria Wilson"
export const FeaturedTutorials = "Featured Tutorials"
export const WebDesign = "Web Design"
export const SeeAll = "See All"
export const BrowseArticles = "Browse Articles"


// Pages
export const RememberMe = "Remember me"
export const SignIn = "Sign In"
export const SignUp = "Sign Up"
export const LoginWithAuth0 = "Login With Auth0"
export const LoginWithJWT = "Login With JWT"

export const EnterPassword = "Enter your Password"
export const Unlock = "Unlock"
export const CREATEPASSWORD = "CREATE YOUR PASSWORD"
export const RetypePassword = "Confirmar Alteração"
export const NewPassword = "Alterar senha"
export const CNPJ = "CNPJ"
export const ResetPassword = "Reset Your Password"
export const EnterMobileNumber = "Enter Your Mobile Number"
export const EnterOTP = "Enter OTP"
export const Resend = "Resend"
export const MAINTENANCE = "MAINTENANCE"
export const BACK_TO_HOME_PAGE = "BACK TO HOME PAGE"
export const WE_ARE_COMING_SOON = "WE ARE COMING SOON"

export const SampleCard = "Sample Card"
export const Videos = "Videos"
export const Images = "Images"
export const SearchSetting = "Search setting"
export const Language = "Language"
export const Flights = "Flights"
export const Endless_Education_Info = "Endlesseducation.info/"

// Suport-ticket
export const Smooth = "Smooth"
export const Running = "Running"

// Layout
export const EN = "EN"
export const English = "English"
export const Spanish = "Spanish"
export const Portuguese = "Portuguese"
export const French = "French"
export const LockScreen = "Lock Screen"
export const FRIENDLIST = "FRIEND LIST"
export const Customizer = "Customizer"
export const Customize = "Customize"
export const PreviewRealTime = "Preview Real Time"
export const Configuration = "Configuration"
export const CopyText = "Copy text"
export const LayoutType = "Layout Type"
export const LTRLayout = "LTR Layout"
export const RTLLayout = "RTL Layout"
export const SidebarType = "Sidebar Type"
export const SidebarSettings = "Sidebar settings"
export const IconColor = "icon Color"
export const LightLayout = "Light layout"
export const DarkLayout = "Dark Layout"
export const MixLayout = "Mix Layout"
export const SidebarBackgroundSetting = "Sidebar background setting"
export const Pattern = "Pattern"
export const Border = "Border"

// Custom
export const Create = "Cadastrar"
export const Salvar = "Salvar alterações"
export const TestEndpoint = "http://localhost:5001/icetran-vendas-bdcba/us-central1/api"
export const EditSale = "Editar venda"
export const Id = "Id"
export const Client = "Cliente"
export const SaleValue = "Valor"
export const SaleStatus = "Status"
export const SaleIntegration = "Integração"
export const Reference = "Referência"
export const EditCourse = "Editar curso"
export const EditCategoria = "Editar Categoria"
export const CustomTitle = "Título"
export const NewCustomTitle = "Novo título"
export const EditCategories = "Editar categorias"
export const Icon = "Ícone"
export const NewIcon = "Novo ícone"
export const EditTerms = "Editar termos"
export const EditContracts = "Editar contratos"
export const EditCoursesStep = "Editar passo de cursos"
export const EditLevels = "Editar nível de usuário"

// Termos
export const TermTitle = "Termo"

// Contratos
export const ContractTitle = "Contrato"

// Passo de cursos
export const CoursesStepTitle = "Passo de cursos"

export const CustomNewUser = "Novo usuário"
export const NewState = "Novo estado"

// Mensagem de CPF não encontrado para consulta de clientes.
export const ClientCPFNotFound = "CPF não cadastrado. Por favor, insira os dados do novo cliente abaixo:"

// Mensagem de CPF encontrado para consulta de clientes.
export const ClientCPFFound = "CPF cadastrado."

export const ZipCode = "CEP"
export const Street = "Rua"
export const Complement = "Complemento"
export const District = "Bairro"
export const FU = "Unidade federativa"
export const PartnerQuestion = "Parceiro"

export const Team = "Time"
export const ErrorInUserCreation = "Erro no cadastro do usuário. Por favor, verifique as informações digitadas.";
export const Role = "Cargo"