import { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import convertToBase64 from "../../../services/convertToBase64";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import fus from '../../../utils/fus';
import { toast } from "react-toastify";

const NoticesComponent = () => {
    const [allCourses, setAllCourses] = useState([]);
    const [base64, setBase64] = useState('');
    const [courses, setCourses] = useState([]);
    
    const [notices, setNotices] = useState([]);
    const [noticeModalIsOpen, setNoticeModalIsOpen] = useState(false);
    const [numberOfNotices, setNumberOfNotices] = useState(-1);
    const [noticeTitle, setNoticeTitle] = useState('');
    const [noticeBody, setNoticeBody] = useState('');
    const [noticeDateStart, setNoticeDateStart] = useState(Date);
    const [noticeDateEnd, setNoticeDateEnd] = useState(Date);
    const [url, setUrl] = useState('');
    
    const [edit, setEdit] = useState([]);

    useEffect(() => {
        if(edit.length > 0){
            setNoticeTitle(edit[0].title);
            setNoticeBody(edit[0].text);
            setUrl(edit[0].url);
        }
    }, [edit])
    const fus = [
        {
            fu: 'Acre',
            initials: 'AC'
        },
        {
            fu: 'Alagoas',
            initials: 'AL'
        },
        {
            fu: 'Amapá',
            initials: 'AP'
        },
        {
            fu: 'Amazonas',
            initials: 'AM'
        },
        {
            fu: 'Bahia',
            initials: 'BA'
        },
        {
            fu: 'Ceará',
          initials: 'CE'
        },
        {
            fu: 'Espirito Santo',
            initials: 'ES'
        },
        {
            fu: 'Goiás',
            initials: 'GO'
        },
        {
            fu: 'Maranhão',
            initials: 'MA'
        },
        {
            fu: 'Mato Grosso',
            initials: 'MT'
        },
        {
          fu: 'Mato Grosso do Sul',
          initials: 'MS'
        },
        {
            fu: 'Minas Gerais',
            initials: 'MG'
        },
        {
            fu: 'Pará',
          initials: 'PA'
        },
        {
            fu: 'Paraíba',
          initials: 'PB'
        },
        {
          fu: 'Paraná',
          initials: 'PR'
        },
        {
            fu: 'Pernambuco',
            initials: 'PE'
        },
        {
          fu: 'Piauí',
          initials: 'PI'
        },
        {
            fu: 'Rio de Janeiro',
            initials: 'RJ'
        },
        {
            fu: 'Rio Grande do Norte',
            initials: 'RN'
        },
        {
            fu: 'Rio Grande do Sul',
            initials: 'RS'
        },
        {
            fu: 'Rondônia',
            initials: 'RO'
        },
        {
          fu: 'Roraima',
          initials: 'RR'
        },
        {
            fu: 'Santa Catarina',
            initials: 'SC'
        },
        {
          fu: 'São Paulo',
          initials: 'SP'
        },
        {
          fu: 'Sergipe',
          initials: 'SE'
        },
        {
            fu: 'Tocantins',
            initials: 'TO'
        },
        {
            fu: 'Distrito Federal',
          initials: 'DF'
        }
    ]
    
    const [fusSelected, setFusSelected] = useState([]);
    async function getCourses() {
        await axios
        .get(`${LOCAL_BACKEND_URL}/courses`, {
            headers: { Authorization: `bearer ${localStorage.getItem('token')}` },
        })
        .then((res) => setAllCourses(res.data.courses))
        .catch((err) => console.log(err.response.data));
    }
    
    useEffect(() => {
        getNotices();
        getCourses();
    }, []);

    async function getNotices() {
        axios.get(`${LOCAL_BACKEND_URL}/activenotices`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setNumberOfNotices(res.data.notices.length);
                const temporaryNotices = [];
                res.data.notices.forEach(notice => {
                    temporaryNotices.push(notice);
                });
                setNotices(temporaryNotices);
            })
            .catch(err => console.log(err));
    }

    async function convert(e) {
        const base64 = await convertToBase64(e);

        console.log(base64);
        setBase64(base64)
    }

    const toggle = () => {
        if(edit) setEdit([]);
        setNoticeModalIsOpen(!noticeModalIsOpen);
    }
    function handleSubmit() {
        if(noticeModalIsOpen) sendNotice();
        setNoticeModalIsOpen(!noticeModalIsOpen);
        setEdit([]);
        console.log('Passei aqui')
    }

    function handleEditSubmit(id) {
        if(noticeModalIsOpen) updateNotice(id);
        setNoticeModalIsOpen(!noticeModalIsOpen);
        setEdit([]);
        getNotices();
    }
    async function updateNotice(id) {
        const coursesFiltered = allCourses.filter((item, index) => courses.includes(item.title) )
        let references = [];
        console.log(coursesFiltered)
        for (let index = 0; index < coursesFiltered.length; index++) {
            references.push(coursesFiltered[index].courseId)
            
        }
        await axios.patch(`${LOCAL_BACKEND_URL}/notice/${id}`, {
            text: noticeBody,
            image: base64,
            title: noticeTitle,
            courses: references,
            fu: fusSelected,
            url,
            startDate: noticeDateStart.replace(noticeDateStart.slice(-5, -3), `${Number(noticeDateStart.slice(-5, -3)) +3}`),
            finishDate: noticeDateEnd.replace(noticeDateEnd.slice(-5, -3), `${Number(noticeDateEnd.slice(-5, -3)) +3}`)

        }, {
            headers: {'Authorization': `bearer ${localStorage.getItem('token')}`}
        })
            .then(res => toast.success('Recado salvo!'))
            .catch(err =>toast.error(err.response.data))
        console.log(id)
    }

    function handleCourses(course) {
        setCourses([...courses,course])
    }

    function handleFus(fu) {
        setFusSelected([...fusSelected,fu])
    }

    async function sendNotice() {
        const coursesFiltered = allCourses.filter((item, index) => courses.includes(item.title) )
        let references = [];
        console.log(coursesFiltered)
        for (let index = 0; index < coursesFiltered.length; index++) {
            references.push(coursesFiltered[index].courseId)
            
        }
        axios.post(`${LOCAL_BACKEND_URL}/notice`, {
            text: noticeBody,
            image: base64,
            title: noticeTitle,
            courses: references,
            fu: fusSelected,
            url,
            startDate: noticeDateStart.replace(noticeDateStart.slice(-5, -3), `${Number(noticeDateStart.slice(-5, -3)) +3}`),
            finishDate: noticeDateEnd

        }, {
            headers: {'Authorization': `bearer ${localStorage.getItem('token')}`}
        })
            .then(res => toast.success('Recado criado!'))
            .catch(err => toast.error('Não foi possível criar esse recado.'))
    }
    console.log('notices', notices)
    return (
            <div className="card" style={cardStyle}>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        {edit.length === 0 ? <h5 className="text-uppercase">{noticeModalIsOpen ? 'Cadastrar Recado' : 'Recados'}</h5> : <h5 className="text-uppercase">Editar Recado</h5>}
                    </div>
                    {
                        localStorage.getItem('isAdmin') && !noticeModalIsOpen && (
                            <div className="btn btn-primary px-5 py-2" onClick={toggle}>
                                Criar Recado
                            </div>
                        )
                    }
                </div>
                <div className="card-body">
                    <div>
                        {
                            notices.length >= numberOfNotices && noticeModalIsOpen === false ? 
                            notices.map(notice => (
                                <MessageRectangle 
                                    notice={notice} 
                                    noticeText={notice.text} 
                                    noticeDate={new Date(notice.startDate._seconds)} 
                                    setEdit={setEdit}
                                    setNoticeModalIsOpen={setNoticeModalIsOpen}
                                />
                            ))
                            :
                            ''
                        }
                    </div>
                     {
                        noticeModalIsOpen && edit.length === 0 && (
                            <div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeTitle" className="mt-3">
                                        Titulo:
                                        <input type="text" name="noticeTitle" className="form-control form-control-lg" onChange={(e) => setNoticeTitle(e.target.value)}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    Conteúdo:
                                    <textarea  name="noticeContent" className="form-control form-control-lg" onChange={(e) => setNoticeBody(e.target.value)}/>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Imagem:
                                        <input type="file" name="noticeImage" className="form-control form-control-sm" onChange={(e) => {
                                            convert(e)
                                        }}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="url" className="mt-3">
                                        Url:
                                        <input type="text" name="url" className="form-control form-control-lg" onChange={(e) => setUrl(e.target.value)}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Inicio:
                                        <input type="datetime-local" name="noticeImage" className="form-control form-control-sm" onChange={(e) => setNoticeDateStart(e.target.value.replaceAll('-', '/').replace('T', ' '))}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Fim:
                                        <input type="datetime-local" name="noticeImage" className="form-control form-control-sm" onChange={(e) => setNoticeDateEnd(e.target.value.replaceAll('-', '/').replace('T', ' '))} />
                                    </label>
                                </div>
                                <div className="mt-3 form-group">
                                    <select onChange={(e) => handleCourses(e.target.value)} className="w-75 m-auto form-control form-control-lg">
                                        <option selected disabled>Cursos</option>
                                        {allCourses.map(course => (
                                            <option key={course._id} value={course.title}>{course.title}</option>
                                        ))}
                                    </select>
                                    <div className="m-auto w-75">
                                        {courses.map((course, index) => (
                                            <div className="mt-3 d-flex justify-content-between">{course}{() => console.log('oi aqui', course)}<button className="btn btn-danger" onClick={() => setCourses(courses.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-3 form-group">
                                    <select onChange={(e) => handleFus(e.target.value)} className="w-75 m-auto form-control form-control-lg">
                                        <option selected disabled>Estados</option>
                                        {fus.map(fu => (
                                            <option key={fu.id} value={fu.initials}>{fu.initials}</option>
                                        ))}
                                    </select>
                                    <div className="m-auto w-75">
                                        {fusSelected.map((fu, index) => (
                                            <div className="mt-3 d-flex justify-content-between" key={fu}>{fu}<button className="btn btn-danger" onClick={() => setFusSelected(fusSelected.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) 
                    }
                    {
                        noticeModalIsOpen && edit.length > 0 && (
                            <div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeTitle" className="mt-3">
                                        Titulo:
                                        <input type="text" name="noticeTitle" value={noticeTitle} className="form-control form-control-lg" onChange={(e) => setNoticeTitle(e.target.value)}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    Conteúdo:
                                    <textarea  name="noticeContent" className="form-control form-control-lg" value={noticeBody} onChange={(e) => setNoticeBody(e.target.value)}/>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Imagem:
                                        <input type="file" name="noticeImage" className="form-control form-control-sm" onChange={(e) => {
                                            convert(e)
                                        }}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="url" className="mt-3">
                                        Url:
                                        <input type="text" name="url" className="form-control form-control-lg" value={url} onChange={(e) => setUrl(e.target.value)}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Inicio:
                                        <input type="datetime-local" name="noticeImage" className="form-control form-control-sm"  onChange={(e) => setNoticeDateStart(e.target.value.replaceAll('-', '/').replace('T', ' '))}/>
                                    </label>
                                </div>
                                <div className="w-75 m-auto form-group">
                                    <label for="noticeImage" className="mt-3">
                                        Fim:
                                        <input type="datetime-local" name="noticeImage" className="form-control form-control-sm" onChange={(e) => setNoticeDateEnd(e.target.value.replaceAll('-', '/').replace('T', ' '))} />
                                    </label>
                                </div>
                                <div className="mt-3 form-group">
                                    <select onChange={(e) => handleCourses(e.target.value)} className="w-75 m-auto form-control form-control-lg">
                                        <option selected disabled>Cursos</option>
                                        {allCourses.map(course => (
                                            <option key={course._id} value={course.title}>{course.title}</option>
                                        ))}
                                    </select>
                                    <div className="m-auto w-75">
                                        {courses.map((course, index) => (
                                            <div className="mt-3 d-flex justify-content-between">{course}{() => console.log('oi aqui', course)}<button className="h6" onClick={() => setCourses(courses.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-3 form-group">
                                    <select onChange={(e) => handleFus(e.target.value)} className="w-75 m-auto form-control form-control-lg">
                                        <option selected disabled>Estados</option>
                                        {fus.map(fu => (
                                            <option key={fu.id} value={fu.initials}>{fu.initials}</option>
                                        ))}
                                    </select>
                                    <div className="m-auto w-75">
                                        {fusSelected.map((fu, index) => (
                                            <div className="mt-3 d-flex justify-content-between" key={fu}>{fu}<button className="h6" onClick={() => setFusSelected(fusSelected.filter((_, i) => i !== index ))}><i className="fa fa-trash"></i></button></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {localStorage.getItem('isAdmin') && noticeModalIsOpen && (
                    <div className="text-center mb-3">
                        <div className="btn btn-primary px-5 py-2" onClick={edit.length === 0 ? handleSubmit : () => handleEditSubmit(edit[0].noticeId)}>
                            Salvar
                        </div>
                        <div className="btn btn-danger ml-2 px-5 py-2" onClick={toggle}>
                            Cancelar
                        </div>
                    </div>
                )}
            </div>
    );
}

const MessageRectangle = (props) => {
    const [modalConfirmationIsOpen,setModalConfirmationIsOpen] = useState(false);
    const createdAt = new Date(props.notice.createdAt._seconds * 1000).toLocaleDateString('pt-BR');
    const notice = props.notice

    const toggle = () => {
        setModalConfirmationIsOpen(!modalConfirmationIsOpen);
    }

    const toggleEdit = () => {
        props.setEdit([notice])
        props.setNoticeModalIsOpen(true);
    }

    async function handleDeleteNotice(id) {
        await axios.delete(`${LOCAL_BACKEND_URL}/notice/${id}`, {
                headers: {'Authorization': `bearer ${localStorage.getItem('token')}`}
            })
            .then(res =>{
                console.log(res)
                if (res.status === 200) setModalConfirmationIsOpen(!modalConfirmationIsOpen)
            })
            .catch(err => console.log(err.response.data))
    }
    return(
        <Fragment>
            <div style={messageBoxStyle}>
                <div className="d-flex flex-col justify-content-between w-100 p-3">
                    <div>
                        <h4>{props.notice.title}</h4>
                        <p>{props.notice.text}</p>
                        <a href={props.notice.url}>Saiba mais</a>
                        <div>
                            <span>Criado em: {createdAt}</span>
                        </div>
                    </div>
                    {localStorage.getItem('isAdmin') && <div className="">
                        <div onClick={toggleEdit}>
                            <div className="btn btn-sm btn-warning">
                                <div className="icon icon-pencil"></div>
                            </div>
                        </div>
                        <div onClick={() => setModalConfirmationIsOpen(!modalConfirmationIsOpen)}>
                            <div className="btn mt-3 btn-sm btn-danger">
                                <div className="icon icon-trash"></div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <Modal isOpen={modalConfirmationIsOpen} className="card">
                <ModalHeader className="card">Confirmar ação</ModalHeader>
                <ModalBody>
                    <h5>Você tem certeza que deseja remover o recado abaixo?</h5>
                    <div className="mt-4 mb-3 mw-200 d-flex justify-content-start align-items-center">
                        <div>
                            <div className="d-flex flex-col align-items-center">
                                Titulo: <h5 className="ml-1">{props.notice.title}</h5>
                            </div>
                            <p>ID: {props.notice.noticeId}</p>
                            <p>Data: {createdAt}</p>
                        </div>
                    </div>
                    <p className="mt-4">
                        <span style={{ color: "red", fontWeight: "bold", }}>Atenção</span> essa ação não poderá ser revertida.
                    </p>
                </ModalBody>
                <ModalFooter>
                    {<Button color="primary" onClick={toggle}>Cancelar</Button>}
                    <Button color="danger" onClick={() => handleDeleteNotice(props.notice.noticeId)}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}




export default NoticesComponent;

const cardStyle = {
    position: 'relative',
    height: '95%',
    overflow: 'auto'
}

const messageBoxStyle = {
    width: '100%',
    padding: '1vh',
    marginBottom: '2vh',
    backgroundColor: 'rgba(174, 184, 174, 0.2)',
    color: 'black',
    borderRadius: '1.2em',
    display: 'flex',
    flexDirection: 'row'
}

const dateParagraph = {
    textAlign: 'right'
}
