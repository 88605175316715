import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import seven from "../../assets/images/user/7.jpg";
import { DollarSign, User } from "react-feather";
import {
  CreateClients,
  CPF,
  Identification,
  UpdateProfile,
  EmailAddress,
  PhoneNumber,
  CancelUpdateProfile,
  Name,
  RetypePassword,
  Password,
  AccessLevel,
  OracleId,
  CheckoutSell,
  Carrinho,
  curso01,
  InfoSell,
  NameFull,
  CpfCheckout,
  EmailCheckout,
  TelCheckout,
  BirthCheckout,
  RgCheckout,
  CnhCheckout,
  CategoryCheckout,
  Renach,
  PayCheckout,
  EndSell,
  Contrato1,
  Contrato2,
  Pix,
  CreditCardOption,
  BoletoB,
  ButtonWhats,
  BirthDate,
  ContratoHiperLink,
  Contrato1final,
  Contrato1T,
  Contrato2T,
  ClientCPFNotFoundCheckout,
  ClientsCPFFound,
  Email,
} from "../../constant";
import "./checkout.css";
import PaymentForm from "./CardSell";
import { mask, mask as masker, unMask } from "remask";
import Boleto2 from "./Boleto2";
import { Link, NavLink } from "react-router-dom";
import Contrato from "./contrato";
import CheckoutBody from "./checkoutBody";
import axios from "axios";

import { LOCAL_BACKEND_URL } from "../../data/urls";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cards from "react-credit-cards";

import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3";
import ibreptranlogo from "../../assets/images/ibreptran/logoibreptran.png";
import downloadContent from "../../services/downLoadContent";

export default function CheckoutCarrinhoTeste({ history }) {
  const [cpf, setCpf] = useState("");
  const [cnh, setCnh] = useState("");
  const [renach, setRenach] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientBirth, setClientBirth] = useState("");
  const [clientRg, setClientRg] = useState("");
  const [clientCnh, setClientCnh] = useState("");
  const [clientCnhCategory, setClientCnhCategory] = useState("");
  const [clientRenach, setClientRenach] = useState("");
  const [clients, setClients] = useState([]);
  const [clientWarningVisibility, setClientWarningVisibility] =
    useState("none");
  const [clientFormVisibility, setClientFormVisibility] = useState("none");
  const [clientWarningMessage, setClientWarningMessage] = useState("");
  const [coursesFromDb, setCoursesFromDb] = useState([]);

  const [contractCheckboxIsChecked, setContractCheckboxIsChecked] =
    useState(false);
  const [ticketLinkContainer, setTicketLinkContainer] = useState(false);
  const [ticketLinkURL, setTicketLinkURL] = useState("");

  const [showContract, setShowContract] = useState(true);
  const [contractBody, setContractBody] = useState("");
  const [contractId, setContractId] = useState("");

  const [installmentsToShow, setInstallmentsToShow] = useState(0);
  const [installmentSelected, setInstallmentSelected] = useState(1);

  let coursesTeste = [];
  const [cvc, setCvc] = useState("");
  const [gender, setGender] = useState("");

  const [idCard, setIdCard] = useState("");
  const idCardPattern = ["9.999.999", "99.999.999", "99.999.999-9"];
  const idCardMask = (idCardValue) => {
    setIdCard(setGenericMask(idCardValue, idCardPattern));
  };

  const [bornDate, setBornDate] = useState("");

  const [email, setEmail] = useState("");
  //Numero de Telefone Mask
  const [phoneNumber, setPhoneNumber] = useState("");
  const phonePattern = ["(99) 9999-9999", "(99) 99999-9999"];
  const phoneMask = (phoneValue) => {
    setPhoneNumber(setGenericMask(phoneValue, phonePattern));
  };

  const setGenericMask = (masked, maskPattern) => {
    // return cpf.format(cpfValue);
    masked = unMask(masked);
    return mask(masked, maskPattern);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Payment
  const [fu, setFu] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(Number);

  const query = new URLSearchParams(document.location.search);
  //Object with id
  const productId = query.entries();
  let productsId = [];
  let stateId = [];
  for (let i of productId) {
    if (i[0] === "fu") {
      stateId.push(i[1]);
    }
    if (i[0] === "id") productsId.push(Number(i[1]));
  }

  async function getCoursesFromDb() {
    for (const item of productsId) {
      await axios
        .get(`${LOCAL_BACKEND_URL}/checkout/${item}/${stateId[0]}`, {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((resp) => {
          coursesTeste.push(resp.data.course);
          setCoursesFromDb([...coursesTeste]);
          if (resp.data.course.maxInstallments < installmentsToShow) return;
          setInstallmentsToShow(resp.data.course.maxInstallments);
        })
        .catch((err) => console.log("error", err.response.data));
    }
  }
  console.log("installmentsToShow", installmentsToShow);
  useEffect(() => {
    getCoursesFromDb();
    getContract();
    setFu(stateId[0]);
  }, []);
  const total = coursesFromDb.reduce(
    (preVal, currentVal) => (preVal += parseFloat(currentVal.price.value)),
    0
  );
  const totalFormated = parseFloat(
    Number(total / 100).toFixed(2)
  ).toLocaleString("pt-BR", {
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  const [creditPayment, setCreditPayment] = useState("none");
  const [boletoPayment, setBoletoPayment] = useState("none");

  const paymentVisibility = (e) => {
    setCreditPayment("none");
    setBoletoPayment("none");

    if (e === "Boleto") {
      setBoletoPayment("block");
      setPaymentMethod(3);
    } else if (e === "CreditCard") {
      setCreditPayment("block");
      setPaymentMethod(2);
    }
  };

  const [tel, setTel] = useState("");
  const TelPatterns = ["(99) 9999-9999", "(99) 9 9999-9999"];
  const setTelValue = (telValue) => {
    setTel(telValue);
  };

  const cpfPatterns = ["999.999.999-99", "99.999.999/9999-99"];
  const setCpfValue = (cpfValue) => {
    setCpf(cpfValue);
  };
  const maskGeneric = (maskValue, patterns) => {
    maskValue = unMask(maskValue);
    return mask(maskValue, patterns);
  };

  const [couponCode, setCouponCode] = useState("");
  async function handleDiscountCoupom(e) {
    try {
      const newToken = executeRecaptcha();
      newToken.then((res) => {
        axios
          .post(`${LOCAL_BACKEND_URL}/couponverify`, {
            code: couponCode,
            courseReference: 20,
            fu,
            token: res,
          })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => console.log(err.response.data));
      });
    } catch (err) {
      setTimeout(() => {
        toast.error("Oppss... Parece que você é um robô.");
      }, 200);
    }
  }
  // Card info
  const [cardCvc, setCardCvc] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardFocused, setCardFocused] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  // Boleto info
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [complemento, setComplemento] = useState("");
  const [numero, setNumero] = useState("");

  async function sendSale(e) {
    e.preventDefault();

    if (!contractCheckboxIsChecked) {
      toast.error("Aceite os Termos de Contrato");
      return;
    }
    if (!fu) {
      toast.error("Selecione o Estado");
      return;
    }

    try {
      const newToken = executeRecaptcha();

      newToken.then((res) => {
        axios
          .post(
            `${LOCAL_BACKEND_URL}/checkout`,
            {
              courseReference: productsId,
              clientCpf: cpf,
              fu: fu,
              cnh: cnh,
              renach: renach,
              paymentMethod: paymentMethod,
              cardHolderName: cardName,
              cardNumber: Number(cardNumber),
              expirationMonth: cardExpiry.split("/")[0],
              expirationYear: cardExpiry.split("/")[1],
              securityCode: Number(cardCvc),
              installments: installmentSelected,
              zipCode: unMask(cep),
              street: rua,
              number: Number(numero),
              complement: complemento,
              district: bairro,
              city: "Florianópolis",
              country: "Brasil",
              name: clientName,
              email: email,
              phoneNumber: unMask(phoneNumber),
              idCard: unMask(idCard),
              bornDate: bornDate,
              gender,
              contractId: contractId,
              token: res,
            },
            {
              headers: {
                Authorization: `bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            if (paymentMethod === 3) {
              setTicketLinkContainer(true);
              setTicketLinkURL(resp.data.bankSlip);
            }
            setTimeout(() => {
              toast.success("Compra realizada com sucesso.");
            }, 200);
          })
          .catch((err) => {
            console.log(err.response.data);
            setTimeout(() => {
              toast.error(`${err.response.data.error}`);
            }, 200);
          });
      });
    } catch (err) {
      setTimeout(() => {
        toast.error("Erro no cadastro.");
      }, 200);
    }
  }

  async function getContract() {
    try {
      await axios
        .post(`${LOCAL_BACKEND_URL}/checkoutcontract`, {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log("res.data.contrac", res.data.contract);
          setContractBody(res.data.contract[0].body);
          setContractId(res.data.contract[0].contractId);
        })
        .catch((err) => console.log(err.data.response.error));
    } catch (err) {
      setTimeout(() => {
        toast.error("Erro na busca do contrato.");
        console.log(err);
      }, 200);
    }
  }
  const DownloadContract = () => (
    <div
      onClick={() => downloadContent(contractBody)}
      className="btn btn-info mt-3"
    >
      Baixar Contrato
    </div>
  );

  return (
    <>
      <div className="allPage">
        <div className="container">
          <form className="card">
            <header>
              <img
                src={ibreptranlogo}
                class="col-sm-6"
                className="logo"
                alt="logo-icetran"
              />
              <h4 className="carrinho">{CheckoutSell}</h4>
            </header>
            <div className="checkoutBody">
              <div className="container-fluid">
                {/* <!-- Force next columns to break to new line --> */}
                <div className="checkoutTop">
                  <h3 className="row justify-content-between ml-3 mr-5 mt-4 mb-4">
                    {" "}
                    {Carrinho}
                  </h3>
                  {coursesFromDb.map((product) =>
                    product ? (
                      <div className="total" key={product.id}>
                        <label className="form-label mt-4 mb-4 col-lg-10">
                          {!productId
                            ? "Sem curso, volte a pagina inicial"
                            : product.name}
                        </label>
                        <label className="form-label mt-4 mb-10 col-lg-2">
                          {/*product.value*/}R${" "}
                          {parseFloat(product.price.value / 100).toLocaleString(
                            "pt-BR",
                            {
                              currency: `${product.price.currencyCode}`,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </label>
                      </div>
                    ) : (
                      false
                    )
                  )}
                  <div className="preco">
                    <label className="form-label mt-4 mb-10 col-lg-2">
                      <span style={{ float: "left" }}>Total</span>
                    </label>
                    <label className="form-label mt-4 mb-10 col-lg-10">
                      <span style={{ float: "right" }}>R$ {totalFormated}</span>
                    </label>
                  </div>
                </div>
                <div className="cupom">
                  <div className="row">
                    <input
                      className="form-control col-md-10"
                      type="text"
                      placeholder="Cupom de desconto"
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button
                      type="button"
                      class="buttonCupom col-md-2"
                      onClick={handleDiscountCoupom}
                    >
                      Aplicar
                    </button>
                    <div className="edit-profile w-100">
                      <div class="hr" />
                      <h4 className="row justify-content-between ml-3 mr-5">
                        {" "}
                        {InfoSell}
                      </h4>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">Nome*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">{CpfCheckout}</label>
                          <input
                            className="form-control"
                            type="text"
                            value={cpf}
                            onChange={(e) => setCpfValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">Email*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">Telefone*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => phoneMask(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">CNH*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={cnh}
                            onChange={(e) => setCnh(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">Renach*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={renach}
                            onChange={(e) => setRenach(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">RG*</label>
                          <input
                            className="form-control"
                            type="text"
                            value={idCard}
                            onChange={(e) => setIdCard(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">
                            Data de nascimento
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            value={bornDate}
                            onChange={(e) => {
                              console.log(e.target.value)
                              setBornDate(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col" style={{ display: "flex" }}>
                        <div className="form-group row w-100 ml-3 mr-3">
                          <label className="form-label">Gênero</label>
                          <select
                            className="form-control digits"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value={null}>
                              {"Escolha um genêro..."}
                            </option>
                            <option value={"m"}>{"Masculino"}</option>
                            <option value={"f"}>{"Feminino"}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col"></div>
                      <div
                        className="card-body col-md-12"
                        style={{ display: clientWarningVisibility }}
                      >
                        <div className="row">{clientWarningMessage}</div>
                      </div>
                      <div style={{ display: clientFormVisibility }}>
                        <CheckoutBody
                          telNumber={tel}
                          telMask={setTelValue}
                          BirthCheckout={BirthCheckout}
                          setClientName={setClientName}
                          setClientEmail={setClientEmail}
                          setClientBirth={setClientBirth}
                          setClientRg={setClientRg}
                          setClientCnh={setClientCnh}
                          setClientCnhCategory={setClientCnhCategory}
                          setClientRenach={setClientRenach}
                          setGender={setGender}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hr" />
                <h4 className="row justify-content-between ml-3 mr-5">
                  {" "}
                  {PayCheckout}
                </h4>
                <div className="row ml-3 mr-5">
                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="boleto"
                      name="payWay"
                      value="Boleto"
                      onChange={(e) => paymentVisibility(e.target.value)}
                    />
                    <label for="Boleto Bancário">{BoletoB}</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      id="payWay"
                      name="payWay"
                      value="CreditCard"
                      onChange={(e) => paymentVisibility(e.target.value)}
                    />
                    <label for="Cartão de Crédito">{CreditCardOption}</label>
                  </div>
                  <div style={{ display: creditPayment }}>
                    <PaymentForm
                      setCardNumber={setCardNumber}
                      setCardName={setCardName}
                      setCardFocused={setCardFocused}
                      setCardExpiry={setCardExpiry}
                      setCardCvc={setCardCvc}
                      setEstado={setEstado}
                      setCidade={setCidade}
                      setBairro={setBairro}
                      setRua={setRua}
                      setCep={setCep}
                      setComplemento={setComplemento}
                      setNumero={setNumero}
                      estado={estado}
                      cidade={cidade}
                      bairro={bairro}
                      rua={rua}
                      cep={cep}
                      cardExpiry={cardExpiry}
                      installments={installmentsToShow}
                      total={Number(total / 100)}
                      setInstallmentSelected={setInstallmentSelected}
                      installmentSelected={installmentSelected}
                    >
                      <div className="col-md-6">
                        <Cards
                          cvc={cardCvc}
                          expiry={cardExpiry}
                          focused={cardFocused}
                          name={cardName}
                          number={cardNumber}
                          placeholders={{ name: "Seu Nome Aqui" }}
                        />
                      </div>
                    </PaymentForm>
                  </div>
                  <div style={{ display: boletoPayment }}>
                    <Boleto2
                      setEstado={setEstado}
                      setCidade={setCidade}
                      setBairro={setBairro}
                      setRua={setRua}
                      setCep={setCep}
                      setComplemento={setComplemento}
                      setNumero={setNumero}
                      estado={estado}
                      cidade={cidade}
                      bairro={bairro}
                      rua={rua}
                      cep={cep}
                    />
                  </div>
                </div>

                <div class="hr" />
                <div class="col-lg-12">
                  <div>
                    <input
                      type="checkbox"
                      id="contrato1"
                      name="contrato1"
                      value="contrato2"
                      className="row col-md-1"
                      onChange={(e) =>
                        setContractCheckboxIsChecked(e.target.checked)
                      }
                    />
                    <text for="contrato1" class="col-md-12">
                      <h5>LI E CONCORDO COM OS TERMOS DO CONTRATO</h5>
                    </text>
                  </div>
                  <div className="text-right">
                    <DownloadContract />
                  </div>
                </div>
              </div>
              <div class="hr" />
              <div className="text-center">
                {!ticketLinkContainer ? (
                  <div
                    className="btn btn-primary px-4 py-2"
                    onClick={(e) => sendSale(e)}
                  >
                    <span className="h4 uppercase">{EndSell}</span>
                  </div>
                ) : (
                  <div className="text-center">
                    <div>Acesse o Boleto:</div>
                    <div className="btn btn-info px-4 py-2">
                      <a href={ticketLinkURL} className="h4 uppercase">
                        BOLETO
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <footer class="row center-block text-center">
          <div class="col-md-6">Atendimento comercial para todo o Brasil.</div>
          <div class="col-md-6" style={{ color: "white" }}>
            © IBREPTRAN
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=55###########"
            target="_blank" rel="noreferrer"
          >
            <img
              class="whatsapp"
              src="https://assets.boxloja.io/shop/img/whatsapp.png"
            />
          </a>
        </footer>
        <ToastContainer />
      </div>
    </>
  );
}

const cpfIsRegistered = (formattedCpf, clients) => {
  const unformattedCpf = formattedCpf.split(".").join("").split("-").join("");
  for (let i = 0; i < clients.length; i++) {
    if (clients[i].clientCpf === unformattedCpf) {
      return true;
    }
  }
  return false;
};
