import React, { Fragment, useEffect, useState } from 'react';
import { DollarSign, Tag, ShoppingBag, Sun } from 'react-feather';
import CountUp from 'react-countup';
import ChartistGraph from 'react-chartist';
import { chart1, chartsmall } from '../../../data/default';
import configDB from '../../../data/customizer/config';
import { TotalVisits, TotalSale, TotalValue, TotalTax, TotalEarning, ProductionValuation, TotalSells, TotalCoursesSell, Last12months, TotalMoney, TotalCourses, TicketMedio, TotalMoneySell, AllSells } from '../../../constant'
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import { AiFillSignal } from 'react-icons/ai';

import { requestAuthConfig } from '../../../utils/utilTools';

import DataTable from 'react-data-table-component';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';

import NoticesComponent from './noticesComponent';


const primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;


const EventChartsChange = React.memo(({ history }) => {

    const [jan, setJan] = useState(0);
    const [feb, setFeb] = useState(0);
    const [march, setMarch] = useState(0);
    const [april, setApril] = useState(0);
    const [may, setMay] = useState(0);
    const [jun, setJun] = useState(0);
    const [jul, setJul] = useState(0);
    const [ago, setAgo] = useState(0);
    const [set, setSet] = useState(0);
    const [october, setOctober] = useState(0);
    const [nov, setNov] = useState(0);
    const [dec, setDec] = useState(0);

    const [faturamento, setFaturamento] = useState(0)
    const [salesNumber, setSalesNumber] = useState(0)

    const [responseData, setResponseData] = useState([]);
    const [data, setData]                 = useState([]);
    const [invoiceAndId, setInvoiceAndId] = useState({});

    // state variables used to implement pagination
    const [paginationCountArray, setPaginationCountArray] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPagination, setCurrentPagination] = useState(0);
    const [totalNumberOfInvoices, setTotalNumberOfInvoices] = useState(0);
    const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const [TotalSales, setTotalSales] = useState(0);
    const [TotalSalesNumber, setTotalSalesNumber] = useState(0);

    const SalesDate = new Date();
    const SalesYear = SalesDate.getFullYear();
    const SalesMonth = SalesDate.getMonth() + 1;

    useEffect(() => {
        if(localStorage.getItem('isAdmin')) {
            axios.get(`${LOCAL_BACKEND_URL}/sales/${SalesMonth}/${SalesYear}/3`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                setTotalSales(response.data.totalValue / 100)
                setTotalSalesNumber(response.data.salesNumber)
            })
            .catch(err => console.log(err))
        } else {
            axios.get(`${LOCAL_BACKEND_URL}/partnerneedstopay/${SalesMonth}/${SalesYear}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then(res => {
                    console.log(res);
                    setTotalSales(res.data.totalToPay / 100)
                })
                .catch(err => console.log(err))
        }

    }, [])


    useEffect(() => {

        axios.get(`${LOCAL_BACKEND_URL}/invoices?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}&status=${0}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                try {
                    setResponseData(response.data.invoices);
                    setTotalNumberOfInvoices(response.data.invoices.length);
                    setNumberOfPages(response.data.info.numPages);
                }
                catch (err) {
                    console.log(err);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });

        if(localStorage.getItem('isAdmin')) {
            axios.get(`${LOCAL_BACKEND_URL}/billing/${month}/${year}`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setSalesNumber(res.data.salesNumber)
                setFaturamento(res.data.totalBilling)

            })
            .catch((err) => {
                // console.log(err)
            });
        } else {
            axios.get(`${LOCAL_BACKEND_URL}/partnerbilling/${month}/${year}`, {
                headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    setSalesNumber(res.data.salesNumber)
                    setFaturamento(res.data.totalBilling)
    
                })
                .catch((err) => {
                    console.log(err.data.response)
                });
        }

        // GET request to retrieve all registered sales


        axios.get(`${LOCAL_BACKEND_URL}/sales/1/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setJan(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/2/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setFeb(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/3/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setMarch(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/4/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setApril(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/5/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setMay(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/6/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setJun(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/7/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setJul(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/8/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setAgo(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/9/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setSet(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/10/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setOctober(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/11/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setNov(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
        axios.get(`${LOCAL_BACKEND_URL}/sales/12/${SalesYear}/3`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setDec(Number(res.data.totalValue / 100))
            })
            .catch((err) => {
                // console.log(err)
            });
    }, []);

    useEffect(() => {
        if (responseData.length > 0 && numberOfPages > 0) {
            let carrierArray = [];
            const tempInvoicesAndIds = {};
            responseData.forEach(invoice => {
                carrierArray.push(invoice);
                carrierArray[carrierArray.length - 1].expirationDate = new Date(invoice.expirationDate._seconds * 1000).toLocaleDateString('pt-BR');
                carrierArray[carrierArray.length - 1].createdAt = new Date(invoice.createdAt._seconds * 1000).toLocaleDateString('pt-BR');
                tempInvoicesAndIds[invoice.invoiceId] = invoice;
            });
            setData(carrierArray);
            setCurrentPaginationLength(carrierArray.length);
            setInvoiceAndId(tempInvoicesAndIds);

            // Creates an array containing the number of pages the pagination should have
            const tempCountArray = [];
            for (let i = 1; i <= numberOfPages; i++) {
                tempCountArray.push(i);
            }
            setPaginationCountArray(tempCountArray);

        }
    }, [responseData, numberOfPages]);

    const changeTableData = (dataIndex) => {
        setCurrentPagination(parseInt(dataIndex) - 1);
        axios.get(`${LOCAL_BACKEND_URL}/invoices?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then((response) => {
            setResponseData(response.data.invoices);
            setTotalNumberOfInvoices(response.data.invoices.length);
            setNumberOfPages(response.data.info.numPages);
          })
          .catch((error) => {
            console.log(error.message);
          });
      }

      const columns = [
        {
            name: 'Data',
            selector: 'createdAt',
            sortable: true,
            center: true,
            cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.createdAt}</div>,
        },
        {
            name: 'Data de vencimento',
            selector: 'expirationDate',
            sortable: true,
            center: true,
            cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.expirationDate}</div>,
        },

        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            center: true,
            cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>{
                (() => {
                    if (row.status === 0) {
                        return <div className="btn btn-warning pl-3 pr-3 pt-1 pb-1" role="alert" >Pendente</div>
                    } else if (row.status === 1) {
                        return <div className="btn btn-success pl-3 pr-3 pt-1 pb-1" role="alert">Pago</div>
                    } else if (row.status === 2) {
                        return <div className="btn btn-danger pl-3 pr-3 pt-1 pb-1" role="alert" >Cancelada</div>
                    } else if (row.status === 3) {
                        return <div className="btn btn-warning pl-3 pr-3 pt-1 pb-1" role="alert" >Reembolsada</div>
                    }
                })()
            }</div>
        },
        /*{
            name: 'Concluir/Cancelar',
            selector: <div>teste</div>,
            sortable: true,
            center: true,
            cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>{
                (() => {
                    if (row.status === '2') {
                        return <button className="btn btn-danger" onClick={() => history.push(
                            {
                                pathname: `${process.env.PUBLIC_URL}/edit/fatura`,
                                invoiceId: row.invoiceId,
                                invoiceInfo: { ...invoiceAndId[row.invoiceId] }
                            }
                        )} className="btn btn-success">Concluída</button>
                    } else if (row.status !== '2') {
                        return <button className="btn btn-danger" onClick={() => history.push(
                            {
                                pathname: `${process.env.PUBLIC_URL}/edit/fatura`,
                                invoiceId: row.invoiceId,
                                invoiceInfo: { ...invoiceAndId[row.invoiceId] }
                            }
                        )} className="btn btn-danger">Cancelar</button>
                    } else if (row.status === 3) {
                        return <div className="btn btn-warning" role="alert" >Fim</div>
                    }
                })()
            }</div>,
        },*/
        {
            name: 'Ver Vendas',
            selector: 'see',
            sortable: false,
            center: true,
            cell: row => <button className="btn btn-info" style={{ overflow: 'inherit', textAlign: 'center' }} onClick={() => history.push(
                {
                    pathname: `${process.env.PUBLIC_URL}/fatura/vendas`,
                    invoiceId: row.invoiceId,
                    invoiceInfo: { ...invoiceAndId[row.sales] },
                    invoice: row
                }
            )}>Ver</button>
        },
        {
            name: 'Gerar Boleto',
            sortable: false,
            center: true,
            cell: row => <button
                            className={ row.bankSlipUrl === 'Already empty' ? 'btn btn-danger disabled' : 'btn btn-danger' }
                            style={{ overflow: 'inherit', textAlign: 'center', pointerEvents: row.bankSlipUrl === 'Already empty' ?  'none' : '' }}
                            disabled={row.bankSlipUrl === 'Already empty'}
                            onClick={(() => {
                                return window.open(row.bankSlipUrl, '_blank') 
                            })}
                        >Gerar Boleto </button>
        },
    ];

    return (
        <Fragment >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body row" style={{ justifyContent: 'space-between' }}>
                        <div className="media-body px-5 ">
                            <h5 className="mt-0 mb-0 f-w-600" style={{ color: 'gold' }}>
                                R$ 
                                <span> <CountUp className="counter " end={TotalSales} /></span>
                            </h5>
                            <h6>{TotalMoneySell}</h6>
                        </div>
                        {
                            localStorage.getItem('isAdmin') 
                            ? 
                            <GiReceiveMoney 
                                className="col-md-2 flex px-4" 
                                size={60} 
                            />
                            :
                            <GiPayMoney 
                                className="col-md-2 flex px-4" 
                                size={60} 
                                color="red" 
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body row">
                        <div className="media-body px-5">
                            <h5 className="mt-0 mb-0 f-w-600">
                                <span>
                                    <CountUp className="counter mb-10" end={TotalSalesNumber} />
                                </span>
                            </h5>
                            <h6>{TotalCoursesSell}</h6>
                        </div>
                        <AiFillSignal className="col-md-2 flex px-4" size={60} />
                    </div>
                </div>
            </div>

            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">
                        <h5>{Last12months}</h5>
                    </div>
                    <div className="card-body">
                        <div className="show-value-top d-flex">
                            <div className="value-left d-inline-block">
                                <div className="square bg-primary d-inline-block"></div><span>{AllSells}</span>
                            </div>
                            {/* <div className="value-right d-inline-block">
                                <div className="square d-inline-block bg-secondary"></div><span>{TotalCourses}</span>
                            </div> */}
                        </div>
                        <div className="smooth-chart flot-chart-container">
                            <ChartistGraph key="1" className="ct-chart-line padding-left-8" data={{
                                labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
                                series: [
                                    [
                                        jan, 
                                        feb, 
                                        march, 
                                        may, 
                                        april, 
                                        jun, 
                                        jul, 
                                        ago, 
                                        set, 
                                        october, 
                                        nov, 
                                        dec, 
                                        0
                                    ],
                                ]
                            }} type={'Line'} listener={{
                                "draw": function (data) {
                                    if (data.type === 'line' || data.type === 'area') {
                                        data.element.animate({
                                            d: {
                                                begin: 2000 * data.index,
                                                dur: 2000,
                                                from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                                                to: data.path.clone().stringify(),
                                            }
                                        });
                                    }
                                }
                            }} options={chart1} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4" style={noticesComponentStyle}>
                <NoticesComponent />
            </div>

            {
                localStorage.getItem('isAdmin') === 'true' ?
                    <></>
                    :
                    <div className="col-xl-12 xl-100">
                        <div className="datatable-react form-group">
                            <DataTable
                                columns={columns}
                                data={data}
                                striped={true}
                                center={true}
                                noDataComponent="Não possui nenhum dado."
                            />
                            <div className="container d-flex justify-content-between mw-100 align-items-center">
                                <div className="mt-5">
                                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfInvoices}` : ''}
                                </div>
                                <nav aria-label="Page navigation example">
                                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                                        {
                                            currentPagination === 0 ?
                                            <PaginationItem disabled>
                                                <PaginationLink first href="#javascript" />
                                            </PaginationItem>
                                            :
                                            <PaginationItem>
                                                <PaginationLink
                                                first
                                                onClick={() => changeTableData((currentPagination + 1) - 1)}
                                                />
                                            </PaginationItem>
                                        }
                                        {
                                            paginationCountArray.map(item =>
                                            <PaginationItem>
                                                <PaginationLink onClick={() => changeTableData(item)}>
                                                {item}
                                                </PaginationLink>
                                            </PaginationItem>
                                            )
                                        }
                                        {
                                            currentPagination === numberOfPages - 1 ?
                                            <PaginationItem disabled>
                                                <PaginationLink last href="#javascript" />
                                            </PaginationItem>
                                            :
                                            <PaginationItem>
                                                <PaginationLink
                                                last
                                                onClick={() => changeTableData((currentPagination + 1) + 1)}
                                                />
                                            </PaginationItem>
                                        }
                                    </Pagination>
                                </nav>
                            </div>
                        </div>
                    </div>
            }

        </Fragment>
    );
});

const noticesComponentStyle = {
    width: '100%'
}

export default EventChartsChange;