import React, { useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { User } from 'react-feather';
import { CreateUser, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, Password, AccessLevel, OracleId, SellCreate, CPF, Course, UF, Identification, Ordem, Referencia, CourseEdit, Titulo, Status, Imagem } from '../../constant'

export default function CoursesEdit({ history }) {


    return (
        <>
            <Breadcrumb parent="Cursos" title="Editando Curso" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><User /> {CourseEdit}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                    <label className="form-label">{Imagem}</label>
                                            <img className="img-60 rounded-circle" alt="" src={seven} />
                                            <input className="form-control w-75" type="file" />
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Identification}*</label>
                                                <input className="form-control" type="number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Ordem}*</label>
                                                <input className="form-control" type="number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Referencia}</label>
                                                <input className="form-control" type="number" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Titulo}</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Status}</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        
                                        {/* <!-- Force next columns to break to new line --> */}
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/lista`)}>{CancelUpdateProfile}</button>
                                    <button type="submit"className="btn btn-primary ml-3">{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
