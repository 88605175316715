import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { New } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import { termosData } from '../../../data/termosData';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

const Termos = ({ history }) => {

  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [termosAndIds, setTermosAndIds] = useState([]);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/termos`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        console.log(response.data);
        setResponseData(response.data.userList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (responseData.length > 0) {
      let carrierArray = [];
      let termosAndIdsCarrier = {};
      responseData.forEach(user => {
        carrierArray.push(user.data);
        termosAndIdsCarrier[user.data.displayName] = user.id;
      });
      setTableData(carrierArray);
      setTermosAndIds({ ...termosAndIdsCarrier });
    }
  }, [responseData]);

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      cell: row => <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", }}>{row.id}</div>,
      center: true,
    },
    {
      name: 'Icon',
      selector: 'icon',
      cell: row => <img style={{ marginLeft: "4px", borderRadius: "50%", width: "50px", height: "50px", marginRight: "10px" }} alt="row.icon" src={row.icon} />,
      sortable: false,
      center: true,
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      center: true,
      grow: 3,
      cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.title}</div>,
    },
    {
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "60%" }}><li className="list-group-item-action"><button
        style={{ marginRight: 4 }}
        onClick={() => history.push(`${process.env.PUBLIC_URL}/edit/termo`)} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
        {/* <li className="list-group-item-action"><ButtonDeleteUser/></li> */}
      </div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {

  // }, []);

  return (
    <div>
      <Breadcrumb parent="Termos" title="Termos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Termos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/create/new-term`)} type="button">{New}</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <Typeahead
                    id="custom-typeahead"
                    multiple
                    options={[]}
                    placeholder="Digite para filtrar"
                  />
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={tableData}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Termos;
