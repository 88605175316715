import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { DollarSign, Tag, ShoppingBag, MessageCircle, MinusCircle, ThumbsUp, MessageSquare, Briefcase, MoreHorizontal, Send, Activity, Anchor, Compass, Cpu, Slack, Umbrella, Box, Book } from 'react-feather';
import { calcultionOptions, calcultionData } from '../../../data/default'
import ChartistGraph from 'react-chartist';
import EventCharts from './eventCharts';
import configDB from '../../../data/customizer/config';
import { New, NewSale, NewMessage, NewVisits, TotalProfit, AllCustomIncome, All, TotalInvestment, TotalReview, CustomerReview, Change, Online, MarshiKisteen, Dashboard, Ui, Xi, Message, Portfolio, NewUser, Month, Today, NickStone, Follow, WiltorNoice, NewReport, TotalFeedback, MilanoEsco, AnnaStrong, RecentNotification, Order, Download, Trash, ByKan, ByKaint, ByTailer, ByWaiter, ByComman, Calculation, TotalIncome, TotalLoss, Conversations, View, Media, Search, SellingUpdate, Shipping, Purchase, TotalSell, Feedback, ByCall, Activitys, MediumTicket, SellCfc, AllCfc, CfcProfit, AllSells, NewSell, Details, Detaills, Fulano, Ciclano, SellingCourses, DashboardCourses, Ronaldo, Joaozinho, LastestSells, Last12months, TotalCoursesSell, TotalSells, TotalMoney, TotalCourses, Taxista, Infratores, Reciclagem, Escolar, SellingCoursesCFC, DashboardCFC, Mes, SeisMeses, Ano, TresMeses, Estados, AllCourses, Course, Courses, CFC1, CFC2, CFC3, CFC4, CFC5, CFC6, DestaqueVendas, TodasVendas, Opcao, Estado, Curso, Cfc, EstadoGrafico } from '../../../constant'
import EventChartsChange from './eventChartschange';
import Chart from 'react-google-charts';
import DestaqueCfc from '../../base/lists/destaqueCfc';
import DestaqueCurso from '../../base/lists/destaqueCurso';
import DestaqueEstado from '../../base/lists/destaqueEstados';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import DataTable from 'react-data-table-component';
import LastSells from '../../base/lists/lastSales';
import { requestAuthConfig } from '../../../utils/utilTools';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import CompCarousel from '../../carousel';

var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;


const Default = ({ history }) => {


    const [responseData, setResponseData] = useState([]);
    const [saleAndId, setSaleAndId] = useState([]);
    const [refresh, setRefresh] = useState();
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [ufData, setUfData] = useState([]);

    const [clients, setClients] = useState([]);
    const [clientsAndIds, setClientsAndIds] = useState({});

    useEffect(() => {
        // GET request to retrieve all registered sales
        axios.get(`${LOCAL_BACKEND_URL}/sales`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                setResponseData([...response.data.sales]);
                console.log(response)
            })
            .catch((err) => {
                // console.log(err)
            });



        // GET request to retrieve all registered clients
        axios.get(`${LOCAL_BACKEND_URL}/allclients`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                setClients(response.data.clients);
            })
            .catch((err) => {
                // console.log(err)
            });
    }, []);

    useEffect(() => {
        if (responseData.length > 0) {
            console.log(responseData);
            let carrierArray = [];
            const tempSalesAndIds = {};
            responseData.forEach(sale => {
                carrierArray.push(sale);
                tempSalesAndIds[sale.saleId] = sale;
            });
            console.log(carrierArray);
            setData(carrierArray);
            setSaleAndId(tempSalesAndIds);

        }
    }, [responseData]);

    useEffect(() => {
        if (clients.length > 0) {
            console.log(clients);
            const tempClientsAndIds = {};
            clients.forEach(client => {
                tempClientsAndIds[client.clientId] = client.name;
            });
            setClientsAndIds(tempClientsAndIds);
            setName(tempClientsAndIds.name)
        }
    }, [clients]);

    useEffect(() => {
        console.log(clientsAndIds);
    }, [clientsAndIds]);



    const [cfcMode, setCfcMode] = useState('block')
    const [courseMode, setCourseMode] = useState('none')
    const [ufMode, setUfMode] = useState('none')

    const paymentVisibility = (e) => {
        setCfcMode('none')
        setCourseMode('none')
        setUfMode('none')


        if (e === 'Cfc') { setCfcMode('block') }
        else if (e === 'Courses') { setCourseMode('block') }
        else if (e === 'Estado') { setUfMode('block') }
        console.log(e)
    }

    function handleChange(e) {
        history.push(`/loja${e}`)
    }
    return (
        <Fragment>
            <div className='d-flex align-items-center justify-content-center'>
                <Breadcrumb parent="Home" title="Geral" />
                <div>
                    <select className='mr-3 p-3 border-0 text-uppercase' onChange={(e) => handleChange(e.target.value)}>
                        <option selected className=''>Administrar</option>
                        <option value={'/vendas/novaVenda'}>Cadastrar Venda</option>
                        {localStorage.getItem('isAdmin') && <option value={'/criar/parceiro'}>Cadastrar Parceiro</option>}
                        <option value={'/base/clientList'}>Matriculas</option>
                    </select>
                </div>
            </div>
            <CompCarousel />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-100">
                        <div className="row">

                            <EventChartsChange

                            />
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <LastSells />
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4><strong>{DestaqueVendas}</strong></h4>
                                <div className="pull-right right-header">
                                    <div className="onhover-dropdown">
                                        <button className="btn btn-primary btn-pill" type="button">{Opcao} <span className="pr-0"><i className="fa fa-angle-down"></i></span></button>
                                        <div className="onhover-show-div right-header-dropdown">
                                            {localStorage.getItem('isAdmin') ? <a className="d-block"
                                                href="#javascript"
                                                onClick={(e) => paymentVisibility('Cfc')} >{Cfc}</a> : null}
                                            <a className="d-block"
                                                href="#javascript"
                                                onClick={(e) => paymentVisibility('Estado')}
                                            >{EstadoGrafico}</a>
                                            <a className="d-block"
                                                href="#javascript"
                                                onClick={(e) => paymentVisibility('Courses')}>{Curso}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: cfcMode }}>
                                <DestaqueCfc />
                            </div>
                            <div style={{ display: courseMode }}>
                                <DestaqueCurso />
                            </div>
                            <div style={{ display: ufMode }}>
                                <DestaqueEstado />
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </Fragment >
    );
};

export default Default;