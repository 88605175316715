import React, { useState, useEffect } from 'react';
import { EditSale, Edit, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, NewPassword, AccessLevel, Salvar } from "../../constant";
import Breadcrumb from "../common/breadcrumb";
import { Icon, NewIcon, TermTitle, NewCustomTitle, EditTerms } from "../../constant";
import seven from '../../assets/images/user/7.jpg';


const EditTermo = ({ history }) => {

    // const [data,setData] = useState([])

    // useEffect(() => {
    //     axios.get(`${process.env.PUBLIC_URL}/api/user-edit-table.json`).then(res => setData(res.data))
    // },[])

    return (
        <>
            <Breadcrumb parent="Termos" title="Editar termos" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditTerms}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{TermTitle}</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{NewCustomTitle}</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{NewIcon}</label>
                                                <input className="form-control" type="email" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/ `)}>{CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" type="submit">{Salvar}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditTermo;