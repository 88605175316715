import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Breadcrumb, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { API, CancelUpdateProfile, Create, UpdateProfile } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import seven from '../../../assets/images/user/7.jpg';

import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Delete } from 'react-feather';

import { requestAuthConfig } from '../../../utils/utilTools';

// Imports for payment amount input mask
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

import convertToBase64 from '../../../services/convertToBase64';
import { toast } from 'react-toastify';

const CreateNewCourse = ({ history }) => {

    const submitButton = useRef(null);

    const [order, setOrder] = useState('');
    const [reference, setReference] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesId, setCategoriesId] = useState({});
    const [slug, setSlug] = useState('');
    const [pricePolicies, setPricePolicies] = useState([]);
    const [pricePoliciesInfo, setPricePoliciesInfo] = useState([]);
    // Actual price policy object
    const [pricePolicy, setPricePolicy] = useState({});
    const [submitBool, setSubmitBool] = useState(false);

    const [base64, setBase64] = useState('');
    const [photoURL, setPhotoURL] = useState(seven);
    const isTheImageURLValid = (url, result) => {
        if (result === 'success') {
            setPhotoURL(url);
        }
        else {
            setPhotoURL(seven);
        }
    }

    async function convert(e) {
        const base64 = await convertToBase64(e);
        setBase64(base64);
    }

    const [data, setData] = useState([]);
    const [categoryMap, setCategoryMap] = useState([]);

    useEffect(() => {
        if (pricePolicies.length > 0) {
            submitButton.current.focus();
        }
    }, [pricePolicies]);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/categories`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setCategories([...res.data.categories]);

            })
            .catch((err) => { console.log(err) })
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            let tempCategories = {};
            categories.forEach(category => {

                tempCategories[category.name] = category.categoryId;
            });
            setCategoriesId({ ...tempCategories });

        }
    }, [categories]);

    const deletePricePolicy = (index, state) => {
        const tempPricePolicies = [...pricePolicies];
        const anotherTempPolicies = [...pricePolicies];
        const tempPricePoliciesInfo = [...pricePoliciesInfo];

        const tempPricePolicy = {...pricePolicy};

        const filtered = tempPricePolicies.filter(policy => {
            return policy !== tempPricePolicies[index];
        });
        tempPricePoliciesInfo.splice(index, Number.MAX_VALUE);

        delete tempPricePolicy[state];
        
        setPricePolicies([...filtered, ...anotherTempPolicies.splice(index, Number.MAX_VALUE)]);
        setPricePoliciesInfo([...tempPricePoliciesInfo]);
        setPricePolicy(tempPricePolicy);
    }
    
    const handlePolicySubmit = (state, object) => {
        const tempPricePolicy = {...pricePolicy};
        tempPricePolicy[state] = {...object};
        setPricePolicy(tempPricePolicy);
    }

    const createPricePolicy = () => {
        const tempPricePolicies = [...pricePolicies];
        const tempPricePoliciesInfo = [...pricePoliciesInfo];
        tempPricePolicies.push(
            <PricePolicyForm
                number={tempPricePolicies.length + 1}
                deleteHandler={deletePricePolicy}
                submitControl={submitBool}
                handleSubmit={handlePolicySubmit}   
            />
        );
        tempPricePoliciesInfo.push({
            'state': '',
            'value': '',
            'partnerValue': '',
            'integrationUF': '',
            'active': true,
            'isOpen': true
        })
        setPricePolicies(tempPricePolicies);
        setPricePoliciesInfo(tempPricePoliciesInfo);
    }


    async function patchCourse(data, id) {
        await axios.patch(`${LOCAL_BACKEND_URL}/course/${id}`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            console.log(res);
            toast.success('Politica de Preço criada');
        })
        .catch(err => {
            console.log(err);
            toast.error('Não foi possivel criar Politica de Preço.')
        })
    }


    const submitHandler = (e) => {
        e.preventDefault();

        setSubmitBool(true);

        const submitObject = {
            'order': parseInt(order),
            'reference': parseInt(reference),
            'categoryId': categoriesId[category],
            'image': base64,
            'title': title,
            'pricePolicy': pricePolicy,
            'descriptionHtml': description,
            'description': description,
            'slug': slug,
            'imagePath': ' ',
            'imageURL': ' ',
            'requirements': requirements

        }

        axios.post(`${LOCAL_BACKEND_URL}/course`, submitObject, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                toast.success('Curso criado!')
                const courseId = res.data.id;
                patchCourse(submitObject, courseId);
                console.log(res);
                history.push(`${process.env.PUBLIC_URL}/base/cursos`);
            })
            .catch(err => {
                    toast.error('Não foi possível criar esse curso.')
                    console.log(err)
                }
            );
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();
    return (
        <div>
            <Breadcrumb parent="Cursos" title="Cadastrar Curso" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-4 mr-5">
                                    <h5>Novo Produto</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                    <img
                                        className="img-70 rounded-circle"
                                        alt=""
                                        src={photoURL}
                                        onError={() => setPhotoURL(seven)}
                                    />
                                    <input
                                        className="form-control w-75"
                                        type="file"
                                        placeholder="Insira a URL do avatar do curso"
                                        onChange={(e) => convert(e)}
                                    />
                                </div>
                                <FormGroup>
                                        <Label for="order">Título</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={title}
                                            placeholder="Título"
                                            onChange={e => setTitle(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O título do curso é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="order" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="order">Descrição</Label>
                                        <textarea
                                            className="form-control"
                                            name="descrição"
                                            id="descrição"
                                            value={description}
                                            placeholder="Descrição (HTML)"
                                            onChange={e => setDescription(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A descrição do curso é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="order" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="order">Requisitos do Curso</Label>
                                        <textarea
                                            className="form-control"
                                            name="descrição"
                                            id="descrição"
                                            value={requirements}
                                            placeholder="Requisitos (HTML)"
                                            onChange={e => setRequirements(e.target.value)}
                                            ref={
                                                register({
                                                    required: "Os requisitos do curso é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="order" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="order">Slug (URL do curso)</Label>
                                        <input
                                            className="form-control"
                                            type='text'
                                            name="descrição"
                                            id="descrição"
                                            value={slug}
                                            placeholder="Descrição"
                                            onChange={e => setSlug(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A descrição do curso é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="order" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="order">Order</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="order"
                                            id="order"
                                            placeholder="Usuário"
                                            onChange={e => setOrder(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A order do curso é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="order" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="reference">Referência</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="reference"
                                            id="reference"
                                            placeholder="Usuário"
                                            onChange={e => setReference(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A referência do curso é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="reference" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="categoryId" className="form-label">Categoria</Label>
                                        <select className='form-control' value={category} onChange={e => setCategory(e.target.value.trim())}>
                                            <option value="null">Selecione a Categoria</option>
                                            {
                                                categories.map(category =>
                                                <option>
                                                    {category.name}
                                                </option>)
                                            }
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                    <div className="card-header">
                                        <div className="row justify-content-between mr-3">
                                            <h5>Política de preço</h5>
                                        </div>
                                    </div>
                                        { pricePolicies }
                                        <button type="button" className="btn btn-primary ml-3" onClick={createPricePolicy}>Adicionar política</button>
                                    </FormGroup>

                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/cursos`)}>{CancelUpdateProfile}</button>
                                        <button ref={submitButton} type="submit" className="btn btn-primary ml-3" onClick={submitHandler}>{UpdateProfile}</button>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CreateNewCourse;

const PricePolicyForm = (props) => {

    const [value, setValue] = useState('');
    const [partnerValue, setPartnerValue] = useState('');
    const [integrationUF, setIntegrationUF] = useState('');
    const [active, setActive] = useState('0');
    const [isOpen, setIsOpen] = useState('0');

    // Mask for sale amount
    const valueMask = createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false
    });

    useEffect(() => {
        if (integrationUF !== '') {
            props.handleSubmit(integrationUF, {
                'value': (parseFloat(parseValueAmount(value)) * (10 ** 2)).toString(),
                'partnerValue': (parseFloat(parseValueAmount(partnerValue)) * (10 ** 2)).toString(),
                'integrationUF': integrationUF,
                'active': active === '0' ? true : false,
                'isOpen': isOpen === '0' ? true : false
            });
        }
    });

    return (
        <>
            <div className="card-header">
                <div className="row justify-content-between mr-3">
                    <h5>Política {props.number} &nbsp;&nbsp;&nbsp;&nbsp; <Button onClick={() => props.deleteHandler(props.number-1, integrationUF)}><Delete /></Button> </h5>
                </div>
            </div>
            <FormGroup>
                <label className="form-label">Estado</label>
                <select
                    className="form-control"
                    value={integrationUF}
                    onChange={(e) => {
                        setIntegrationUF(e.target.value);
                    }}
                >
                    <option value={null}>Selecione o Estado</option>
                    <option value="SP">São Paulo</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="PR">Paraná</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="DF">Distrito Federal</option>
                </select>
            </FormGroup>
            <FormGroup>
                <Label for="partnerValue">Valor do parceiro</Label>
                <MaskedInput
                    className="form-control"
                    value={partnerValue}
                    mask={valueMask}
                    placeholder="Valor do parceiro"
                    onChange={e => setPartnerValue(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="value">Valor</Label>
                <MaskedInput
                    className="form-control"
                    value={value}
                    mask={valueMask}
                    placeholder="Valor"
                    onChange={e => setValue(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label className="form-label">Ativo?</label>
                <select
                    className="form-control"
                    value={active}
                    onChange={(e) => {
                        setActive(e.target.value);
                    }}
                >
                    <option value={null}>Selecione uma opção</option>
                    <option value="0">Sim</option>
                    <option value="1">Não</option>
                </select>
            </FormGroup>
            <FormGroup>
                <label className="form-label">Aberto?</label>
                <select
                    className="form-control"
                    value={isOpen}
                    onChange={(e) => {
                        setIsOpen(e.target.value);
                    }}
                >
                    <option value={null}>Selecione uma opção</option>
                    <option value="0">Sim</option>
                    <option value="1">Não</option>
                </select>
            </FormGroup>
        </>
    );
}


export function testImage(url, callback, timeout) {
    timeout = timeout || 5000;
    var timedOut = false, timer;
    var img = new Image();
    img.onerror = img.onabort = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "error");
        }
    };
    img.onload = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "success");
        }
    };
    img.src = url;
    timer = setTimeout(function () {
        timedOut = true;
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        callback(url, "timeout");
    }, timeout);
}

const parseValueAmount = (amount) => {
    return amount
        .replace(/^\D+/g, '')
        .split('.')
        .join('')
        .replace(',', '.');
}