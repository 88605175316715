import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { CancelUpdateProfile, Create, TestEndpoint, UpdateProfile } from '../../constant';
import { LOCAL_BACKEND_URL } from '../../data/urls';

// Imports for the value mask
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';


const CreatePlan = ({ history }) => {

  const [name, setName] = useState('');
  const [fus, setFus] = useState('');
  const [amount, setAmount] = useState('');
  const [credits, setCredits] = useState('');

  const addOrRemoveFu = (fu) => {
    const tempFus = [...fus];
    if (tempFus.includes(fu)) {
      tempFus.splice(tempFus.indexOf(fu), 1);
    }
    else {
      tempFus.push(fu);
    }
    setFus(tempFus);
    console.log(tempFus);
  }

  // Mask for sale amount
  const saleMask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 13,
    allowNegative: false,
    allowLeadingZeroes: false
  });

  const submitHandler = event => {
    /* event.preventDefault(); */
    const submitData = {
      'name': name,
      'fus': fus,
      'value': parseAmount(amount),
      'credits': credits
    }

    axios.post(`${LOCAL_BACKEND_URL}/plan`, submitData, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
      .then(res => {
        history.push(`${process.env.PUBLIC_URL}/base/planos`);
      })
      .catch(err => console.log(err.message));

  }

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = event => submitHandler();

  return (
    <div>
      <Breadcrumb parent="Planos" title="Planos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cadastrar plano</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6 ml-3">
                      <FormGroup>
                        <Label for="name">Nome</Label>
                        <input

                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nome"
                          onChange={e => setName(e.target.value)}
                          ref={
                            register({
                              required: "O nome do Plano é obrigatório.",
                              minLength: 2
                            })
                          }
                        />
                        <ErrorMessage errors={errors} name="name" />

                      </FormGroup>
                    </div>


                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="value">Valor</Label>
                      <MaskedInput
                        className="form-control"
                        name="value"
                        value={amount}
                        mask={saleMask}
                        onChange={e => setAmount(e.target.value)}
                        ref={
                          register({
                            required: "O valor do plano é obrigatório.",
                            minLength: 2
                          })
                        }
                      />
                      <ErrorMessage errors={errors} name="value" />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="credits">Créditos</Label>
                      <input
                        value={credits}
                        type="text"
                        name="credits"
                        className="form-control"
                        placeholder="Digite os créditos"
                        onChange={e => setCredits(e.target.value)}
                        ref={
                          register({
                            required: "A quantidade de créditos é obrigatória.",
                            minLength: 2
                          })
                        }
                      />
                      <ErrorMessage errors={errors} name="credits" />

                    </FormGroup>
                  </div>
                  <FormGroup check inlist onChange={(e) => addOrRemoveFu(e.target.value)}>
                    <h4 className="row" for="url">Estados</h4>
                    <div className="row">
                      <div className="col-3">
                        <li>
                          <Input type="checkbox" value="SP" />
                          <Label for="SP">São Paulo</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="SC" />
                          <Label for="SC">Santa Catarina</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="MG" />
                          <Label for="MG">Minas Gerais</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="RS" />
                          <Label for="RS">Rio Grande do Sul</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="MT" />
                          <Label for="MT">Mato Grosso</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="MS" />
                          <Label for="MS">Mato Grosso do Sul</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="PR" />
                          <Label for="PR">Paraná</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="AC" />
                          <Label for="AC">Acre</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="AL" />
                          <Label for="AL">Alagoas</Label>
                        </li>
                      </div>
                      <div className="col-3">
                        <li>
                          <Input type="checkbox" value="AP" />
                          <Label for="AP">Amapá</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="AM" />
                          <Label for="AM">Amazonas</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="BA" />
                          <Label for="BA">Bahia</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="CE" />
                          <Label for="CE">Ceará</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="ES" />
                          <Label for="ES">Espírito Santo</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="GO" />
                          <Label for="GO">Goiás</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="MA" />
                          <Label for="MA">Maranhão</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="PA" />
                          <Label for="PA">Pará</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="PB" />
                          <Label for="PA">Paraíba</Label>
                        </li>
                      </div>

                      <div className="col-3">
                        <li>
                          <Input type="checkbox" value="PE" />
                          <Label for="PE">Pernambuco</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="PI" />
                          <Label for="PI">Piauí</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="RJ" />
                          <Label for="RJ">Rio de Janeiro</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="RR" />
                          <Label for="RR">Roraima</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="SE" />
                          <Label for="SE">Sergipe</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="TO" />
                          <Label for="TO">Tocantins</Label>
                        </li>
                        <li>
                          <Input type="checkbox" value="DF" />
                          <Label for="DF">Distrito Federal</Label>
                        </li>
                      </div>
                    </div>
                    {/* </Input> */}
                  </FormGroup>
                  <div className="card-footer text-right">
                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/planos`)}>{CancelUpdateProfile}</button>
                    <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );

}

export default CreatePlan;

const parseAmount = (amount) => {
  return amount
    .replace(/^\D+/g, '')
    .split('.')
    .join('')
    .replace(',', '.');
}