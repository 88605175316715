import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { New } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import { Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../../../components/base/lists/filter.css';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import ButtonDeletePartners from '../../icetran/ButtonDeletePartners';
import { db } from '../../../data/config';

import { requestAuthConfig } from '../../../utils/utilTools';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { toast } from 'react-toastify';

const EstadosCFC = ({ history }) => {
  const [firstCreditsModal, setFirstCreditsModal] = useState(false);
  const [firstCreditClientSelected, setFirstCreditClientSelected] = useState({})

  const [data, setData] = useState();
  const [responseData, setResponseData] = useState([]);
  const [partnerAndId, setPartnerAndId] = useState([]);
  const [refresh, setRefresh] = useState();

  // state variables to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfPartners, setTotalNumberOfPartners] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);


  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/partners?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.partners);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((err) => { console.log(err) })
  }, []);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/everySinglePartner`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfPartners(res.data.partners.length);
      })
      .catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      responseData.forEach(partner => {
        carrierArray.push(partner);
      });
      console.log(carrierArray);
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);

    }
  }, [responseData, numberOfPages]);

  useEffect(() => {
    if (responseData.length > 0) {
      console.log(responseData);
      let carrierArray = [];
      let partnerAndIdCarrier = {};
      responseData.forEach(partner => {
        carrierArray.push(partner)
        partnerAndIdCarrier[partner.name] = partner.partnerId;
      })
      console.log(partnerAndIdCarrier);
      setData(carrierArray)
      setPartnerAndId({ ...partnerAndIdCarrier });
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/partners?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.partners);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  console.log('data parcieros',data)
  const columns = [
    {
      name: 'Parceiro',
      selector: 'name',
      sortable: true,
      center: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.name}</div>,

    },
    {
      name: 'Estado (UF)',
      selector: 'adress.fu',
      sortable: true,
      center: true,
    },
    {
      name: 'CNPJ',
      selector: 'document',
      sortable: true,
      center: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.email}</div>,

    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => (
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%" }}>
          <li className="list-group-item-action">
            <button
              style={{ marginRight: 8 }}
              onClick={() => history.push({
                pathname: `${process.env.PUBLIC_URL}/editar/parceiro`,
                state: {
                  partnerId: partnerAndId[row.name],
                  partnerInfo: getPartnerData(responseData, partnerAndId[row.name])
                }
              }
              )} 
              className="btn btn-sm btn-warning"
            >
                <i className="icon icon-pencil"></i>
            </button>
          </li>
          <li className="list-group-item-action mr-2">
            <ButtonDeletePartners
              refreshPage={setRefresh}
              getPartnerFunction={getPartnerData}
              partner={responseData}
              partnerId={partnerAndId[row.name]}
            />
            </li>
            {
              !row.credits && (row.invoicementType === 1 || row.invoicementType === 2) && (
                <li 
                  className="list-group-item-action"
                  style={{ marginRight: 8 }}
                >
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => toggleFirstCredtis(row)}
                  >
                    <i className="icon icon-plus"></i>
                  </button>
                </li>

              )
            }
        </div>
      ),
      center: true,
    },
    
  ];
  const [searchTerm, setSearchTerm] = useState('');

  function searchPartner(rows) {
    return rows && rows.length ? rows.filter(row =>
      row.name.toLowerCase().indexOf(searchTerm) > -1 ||
      row.adress.fu.toLowerCase().indexOf(searchTerm) > -1 ||
      row.document.toLowerCase().indexOf(searchTerm) > -1 ||
      row.email.toLowerCase().indexOf(searchTerm) > -1) : rows
  }

  function toggleFirstCredtis(client)
  {
    setFirstCreditsModal(!firstCreditsModal);
    setFirstCreditClientSelected(client);
  }

  async function handleFirstCredit(id)
  {
    try {
      axios.post(`${LOCAL_BACKEND_URL}/firstcreditaddbyadmin`, {
        partnerId: id
      }, {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('token')}`
        }
      })
      .then(res => {
        toast.success('Créditos Adicionados');
        setFirstCreditsModal(!firstCreditsModal);
      })
      .catch(e => {
        console.log(e.response.data)
        toast.error('Não foi possível adicionar.')
      })
    }catch(err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Breadcrumb parent="Parceiros" title="Todos os Parceiros" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Parceiros - Todos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/criar/parceiro`)} type="button">{New}</button>
                </div>
              </div>
              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchPartner(data)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />
                
                <Modal
                  isOpen={firstCreditsModal}
                  className='bg-transparent'
                >
                  <ModalHeader
                  >
                    <div>
                      <span className='h3'>
                        Adicionar Créditos
                      </span>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div className='w-100'>
                      <div style={{flexDirection: 'row'}} className='w-100'>
                        <div className='mt-2'>
                          Parceiro: 
                          <span className='text-dark'>
                             {' ' + firstCreditClientSelected.name}
                          </span>
                        </div>
                        <div className='mt-2'>
                          E-mail: 
                          <span className='text-dark'>
                             {' ' + firstCreditClientSelected.email}
                          </span>
                        </div>
                        <div className='mt-2'>
                          Contrato: 
                          <span className='text-dark'>
                             {' ' + firstCreditClientSelected.contractIsSigned ? 'Assinado' : 'Sem assinar'}
                          </span>
                        </div>
                      </div>
                      <div className='p-2 d-flex justify-content-around mt-3'>
                        <div className='btn btn-success uppercase' onClick={() => handleFirstCredit(firstCreditClientSelected.partnerId)}>Adicionar</div>
                        <div className='btn btn-danger uppercase' onClick={() => setFirstCreditsModal(!firstCreditsModal)}>Cancelar</div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                  {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfPartners}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                  <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                            <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                            />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                            <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                            />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstadosCFC;

const getPartnerData = (partner, idToBeFound) => {
  let returnValue = 'Partner not found';
  partner.forEach(partner => {
    if (partner.partnerId === idToBeFound) {
      returnValue = { ...partner };
    }
  });
  return returnValue;
}