import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import { Breadcrumb, Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
    API,
    CancelUpdateProfile,
    Create,
    UpdateProfile,
} from "../../../constant";
import { LOCAL_BACKEND_URL } from "../../../data/urls";

import { useForm } from "react-hook-form";

import convertToBase64 from "../../../services/convertToBase64";
import { toast } from "react-toastify";

const NewCredits = ({ history }) => {
    const submitButton = useRef(null);

    const [note, setNote] = useState("");
    const [credits, setCredits] = useState([]);
    const [partner, setPartner] = useState("");
    const [partners, setPartners] = useState([]);
    const [amountCredits, setAmountCredits] = useState("");
    const [submitBool, setSubmitBool] = useState(false);


    const [data, setData] = useState([]);
    const [categoryMap, setCategoryMap] = useState([]);

    // async function convert(e) {
    //     const base64 = await convertToBase64(e);
    //     setBase64(base64);
    // }

    // const now = new Date();
    // var date =
    // String(now.getDate()).padStart(2, "0") +
    // "-" +
    // String(now.getMonth() + 1).padStart(2, "0") +
    // "-" +
    // now.getFullYear();
    

    // useEffect(() => {
    //     axios
    //         .get(`${LOCAL_BACKEND_URL}/categories`, {
    //             headers: {
    //                 'Authorization': `bearer ${localStorage.getItem("token")}`,
    //             },
    //         })
    //         .then((res) => {
    //             setCategories([...res.data.categories]);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/credits`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem("token")}`,
            }
        })
            .then((res) => {
                setCredits([...res.data.credits]);
            })
            .catch((err) => { console.log(err) })
    }, []);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/partners`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem("token")}`,
            },
        })
            .then ((res) => {
                setPartners([...res.data.partners]);
            })
            .catch((err) => { console.log(err) })
    }, []);

    async function patchCourse(data, id) {
        await axios.patch(`${LOCAL_BACKEND_URL}/course/${id}`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            console.log(res);
            toast.success('Politica de Preço criada');
        })
        .catch(err => {
            console.log(err);
            toast.error('Não foi possivel criar Politica de Preço.')
        })
    }

    const submitHandler = (e) => {
        e.preventDefault();

        setSubmitBool(true);

        const submitObject = {
            'partnerId': partner,
            'tid': note,
            'disabled': false,
            'packageValue': parseInt(amountCredits)
        }

        axios.post(`${LOCAL_BACKEND_URL}/creditTransactions`, submitObject, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                toast.success('Crédito Adicionado com Sucesso!')
                // const creditsTransactionsId = res.data.id;
                // patchCourse(submitObject, creditsTransactionsId);
                // console.log(res);
                history.push(`${process.env.PUBLIC_URL}/creditos/por-pacote`);
            })
            .catch(err => {
                toast.error('Não foi possível adicionar este pacote!')
                console.log(err)
            }
            );
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (event) => submitHandler();

    return (
        <div>
            <Breadcrumb parent="Adição de Créditos" title="Novo Crédito" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-4 mr-5">
                                    <h5>Adicionar Crédito</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <div className="card">
                                    <div className="card-header">
                                        <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                            <FormGroup>
                                                <Label for="partner">Nome do CFC</Label>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => setPartner(e.target.value)}
                                                >
                                                    <option value="null">Selecione o CFC</option>
                                                    {
                                                        partners.map(partner => 
                                                            <option value={partner.partnerId}>
                                                                {partner.name}
                                                            </option>)
                                                    }
                                                </select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="note">Observação</Label>
                                                <textarea
                                                    className="form-control"
                                                    value={note}
                                                    placeholder="Observação da Venda"
                                                    onChange={(e) => setNote(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="validity">Validade dos Créditos</Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="validity"
                                                    id="validity"
                                                    value="30 dias"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="amountCredits">Quantidade de Créditos</Label>
                                                <select
                                                    className="form-control"
                                                    onChange={e => setAmountCredits(e.target.value)}>
                                                    <option value="null">Escolha a Quantidade</option>
                                                    {
                                                        credits.map(credit =>
                                                            <option value={credit.value}>
                                                                {credit.value}
                                                            </option>)
                                                    }
                                                </select>
                                            </FormGroup>

                                            <div className="card-footer text-right">
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() =>
                                                        history.push(
                                                            `${process.env.PUBLIC_URL}/creditos/por-pacote`
                                                        )
                                                    }
                                                >
                                                    {CancelUpdateProfile}
                                                </button>
                                                <button
                                                    ref={submitButton}
                                                    type="submit"
                                                    className="btn btn-primary ml-3"
                                                    onClick={submitHandler}
                                                >
                                                    {UpdateProfile}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NewCredits;
