import React, { useState, useEffect } from 'react';
import { Edit, CancelUpdateProfile, Salvar } from "../../../constant";
import Breadcrumb from "../../common/breadcrumb";
import { useForm } from 'react-hook-form';

import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { FormGroup, Input, Label } from 'reactstrap';
import { StatesPerGroupBR } from '../../../utils/utilTools';

const AllowedFUs = ({ history }) => {

    const [allowedFUs, setAllowedFUs] = useState([]);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/allowedfus`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setAllowedFUs(res.data.fus);
            })
            .catch(err => console.log(err.response.data));

    }, []);

    const submitHandler = event => {
        /* event.preventDefault(); */
        axios.patch(`${LOCAL_BACKEND_URL}/allowedfus`, {
            'fus': allowedFUs,
        }, {
        headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
        }
        })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/dashboard`);
            })
            .catch(err => console.log(err.response.data));
    };

    const fuCheckedHandler = (checked, fu) => {
        if(checked){
            setAllowedFUs(allowedFUs => [...allowedFUs, fu]);
        }else{
            setAllowedFUs(allowedFUs.filter(federalUnit => federalUnit !== fu));
        };
    };

    const { handleSubmit } = useForm();
    const onSubmit = event => submitHandler();
    return (
        <div>
            <Breadcrumb parent="Estados Autorizados" />
            <div className="container-fluid">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="datatable-react mr-5 ml-5 form-group">
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit />Estados autorizados</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-12">
                                            <FormGroup>
                                                {/* <h4 className="row" for="allowedFUs">Estados Permitidos</h4> */}
                                                <div className="row">
                                                    <div className="col-3">
                                                        {
                                                            StatesPerGroupBR.UF[0].map(fu => {
                                                                return(
                                                                    <li>
                                                                        <Input type="checkbox" value={fu.sigla} checked={allowedFUs.includes(fu.sigla) ? true : false} onChange={e => fuCheckedHandler(e.target.checked, e.target.value)}/>
                                                                        <Label for={fu.sigla}> {fu.nome} </Label>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-3">
                                                        {
                                                            StatesPerGroupBR.UF[1].map(fu => {
                                                                return(
                                                                    <li>
                                                                        <Input type="checkbox" value={fu.sigla} checked={allowedFUs.includes(fu.sigla) ? true : false} onChange={e => fuCheckedHandler(e.target.checked, e.target.value)}/>
                                                                        <Label for={fu.sigla}> {fu.nome} </Label>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-3">
                                                        {
                                                            StatesPerGroupBR.UF[2].map(fu => {
                                                                return(
                                                                    <li>
                                                                        <Input type="checkbox" value={fu.sigla} checked={allowedFUs.includes(fu.sigla) ? true : false} onChange={e => fuCheckedHandler(e.target.checked, e.target.value)}/>
                                                                        <Label for={fu.sigla}> {fu.nome} </Label>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>                
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/dashboard`)}>
                                        {CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" onClick={handleSubmit} type="submit">{Salvar}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllowedFUs;