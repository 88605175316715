import React from "react";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DownloadToExcel = (props) => 
{
    const isAdmin = localStorage.getItem('isAdmin');
    const data = props.data;
    const dataSet = [
        {
            columns: [
                // Cliente
                {
                    title: 'Cliente',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'RG do Cliente',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Curso',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 180
                    }
                },
                {
                    title: 'ID do Curso',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 75
                    }
                },
                {
                    title: 'ID do Cliente',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Celular',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Cidade',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'CEP',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'E-mail',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 180
                    }
                },
                {
                    title: 'Data de Conclusão',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Integração com COFECI',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 180
                    }
                },
                {
                    title: 'ID do Contrato',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'ID da Oferta',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'ID da Turma',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'ID do Atendente',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                /*{
                    title: 'Curso',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },*/
                // Financeiro
                {
                    title: 'Parcelas',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Valor da Parcela',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Valor Total',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                // Integração
                {
                    title: 'Estado de Integração',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'ID do Parceiro',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
                {
                    title: 'Nome do Parceiro',
                    style: {
                        font: {
                            sz: "15", 
                            bold: true
                        }
                    }, 
                    width: {
                        wpx: 150
                    }
                },
            ],
            data: data.map(sale => {
                if(!sale) return;
                return [
                    {
                        value: sale.clientName ? `${sale.clientName}` : ' ', 
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog ? `${sale.requestLog.matriculas.rg}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.courseTitle ? `${sale.courseTitle}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.courseReference ? `${sale.courseReference}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.clientId ? `${sale.clientId}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog ? `${sale.requestLog.matriculas.celular}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog ? `${sale.requestLog.matriculas.cidade}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog ? `${sale.requestLog.matriculas.cep}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog ? `${sale.requestLog.matriculas.email}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value:sale.conclusionDate ? `${sale.conclusionDate}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.cofeciIntegration ? `${sale.cofeciIntegration}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.contractId ? `${sale.contractId}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.idOferta ? `${sale.idOferta}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.idTurma ? `${sale.idTurma}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.idAtendente ? `${sale.idAtendente}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.installmentsNumber && isAdmin ? `${sale.installmentsNumber}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.installmentsValue && isAdmin ? `${sale.installmentsValue}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.requestLog && isAdmin ? `${sale.requestLog.matriculas.financeiro.valor_total}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.integrationUf ? `${sale.integrationUf}` : ' '
                    },
                    {
                        value: sale.partnerId ? `${sale.partnerId}` : ' ',
                        style: {
                            font: {
                                sz: "14"
                            }
                        }
                    },
                    {
                        value: sale.partnerName ? `${sale.partnerName}` : ' '
                    }
                ] 
            })
        }
    ]


  return (
    <ExcelFile
      fileName={"relatorio_de_vendas"}
      element={
        <button 
            type="button"
            className="btn btn-info mt-2 w-100 mr-2"
        >
          {props.buttonText}
        </button>
      }
    >
        <ExcelSheet 
            dataSet={dataSet} 
            name={"relatorio_de_vendas"} 
        />
    </ExcelFile>
  );
};


export default DownloadToExcel;