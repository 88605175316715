export default function diferenceInDays(start, end)
{
    const startDate = new Date(start) ;
    const endDate = new Date(end);

    const diffDays = Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24));
    let arr = []
    for (let i = 0; i <= diffDays; i++) {
    startDate.setDate(startDate.getDate() + 1);
    arr.push(`${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`);
    }
     return arr;
}