import { LOCAL_BACKEND_URL } from "../data/urls";
import axios from 'axios';
import { MaxNumberOfItemsPerTable } from "../constant/designControl";

export const splitResponseArray = (response, chunk) => {
    const tempArray = [];
    for (let i = 0; i < response.length; i += chunk) {
        tempArray.push(response.slice(i, i + chunk));
    }
    return tempArray;
}

export const getAllPagesOfResource = (endpoint) => {
    let numberOfPages;
    const allResourceData = {};
    axios.get(`${LOCAL_BACKEND_URL}/${endpoint}?limit=${MaxNumberOfItemsPerTable}&page=1`)
        .then(res => {
            numberOfPages = parseInt(res.info.numPages);
            for (const key of Object.keys(res)) {
                if (key !== 'info') {
                    allResourceData[key] = res[key];
                }
            }
            for (let i = 2; i <= numberOfPages; i++ ) {
                axios.get(`${LOCAL_BACKEND_URL}/${endpoint}?limit=${MaxNumberOfItemsPerTable}&page=${i}`)
                    .then(res => {
                        for (const key of Object.keys(res)) {
                            if (key !== 'info') {
                                allResourceData[key] = {...allResourceData, ...res[key]};
                            }
                        }
                        return allResourceData;
                    })
                    .catch(err => console.log(err.message));
            }
        })
        .catch(err => console.log(err.message));
}

export const formatReais = (reais) => {
    const newReais = reais.includes(',') ? reais.toString().split(',') : reais.toString().split('.');
    newReais[0] = newReais[0].split('').reverse().join('');
    newReais[0] = formatDots(newReais[0]);
    return newReais[1] ? `R$ ${newReais.join(',')}` : `R$ ${newReais[0]}`;
}

const formatDots = (unformatted) => {
	var chunks = [];

    for (let i = 0, charsLength = unformatted.length; i < charsLength; i += 3) {
        chunks.push(unformatted.substring(i, i + 3));
    }
    
    chunks = chunks.map(item => item.split('').reverse().join(''));
    
    return chunks.reverse().join('.');
}

export const requestAuthConfig = {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  };

export const StatesBR = {
    "UF": [
        {"nome": "Acre", "sigla": "AC"},
        {"nome": "Alagoas", "sigla": "AL"},
        {"nome": "Amapá", "sigla": "AP"},
        {"nome": "Amazonas", "sigla": "AM"},
        {"nome": "Bahia", "sigla": "BA"},
        {"nome": "Ceará", "sigla": "CE"},
        {"nome": "Distrito Federal", "sigla": "DF"},
        {"nome": "Espírito Santo", "sigla": "ES"},
        {"nome": "Goiás", "sigla": "GO"},
        {"nome": "Maranhão", "sigla": "MA"},
        {"nome": "Mato Grosso", "sigla": "MT"},
        {"nome": "Mato Grosso do Sul", "sigla": "MS"},
        {"nome": "Minas Gerais", "sigla": "MG"},
        {"nome": "Pará", "sigla": "PA"},
        {"nome": "Paraíba", "sigla": "PB"},
        {"nome": "Paraná", "sigla": "PR"},
        {"nome": "Pernambuco", "sigla": "PE"},
        {"nome": "Piauí", "sigla": "PI"},
        {"nome": "Rio de Janeiro", "sigla": "RJ"},
        {"nome": "Rio Grande do Norte", "sigla": "RN"},
        {"nome": "Rio Grande do Sul", "sigla": "RS"},
        {"nome": "Rondônia", "sigla": "RO"},
        {"nome": "Roraima", "sigla": "RR"},
        {"nome": "Santa Catarina", "sigla": "SC"},
        {"nome": "São Paulo", "sigla": "SP"},
        {"nome": "Sergipe", "sigla": "SE"},
        {"nome": "Tocantins", "sigla": "TO"}

    ]
};

export const StatesPerGroupBR = {
    "UF": [
        [
            {"nome": "Acre", "sigla": "AC"},
            {"nome": "Alagoas", "sigla": "AL"},
            {"nome": "Amapá", "sigla": "AP"},
            {"nome": "Amazonas", "sigla": "AM"},
            {"nome": "Bahia", "sigla": "BA"},
            {"nome": "Ceará", "sigla": "CE"},
            {"nome": "Distrito Federal", "sigla": "DF"},
            {"nome": "Espírito Santo", "sigla": "ES"},
            {"nome": "Goiás", "sigla": "GO"}
        ],
        [
            {"nome": "Maranhão", "sigla": "MA"},
            {"nome": "Mato Grosso", "sigla": "MT"},
            {"nome": "Mato Grosso do Sul", "sigla": "MS"},
            {"nome": "Minas Gerais", "sigla": "MG"},
            {"nome": "Pará", "sigla": "PA"},
            {"nome": "Paraíba", "sigla": "PB"},
            {"nome": "Paraná", "sigla": "PR"},
            {"nome": "Pernambuco", "sigla": "PE"},
            {"nome": "Piauí", "sigla": "PI"}
        ],
        [
            {"nome": "Rio de Janeiro", "sigla": "RJ"},
            {"nome": "Rio Grande do Norte", "sigla": "RN"},
            {"nome": "Rio Grande do Sul", "sigla": "RS"},
            {"nome": "Rondônia", "sigla": "RO"},
            {"nome": "Roraima", "sigla": "RR"},
            {"nome": "Santa Catarina", "sigla": "SC"},
            {"nome": "São Paulo", "sigla": "SP"},
            {"nome": "Sergipe", "sigla": "SE"},
            {"nome": "Tocantins", "sigla": "TO"}
        ]

    ]
};