import axios from "axios";
import { toast } from "react-toastify";
import { LOCAL_BACKEND_URL } from "../data/urls";

export const fetchCredits = async (token) => {
    return axios.get(`${LOCAL_BACKEND_URL}/credits`, {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then(res => res)
        .catch(err => console.log('Erro: ', err));
}

export const buyCreditPackage = async (data) => {
    return axios.post(`${LOCAL_BACKEND_URL}/addsinglecredits`, {
      creditsPackageId: data.creditsPackageId,
      partnerId: data.partnerId,
      cardHolderName: data.cardHolderName,
      cardNumber: data.cardNumber,
      expirationMonth: data.expirationMonth,
      expirationYear: data.expirationYear,
      securityCode: data.securityCode

    }, {
      headers: { "authorization": `Bearer ${localStorage.getItem('token')}`}
    })
      .then((res) => {
        toast.success('Créditos adicionados!');
      })
      .catch(err => {
        toast.error('Não foi possivel realizar a compra.')
        console.log(err.response.data)
      })
  }