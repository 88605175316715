import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { CancelUpdateProfile, Create, Option, Salvar, TestEndpoint } from '../../constant';
import { useLocation } from 'react-router';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { requestAuthConfig } from '../../utils/utilTools';


const EditStatus = ({ history }) => {

    const location = useLocation();

    const [status, setStatus] = useState('');

    const [invoiceModelId, setInvoiceModelId] = useState('');

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/invoice/${location.invoiceId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                setStatus(res.data.invoice.status);

            })
            .catch(err => console.log(err));
    }, []);

    const submitHandler = event => {
        /*  event.preventDefault(); */

        axios.patch(`${LOCAL_BACKEND_URL}/invoice/${location.invoiceId}`, {
            'status': status,


        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/financeiro/faturas`);
            })
            .catch(err => {
                console.log(invoiceModelId)
                console.log(err.response.data);
            })
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <div>
            <Breadcrumb parent="Financeiro" title="Faturas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Editar Status</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <Label for="status">Status</Label>
                                        <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            onChange={e => setStatus(e.target.value)}
                                            <option value={null}>Escolha o tipo</option>
                                            <option value={0}> Pendente</option>
                                            <option value={1}>Pago</option>
                                            <option value={2}>Cancelada</option>
                                            <option value={3}>Reembolsada</option>


                                        </select>
                                    </div>

                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/financeiro/faturas`)}>{CancelUpdateProfile}</button>
                                        <button className="btn btn-primary ml-3" onClick={handleSubmit} type="submit">{Salvar}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditStatus;