import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import man from '../../assets/images/dashboard/user.png';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { requestAuthConfig } from '../../utils/utilTools';

export default function ButtonDeleteCourses(props) {
    const [modal, setModal] = useState();

    const toggle = () => {
        setModal(!modal)
    }

    const [refresh, setRefresh] = useState();

    const [courseData, setCourseData] = useState({});

    useEffect(() => {
        setCourseData(props.getCourseFunction(props.course, props.courseId));
    }, [props.course]);

    const deleteCourseHandler = () => {
        axios.delete(`${LOCAL_BACKEND_URL}/course/${courseData.courseId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                console.log(res);
                toggle();
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            });

    }

    return (
        <>
            <button onClick={toggle} className="btn btn-sm btn-danger">
                <i className="icon icon-trash"></i>
            </button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmar ação</ModalHeader>
                <ModalBody>
                    <h5>Você tem certeza que deseja remover o Curso abaixo?</h5>
                    <div className="mt-4 mb-3 mw-200 d-flex justify-content-start align-items-center">
                        <img className="img-70 rounded-circle mr-3" alt="man" src={courseData.image} />
                        <h5>{courseData.title}</h5>
                    </div>
                    <ul>
                        <li className="h6">Referência: <span>{courseData.reference}</span></li>

                    </ul>
                    <p className="mt-4">
                        <span style={{ color: "red", fontWeight: "bold", }}>Atenção</span> essa ação não poderá ser revertida.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Cancelar</Button>
                    <Button color="danger" onClick={deleteCourseHandler}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}