import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Create, TestEndpoint } from '../../constant';

const CfcCreate = () => {

  const [user, setUser] = useState('');
  const [state, setState] = useState('');

  const submitHandler = event => {
    event.preventDefault();
    axios.post(TestEndpoint, {
      'name': user,
      'state': state
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
      .catch(err => console.log(err));
  }

  return (
    <div>
      <Breadcrumb parent="Parceiros" title="CFCs" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cadastrar Parceiro</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <Form onSubmit={e => console.log(e)}>
                  <FormGroup>
                    <Label for="name">Usuário</Label>
                    <Input
                      type="text"
                      name="user"
                      id="user"
                      placeholder="Usuário"
                      onChange={e => setUser(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">Estado (UF)</Label>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="Estado"
                      onChange={e => setState(e.target.value)}
                    />
                  </FormGroup>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={submitHandler}
                  >
                    {Create}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default CfcCreate;