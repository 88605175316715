import React, { useState, useEffect, useCallback } from 'react';
import logo from '../assets/images/icetran/IcetranBlueLogo.png';
import openEye from '../assets/images/other-images/open-eye.png';
import closeEye from '../assets/images/other-images/hidden-eye.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import axios from 'axios';
import ibreptranlogo from '../assets/images/ibreptran/logoibreptran.png'
import { LOCAL_BACKEND_URL } from '../data/urls';

import {
    useGoogleReCaptcha,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Signin = ({ history }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hiddenPassword, setHiddenPassword] = useState(true);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
        try {
            const newToken = executeRecaptcha();
            newToken.then(res => {
                axios.post(`${LOCAL_BACKEND_URL}/login`, {
                    'email': email,
                    'password': password,
                    'token': res
                })
                    .then(res => {
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('profileURL', res.data.photoURL);
                        localStorage.setItem('userName', res.data.displayName);
                        localStorage.setItem('userId', res.data.userId);
                        localStorage.setItem('isAdmin', res.data.isAdmin);
                        window.location = `${process.env.PUBLIC_URL}/dashboard`

                    })
                    .catch(err => {
                        console.log(err);
                        setTimeout(() => {
                            toast.error("Oppss... Usuário ou password inválido.");
                        }, 200);
                    });
            });
        } catch (err) {
            setTimeout(() => {
                toast.error("Oppss... Parece que você é um robô.");
            }, 200);
        }
    };

    const passwordVisibleHandler = () => {
        setHiddenPassword(!hiddenPassword);
    };

    return (
        <div>
            <GoogleReCaptcha onVerify={t => console.log({ t })} />
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={ibreptranlogo} className="mb-3 img-fluid" alt="Ibreptran" /></div>
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Acesso</h4>
                                                </div>
                                                <form className="theme-form" >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" type="email" name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Senha</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <input className="form-control"
                                                                type={hiddenPassword ? "password" : "text"}
                                                                name="password"
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                            <div>
                                                                <img
                                                                    style={{ height: "2rem", cursor: "pointer" }}
                                                                    id="showPassword" src={hiddenPassword ? openEye : closeEye}
                                                                    onClick={passwordVisibleHandler}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Permanecer conectado</label>
                                                    </div>
                                                    
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block p-2" type="button" onClick={() => handleSubmit()} >Entrar</button>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0" style={forgotPasswordStyle}>
                                                        <a href={`${process.env.PUBLIC_URL}/recuperarsenha`} className="link-secondary">Esqueceu a senha?</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Signin);

const forgotPasswordStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}