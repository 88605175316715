export const termosData = [
    {
        'id': 0,
        'icon': 'https://cdn-icons-png.flaticon.com/128/2702/2702058.png',
        'title': 'Escolha uma opção',
    },
    {
        'id': 1,
        'icon': 'https://img-premium.flaticon.com/png/128/3290/premium/3290728.png?token=exp=1631737903~hmac=f8e62ab40dca7b36b426b98cc9afa894',
        'title': 'LI E CONCORDO COM OS TERMOS DO CONTRATO',
    },
    {
        'id': 17,
        'icon': 'https://img-premium.flaticon.com/png/128/4345/premium/4345016.png?token=exp=1631737903~hmac=c24a94d509e7f720911d199d6b49c2f4',
        'title': 'LI E CONCORDO COM OS TERMOS DO CONTRATO DO CURSO ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE EMERGÊNCIA',
    },
    {
        'id': 15,
        'icon': 'https://cdn-icons-png.flaticon.com/128/943/943800.png',
        'title': 'LI E CONCORDO COM OS TERMOS DO CONTRATO DO CURSO ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE COLETIVO DE PASSAGEIROS',
        'URL': 'MOT',
    },
    {
        'id': 13,
        'icon': 'https://cdn-icons-png.flaticon.com/128/992/992633.png',
        'title': 'LI E CONCORDO COM OS TERMOS DO CONTRATO DO CURSO ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE DE CARGA INDIVISÍVEL',
    },
    {
        'id': 16,
        'icon': 'https://cdn-icons-png.flaticon.com/128/5010/5010276.png',
        'title': 'LI E CONCORDO COM OS TERMOS DO CONTRATO DO CURSO ATUALIZAÇÃO DE CONDUTORES DE VEÍCULOS DE TRANSPORTE DE ESCOLARES',
    },
];