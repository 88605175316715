import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { New } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../../../components/base/lists/filter.css';
import axios from 'axios';
import ButtonDeleteSms from '../../icetran/ButtonDeleteSms';
import { db } from '../../../data/config';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import ButtonDeleteTeams from '../../icetran/ButtonDeleteTeams';

import { requestAuthConfig } from '../../../utils/utilTools';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';

const DataTeams = ({ history }) => {

    const [responseData, setResponseData] = useState([]);
    const [data, setData] = useState([]);
    const [teamAndId, setTeamAndId] = useState([]);

    const [refresh, setRefresh] = useState();

    // state variables used to implement pagination
    const [paginationCountArray, setPaginationCountArray] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPagination, setCurrentPagination] = useState(0);
    const [totalNumberOfTeams, setTotalNumberOfTeams] = useState(0);
    const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/teams?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setResponseData([...res.data.teams]);
                setNumberOfPages(res.data.info.numPages);
            })
            .catch((err) => { console.log(err) });

            axios.get(`${LOCAL_BACKEND_URL}/allTeams`, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                }
              })
                .then(res => {
                  setTotalNumberOfTeams(res.data.teams.length);
                })
                .catch(err => console.log(err.message));

    }, []);

    useEffect(() => {
        if (responseData.length > 0 && numberOfPages > 0) {
            let carrierArray = [];
            let teamAndIdCarrier = {};
            responseData.forEach(team => {
                carrierArray.push(team);
                teamAndIdCarrier[team.name] = team.teamId;
            });
            console.log(carrierArray);
            setData(carrierArray);
            setCurrentPaginationLength(carrierArray.length);
            setTeamAndId({ ...teamAndIdCarrier });

             // Creates an array containing the number of pages the pagination should have
            const tempCountArray = [];
            for (let i = 1; i <= numberOfPages; i++) {
                tempCountArray.push(i);
            }
            setPaginationCountArray(tempCountArray);

        }
    }, [responseData, numberOfPages]);

    const changeTableData = (dataIndex) => {
        setCurrentPagination(parseInt(dataIndex) - 1);
        axios.get(`${LOCAL_BACKEND_URL}/teams?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then((response) => {
            setResponseData(response.data.teams);
            setNumberOfPages(response.data.info.numPages);
          })
          .catch((error) => {
            console.log(error.message);
          });
      }


    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
            cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.name}</div>,
            center: true,
        },
        {
            name: 'Quantidade de integrantes',
            selector: 'type',
            sortable: true,
            cell: row => <div>{
                (() => {
                    if (row.type === 1) {
                        return 'Venda criada'
                    } else if (row.type === 2) {
                        return 'Venda negada'
                    } else if (row.type === 3) {
                        return 'Venda concluída'
                    } else if (row.type === 4) {
                        return 'Venda concluída (parceiro)'
                    } else if (row.type === 5) {
                        return 'Link gerado'
                    }
                    return 'Inválido'
                })()
            }</div>,
            center: true,
        },
        {
            name: 'Ações',
            selector: 'edit',
            sortable: false,
            cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%" }}><li className="list-group-item-action"><button
                style={{ marginRight: 4 }}
                onClick={() => history.push({
                    pathname: `${process.env.PUBLIC_URL}/edit/time`, state: {
                        teamId: teamAndId[row.name],
                        teamInfo: getTeamData(responseData, teamAndId[row.name])
                    }
                })} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
                <li className="list-group-item-action">
                    <ButtonDeleteTeams
                        refreshPage={setRefresh}
                        getTeamFunction={getTeamData}
                        team={responseData}
                        teamId={teamAndId[row.name]}
                    />
                </li>
            </div >,
            center: true,
        },
    ];

    const [searchTerm, setSearchTerm] = useState('');

    function searchTeams(rows) {
        return rows.filter(row =>
            row.name.toLowerCase().indexOf(searchTerm) > -1)
    }

    // const contextActions = useMemo(() => {

    // }, []);
console.log(data)
    return (
        <div>
            <Breadcrumb parent="Usuários" title="Times" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Times</h5>
                                    <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/cadastrar/times`)} type="button">{New}</button>
                                </div>
                            </div>
                            <div className="ml-5 mt-3 mb-3 col-sm-4">
                                <div className="form-group row">
                                    <div className="search_wrap search_wrap_3">
                                        <div className="search_box">
                                            <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <DataTable
                                    columns={columns}
                                    data={searchTeams(data)}
                                    striped={true}
                                    center={true}
                                    noDataComponent="Não possui nenhum dado."

                                // contextActions={contextActions}
                                />


                                <div className="container d-flex justify-content-between mw-100 align-items-center">
                                    <div className="mt-5">
                                        {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfTeams}` : ''}
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                                            {
                                                currentPagination === 0 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink first href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    first
                                                    onClick={() => changeTableData((currentPagination + 1) - 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                            {
                                                paginationCountArray.map(item =>
                                                <PaginationItem>
                                                    <PaginationLink onClick={() => changeTableData(item)}>
                                                    {item}
                                                    </PaginationLink>
                                                </PaginationItem>
                                                )
                                            }
                                            {
                                                currentPagination === numberOfPages - 1 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink last href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    last
                                                    onClick={() => changeTableData((currentPagination + 1) + 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                        </Pagination>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DataTeams;

const getTeamData = (team, idToBeFound) => {
    let returnValue = {
        name: 'time não encontrado'
    }
    team.forEach(team => {
        if (team.teamId === idToBeFound) {
            returnValue = { ...team };
        }
    });
    return returnValue;
}
