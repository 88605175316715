import React, { useState, useMemo, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { New } from "../../../constant";
import { Typeahead } from "react-bootstrap-typeahead";
import DataTable from "react-data-table-component";
import { contratosData } from "../../../data/contratosData";
import ButtonDeleteUser from "../../icetran/ButtonDeleteUser";
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import { db } from "../../../data/config";
import ButtonEmailDelete from "../../icetran/ButtonDeleteEmail";
import ButtonContractDelete from "../../icetran/ButtonContractDelete";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { requestAuthConfig } from "../../../utils/utilTools";

const Contratos = ({ history }) => {
  const [totalNumberOfContracts, setTotalNumberOfContracts] = useState(0)
  const [listOfContracts, setListOfContracts] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [contractAndId, setContractAndIds] = useState([]);

  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    async function getData(){
      await getContract();
      setTotalNumberOfContracts(responseData.length);
    }getData();
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      let contractsAndIdCarrier = {};
      responseData.forEach((contract) => {
        carrierArray.push(contract);
        contractsAndIdCarrier[contract.title] = contract.contractId;
      });
      setTableData(carrierArray);
      setContractAndIds(contractsAndIdCarrier);

      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const columns = [
    {
      name: "Id",
      selector: "contractId",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {row.contractId}
        </div>
      ),
      center: true,
    },
    {
      name: "Título",
      selector: "title",
      sortable: true,
      center: true,
      grow: 2,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>
          {row.title}
        </div>
      ),
    },
    {
      name: "Ações",
      selector: "edit",
      sortable: false,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <li  className="list-group-item-action">
            <button
              style={{ marginRight: 4 }}
              onClick={() =>
                history.push({
                  pathname: `${process.env.PUBLIC_URL}/edit/contrato`,
                  state: {
                    contractId: contractAndId[row.title],
                    contractInfo: getContractData(
                      responseData,
                      contractAndId[row.title]
                    ),
                  },
                })
              }
              className="btn btn-sm btn-warning"
            >
              <i className="icon icon-pencil"></i>
            </button>
          </li>
          <li  className="list-group-item-action">
            <ButtonContractDelete
              refreshPage={setRefresh}
              getContractFunction={getContractData}
              contract={responseData}
              contractId={contractAndId[row.title]}
            />
          </li>
        </div>
      ),
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  useEffect(() => {
    const fetchContractData = async () => {
      const data = await db.collection("contract").get();
    };
  }, []);
  // }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios
      .get(
        `${LOCAL_BACKEND_URL}/contracts?limit=${2}&page=${dataIndex}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setTableData(res.data.contracts);
        setNumberOfPages(res.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  async function getContract() {
    try {
      await axios
        .get(
          `${LOCAL_BACKEND_URL}/contracts?limit=${2}&page=${
            currentPagination + 1
          }`,
          {
            headers: {
              Authorization: `bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.contracts);
          setResponseData(response.data.contracts);
          setNumberOfPages(response.data.info.numPages);
        })
        .catch((error) => {
          console.log(error);
        });
    }catch(e) {
      console.log(e);
    }
    setCurrentPaginationLength(tableData.length)
  }

  function search(rows) {
    return rows.filter(row => 
      row.title.toLowerCase().indexOf(searchTerm) > -1)
  }

  return (
    <div>
      <Breadcrumb parent="Parceiros" title="Contratos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Contratos</h5>
                  <button
                    className="btn btn-primary pr-4 pl-4"
                    onClick={() =>
                      history.push(
                        `${process.env.PUBLIC_URL}/cadastrar/contrato`
                      )
                    }
                    type="button"
                  >
                    {New}
                  </button>
                </div>
              </div>
              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input
                        type="text"
                        className="form-control"
                        value={searchTerm}
                        placeholder="Digite o que está buscando"
                        onChange={(e) =>
                          setSearchTerm(e.target.value.toLowerCase())
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={search(tableData)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                  // contextActions={contextActions}
                />
              </div>
              <div className="container d-flex justify-content-between mw-100 align-items-center">
                <div className="mt-5">
                  {currentPaginationLength > 0
                    ? `${10 * currentPagination + 1}-${
                        10 * currentPagination + currentPaginationLength
                      } de ${totalNumberOfContracts}`
                    : ""}
                </div>
                <nav aria-label="Page navigation example">
                  <Pagination
                    aria-label="Page navigation"
                    className="mt-2 mr-1 pagination justify-content-end pagination-primary"
                  >
                    {currentPagination === 0 ? (
                      <PaginationItem disabled>
                        <PaginationLink first href="#javascript" />
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          first
                          onClick={() =>
                            changeTableData(currentPagination + 1 - 1)
                          }
                        />
                      </PaginationItem>
                    )}
                    {paginationCountArray.map((item) => (
                      <PaginationItem>
                        <PaginationLink onClick={() => changeTableData(item)}>
                          {item}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    {currentPagination === numberOfPages - 1 ? (
                      <PaginationItem disabled>
                        <PaginationLink last href="#javascript" />
                      </PaginationItem>
                    ) : (
                      <PaginationItem>
                        <PaginationLink
                          last
                          onClick={() =>
                            changeTableData(currentPagination + 1 + 1)
                          }
                        />
                      </PaginationItem>
                    )}

                    {/* <PaginationItem disabled>
                      <PaginationLink first href="#javascript" />
                    </PaginationItem>

                    <PaginationItem>
                      <PaginationLink href="#javascript">
                        {"1"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#javascript">
                        {"2"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#javascript">
                        {"3"}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink last href="#javascript" />
                    </PaginationItem>*/}
                  </Pagination>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contratos;

const getContractData = (contract, idToBeFound) => {
  let returnValue = "Partner not found";
  contract.forEach((contract) => {
    if (contract.contractId === idToBeFound) {
      returnValue = { ...contract };
    }
  });
  return returnValue;
};
