import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../data/urls";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Boleto2 from "../checkout/Boleto2";
import Cards from "react-credit-cards";
import { toast } from 'react-toastify';
import { Logout_From_Firebase } from "../common/header-component/userMenu";

export default function ChangePlans({ history }) {
  const [open, setOpen] = useState(false);
  const [credits, setCredits] = useState([]);
  const [plans, setPlans] = useState([]);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const isAdmin = localStorage.getItem("isAdmin");

  const [paymentVisible, setPaymentVisible] = useState("none");
  const [boletoPayment, setBoletoPayment] = useState("none");
  const [creditPayment, setCreditPayment] = useState("none");

  // Item informations
  const [item, setItem] = useState({});
  const [planId, setPlanId] = useState('')

  // Cart info
  const [cardCvc, setCardCvc] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardFocused, setCardFocused] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  // Boleto info
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [complemento, setComplemento] = useState("");
  const [numero, setNumero] = useState("");

  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState("");

  const [currentPlanId, setCurrentPlanId] = useState('');

  useEffect(() => {
    getPlans();
  }, []);

  const columnsOfPlans = [
    {
      name: "Nome",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Creditos",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.credits}
        </div>
      ),
    },
    {
      name: "Valor",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      name: " ",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="btn btn-success p-2"
            style={{ borderRadius: 5 }}
            onClick={() => {
              openModalAndSetItem(row);
              setCurrentPlanId(row.planId);
            }}
          >
            ESCOLHER
          </div>
        </div>
      ),
    },
  ];

  const fus = [
    {
      id: 1,
      name: "Santa Catarina",
      initials: "SC",
    },
    {
      id: 2,
      name: "São Paulo",
      initials: "SP",
    },
    {
      id: 3,
      name: "Parana",
      initials: "PR",
    },
    {
      id: 4,
      name: "Rio Grande Do Sul",
      initials: "RS",
    },
  ];

  const column = [
    {
      name: "Nome",
      selector: '<div>teste</div>',
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Creditos",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.credits}
        </div>
      ),
    },
    {
      name: "Valor",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.value}
        </div>
      ),
    },
  ];

  const columnsOfPartners = [
    {
      name: "Nome",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Email",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: " ",
      selector: <div>teste</div>,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="btn btn-success p-2"
            onClick={() => {
              setPaymentVisible("block");
              setPartnerId(row.partnerId);
            }}
          >
            Selecionar
          </div>
        </div>
      ),
    },
  ];

  function onCloseModal() {
    setBoletoPayment("none");
    setCreditPayment("none");
    setPartners([]);
    setPaymentVisible("none");
    setPartnerId("");
    console.log("Fechou");
  }

  function openModalAndSetItem(item) {
    setOpen(true);
    setItem({ name: item.name, credits: item.credits, value: item.value, planId: item.planId });
    if(!isAdmin) setPaymentVisible('block');
    console.log('item recebido', item)
  }
  console.log(item)
  async function getPlans() {
    await axios
      .get(`${LOCAL_BACKEND_URL}/plans`, {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => setPlans(res.data.plans))
      .catch((err) => console.log("Errro", err));
  }

  async function changePlan() {
    axios
      .post(
        `${LOCAL_BACKEND_URL}/changeplan`,
        {
          planId: item.planId,
          cardHolderName: cardName,
          cardNumber: cardNumber,
          expirationMonth: cardExpiry.slice(0, 2),
          expirationYear: cardExpiry.slice(3, 5),
          securityCode: cardCvc,
          partnerId
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success('Plano alterado!');
        setTimeout(() => {
          Logout_From_Firebase(history);
        }, 300)
        setOpen(false)
        console.log(res);
      })
      .catch((err) => { 
        toast.error('Não foi possivel alterar o plano.')
        console.log(err.response.data)
      });
  }

  async function getPartners(fu) {
    await axios
      .get(`${LOCAL_BACKEND_URL}/allPartners/${fu}`, {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => setPartners(res.data.partners))
      .catch((err) => console.log(err.response.data));
  }

  return (
    <Fragment>
      <Breadcrumb title="Mudar Plano" parent="Mudar Plano" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Mudar de plano</h5>
                  {/* <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/financeiro/fatura`)} type="button">{New}</button> */}
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5">
                <DataTable
                  columns={columnsOfPlans}
                  data={plans}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                  // contextActions={contextActions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        size="lg"
        onClosed={() => onCloseModal()}
      >
        <ModalHeader className="d-flex justify-content-center">
          <div className="d-flex mb-3 justify-content-center">
            Finalizar compra
          </div>
          <div className="d-flex mb-3">Plano selecionado</div>
          <div>
            <div className="datatable-react">
              <DataTable
                columns={column}
                data={[item]}
                striped={true}
                center={true}
                noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
              />
            </div>
          </div>
          {isAdmin && (
            <div className="d-flex flex-column mt-3 justify-content-center">
              <div>Selecione o estado do parceiro</div>
              <div className="mt-2">
                <select
                  name="fus"
                  onChange={(e) => getPartners(e.target.value)}
                >
                  <option disabled selected>
                    Estados
                  </option>
                  {fus.map((fu) => (
                    <option value={fu.initials}>{fu.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {isAdmin && partners.length > 0 && (
            <div className="mt-3">
              <DataTable
                columns={columnsOfPartners}
                data={partners}
                striped={true}
                center={true}
                noDataComponent="Não possui nenhum dado"
              />
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <div style={{ display: paymentVisible }}>
            <div className="d-flex justify-content-sm-around">
              <div
                className="btn btn-secondary"
                onClick={() => {
                  setCreditPayment("block");
                  setBoletoPayment("none");
                }}
              >
                Cartão de Crédito
              </div>
              <button
                className="btn btn-secondary"
                disabled
                style={{pointerEvents: 'none'}}
                onClick={() => {
                  setBoletoPayment("block");
                  setCreditPayment("none");
                }}
              >
                Boleto/PIX
              </button>
            </div>
            <div>
              <div style={{ display: creditPayment }}>
                <div className="mt-5 mb-2" />
                <form className="cardForm mb-4">
                  <div className="row">
                    <div className="col-sm">
                      <input
                        className="form-control"
                        type="tel"
                        name="number"
                        placeholder="Número do Cartão"
                        onChange={(e) => setCardNumber(e.target.value)}
                        onFocus={(e) => setCardFocused(e.target.name)}
                      />
                    </div>
                    <div className="col-sm">
                      <input
                        className="form-control"
                        type="tel"
                        name="number"
                        placeholder="Nome impresso no cartão"
                        onChange={(e) => setCardName(e.target.value)}
                        onFocus={(e) => setCardFocused(e.target.name)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm mt-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="number"
                          name="expiry"
                          placeholder="VALIDADE*"
                          onChange={(e) => setCardExpiry(e.target.value)}
                          onFocus={(e) => setCardFocused(e.target.name)}
                        />
                      </div>
                    </div>
                    <div className="col-sm mt-3">
                      <input
                        className="form-control"
                        type="number"
                        name="cvc"
                        placeholder="CVV*"
                        onChange={(e) => setCardCvc(e.target.value)}
                        onFocus={(e) => setCardFocused(e.target.name)}
                      />
                    </div>
                    <div className="col-md-6 ml-3">
                      <label for="parcelas">
                        <div />
                      </label>
                      <select
                        id="parcelas"
                        name="parcelas"
                        className="form-control"
                      >
                        <option value="1x">1 x R$ 1000</option>
                        <option value="2x">2 x R$ 500</option>
                        <option value="3x">3 x R$ 333</option>
                        <option value="4x">4 x R$ 250</option>
                        <option value="5x">5 x R$ 200</option>
                      </select>
                    </div>
                  </div>
                  <Cards
                    cvc={cardCvc}
                    expiry={cardExpiry}
                    focused={cardFocused}
                    name={cardName}
                    number={cardNumber}
                    placeholders={{ name: "Seu Nome Aqui" }}
                  />
                  <div className="d-flex justify-content-center mt-5">
                    <div
                      className="btn btn-success m-auto w-50 p-2"
                      onClick={() => changePlan(currentPlanId)}
                    >
                      Finalizar compra
                    </div>
                  </div>
                </form>
              </div>
              <div style={{ display: boletoPayment }}>
                <Boleto2
                  setEstado={setEstado}
                  setCidade={setCidade}
                  setBairro={setBairro}
                  setRua={setRua}
                  setCep={setCep}
                  setComplemento={setComplemento}
                  setNumero={setNumero}
                  estado={estado}
                  cidade={cidade}
                  bairro={bairro}
                  rua={rua}
                  cep={cep}
                />
                <div className="d-flex justify-content-center">
                  <div
                    className="btn btn-success m-auto w-50 p-2"
                  >
                    Finalizar compra
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
