import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { New, LevelsHeader } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import {levelsData} from '../../../data/levelsData';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';

const Levels = ({ history }) => {

  const [data, setData] = useState(levelsData);
  const columns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      cell: row => <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", }}>{row.id}</div>,
      center: true,
    },
    {
      name: 'Nível',
      selector: 'level',
      sortable: true,
      center: true,
    },
    {
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%"}}><li className="list-group-item-action"><button onClick={() =>  history.push(`${process.env.PUBLIC_URL}/edit/nivel-usuario`)} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
        {/* <li className="list-group-item-action"><ButtonDeleteUser/></li> */}
        </div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {

  // }, []);

  return (
    <div>
      <Breadcrumb parent="Níveis" title="Níveis" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>{LevelsHeader}</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() =>  history.push(`${process.env.PUBLIC_URL}/levels/levelsCreate`)} type="button">{New}</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <Typeahead
                    id="custom-typeahead"
                    multiple
                    options={[]}
                    placeholder="Digite para filtrar"
                  />
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={data}
                  striped={true}
                  center={true}
                  // contextActions={contextActions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Levels;
