import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Clients, New } from '../../../constant';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { splitResponseArray } from '../../../utils/utilTools';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { db } from "../../../data/config";
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import ButtonDeleteClients from '../../icetran/ButtonDeleteClients';

import { requestAuthConfig } from '../../../utils/utilTools';

const ClientsList = ({ history }) => {

  const [data, setData] = useState();
  const [responseData, setResponseData] = useState([]);
  const [splitData, setSplitData] = useState([]);
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [clientsAndIds, setClientsAndIds] = useState({});
  const [totalNumberOfClients, setTotalNumberOfClients] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);


  const [refresh, setRefresh] = useState();

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/clients?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.clients);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/allClients`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfClients(res.data.clients.length);
      })
      .catch(err => console.log(err.message));
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    console.log(searchTerm)
  }, [searchTerm]);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {

      // Populates the table for the first time
      let carrierArray = [];
      let clientsAndIdsCarrier = {};
      responseData.forEach(client => {
        carrierArray.push(client);
        clientsAndIdsCarrier[client.name] = client.clientId;
      });
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);
      setClientsAndIds(clientsAndIdsCarrier);

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/clients?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.clients);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  const columns = [
    {
      name: 'ID',
      selector: 'idCard',
      sortable: true,
      center: true,
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      center: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.name}</div>
    },
    {
      name: 'CPF',
      selector: 'clientCpf',
      sortable: true,
      center: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.email}</div>
    },
    {
      name: 'Telefone',
      selector: 'phoneNumber',
      sortable: true,
      center: true
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%" }}>
        <li  className="list-group-item-action"><button
        style={{ marginRight: 4 }}
        onClick={() => history.push(
          {
            pathname: `${process.env.PUBLIC_URL}/clients/clientsEdit`,
            clientId: clientsAndIds[row.name],
            clientInfo: getClientData(responseData, clientsAndIds[row.name])
          }
        )} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
        <li  className="list-group-item-action" style={{ marginLeft: '1vw' }}>
          <ButtonDeleteClients
            refreshPage={setRefresh}
            getClientFunction={getClientData}
            client={responseData}
            clientId={clientsAndIds[row.name]}
          /></li>
      </div>,
      center: true,
    },
  ];

 
  function searchClients(rows) {
    return rows && rows.length ? rows.filter(row => 
      row.name.toLowerCase().indexOf(searchTerm) > -1 || 
      row.email.toLowerCase().indexOf(searchTerm) > -1 || 
      row.clientCpf.toString().indexOf(searchTerm) > -1) : rows
  }

  return (
    <div>
      <Breadcrumb parent="Clientes" title="Clientes" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>{Clients}</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/users/novo-cliente`)} type="button">{New}</button>
                </div>
              </div>
              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchClients(data)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfClients}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                  <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                            <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                            />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                            <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                            />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsList;

const getClientData = (client, idToBeFound) => {
  let returnValue = 'Client not found';
  client.forEach(client => {
    if (client.clientId === idToBeFound) {
      returnValue = { ...client };
    }
  });
  return returnValue;
}