import React, { useEffect, useState } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import { ToastContainer } from 'react-toastify';
import Loader from './common/loader';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../data/urls';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router';

const AppLayout = (props) => {

    const history = useHistory();

    useEffect(() => {
        console.log(`${window.location.pathname}`)
        if ((localStorage.getItem('invoicement') && window.location.pathname !== '/loja/assinar-contrato') || (localStorage.getItem('FirstCredits') === 'false' && !localStorage.getItem('isAdmin')) ) {
            axios.get(`${LOCAL_BACKEND_URL}/contractisSigned`, {
                headers: {
                  'Authorization': `bearer ${localStorage.getItem('token')}`
                  }
              })
                .then(res => {
                    if (!res.data.contractIsSigned ) {
                        history.push('/loja/assinar-contrato');
                    }
                    localStorage.setItem('isSigned',res.data.contractIsSigned)
                })
                .catch(err => console.log(err));
        }
        if(localStorage.getItem('FistCredits') === 'false' && !localStorage.getItem('isAdmin')) {
            history.push('/loja/assinar-contrato');
        }
        if(localStorage.getItem('FirstCredits') === 'false' && localStorage.getItem('invoicementType') !== 'Avulso' && !localStorage.getItem('isAdmin')) {
            history.push('/loja/assinar-contrato');
        }
        if(localStorage.getItem('FirstCredits') === 'true') history.push('/loja/dashboard');

        if(localStorage.getItem('isBlocked') === 'true') history.push('/loja/debito-parceiro');
    }, []);

    const minimizeSidebar = () => {
        if (!document.querySelector(".page-main-header").classList.contains('open') && !document.querySelector(".page-sidebar").classList.contains('open')) {
            document.querySelector(".page-main-header").classList.add('open');
            document.querySelector(".page-sidebar").classList.add('open');
        }
    }

    return (
        <>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="page-body" onClick={() => minimizeSidebar()}>
                        { props.children }
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AppLayout;