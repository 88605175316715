import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Users, New, } from '../../../constant';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import man from '../../../assets/images/dashboard/user.png';
import '../../../components/base/lists/filter.css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import { db } from "../../../data/config";
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { mask, unMask } from 'remask';

import { requestAuthConfig } from '../../../utils/utilTools';
import { parseStates } from './planos';
import ButtonDeleteCoupon from '../../icetran/ButtonDeleteCoupon';

const Coupons = ({ history }) => {

  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [couponsAndIds, setCouponsAndIds] = useState([]);
  
  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfCoupons, setTotalNumberOfCoupons] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/coupons?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.coupons);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/allCoupons`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfCoupons(res.data.coupons.length);
      })
      .catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {

      // Populates the table for the first time
      let carrierArray = [];
      responseData.forEach(coupon => {
        carrierArray.push(coupon);
      });
      setTableData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      console.log(tempCountArray);
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/coupons?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.coupons);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const phonePattern = ['+99 (99) 9999-9999', '+99 (99) 99999-9999'];
  const phoneMask = (phoneValue) => {
    return setGenericMask(phoneValue, phonePattern);
  }

  const setGenericMask = (masked, maskPattern) => {
    // return cpf.format(cpfValue);
    masked = unMask(masked);
    return mask(masked, maskPattern);
  }

  const columns = [
    {
      name: 'Cupom',
      selector: 'code',
      sortable: true,
      cell: row => <div>{row.code}</div>,
      center: true,
    },
    {
      name: 'Desconto',
      selector: 'discount',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.discount}</div>,
      center: true,
    },
    {
      name: 'Estados',
      selector: 'fus',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>
          {
            parseStates(row.fus)
          }
        </div>,
      center: true,
    },
    {
      name: 'Disponível?',
      selector: 'available',
      sortable: true,
      center: true,
      cell: row => <div>{ row.available ? 'Sim' : 'Não' }</div>
    },
    {
      name: 'Desabilitado?',
      selector: 'disabled',
      sortable: true,
      center: true,
      cell: row => <div>{ row.disabled ? 'Sim' : 'Não' }</div>
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "50%" }}><li className="list-group-item-action">
        <button
          style={{ marginRight: 8 }}
          onClick={() => history.push({
            pathname: `${process.env.PUBLIC_URL}/editar/cupom`,
            state: {
              couponId: row.couponId,
              couponInfo: row
            }
          })}
          className="btn btn-sm btn-warning">
          <i className="icon icon-pencil"></i>
        </button></li>
        <li className="list-group-item-action">
          <ButtonDeleteCoupon
            coupon={row}
          />
        </li></div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  function searchCoupons(rows) {
    return rows.filter(row =>
      row.code.toLowerCase().indexOf(searchTerm) > -1)
  }
  // }, []);

  return (
    <div>
      <Breadcrumb parent="Financeiro" title="Cupons" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cupons</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/criar/cupom`)} type="button">{New}</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <div className="form-group row">
                    <div className="search_wrap search_wrap_3">
                      <div className="search_box">
                        <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchCoupons(tableData)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />


                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfCoupons}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                              <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                              />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                              <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                              </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                              <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                              />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coupons;

