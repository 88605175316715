import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { CancelUpdateProfile, Create, Option, Salvar, TestEndpoint } from '../../constant';
import { useLocation } from 'react-router';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';

const EditCreditPackage = ({ history }) => {

    const location = useLocation();

    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [credits, setCredits] = useState('');

    useEffect(() => {
        setName(location.state.creditInfo.name);
        setValue(location.state.creditInfo.credits);
        setCredits(location.state.creditInfo.value);
    }, []);

    const submitHandler = event => {
        axios.patch(`${LOCAL_BACKEND_URL}/credit/${location.state.creditId}`, {
            'name': name,
            'credits': parseInt(credits),
            'value': parseFloat(value)
        }, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/pacotes/creditos`);
            })
            .catch(err => {
                console.log(err.response.data);
            })
    };

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Editar SMS</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                            <form className="form">
                                    <FormGroup>
                                        <Label for="name">Nome</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={name}
                                            placeholder="Nome do pacote"
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Créditos</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            value={credits}
                                            placeholder="Quantidade de créditos"
                                            onChange={e => setCredits(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Valor</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            value={value}
                                            placeholder="Valor"
                                            onChange={e => setValue(e.target.value)}
                                        />
                                    </FormGroup>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/pacotes/creditos`)}>{CancelUpdateProfile}</button>
                                        <button className="btn btn-primary ml-3" onClick={submitHandler} type="button">{Salvar}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditCreditPackage;