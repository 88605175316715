import React,{ useState, useEffect } from "react";
import Chart from "react-google-charts";
import { DashboardCourses, DestaqueVendasCursos, Escolar, Infratores, Purchase, Reciclagem, SellingCourses, SellingCoursesCFC, Shipping, Taxista, TodasVendas, TotalSell } from "../../../constant";
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import axios from "axios";

const DestaqueCurso = () => {

    const [data, setData] = useState([])
    
    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/coursessales?page=1&limit=999`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then((response) => {
                setData(response.data.salesNumber)
            })
            .catch((err) => {
                console.log(err)
            });

    }, [])

    const googleData = data.map((d) => [d.course, d.salesNumber]);


    googleData.unshift(["Curso", "Numero de Vendas"])

    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body">
                    <h5><strong>{DestaqueVendasCursos}</strong></h5><span className="d-block fonts-dashboard">{TodasVendas}</span>
                    <Chart
                        height={'350px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={googleData}                   
                        options={{
                            title: 'Vendas Por Cursos',
                            sliceVisibilityThreshold: 0., // 20%
                            slices: [
                                {
                                    color: "#32CD32"
                                },
                                {
                                    color: "#00FF7F"
                                },
                                {
                                    color: "#98FB98"
                                },
                                {
                                    color: "#90EE90"
                                },
                                {
                                    color: "#00FA9A"
                                }
                            ],
                            chartArea: {
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: "70%"
                            }
                        }}
                        rootProps={{ 'data-testid': '7' }}
                    />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Reciclagem}</h6>

                                <h5 className="mb-0 f-18">{"+500"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Reciclagem}</h6>
                                <h5 className="mb-0 f-18">{"+120"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Taxista}</h6>
                                <h5 className="mb-0 f-18">{"-410"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Infratores}</h6>
                                <h5 className="mb-0 f-18">{"-155"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Infratores}</h6>
                                <h5 className="mb-0 f-18">{"+920"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{Escolar}</h6>
                                <h5 className="mb-0 f-18">{"+500"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">{Escolar}</h6>
                                <h5 className="mb-0 f-18">{"+500"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">{Infratores}</h6>
                                <h5 className="mb-0 f-18">{"+120"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0">
                                <h6 className="mb-0 f-w-700">{Escolar}</h6>
                                <h5 className="mb-0 f-18">{"-410"}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DestaqueCurso;