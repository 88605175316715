import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { cnpj } from 'cpf-cnpj-validator';
import { toInteger } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { mask, unMask } from 'remask';
import { Bairro, BirthDate, BoletoB, CancelUpdateProfile, CepCheckout, Cidade, Complemento, Create, Estado, Faturamento1, FU, Numero, Pais, PhoneNumber, Referencia, Rua, TestEndpoint, UpdateProfile } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';


const ClientsCreateTeste = ({ history }) => {

    const [name, setName] = useState('');

    //CPF MASK
    const [cpf, setCpf] = useState('');
    const cpfPatterns = ['999.999.999-99', '99.999.999/9999-99']
    const setCpfValue = (cpfValue) => {
        setCpf(maskGeneric(cpfValue, cpfPatterns))
    }

    const [email, setEmail] = useState('')
    //Numero de Telefone Mask
    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['(99) 9999-9999', '(99) 99999-9999'];
    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    const [idCard, setIdCard] = useState('');
    const [bornDate, setBornDate] = useState('');
    const [createAt, setCreateAt] = useState('');
    const [gender, setGender] = useState('');
    const [clientId, setClientId] = useState('');

    //Adress
    const [country, setCountry] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');

    const cepPatterns = ['99999-999']
    const setCepValue = (cepValue) => {
        setCep(maskGeneric(cepValue, cepPatterns))
    }
    const maskGeneric = (maskValue, patterns) => {
        maskValue = unMask(maskValue)
        return mask(maskValue, patterns)
    }
    useEffect(() => {

        if (unMask(cep).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(cep)}/json/`)
                .then(res => {
                    setRua(res.data.logradouro);
                    setBairro(res.data.bairro)
                    setCidade(res.data.localidade)
                    setEstado(res.data.uf)
                    setCountry('Brasil')
                })
                .catch(err => console.log(err))
        }
        else {
            setRua('')
            setCidade('')
            setBairro('')
            setEstado('')
            setCountry('')
            setCidade('')
        }
    }, [cep])

    const [clientCpf, setClientCpf] = useState('');
    const maskCnpjPatterns = ['999.999.999-99', '99.999.999/9999-99'];
    const cnpjHandler = async (cnpjValue) => {
        setClientCpf(setGenericMask(cnpjValue, maskCnpjPatterns));
    }
    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }



    const submitHandler = event => {
        /* event.preventDefault(); */
        console.log(cidade)
        console.log(country)
        axios.post(`${LOCAL_BACKEND_URL}/client`, {
            'name': name,
            'clientCpf': `${unMask(clientCpf)}`,
            'email': email,
            'phoneNumber': `+55${unMask(phoneNumber)}`,
            'idCard': idCard,
            'bornDate': bornDate,
            'createAt': createAt,
            'gender': gender,
            'clientId': clientId,
            'zipCode': unMask(cep),
            'street': rua,
            'number': `${numero}`,
            'complement': `${complemento}`,
            'district': `${bairro}`,
            'city': `${cidade}`,
            'fu': estado,
            'country': `${country}`,


        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                toast.success('Cliente cadastrado!');
                console.log(res)
                history.push(`${process.env.PUBLIC_URL}/base/lista-clientes`);
            })
            .catch(err => {
                toast.error('Não foi possível cadastrar esse cliente.');
                console.log(err.response.data)
            })
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();
    return (
        <>    
            <div>
            <Breadcrumb parent="Cadastrar Cliente" title="Cadastrar Cliente" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row justify-content-between ml-3 mr-5">
                                        <h5>Cadastrar Cliente</h5>
                                    </div>
                                </div>
                                <div className="datatable-react mr-5 ml-5 form-group">
                                    <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                        <FormGroup>
                                            <Label for="partner">Nome</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Nome do Cliente"
                                                onChange={e => setName(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O nome do Cliente é obrigatória.",
                                                        minLength: 2
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="name" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="cnpj">CPF</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="cnpj"
                                                value={clientCpf}
                                                placeholder="Número do CPF"
                                                onChange={e => cnpjHandler(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O CPF é obrigatório.",
                                                        minLength: 10
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="cnpj" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="partnerRef">Email</Label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                placeholder="email@email.com"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O email é obrigatório.",
                                                        minLength: 3
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="email" />
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="form-label">{PhoneNumber}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={phoneNumber}
                                                placeholder='Telefone com o DDD'
                                                name="phoneNumber"
                                                onChange={(e) => phoneMask(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O número de telefone é obrigatório.",
                                                        minLength: 3
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="phoneNumber" />
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="form-label">Número do RG</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={idCard}
                                                placeholder='Digite somente os números do RG'
                                                name="rg"
                                                onChange={(e) => setIdCard(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O número do RG é obrigatório.",
                                                        minLength: 4
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="rg" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="bornDate">Data de Nascimento</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="bornDate"
                                                placeholder="27/03/1998"
                                                value={bornDate}
                                                onChange={e => setBornDate(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "A data de nascimento é obrigatória.",

                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="bornDate" />
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="form-label">Genêro</label>
                                            <select
                                                className="form-control digits"
                                                id="gender"
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                <option value={null}>{"Escolha um genêro..."}</option>
                                                <option value={'m'}>{"Masculino"}</option>
                                                <option value={'f'}>{"Feminino"}</option>
                                            </select>
                                        </FormGroup>

                                        <div className="row">
                                            {/* <!-- Force next columns to break to new line --> */}
                                            <div className="w-100"></div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">{CepCheckout}</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="cep"
                                                        value={cep}
                                                        onChange={(e) => setCepValue(e.target.value)}
                                                        ref={
                                                            register({
                                                                required: "O número do CEP é obrigatório.",
                                                                minLength: 8
                                                            })
                                                        }
                                                    />
                                                    <ErrorMessage errors={errors} name="cep" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{Rua}</label>
                                                    <input
                                                        value={rua}
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => setRua(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label">{Numero}</label>
                                                    <input
                                                        className="form-control"
                                                        value={numero}
                                                        name="numero"
                                                        onChange={(e) => setNumero(e.target.value)}
                                                        type="text"
                                                        ref={
                                                            register({
                                                                required: "O número é obrigatório.",
                                                                minLength: 1
                                                            })
                                                        }
                                                    />
                                                    <ErrorMessage errors={errors} name="numero" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">{Complemento}</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={complemento}
                                                        name="complemento"
                                                        onChange={(e) => setComplemento(e.target.value)}
                                                        ref={
                                                            register({
                                                                required: "O complemento é obrigatório.",
                                                                minLength: 1
                                                            })
                                                        }
                                                    />
                                                    <ErrorMessage errors={errors} name="complemento" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">{Bairro}</label>
                                                    <input value={bairro}
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => setBairro(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">{Cidade}</label>
                                                    <input value={cidade} className="form-control" type="text"
                                                        onChange={(e) => setCidade(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{Estado}</label>
                                                    <select className="form-control" value={estado}>
                                                        <option value={null}>Selecione o Estado</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                        <option value="DF">Distrito Federal</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{Pais}</label>
                                                    <input
                                                        value={country}
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => setCountry(e.target.value)} />
                                                </div>
                                            </div>
                                            {/* <!-- Force next columns to break to new line --> */}
                                            <div className="w-100"></div>


                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/lista-clientes`)}>{CancelUpdateProfile}</button>
                                            <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                                            {/* <Button
                                                color="primary"
                                                type="submit"
                                                onClick={submitHandler}
                                            >
                                                {Create}
                                            </Button> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}

export default ClientsCreateTeste;