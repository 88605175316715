import axios from "axios";
import { LOCAL_BACKEND_URL } from "../data/urls";

export const fetchProductApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchSales = (url, MaxNumberOfItemsPerTable, currentPagination) => {
    const urlToSend = MaxNumberOfItemsPerTable && currentPagination ? `${LOCAL_BACKEND_URL}${url}?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}` : `${LOCAL_BACKEND_URL}${url}`
    return axios.get(urlToSend, {
        headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
        }
    })
}

export const fetchPartner = async (token, fu) => {

    return axios.get(`${LOCAL_BACKEND_URL}/allPartners/${fu}`, {
        'headers': { 'Authorizaton': `Bearer ${token}` }
    })
        .then(res => res)
        .catch(err => console.log('Error: ', err));
}


