import React, { useState, useEffect } from "react";
import { CPF, Salvar } from "../../constant";
import {
  EditSale,
  Edit,
  UpdateProfile,
  EmailAddress,
  PhoneNumber,
  CancelUpdateProfile,
  Name,
  RetypePassword,
  NewPassword,
  AccessLevel,
} from "../../constant";
import Breadcrumb from "../common/breadcrumb";
import {
  NewId,
  Id,
  Client,
  Course,
  UF,
  SaleValue,
  SaleStatus,
  SaleIntegration,
} from "../../constant";
import { mask, unMask } from "remask";

import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../data/urls";

// Imports for payment amount input mask
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

import { useLocation } from "react-router";

import { MaxNumberOfItemsPerTable } from "../../constant/designControl";

import { requestAuthConfig } from "../../utils/utilTools";

const EditSales = ({ history }) => {
  const location = useLocation();
  console.log(location)

  const [clientWarningVisibility, setClientWarningVisibility] =
    useState("none");
  const [clientFormVisibility, setClientFormVisibility] = useState("none");
  const [clientWarningMessage, setClientWarningMessage] = useState("");
  // State variables to retrieve and organize course data
  const [course, setCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseTitles, setCourseTitles] = useState([]);
  const [coursesTitlesAndReferences, setCoursesTitlesAndReferences] =
    useState("");

  // Client/clients state
  const [clients, setClients] = useState([]);
  const [tempClientsData, setTempClientsData] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(-1);
  const [client, setClient] = useState("");
  const [clientsAndIds, setClientsAndIds] = useState({});
  const [idsAndClients, setIdsAndClients] = useState({});
  const [clientCpf, setClientCpf] = useState("");
  const [clientCNh, setClientCNH] = useState("");

  // Partner/partners state
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState("");
  const [partnersAndIds, setPartnersAndIds] = useState("");
  const [idsAndPartners, setIdsAndPartners] = useState({});
  const [partnersNamesAndReferences, setPartnersNamesAndReferences] = useState(
    {}
  );

  const [value, setValue] = useState("");
  const [fu, setFu] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [integration, setIntegration] = useState("");
  const [invoiced, setInvoiced] = useState("");
  const [saleAmount, setSaleAmount] = useState("");

  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("isAdmin") === "true"
  );

  useEffect(() => {

    // requests for the resources necessary to populate the form
    axios
      .get(`${LOCAL_BACKEND_URL}/courses`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCourses([...res.data.courses]);
      })
      .catch((err) => console.log(err.message));

    axios
      .get(`${LOCAL_BACKEND_URL}/allPartners`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPartners([...res.data.partners]);
      })
      .catch((err) => console.log(err.message));

    axios
      .get(`${LOCAL_BACKEND_URL}/allClients`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setClients([...res.data.clients]);
      })
      .catch((err) => console.log(err.message));

    axios
      .get(`${LOCAL_BACKEND_URL}/client/${location.saleInfo.clientId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setClientCpf(res.data.client.clientCpf);
      })
      .catch((err) => console.log(err.message));

      
    
    setClientCNH(location.saleInfo.cnh);
    setCourse(location.saleInfo.courseTitle);
    setSaleAmount((location.saleInfo.value / 100).toLocaleString());
    setClient(location.saleInfo.clientName);
    setFu(location.saleInfo.fu);
    setPaymentMethod(location.saleInfo.paymentMethod);
    setStatus(3);
    setIntegration(2);

    console.log(isAdmin);
  }, []);

  useEffect(() => {
    if (courses.length > 0) {
      const tempTitlesAndReferences = {};
      const tempCourseTitles = [];
      courses.forEach((course) => {
        tempTitlesAndReferences[course.title] = course.reference;
        tempCourseTitles.push(course.title);
      });
      console.log(tempTitlesAndReferences);
      console.log(tempCourseTitles);
      setCoursesTitlesAndReferences(tempTitlesAndReferences);
      setCourseTitles(tempCourseTitles);
    }
  }, [courses]);

  useEffect(() => {
    if (partners.length > 0) {
      const tempPartnersAndIds = {};
      const tempIdsAndPartners = {};
      const tempPartnersAndReferences = {};
      partners.forEach((partner) => {
        tempPartnersAndIds[partner.partnerId] = partner.name;
        tempIdsAndPartners[partner.name] = partner.partnerId;
        tempPartnersAndReferences[partner.name] = partner.partnerReference;
      });
      console.log(tempPartnersAndIds);
      setPartnersAndIds(tempPartnersAndIds);
      setIdsAndPartners(tempIdsAndPartners);
      setPartnersNamesAndReferences(tempPartnersAndReferences);
    }
  }, [partners]);

  useEffect(() => {
    if (partnersAndIds.length > 0) {
      setPartner(partnersAndIds[location.saleInfo.partnerId]);
    }
  }, [partnersAndIds, partners]);

  useEffect(() => {
    if (clients.length > 0) {
      const tempClientsAndIds = {};
      const tempIdsAndClients = {};
      clients.forEach((client) => {
        tempClientsAndIds[client.clientId] = client.name;
        tempIdsAndClients[client.name] = client.clientId;
      });
      setClientsAndIds(tempClientsAndIds);
      setIdsAndClients(tempIdsAndClients);
    }
  }, [clients]);

  // Mask for sale amount
  const saleMask = createNumberMask({
    prefix: "R$ ",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
    integerLimit: 13,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  const maskCpfPatterns = ["999.999.999-99"];

  const cpfHandler = async (cpfValue) => {
    setClientCpf(setGenericMask(cpfValue, maskCpfPatterns));
  };

  const setGenericMask = (masked, maskPattern) => {
    masked = unMask(masked);
    return mask(masked, maskPattern);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      clientCpf: unMask(clientCpf),
      courseReference: coursesTitlesAndReferences[course],
      value: parseSaleAmount(saleAmount),
      fu: fu,
      paymentMethod: parseInt(paymentMethod),
      status: parseInt(status),
      integration: parseInt(integration),
      invoiced: parseInt(invoiced),
      cnh: clientCNh,
    };

    axios
      .patch(`${LOCAL_BACKEND_URL}/sale/${location.saleId}`, data, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        history.push(`${process.env.PUBLIC_URL}/base/vendas`);
        console.log(res.request);
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <Breadcrumb parent="Vendas" title="Editar Vendas" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    Edit {EditSale}
                  </h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{Course}*</label>
                        <select
                          className="form-control"
                          value={course}
                          onChange={(e) => setCourse(e.target.value)}
                        >
                          <option select="">{"Escolha um curso..."}</option>
                          {courseTitles.map((courseTitle) => {
                            return <option>{courseTitle}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    {isAdmin ? (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Valor da venda</label>
                          <input className="form-control"
                          value={saleAmount}
                          onChange={(e) => setSaleAmount(e.target.value)}
                          >

                          </input>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {isAdmin ? (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">CNH</label>
                          <input className="form-control"
                          value={clientCNh}
                          onChange={(e) => setClientCNH(e.target.value)}
                           />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{Client}</label>
                        <select
                          className="form-control"
                          value={client}
                          disabled={true}
                        >
                          <option select="">{client}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Estado</label>
                        <select
                          className="form-control"
                          value={fu}
                          onChange={(e) => setFu(e.target.value)}
                        >
                          <option value={null}>Selecione o Estado</option>
                          <option value="SP">São Paulo</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="PR">Paraná</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RR">Roraima</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="DF">Distrito Federal</option>
                        </select>
                      </div>
                    </div>
                    {isAdmin ? (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Meio de pagamento
                          </label>
                          <select
                            className="form-control"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          >
                            <option value={null}>
                              Selecione o meio de pagamento
                            </option>
                            <option value={0}>Nenhum</option>
                            <option value={1}>Boleto</option>
                            <option value={2}>Cartão</option>
                            <option value={3}>Pix</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isAdmin ? (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Faturado?</label>
                          <select
                            className="form-control"
                            value={invoiced}
                            onChange={(e) => setInvoiced(e.target.value)}
                          >
                            <option value={null}>
                              Selecione o estado do faturamento...
                            </option>
                            <option value={0}>Não faturado</option>
                            <option value={1}>Faturado</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/base/vendas`)
                    }
                  >
                    {CancelUpdateProfile}
                  </button>
                  <button
                    className="btn btn-primary ml-3"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {Salvar}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSales;

const parseSaleAmount = (amount) => {
  return amount.replace(/^\D+/g, "").split(".").join("");
};
