import React from "react";
import { 
    BirthCheckout, 
    CategoryCheckout, 
    CnhCheckout, 
    EmailAddress, 
    Name, 
    Renach, 
    RgCheckout, 
    TelCheckout 
} from "../../constant";

export default function CheckoutBody(props) {
    return (
        <div className="row">
            <div className="col-lg-12">
                <form className="card">
                    <div className="card-body">
                        <div className="row">
                            {/* <!-- Force next columns to break to new line --> */}
                            <div className="w-100"></div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label" >{Name}*</label>
                                    <input className="form-control" type="text" onChange={(e) => props.setClientName(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label">{EmailAddress}*</label>
                                    <input className="form-control" type="email" onChange={(e) => props.setClientEmail(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label">{TelCheckout}</label>
                                    <input className="form-control" type="tel" id="phone" name="phone" value={props.telNumber} onChange={(e) => props.telMask(e.target.value)} />                                            </div>
                            </div>
                            <div className="w-100"></div>
                        </div>
                    </div>
                    <div className="checkInfo">
                        <div className="row">
                            {/* <!-- Force next columns to break to new line --> */}
                            <div className="w-100"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">{BirthCheckout}</label>
                                    <input className="form-control" type="date" onChange={(e) => props.setClientBirth(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="form-label">{RgCheckout}</label>
                                    <input className="form-control" type="text" onChange={(e) => props.setClientRg(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Gênero</label>
                                    <select className="form-control" onChange={(e) => props.setGender(e.target.value)}>
                                        <option selected disabled>Selecionar</option>
                                        <option value="m">Masculino</option>
                                        <option value="m">Feminino</option>
                                        <option value="ni">Prefiro não informar</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">{CnhCheckout}</label>
                                    <input className="form-control" type="text" onChange={(e) => props.setClientCnh(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label className="form-label">{CategoryCheckout}</label>
                                    <input className="form-control" type="text" onChange={(e) => props.setClientCnhCategory(e.target.value)}/>                                            </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">{Renach}</label>
                                    <input className="form-control" type="text" onChange={(e) => props.setClientRenach(e.target.value)}/>
                                </div>
                            </div>
                            <div className="w-100"></div>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}