import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Users, New, } from '../../../constant';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import man from '../../../assets/images/dashboard/user.png';
import '../../../components/base/lists/filter.css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonDeleteCredit from '../../icetran/ButtonDeleteCredit';
import { db } from "../../../data/config";
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { mask, unMask } from 'remask';

import { requestAuthConfig } from '../../../utils/utilTools';

const CreditsPerPackage = ({ history }) => {

  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [partnersIds, setPartnersIds] = useState({});
  
  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfCreditsTransactions, setTotalNumberOfCreditsTransactions] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/creditstransactions?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.categories);
        setNumberOfPages(response.data.info.numPages);
        
        const temporaryPartners = [];
        let changingObject = {};
        const temporaryPartnersIds = {};
        
        response.data.categories.forEach(category => temporaryPartners.push(category.partnerId));
        temporaryPartners.forEach(partnerId => {
            axios.get(`${LOCAL_BACKEND_URL}/partner/${partnerId}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                  }
            })
                .then(res => {
                    changingObject = partnersIds;
                    temporaryPartnersIds[partnerId] = res.data.Partner.name;
                    setPartnersIds({...changingObject, ...temporaryPartnersIds});
                })
                .catch(err => console.log(err));
        })
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/allCreditsTransactions`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfCreditsTransactions(res.data.creditsTransactions.length);
      })
      .catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {

      // Populates the table for the first time
      let carrierArray = [];
      responseData.forEach(credit => {
        carrierArray.push(credit);
        carrierArray[carrierArray.length - 1].date = new Date(credit.createdAt._seconds * 1000).toLocaleDateString('pt-BR');
      });
      setTableData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);

      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/credits?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.credits);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const columns = [
    {
      name: 'Nome do cartão',
      selector: 'tid',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.tid}</div>,
      center: true
    },
    {
      name: 'Parceiro',
      selector: 'partnerId',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{partnersIds[row.partnerId]}</div>,
      center: true,
    },
    {
      name: 'Tipo',
      selector: 'type',
      sortable: true,
      center: true,
      cell: row => <div>{row.type}</div>
    },
    {
      name: 'Data',
      selector: 'date',
      sortable: true,
      center: true,
      cell: row => <div>{row.date}</div>
    },
    {
      name: 'Valor',
      selector: 'value',
      sortable: true,
      center: true,
      cell: row => <div>{row.packageValue}</div>
    },
  ];

  // const contextActions = useMemo(() => {
  function searchCredits(rows) {
    return rows.filter(row =>
      row.tid.toLowerCase().indexOf(searchTerm) > -1 ||
      row.date.toString().indexOf(searchTerm) > -1 ||
      row.packageValue.toString().indexOf(searchTerm) > -1)
  }
  // }, []);
console.log(tableData)
  return (
    <div>
      <Breadcrumb parent="Adição de créditos" title="Adição de créditos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
              <div className="row justify-content-between ml-3 mr-5">
                  <h5>Adição de Créditos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/creditos/adicionar`)} type="button">{New}</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <div className="form-group row">
                    <div className="search_wrap search_wrap_3">
                      <div className="search_box">
                        <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchCredits(tableData)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />


                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfCreditsTransactions}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                              <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                              />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                              <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                              </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                              <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                              />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditsPerPackage;
