import {
    DollarSign,
    Users,
    User,
    Book,
    UserPlus,
    ShoppingCart,
    BarChart2,
    Flag,
    CreditCard
} from 'react-feather';

import { FaUserTie, FaUsers, FaRegHandPointRight } from 'react-icons/fa';
import { AiOutlineHome } from 'react-icons/ai';
import { BsChatLeftDotsFill } from 'react-icons/bs';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { GiTeamDowngrade } from 'react-icons/gi'
import { BiMapAlt } from "react-icons/bi";





export const MENUITEMS = localStorage.getItem('isAdmin') === 'true' ? [
    {
        title: 'Dashboard', icon: AiOutlineHome, path: '/dashboard/default', type: 'link', active: false
    },
    {
        title: 'Vendas', icon: ShoppingCart, path: '/base/vendas', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Clientes', icon: Users, type: 'sub', badgeType: 'primary', active: false,
        children: [
            { path: '/base/lista-clientes', title: 'Clientes', type: 'link'},
            { path: '/lista-de-espera', title: 'Lista de Espera CP', type: 'link'},
            
        ]
    },
    {
        title: 'Parceiros', icon: FaUserTie, type: 'sub', badgeType: 'primary', active: false,
        children: [
            { path: '/parceiros/estados', title: 'Todos', type: 'link' },
            { path: '/base/contratos', title: 'Contratos', type: 'link' },
            { title: 'Solicitações', type: 'sub', badgeType: 'primary', active: false,
                children: [
                    { path: '/parceiros/solicitacao/aprovacao', type: 'link', title: 'Pendentes de aprovação' },
                    { path: '/parceiros/solicitacao/contrato', type: 'link', title: 'Pendentes de contrato' }
                ]
            },
            
        ]
    },
    {
        title: 'Produtos', icon: Book, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/base/cursos', title: 'Todos os Produtos', type: 'link' },
            { path: '/base/categorias', title: 'Categorias', type: 'link' },
            { path: '/base/planos', title: 'Planos', type: 'link' }
        ]
    },
    {
        title: 'Usuários', icon: FaUsers, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/base/lista', title: 'Gestores', type: 'link' },
            { path: '/base/times', title: 'Times', type: 'link' }
        ]
    },
    {
        title: 'Financeiro', icon: FaMoneyCheckAlt, type: 'sub', badgeType: 'primary', active: false,
        children: [
            { path: '/financeiro/faturas', title: 'Faturas', type: 'link' },
            /*{ path: '/compra-pacotes', title: 'Pacotes de crédito', type: 'link' },
            { path: '/alterar-plano', title: 'Alterar Plano', type: 'link' },*/
            { path: '/base/cupons', title: 'Cupons', type: 'link' }
        ]
    },
    {
        title: 'Relacionamentos', icon: BsChatLeftDotsFill, type: 'sub', badgeType: 'primary', active: false,
        children: [
            { path: '/relacionamentos/sms', title: 'Modelo de SMS Automático', type: 'link' },
            { path: '/relacionamentos/email', title: 'Modelo de Email Automático', type: 'link' },
            { path: '#', title: 'Log de SMSs', type: 'link' },
            { path: '#', title: 'Log de Emails', type: 'link' },
            { path: '/banners', title: 'Banners', type: 'link'}
        ]
    },
    {
        title: 'Relatórios', icon: FaRegHandPointRight, type: 'sub', badgeType: 'primary', active: false,
        children: [
            { path: '/base/relatorio', title: 'Relatório de Vendas', type: 'link' }
        ]
    },
    {
        title: 'Pacotes de créditos', icon: DollarSign, path: '/pacotes/creditos', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Adição de créditos', icon: CreditCard, path: '/creditos/por-pacote', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Estados autorizados', icon: BiMapAlt, path: '/estadosautorizados', type: 'link', badgeType: 'primary', active: false
    }
] : [
    {
        title: 'Dashboard', icon: AiOutlineHome, path: '/dashboard/default', type: 'link', active: false
    },
    {
        title: 'Vendas', icon: ShoppingCart, path: '/base/vendas', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Faturas', icon: FaMoneyCheckAlt, path: '/financeiro/faturas', type: 'link', active: false
    },
    {
        title: 'Pacotes de crédito', icon: ShoppingCart, path: '/compra-pacotes', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Alterar Plano', icon: ShoppingCart, path: '/alterar-plano', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Relatório de Vendas', icon: ShoppingCart, path: '/base/relatorio', type: 'link', badgeType: 'primary', active: false
    }
];
