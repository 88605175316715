import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { Create, Option, TestEndpoint } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

import { StatesPerGroupBR } from '../../../utils/utilTools';

const CreateCoupon = ({ history }) => {

    const [type, setType]                         = useState(0);
    const [discount, setDiscount]                 = useState(0);
    const [code, setCode]                         = useState(null);
    const [courses, setCourses]                   = useState([]);
    const [course, setCourse]                     = useState('');
    const [partners, setPartners]                 = useState([]);
    const [fus, setFus]                           = useState([]);
    const [limit, setLimit]                       = useState(null);
    const [limitDate, setLimitDate]               = useState(null);
    const [partner, setPartner]                   = useState('');
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [selectedCourses, setSelectedCourses]   = useState([]);
    const [isPartner, setIsPartner]               = useState(false);
    const [partnersAreEnabled, setPartnersAreEnabled] = useState(true);

    const [coursesTitlesAndIds, setCoursesTitlesAndIds] = useState('');
    const [partnersNamesAndIds, setPartnersNamesAndIds] = useState({});

    useEffect(() => {
      axios.get(`${LOCAL_BACKEND_URL}/everySingleCourse`, {
          headers: {
          'Authorization': `bearer ${localStorage.getItem('token')}`
          }
      })
          .then((res) => {
              setCourses([...res.data.courses]);
          })
          .catch(err => console.log(err.message));

      axios.get(`${LOCAL_BACKEND_URL}/everySinglePartner`, {
          headers: {
          'Authorization': `bearer ${localStorage.getItem('token')}`
          }
      })
          .then((res) => {
            setPartners([...res.data.partners]);
          })
          .catch(err => console.log(err.message));

  }, []);

  useEffect(() => {
    if (courses.length > 0) {
        const tempTitlesAndIds = {};
        courses.forEach(course => {
            tempTitlesAndIds[course.title] = course.courseId;
        });
        console.log(tempTitlesAndIds)
        setCoursesTitlesAndIds(tempTitlesAndIds);
    }
}, [courses]);

useEffect(() => {
    if (partners.length > 0) {
        const tempPartnersNamesAndIds = {};
        partners.forEach(partner => {
            tempPartnersNamesAndIds[partner.name] = partner.partnerId;
        });
        setPartnersNamesAndIds(tempPartnersNamesAndIds);
    }
}, [partners]);

    const addOrRemoveFu = (fu) => {
      const tempFus = [...fus];
      if (tempFus.includes(fu)) {
        tempFus.splice(tempFus.indexOf(fu), 1);
      }
      else {
        tempFus.push(fu);
      }
      setFus(tempFus);
      console.log(tempFus);
    }

    const addOrRemoveCourse = (course) => {
      const tempCourses = [...selectedCourses];
      tempCourses.includes(course) ? tempCourses.splice(tempCourses.indexOf(course), 1) : tempCourses.push(course);
      setSelectedCourses(tempCourses);
    }

    const addOrRemovePartner = (partner) => {
      const tempPartners = [...selectedPartners];
      if (tempPartners.includes(partner)) {
        tempPartners.splice(tempPartners.indexOf(partner), 1);
      }
      else {
        tempPartners.push(partner);
      }
      console.log(partner)
      setSelectedPartners(tempPartners);
    }

    const submitHandler = event => {
        event.preventDefault();

        axios.post(`${LOCAL_BACKEND_URL}/coupon`, {
          type,
          discount: parseInt(discount),
          code,
          courses: selectedCourses,
          partners: selectedPartners,
          fus,
          limit: parseInt(limit),
          limitDate,
          partner: isPartner === '1' ? true : false

        }, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
            .then(res => {
                console.log(res);
                console.log(res.data);
                history.push(`${process.env.PUBLIC_URL}/base/cupons`);
            })
            .catch(err => console.log(err.response.data));
    }

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Cadastrar Cupom</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form">
                                  <div className="col-md-12" style={{ display: 'flex' }}>
                                    <div className='col-md-6'>
                                      <FormGroup>
                                        <div className="form-group">
                                            <Label for="type">Tipo</Label>
                                            <select
                                              className="form-control"
                                              value={type}
                                              onChange={(e) => setType(e.target.value)}
                                            >

                                                <option value={null}>Escolha o tipo</option>
                                                <option value={0}>Percentual</option>
                                                <option value={1}>Em dinheiro</option>

                                            </select>
                                        </div>
                                      </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                      <FormGroup>
                                          <Label for="discount">Desconto</Label>
                                          <input
                                              className="form-control"
                                              value={discount}
                                              type="text"
                                              name="discount"
                                              placeholder="Desconto"
                                              onChange={e => setDiscount(e.target.value)}
                                          />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="col-md-12" style={{ display: 'flex' }}>
                                    <div className='col-md-6'>
                                      <FormGroup>
                                          <Label for="discount">Código</Label>
                                          <input
                                              className="form-control"
                                              value={code}
                                              type="text"
                                              name="code"
                                              placeholder="Código"
                                              onChange={e => setCode(e.target.value)}
                                          />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <FormGroup check inlist onChange={(e) => addOrRemoveCourse(e.target.value)}>
                                      <label className="form-label">Selecione os cursos</label>
                                      <div
                                        className="col-md-12"
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap'
                                        }}
                                      >
                                        {
                                          courses.map(course => {
                                            return (
                                              <div className="col-md-4">
                                                <Input className="form-check-input" type="checkbox" value={ course.courseId } />
                                                <Label className="form-check-label">
                                                  {course.title}
                                                </Label>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12 mt-3" style={{ display: 'flex' }}>
                                    
                                    <div className="col-md-6">
                                      <div className="form-group">
                                          <label className="form-label">Parceiro?</label>
                                          <select
                                            className="form-control"
                                            value={isPartner}
                                            onChange={e => {
                                              setPartnersAreEnabled(e.target.value !== '1');
                                              setIsPartner(e.target.value);
                                            }}
                                          >
                                            <option value={null}>{"Escolha uma resposta"}</option>
                                            <option value={1}>Sim</option>
                                            <option value={0}>Não</option>                                              
                                          </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12"></div>
                                  <div className="col-md-12 mt-3">
                                    <FormGroup check inlist onChange={(e) => addOrRemovePartner(e.target.value)} disabled={partnersAreEnabled}>
                                      <label className="form-label">Selecione os parceiros</label>
                                      <div
                                        className="col-md-12"
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap'
                                        }}
                                      >
                                        {
                                          partners.map(partner => {
                                            return (
                                              <div className="col-md-4">
                                                <Input className="form-check-input" type="checkbox" value={ partner.partnerId } disabled={partnersAreEnabled} />
                                                <Label className="form-check-label">
                                                  {partner.name}
                                                </Label>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </FormGroup>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <FormGroup check inlist onChange={(e) => addOrRemoveFu(e.target.value)}>
                                      <h5 className="row" for="url">Estados</h5>
                                      <div className="row">
                                        <div className="col-3">
                                            {
                                              StatesPerGroupBR.UF[0].map(stateGroup => {
                                                return (
                                                  <li>
                                                  <Input type="checkbox" value={ stateGroup.sigla } />
                                                  <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                  </li>
                                                )
                                              })
                                            }
                                        </div>
                                        <div className="col-3">
                                        {
                                            StatesPerGroupBR.UF[1].map(stateGroup => {
                                              return (
                                                <li>
                                                <Input type="checkbox" value={ stateGroup.sigla } />
                                                <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                </li>
                                              )
                                            })
                                          }
                                        </div>

                                        <div className="col-3">
                                          {
                                            StatesPerGroupBR.UF[2].map(stateGroup => {
                                              return (
                                                <li>
                                                <Input type="checkbox" value={ stateGroup.sigla } />
                                                <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                </li>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12 mt-3" style={{ display: 'flex' }}>
                                    <div className='col-md-6'>
                                      <FormGroup>
                                          <Label for="discount">Limite</Label>
                                          <input
                                            className="form-control"
                                            value={limit}
                                            type="text"
                                            name="limit"
                                            placeholder="Limite"
                                            onChange={e => setLimit(e.target.value)}
                                          />
                                      </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                          <label className="form-label">Data limite</label>
                                          <input
                                            className="form-control"
                                            type="date"
                                            value={limitDate}
                                            onChange={e => setLimitDate(e.target.value)}
                                          />
                                      </div>
                                    </div>
                                  </div>
                                  <Button
                                      color="primary"
                                      type="button"
                                      onClick={submitHandler}
                                  >
                                      {Create}
                                  </Button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default CreateCoupon;