import React, { useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { FaCogs } from 'react-icons/fa';
import {
  Sells,
  New,
  PendentSale,
  CompleteSale,
  CompleteAllSale,
  Warning,
  Border,
  CancelSales,
  DenySales,
} from "../../../constant";
import "../../../components/base/lists/filter.css";
import { Typeahead } from "react-bootstrap-typeahead";
import DataTable from "react-data-table-component";
import { Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import { db } from "../../../data/config";
import { tableDataVendas } from "../../../data/dummyTableDataVendas";
import { useState } from "react";
import "chartjs-plugin-datalabels";
import { DollarSign, Tag, ShoppingBag } from "react-feather";
import CountUp from "react-countup";
import ChartistGraph from "react-chartist";
import { chartsmall } from "../../../data/default";
import configDB from "../../../data/customizer/config";
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import { GrMoney } from "react-icons/gr";
import { GiMoneyStack, GiRopeway } from "react-icons/gi";
import ButtonDeleteSell from "../../icetran/ButtonDeleteSell";

import { MaxNumberOfItemsPerTable } from "../../../constant/designControl";

import { formatReais } from "../../../utils/utilTools";

import { requestAuthConfig } from "../../../utils/utilTools";
import SaleDetail from "../salesReport/saleDetail";

function oraculoIntegration(id) {
  axios
      .post(
        `${LOCAL_BACKEND_URL}/oraculoIntegration`,
        {
          id: id
        },
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
          },
        },
      )
      .then((response) => {
      })
      .catch((err) => {
        console.log(err.message);
      });
 
}

const primary =
  localStorage.getItem("primary_color") || configDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  configDB.data.color.secondary_color;

const Vendas = ({ history }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [responseData, setResponseData] = useState([]);
  const [refresh, setRefresh] = useState();
  const [data, setData] = useState([]);
  const [saleClient, setSaleClient] = useState("");
  const [saleAndId, setSaleAndId] = useState([]);

  const [salesAndId, setSalesAndId] = useState({});
  const [dataDel, setDataDel] = useState([]);

  const [salesData, setSalesData] = useState([]);
  const [monthValuePendent, setMonthValuePendent] = useState("");
  const [monthValueOk, setMonthValueOk] = useState("");
  const [monthValueDeny, setMonthValueDeny] = useState("");

  const [clients, setClients] = useState([]);
  const [clientsAndIds, setClientsAndIds] = useState({});

  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfSales, setTotalNumberOfSales] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const [numberOfPendingSales, setNumberOfPendingSales] = useState(0);
  const [numberOfCompletedSales, setNumberOfCompletedSales] = useState(0);
  const [numberOfDeniedSales, setNumberOfDeniedSales] = useState(0);

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  // Details
  const [salesReportModal, setSalesReportModal] = useState(false);
  const [saleDatailed, setSaleDatailed] = useState({});
  const [requestLogIsOpen, setRequestLogIsOpen] = useState(false);
  const [registrationEmail, setRegisstrationEmail] = useState("");
  const [registrationDistrict, setRegistrationDistrict] = useState("");
  const [registrationPhone, setRegistrationPhone] = useState("");
  const [registrationCEP, setRegistrationCEP] = useState("");
  const [registrationCity, setRegistrationCity] = useState("");
  const [registrationBirthDay, setRegistrationBirthDay] = useState("");
  const [registrationDoc, setRegistrationDoc] = useState("");
  const [registrationAdress, setRegistrationAdress] = useState("");
  const [registrationFu, setRegistrationFu] = useState("");
  const [registrationPaymentType, setRegistrationPaymentType] = useState(0);
  const [registrationInstallments, setRegistrationInstallments] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [registrationFinantialType, setRegistrationFinantialType] =
    useState("");
  const [registrationInstallmentValue, setRegistrationInstallmentValue] =
    useState("");
  const [registrationFullValue, setRegistrationFullValue] = useState("");
  const [
    registrationExpirationOfTheFirst,
    setRegistrationExpirationOfTheFirst,
  ] = useState("");
  const [registrationExpirationOfTheLast, setRegistrationExpirationOfTheLast] =
    useState("");
  const [registrationClerkId, setRegistrationClerkId] = useState("");
  const [registrationCfcId, setregistrationCfcId] = useState("");
  const [registrationCourseIdsString, setRegistrationCourseIdsString] =
    useState("");
  const [registrationOfferId, setRegistrationOfferId] = useState("");
  const [registrationClassId, setRegistrationClassId] = useState("");
  const [registrationName, setRegistrationName] = useState("");
  const [registrationAdressNumber, setregistrationAdressNumber] = useState(0);
  const [registrationCountry, setRegistrationCountry] = useState("");
  const [registrationRG, setRegistrationRG] = useState("");
  const [registrationSaleDate, setRegistrationSaleDate] = useState("");
  const [registrationContractValue, setRegistrationContractValue] =
    useState("");

  const isAdmin = localStorage.getItem("isAdmin");

  useEffect(() => {
    // GET request to retrieve all registered sales
    axios
      .get(
        `${LOCAL_BACKEND_URL}/sales?limit=${MaxNumberOfItemsPerTable}&page=${
          currentPagination + 1
        }`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setResponseData([...response.data.sales]);
        setNumberOfPages(response.data.info.numPages);
        setSalesData([...response.data.sales]);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(`${LOCAL_BACKEND_URL}/allSales`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((response) => {
        response.data.sales.forEach((sale) => {
          if (sale.status === 2) {
            setNumberOfPendingSales((pendingSales) => pendingSales + 1);
          } else if (sale.status === 3) {
            setNumberOfCompletedSales((completedSales) => completedSales + 1);
          } else if (sale.status === 1) {
            setNumberOfDeniedSales((deniedSales) => deniedSales + 1);
          }
        });
      })
      .catch((err) => console.log(err.error.message));

    //Get de vendas mensais
    axios
      .get(`${LOCAL_BACKEND_URL}/sales/${month}/${year}/2`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMonthValuePendent(response.data.totalValue);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(`${LOCAL_BACKEND_URL}/sales/${month}/${year}/3`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMonthValueOk(response.data.totalValue);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .get(`${LOCAL_BACKEND_URL}/sales/${month}/${year}/0`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMonthValueDeny(response.data.totalValue);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${LOCAL_BACKEND_URL}/allSales`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTotalNumberOfSales(res.data.sales.length);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      const tempSalesAndIds = {};
      responseData.forEach((sale) => {
        carrierArray.push(sale);
        carrierArray[carrierArray.length - 1].date = new Date(
          sale.date._seconds * 1000
        ).toLocaleDateString("pt-BR");
        tempSalesAndIds[sale.saleId] = sale;
      });
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);
      setSaleAndId(tempSalesAndIds);

      // Creates an array containing the number of pages the pagination should have
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios
      .get(
        `${LOCAL_BACKEND_URL}/sales?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setResponseData(response.data.sales);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (salesData.length > 0) {
      console.log(salesData);
      let carrierArray = [];
      let salesAndIdCarrier = {};
      salesData.forEach((sale) => {
        carrierArray.push(sale);
        salesAndIdCarrier[sale.clientName] = sale.saleId;
      });
      setDataDel(carrierArray);
      setSalesAndId({ ...salesAndIdCarrier });
    }
  }, [salesData]);

  useEffect(() => {
    if (clients.length > 0) {
      console.log(clients);
      const tempClientsAndIds = {};
      clients.forEach((client) => {
        tempClientsAndIds[client.clientId] = client.name;
      });
      setClientsAndIds(tempClientsAndIds);
    }
  }, [clients]);

  useEffect(() => {
    if (responseData > 0) {
      console.log(responseData);
      console.log(responseData.totalValue);
      console.log(clientsAndIds);
      console.log(monthValuePendent);
    }
  }, [clientsAndIds]);

  const columns = [
    {
      name: "Data",
      selector: "createAt",
      sortable: true,
      center: true,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>
          {row.date}
        </div>
      ),
    },
    {
      name: "Aluno",
      selector: "clientName",
      sortable: true,
      center: true,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>
          {row.clientName}
        </div>
      ),
    },
    {
      name: "Curso",
      selector: "courseTitle",
      sortable: true,
      center: true,
      grow: 2,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>
          {row.courseTitle}
        </div>
      ),
    },
    {
      name: "Parceiro",
      selector: "nome",
      sortable: true,
      center: true,
      grow: 2,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>
          {row.requestLog && row.requestLog.matriculas.nome}
        </div>
      ),
    },
    {
      name: "UF",
      selector: "fu",
      sortable: true,
      center: true,
      cell: (row) => (
        <div style={{ overflow: "inherit", textAlign: "center" }}>{row.fu}</div>
      ),
    },
    {
      name: "Valor",
      selector: "value",
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          {(() => {
            try {
              return formatReais(row.value);
            } catch (err) {
              return `R$ ${Number(row.value / 100).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(() => {
            if (row.status === 0) {
              return (
                <div className="alert alert-danger p-2" role="alert">
                  Negada
                </div>
              );
            } else if (row.status === 1) {
              return (
                <div className="alert alert-danger p-2" role="alert">
                  Cancelado
                </div>
              );
            } else if (row.status === 2) {
              return (
                <div className="alert alert-warning p-2" role="alert">
                  Pendente
                </div>
              );
            } else if (row.status === 3) {
              return (
                <div className="alert alert-success p-2" role="alert">
                  Concluído
                </div>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Integração",
      selector: "integration",
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          style={{
            minWidth: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(() => {
            if (row.integration === 0) {
              return (
                <div className="alert alert-dark p-2 " role="alert">
                  Nenhum
                </div>
              );
            } else if (row.integration === 1) {
              return (
                <div className="alert alert-success p-2" role="alert">
                  Integrado
                </div>
              );
            } else if (row.integration === 2) {
              return (
                <div className="alert alert-warning p-2" role="alert">
                  Pendente
                </div>
              );
            } else if (row.integration === 3) {
              return (
                <div className="alert alert-danger p-2" role="alert">
                  Erro
                </div>
              );
            } else return "Inválido";
          })()}
        </div>
      ),
    },
    {
      name: "Ações",
      selector: "edit",
      sortable: false,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            minWidth: "200px",
            marginBottom: "1.5vh",
          }}
        >
          <li className="list-group-item-action">
            <button
              className="btn btn-sm btn-info"
              onClick={() => showDetails(row)}
            >
              <i className="icon icon-plus"></i>
            </button>
          </li>
          <li className="list-group-item-action">
            <button
              className="btn btn-sm btn-success"
              onClick={() => oraculoIntegration(row.saleId)}
              styled={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FaCogs />
            </button>
          </li>
          
          <li className="list-group-item-action">
            <button
              onClick={() =>
                history.push({
                  pathname: `${process.env.PUBLIC_URL}/edit/vendas`,
                  saleId: row.saleId,
                  saleInfo: { ...saleAndId[row.saleId] },
                })
              }
              className="btn btn-sm btn-warning"
            >
              <i className="icon icon-pencil"></i>
            </button>
          </li>
          <li className="list-group-item-action">
            <ButtonDeleteSell sale={row} saleId={row.saleId} />
          </li>
        </div>
      ),
      center: true,
    },
  ];
  function searchSales(rows) {
    return rows.filter(row =>
        row.clientName.toLowerCase().indexOf(searchTerm) > -1 ||
        row.courseTitle.toLowerCase().indexOf(searchTerm) > -1 ||
        row.fu.toLowerCase().indexOf(searchTerm) > -1)
  }

  function showDetails(sale) {
    if (sale.requestLog) {
      setRegistrationClassId(sale.requestLog.matriculas.idturma);
      setRegistrationOfferId(sale.requestLog.matriculas.idoferta);
      setRegistrationCourseIdsString(sale.requestLog.matriculas.idcurso);
      setregistrationCfcId(sale.requestLog.matriculas.idcfc);
      setRegistrationClerkId(sale.requestLog.matriculas.idatendente);
      setRegistrationExpirationOfTheLast(
        sale.requestLog.matriculas.financeiro.vencimento_ultima
      );
      setRegistrationExpirationOfTheFirst(
        sale.requestLog.matriculas.financeiro.vencimento_primeira
      );
      setRegistrationFullValue(
        sale.requestLog.matriculas.financeiro.valor_total
      );
      setRegistrationInstallmentValue(
        sale.requestLog.matriculas.financeiro.valor_parcela
      );
      setRegistrationFinantialType(sale.requestLog.matriculas.financeiro.tipo);
      setRegistrationStatus(sale.requestLog.matriculas.financeiro.situacao);
      setRegistrationInstallments(
        sale.requestLog.matriculas.financeiro.parcelas
      );
      setRegistrationPaymentType(
        sale.requestLog.matriculas.financeiro.forma_pagamento
      );
      setRegistrationContractValue(sale.requestLog.matriculas.valor_contrato);
      setRegistrationFu(sale.requestLog.matriculas.estado);
      setRegistrationAdress(sale.requestLog.matriculas.endereco);
      setRegistrationDoc(sale.requestLog.matriculas.documentoaluno);
      setRegistrationBirthDay(sale.requestLog.matriculas.data_nasc);
      setRegistrationCity(sale.requestLog.matriculas.cidade);
      setRegistrationCEP(sale.requestLog.matriculas.cep);
      setRegisstrationEmail(sale.requestLog.matriculas.email);
      setRegistrationDistrict(sale.requestLog.matriculas.bairro);
      setRegistrationPhone(sale.requestLog.matriculas.celular);
      setRegistrationRG(sale.requestLog.matriculas.rg);
      const date = new Date(sale.date._seconds * 1000).toLocaleString("pt-BR");
      setRegistrationSaleDate(date);
      setSaleDatailed(sale);
      setSalesReportModal(!salesReportModal);
    } else {
      setRegistrationClassId(" ");
      setRegistrationOfferId(" ");
      setRegistrationCourseIdsString(" ");
      setregistrationCfcId(" ");
      setRegistrationClerkId(" ");
      setRegistrationExpirationOfTheLast(" ");
      setRegistrationExpirationOfTheFirst(" ");
      setRegistrationFullValue(" ");
      setRegistrationInstallmentValue(" ");
      setRegistrationFinantialType(" ");
      setRegistrationStatus(" ");
      setRegistrationInstallments(" ");
      setRegistrationPaymentType(" ");
      setRegistrationContractValue(" ");
      setRegistrationFu(" ");
      setRegistrationAdress(" ");
      setRegistrationDoc(" ");
      setRegistrationBirthDay(" ");
      setRegistrationCity(" ");
      setRegistrationCEP(" ");
      setRegisstrationEmail(" ");
      setRegistrationDistrict(" ");
      setRegistrationPhone(" ");
      setRegistrationRG(" ");
      const date = new Date(sale.date._seconds * 1000).toLocaleString("pt-BR");
      setRegistrationSaleDate(date);
      setSaleDatailed(sale);
      setSalesReportModal(!salesReportModal);
    }
  }
  return (
    <div>
      <Breadcrumb parent="Vendas" title="Vendas" />
      <div style={{ display: "flex" }}>
        <div className="row" style={{ width: "100%", paddingLeft: 16 }}>
          <div className="col-md-4">
            <div className="alert alert-warning" role="alert">
              <div className="card-body">
                <div className="chart-widget-dashboard">
                  <div className="media">
                    <div className="media-body">
                      <h5 className="mt-0 mb-0 f-w-600">
                        <span>
                          <CountUp
                            className="counter"
                            end={numberOfPendingSales}
                          />
                        </span>
                      </h5>
                      <h5 style={{ color: "white" }}>{PendentSale}</h5>
                    </div>
                    <span>Vendas Diretas</span>
                    {/*  <GrMoney /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="alert alert-success" role="alert">
              <div className="card-body">
                <div className="chart-widget-dashboard">
                  <div className="media">
                    <div className="media-body">
                      <h5 className="mt-0 mb-0 f-w-600">
                        <span>
                          <CountUp
                            className="counter"
                            end={numberOfCompletedSales}
                          />
                        </span>
                      </h5>
                      <h5 style={{ color: "white" }}>{CompleteSale}</h5>
                    </div>
                    {/* <Tag /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="alert alert-danger" role="alert">
              <div className="card-body">
                <div className="chart-widget-dashboard">
                  <div className="media">
                    <div className="media-body">
                      <h5 className="mt-0 mb-0 f-w-600">
                        <span>
                          <CountUp
                            className="counter"
                            end={numberOfDeniedSales}
                          />
                        </span>
                      </h5>
                      <h5 style={{ color: "white" }}>{DenySales}</h5>
                    </div>
                    {/* <GiMoneyStack /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaleDetail
        salesReportModal={salesReportModal}
        saleDatailed={saleDatailed}
        registrationRG={registrationRG}
        registrationSaleDate={registrationSaleDate}
        registrationStatus={registrationStatus}
        registrationInstallments={registrationInstallments}
        registrationPaymentType={registrationPaymentType}
        registrationPhone={registrationPhone}
        registrationEmail={registrationEmail}
        registrationDoc={registrationDoc}
        registrationContractValue={registrationContractValue}
        registrationExpirationOfTheLast={registrationExpirationOfTheLast}
        registrationExpirationOfTheFirst={registrationExpirationOfTheFirst}
        registrationFullValue={registrationFullValue}
        registrationInstallmentValue={registrationInstallmentValue}
        registrationFinantialType={registrationFinantialType}
        registrationBirthDay={registrationBirthDay}
        registrationCEP={registrationCEP}
        registrationFu={registrationFu}
        registrationDistrict={registrationDistrict}
        registrationAdress={registrationAdress}
        setSalesReportModal={setSalesReportModal}
        isAdmin={isAdmin}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>{Sells}</h5>
                  <button
                    className="btn btn-primary pr-4 pl-4"
                    onClick={() =>
                      history.push(`${process.env.PUBLIC_URL}/vendas/novaVenda`)
                    }
                    type="button"
                  >
                    {New}
                  </button>
                </div>
              </div>

              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input
                        type="text"
                        className="form-control"
                        value={searchTerm}
                        placeholder="Digite o que está buscando"
                        onChange={(e) =>
                          setSearchTerm(e.target.value.toLowerCase())
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchSales(data)}
                  striped={true}
                  center={false}
                  noDataComponent="Não possui nenhum dado."
                  // contextActions={contextActions}
                />
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0
                      ? `${10 * currentPagination + 1}-${
                          10 * currentPagination + currentPaginationLength
                        } de ${totalNumberOfSales}`
                      : ""}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination
                      aria-label="Page navigation"
                      className="mt-2 mr-1 pagination justify-content-end pagination-primary"
                    >
                      {currentPagination === 0 ? (
                        <PaginationItem disabled>
                          <PaginationLink first href="#javascript" />
                        </PaginationItem>
                      ) : (
                        <PaginationItem>
                          <PaginationLink
                            first
                            onClick={() =>
                              changeTableData(currentPagination + 1 - 1)
                            }
                          />
                        </PaginationItem>
                      )}
                      {paginationCountArray.map((item) => (
                        <PaginationItem>
                          <PaginationLink onClick={() => changeTableData(item)}>
                            {item}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      {currentPagination === numberOfPages - 1 ? (
                        <PaginationItem disabled>
                          <PaginationLink last href="#javascript" />
                        </PaginationItem>
                      ) : (
                        <PaginationItem>
                          <PaginationLink
                            last
                            onClick={() =>
                              changeTableData(currentPagination + 1 + 1)
                            }
                          />
                        </PaginationItem>
                      )}
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendas;

const getSaleData = (sale, idToBeFound) => {
  let returnValue = "Sale not found";
  sale.forEach((sale) => {
    if (sale.saleId === idToBeFound) {
      returnValue = { ...sale };
    }
  });
  return returnValue;
};
