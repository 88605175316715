import React, { useState } from 'react';
import { Button, Form, Row, Col, Label } from 'reactstrap';
import Breadcrumb from '../../common/breadcrumb';
import DadosContrato from './dadosContrato';
import TermosContrato from './termosContrato';
import axios from 'axios';
import { Create } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { requestAuthConfig } from '../../../utils/utilTools';

const CadastrarContrato = ({ history }) => {

  const [data, setData] = useState('');
  const [url, setUrl] = useState('');
  const [contrato, setContrato] = useState('');
  const [invoicementType, setInvoicementType] = useState(null);
  const [body, setBody] = useState('');
  const [type, setType] = useState(Number)

  const handleDataChange = (value) => {
    setData(value.title)
    setUrl(value.url)
  }

  const [terms, setTerms] = useState({});

  const handleTermChange = (key, val) => {
    const tempTerms = { ...terms };
    tempTerms[key] = val;
    setTerms(tempTerms);
  }

  const deleteTerm = (key) => {
    let tempTerms = { ...terms };
    delete tempTerms[key];
    setTerms({ ...tempTerms });
  }

  const submitHandler = event => {
    event.preventDefault();
    axios.post(`${LOCAL_BACKEND_URL}/contract`, {
      'title': contrato,
      'url': url,
      'body': body,
      'invoicementType': Number(invoicementType),
      'type': type,
      ...terms
    }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
      .then(res => {
        history.push(`/loja/base/contratos`)
      })
      .catch(err => console.log(err));
  }

  return (
    <div>
      <Breadcrumb parent="Contratos" title="Contratos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="datatable-react mr-5 ml-5 form-group">
                <Form>
                  <div className="datatable-react p-4 form-group">
                    {/*  <DadosContrato handleChange={handleDataChange}
                    /> */}
                    <Label for="reference" className="h4 p-2">Título do contrato</Label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Título"
                      onChange={e => setContrato(e.target.value)} />
                    <Label for="reference" className="h4 p-2">Url</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="url"
                      id="url"
                      placeholder="Url do contrato"
                      onChange={e => setUrl(e.target.value)}
                    />
                    <Label for="reference" className="h4 p-2">Corpo do Contrato</Label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="body"
                      id="body"
                      placeholder="Corpo do Contrato"
                      onChange={e => setBody(e.target.value)}
                    />
                    <Label for="reference" className="h4 p-2">Tipo de Faturamento</Label>
                    <select onChange={(e) => setInvoicementType(e.target.value)} className='form-control'>
                      <option selected disabled>Escolha</option>
                      <option value={0}>Avulso</option>
                      <option value={1}>Escalonado</option>
                      <option value={2}>Assinatura</option>
                    </select>

                    <Label for="reference" className="h4 p-2">Usuário</Label>
                    <select onChange={(e) => setType(e.target.value)} className='form-control'>
                      <option selected disabled>Escolha</option>
                      <option value={0}>Aluno</option>
                      <option value={1}>Parceiro</option>
                    </select>
                  </div>

                  <TermosContrato handleChange={handleTermChange} handleDelete={deleteTerm} />
                  <Row>
                    <Col style={{ textAlign: 'center' }}>
                      <Button color="primary" onClick={submitHandler}>{Create}</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default CadastrarContrato;