const fus = [
  {
    id: 1,
    fu: "Acre",
    initials: "AC",
  },
  {
    fu: "Alagoas",
    initials: "AL",
  },
  {
    id: 2,
    fu: "Amapá",
    initials: "AP",
  },
  {
    id: 3,
    fu: "Amazonas",
    initials: "AM",
  },
  {
    id: 4,
    fu: "Bahia",
    initials: "BA",
  },
  {
    id: 5,
    fu: "Ceará",
    initials: "CE",
  },
  {
    id: 6,
    fu: "Espirito Santo",
    initials: "ES",
  },
  {
    id: 7,
    fu: "Goiás",
    initials: "GO",
  },
  {
    id: 8,
    fu: "Maranhão",
    initials: "MA",
  },
  {
    id: 9,
    fu: "Mato Grosso",
    initials: "MT",
  },
  {
    id: 10,
    fu: "Mato Grosso do Sul",
    initials: "MS",
  },
  {
    id: 11,
    fu: "Minas Gerais",
    initials: "MG",
  },
  {
    id: 12,
    fu: "Pará",
    initials: "PA",
  },
  {
    id: 13,
    fu: "Paraíba",
    initials: "PB",
  },
  {
    id: 14,
    fu: "Paraná",
    initials: "PR",
  },
  {
    id: 15,
    fu: "Pernambuco",
    initials: "PE",
  },
  {
    id: 16,
    fu: "Piauí",
    initials: "PI",
  },
  {
    id: 17,
    fu: "Rio de Janeiro",
    initials: "RJ",
  },
  {
    id: 18,
    fu: "Rio Grande do Norte",
    initials: "RN",
  },
  {
    id: 19,
    fu: "Rio Grande do Sul",
    initials: "RS",
  },
  {
    id: 20,
    fu: "Rondônia",
    initials: "RO",
  },
  { 
    id: 21, 
    fu: "Roraima", 
    initials: "RR" 
  },
  { 
    id: 22, 
    fu: "Santa Catarina", 
    initials: "SC" 
  },
  { 
    id: 23, 
    fu: "São Paulo", 
    initials: "SP" 
  },
  { 
    id: 24, 
    fu: "Sergipe", 
    initials: "SE" 
  },
  { 
    id: 25, 
    fu: "Tocantins", 
    initials: "TO" 
  },
  { 
    id: 26, 
    fu: "Distrito Federal", 
    initials: "DF" 
  },
];

export default fus;
