import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Create, TestEndpoint } from '../../constant';
import { allCoursesData } from '../../data/allCoursesData';

const BuscarCurso = () => {

  const [reference, setReference] = useState('');
  const [title, setTitle] = useState('');

  const submitHandler = event => {
    event.preventDefault();
    axios.post(TestEndpoint, {
      'reference': reference,
      'title': title
    } )
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
      .catch(err => console.log(err));
  }

  return (
    <div>
      <Breadcrumb parent="Buscar curso" title="Buscar curso" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Buscar curso</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <Form>
                  <FormGroup>
                    <Label for="reference">Referência</Label>
                    <Input
                      type="text"
                      name="reference"
                      id="reference"
                      placeholder="Referência"
                      onChange={e => setReference(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="title">Título</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Título"
                      onChange={e => setTitle(e.target.value)}
                    />
                  </FormGroup>
                  <Button color="primary" onClick={submitHandler}>Submit</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default BuscarCurso;