import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { Create, Option, TestEndpoint } from '../../../constant';
import { toInteger } from 'lodash';
import { useForm } from 'react-hook-form';
import { LOCAL_BACKEND_URL } from '../../../data/urls';

import { mask, unMask } from 'remask';

import {
    useGoogleReCaptcha,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateCfcSolicitation = ({ history }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [document, setDocument] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [street, setStreet] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [fu, setFu] = useState('');
    const [number, setNumber] = useState(0);
    const [complement, setComplement] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    const maskCNPJPatterns = ['99.999.999/9999-99'];
    const phonePatternMask = ['(99) 9999-9999', '(99) 99999-9999'];
    const zipCodePatternMask   = ['99999-999'];

    const cnpjMask = (cnpjValue) => {
        setDocument(setGenericMask(cnpjValue, maskCNPJPatterns));
    }

    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePatternMask));
    }

    const zipCodeMask = (zipCodeValue) => {
        setZipCode(setGenericMask(zipCodeValue, zipCodePatternMask));
    }

    const setGenericMask = (cnpjValue, maskPattern) => {
        cnpjValue = unMask(cnpjValue);
        return mask(cnpjValue, maskPattern);
    }

    useEffect(() => {

        if (unMask(zipCode).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(zipCode)}/json/`)
                .then(res => {
                    setStreet(res.data.logradouro);
                    setDistrict(res.data.bairro);
                    setCity(res.data.localidade);
                    setFu(res.data.uf);
                    setCountry('Brasil');
                })
                .catch(err => console.log(err));
        }
        else {
            setStreet('');
            setCity('');
            setDistrict('');
            setFu('');
            setCountry('');
            setCity('');
        }
    }, [zipCode]);

    const submitHandler = event => {
        try {
            const newToken = executeRecaptcha();
            newToken.then(res => {
                axios.post(`${LOCAL_BACKEND_URL}/checkoutpartner`, { 
                    email, 
                    name, 
                    phoneNumber: unMask(phoneNumber), 
                    document: unMask(document), 
                    country, 
                    zipCode: unMask(zipCode), 
                    street, 
                    district, 
                    city, 
                    fu, 
                    number: parseInt(number), 
                    complement, 
                    password, 
                    password2,
                    token: res
                })
                .then(res => {
                    setTimeout(() => {
                        toast.success('Solicitação realizada com sucesso.')
                    }, 200);
                })
                .catch(err => {
                    toast.error(err.response.data.error);
                });
            });
        }
        catch (err) {
            setTimeout(() => {
                toast.error("Oppss... Parece que você é um robô.");
            }, 200);
        }


    };

    return (
        <div>
            <GoogleReCaptcha onVerify={t => console.log({ t })} />
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box" style={{ width: '60%' }}>
                                    <div className="text-center"><img src={''} alt="" /></div>
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="text-center mb-5">
                                                <h4>Cadastrar solicitação de parceiro</h4>
                                            </div>
                                            <form className="card">
                                                <div className="form-group">
                                                    <label className="form-label pt-0">{"Email: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Nome: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="name"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"CNPJ: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="document"
                                                    value={document}
                                                    onChange={e => cnpjMask(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Telefone: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={e => phoneMask(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Senha: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="password" 
                                                    name="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Confirme sua senha: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="password" 
                                                    name="password2"
                                                    value={password2}
                                                    onChange={e => setPassword2(e.target.value)}
                                                    required="" />
                                                </div>

                                                <h6>{"Endereço: "}</h6>

                                                <div className="form-group">
                                                    <label className="form-label pt-0">{"CEP: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="zipCode"
                                                    value={zipCode}
                                                    onChange={e => zipCodeMask(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Rua: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="street"
                                                    value={street}
                                                    onChange={e => setStreet(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Número: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="number" 
                                                    name="number"
                                                    value={number}
                                                    onChange={e => setNumber(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Complemento: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="complement"
                                                    value={complement}
                                                    onChange={e => setComplement(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Bairro: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text"
                                                    name="district"
                                                    value={district}
                                                    onChange={e => setDistrict(e.target.value)}  
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Cidade: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="city"
                                                    value={city}
                                                    onChange={e => setCity(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"Estado: "}</label>
                                                    <select 
                                                    className="form-control"
                                                    name="fu"
                                                    value={fu}
                                                    onChange={e => setFu(e.target.value)}>
                                                        <option value={null}>Selecione o Estado</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                        <option value="DF">Distrito Federal</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">{"País: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="country"
                                                    value={country}
                                                    onChange={e => setCountry(e.target.value)}
                                                    required="" />
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => submitHandler()}>{"Cadastrar"}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};
export default CreateCfcSolicitation;