import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';

const NovoTermo = () => {

  const [inputs, setInput] = useState({});

  useEffect(() => {
    setInput({
      'title': '',
      'icon': ''
    });
  }, []);

  const submitHandler = event => {
    event.preventDefault();
    axios.post(`http://localhost:5000/generic`, inputs)
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
      .catch(err => console.log(err));
  }

  const changeHandler = (id, e) => {
    const tempInputs = { ...inputs };
    tempInputs[id] = e.target.value;
    setInput(tempInputs);
  }

  return (
    <div>
      <Breadcrumb parent="Termos" title="Termos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Cadastrar termo</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <Form onSubmit={submitHandler}>
                  <FormGroup>
                    <Label for="title">Título</Label>
                    <Input
                      type="text"
                      value={inputs['title']}
                      name="title"
                      id="title"
                      placeholder="Título"
                      onChange={(e) => changeHandler('title', e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="icon">Icon</Label>
                    <Input
                      type="text"
                      value={inputs['icon']}
                      name="icon"
                      id="icon"
                      placeholder="Ícone"
                      onChange={(e) => changeHandler('icon', e)}
                    />
                  </FormGroup>
                  <Button color="primary" type="submit">Cadastrar</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default NovoTermo;