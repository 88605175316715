import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import { CFC1, CFC2, CFC3, CFC4, CFC5, Courses, DashboardCFC, DestaqueVendas, Estados, Mes, SellingCoursesCFC, TodasVendas } from "../../../constant";

const DestaqueCfc = () => {

    const [responseData, setResponseData] = useState([])

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/partnerssales?page=1&limit=999`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then((response) => {
                setResponseData(response.data.salesNumber)
                console.log(response)

            })
            .catch((err) => {
                console.log(err)
            });

    }, [])

    const cfcData = responseData.map((d) => [d.name, d.salesNumber])

    cfcData.unshift(['CFC', 'Venda'])

    console.log(cfcData);


    return (
        <div className="COL-XS-12 COL-SM-6">
            {localStorage.getItem('isAdmin') ? 
            <div className="card-body">
                <h5><strong>{SellingCoursesCFC}</strong></h5><span className="d-block fonts-dashboard">{TodasVendas}</span>
                <Chart
                    height={'350px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={cfcData}
                    options={{
                        title: 'Vendas Por CFC',
                        sliceVisibilityThreshold: 0., // 20%
                        slices: [
                            {
                                color: "#32CD32"
                            },
                            {
                                color: "#00FF7F"
                            },
                            {
                                color: "#98FB98"
                            },
                            {
                                color: "#90EE90"
                            },
                            {
                                color: "#00FA9A"
                            }
                        ],
                        chartArea: {
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "70%"
                        }
                    }}
                    rootProps={{ 'data-testid': '7' }}
                />
            </div> : null}
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC1}</h6>
                                <h5 className="mb-0 f-18">{"310"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC2}</h6>
                                <h5 className="mb-0 f-18">{"220"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC3}</h6>
                                <h5 className="mb-0 f-18">{"190"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC4}</h6>
                                <h5 className="mb-0 f-18">{"178"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC5}</h6>
                                <h5 className="mb-0 f-18">{"169"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center">
                                <h6 className="mb-0 f-w-700">{CFC5}</h6>
                                <h5 className="mb-0 f-18">{"500"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">{CFC4}</h6>
                                <h5 className="mb-0 f-18">{"500"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0 xs-mb-selling">
                                <h6 className="mb-0 f-w-700">{CFC2}</h6>
                                <h5 className="mb-0 f-18">{"120"}</h5>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="selling-update text-center mb-0">
                                <h6 className="mb-0 f-w-700">{CFC1}</h6>
                                <h5 className="mb-0 f-18">{"410"}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DestaqueCfc;