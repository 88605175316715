export const levelsData = [
    {
        'id': 1,
        'level': 'Administrador',
    },
    {
        'id': 2,
        'level': 'Coordenador',
    },
    {
        'id': 3,
        'level': 'COORDENADOR COMERCIAL',
    },
    {
        'id': 4,
        'level': 'Operador',
    },
    {
        'id': 5,
        'level': 'Vendedor',
    }    
]