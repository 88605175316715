import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../data/urls';

import { requestAuthConfig } from './utilTools';


export function defaultPatch(endpointData) {
    let config = patchConfig(endpointData);
    
    return axios(config);
}

const getConfig = (getEndpoint) => {
    return {
        method: 'get',
        url: `${LOCAL_BACKEND_URL}/${getEndpoint}`,
        ...requestAuthConfig
    }
}

const patchConfig = (patchArgs) => {
    return {
        method: 'patch',
        url: `${LOCAL_BACKEND_URL}/${patchArgs.endpoint}/${patchArgs.id}`,
        ...requestAuthConfig,
        data: patchArgs.data
    };
}