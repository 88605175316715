import React, { useState, useEffect } from 'react';
import { EditSale, Edit, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, NewPassword, AccessLevel, Salvar } from "../../constant";
import Breadcrumb from "../common/breadcrumb";
import { ContractTitle, NewCustomTitle, EditContracts } from "../../constant";
import seven from '../../assets/images/user/7.jpg';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { useLocation } from 'react-router';
import { data } from '../../data/server-table';

import { requestAuthConfig } from '../../utils/utilTools';


const EditContract = ({ history }) => {

    const location = useLocation();

    const [contractData, setContractData] = useState({});
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [contractId, setContractId] = useState('')


    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/contract/${location.state.contractId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                setContractData(res.data)
                setTitle(res.data.contract.title)
                setUrl(res.data.contract.url)
                setContractId(res.data.contract.contractId)
                console.log(res.data.contract.contractId)

            })
            .catch(err => console.log(err));
    }, []);

    const submitHandler = event => {
        event.preventDefault();
        axios.patch(`${LOCAL_BACKEND_URL}/contract/${location.state.contractId}`, {
            'title': title,
            'url': url,
            'contractId': contractId


        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/base/contratos`);
            })
            .catch(err => console.log(err.response.data));
    };
    return (
        <>
            <Breadcrumb parent="Termos" title="Editar termos" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditContracts}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{ContractTitle}</label>
                                                <input className="form-control" type="text"
                                                    value={title}
                                                    onChange={e => setTitle(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">URL</label>
                                                <input className="form-control" type="text"
                                                    value={url}
                                                    onChange={e => setUrl(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/contratos`)}>{CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" type="submit" onClick={submitHandler}>{Salvar}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditContract;