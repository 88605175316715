import axios from "axios";
import { values } from "lodash-es";
import react, { useEffect, useState } from "react";
import { render } from "react-dom";
import {
  Ciclano,
  Detaills,
  Fulano,
  Joaozinho,
  Ronaldo,
  LastestSells,
  Name,
} from "../../../constant";
import { LOCAL_BACKEND_URL } from "../../../data/urls";
import { FcPlus } from "react-icons/fc";
import { FiMinusCircle } from "react-icons/fi";

import { requestAuthConfig } from "../../../utils/utilTools";
import SaleDetail from "../salesReport/saleDetail";

const LastSells = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");

  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);

  const [course, setCourse] = useState("");
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");
  const [course4, setCourse4] = useState("");

  const [responseData, setResponseData] = useState([]);
  const [saleAndId, setSaleAndId] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsAndIds, setClientsAndIds] = useState({});

  // Sale Details 
  const [salesReportModal, setSalesReportModal] = useState(false);
  const [saleDatailed ,setSaleDatailed] = useState({});
  const [requestLogIsOpen, setRequestLogIsOpen] = useState(false);
  const [registrationEmail, setRegisstrationEmail] = useState("");
  const [registrationDistrict, setRegistrationDistrict] = useState("");
  const [registrationPhone, setRegistrationPhone] = useState("");
  const [registrationCEP, setRegistrationCEP] = useState("");
  const [registrationCity, setRegistrationCity] = useState("");
  const [registrationBirthDay, setRegistrationBirthDay] = useState("");
  const [registrationDoc, setRegistrationDoc] = useState("");
  const [registrationAdress, setRegistrationAdress] = useState("");
  const [registrationFu, setRegistrationFu] = useState("");
  const [registrationPaymentType, setRegistrationPaymentType] = useState(0);
  const [registrationInstallments, setRegistrationInstallments] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [registrationFinantialType, setRegistrationFinantialType] = useState("");
  const [registrationInstallmentValue, setRegistrationInstallmentValue] = useState("");
  const [registrationFullValue, setRegistrationFullValue] = useState("");
  const [registrationExpirationOfTheFirst, setRegistrationExpirationOfTheFirst] = useState("");
  const [registrationExpirationOfTheLast, setRegistrationExpirationOfTheLast] = useState("");
  const [registrationClerkId, setRegistrationClerkId] = useState("");
  const [registrationCfcId, setregistrationCfcId] = useState("");
  const [registrationCourseIdsString, setRegistrationCourseIdsString] = useState("");
  const [registrationOfferId, setRegistrationOfferId] = useState("");
  const [registrationClassId, setRegistrationClassId] = useState("");
  const [registrationName, setRegistrationName] = useState("");
  const [registrationAdressNumber, setregistrationAdressNumber] = useState(0);
  const [registrationCountry, setRegistrationCountry] = useState("");
  const [registrationRG, setRegistrationRG] = useState("");
  const [registrationSaleDate, setRegistrationSaleDate] = useState("");
  const [registrationContractValue, setRegistrationContractValue] = useState("");

  const isAdmin = localStorage.getItem('isAdmin');

  useEffect(() => {
    axios
      .get(`${LOCAL_BACKEND_URL}/sales?limit=5&status=3`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response);
        setData([...response.data.sales]);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${LOCAL_BACKEND_URL}/clients`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setClients([...response.data.clients]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("data", data);
  //Clientlist map só com o nome
  //outromap

  //datalist = sale valor e título do curso
  const dataList = data.map((sale) => {
    const priceConverted = typeof sale.value;
    console.log("priceConverted", priceConverted);
    return (
      <div className="card-body">
        <div className="new-users">
          <div className="media">
            <img
              className="rounded-circle image-radius m-r-15"
              src={require("../../../assets/images/user/2.png")}
              alt=""
            />
            <div className="media-body">
              <h6 className="mb-0 f-w-700">{sale.clientName}</h6>
              <p key={sale.saleId} className="mt-2">
                <span>Parceiro: {sale.partnerName}</span>
                <br />
                <span>Curso: {sale.courseTitle}</span>
                <br />
                <span>
                  <FcPlus /> R${" "}
                  {Number(sale.value / 100).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
                <br />
                {/*<span><FiMinusCircle color='red'/> R$ 120,15 </span>*/}
              </p>
            </div>
            <span className="pull-right">
              <button className="btn btn-pill btn-outline-light" onClick={() => showDetails(sale)}>
                {Detaills}
              </button>
            </span>
          </div>
        </div>
        <hr className="paddingLeft-5"></hr>
      </div>
    );
  });
  console.log("data", data);

  function showDetails(sale) {
    if (sale.requestLog) {
      setRegistrationClassId(sale.requestLog.matriculas.idturma);
      setRegistrationOfferId(sale.requestLog.matriculas.idoferta);
      setRegistrationCourseIdsString(sale.requestLog.matriculas.idcurso);
      setregistrationCfcId(sale.requestLog.matriculas.idcfc);
      setRegistrationClerkId(sale.requestLog.matriculas.idatendente);
      setRegistrationExpirationOfTheLast(
        sale.requestLog.matriculas.financeiro.vencimento_ultima
      );
      setRegistrationExpirationOfTheFirst(
        sale.requestLog.matriculas.financeiro.vencimento_primeira
      );
      setRegistrationFullValue(
        sale.requestLog.matriculas.financeiro.valor_total
      );
      setRegistrationInstallmentValue(
        sale.requestLog.matriculas.financeiro.valor_parcela
      );
      setRegistrationFinantialType(sale.requestLog.matriculas.financeiro.tipo);
      setRegistrationStatus(sale.requestLog.matriculas.financeiro.situacao);
      setRegistrationInstallments(
        sale.requestLog.matriculas.financeiro.parcelas
      );
      setRegistrationPaymentType(
        sale.requestLog.matriculas.financeiro.forma_pagamento
      );
      setRegistrationContractValue(sale.requestLog.matriculas.valor_contrato);
      setRegistrationFu(sale.requestLog.matriculas.estado);
      setRegistrationAdress(sale.requestLog.matriculas.endereco);
      setRegistrationDoc(sale.requestLog.matriculas.documentoaluno);
      setRegistrationBirthDay(sale.requestLog.matriculas.data_nasc);
      setRegistrationCity(sale.requestLog.matriculas.cidade);
      setRegistrationCEP(sale.requestLog.matriculas.cep);
      setRegisstrationEmail(sale.requestLog.matriculas.email);
      setRegistrationDistrict(sale.requestLog.matriculas.bairro);
      setRegistrationPhone(sale.requestLog.matriculas.celular);
      setRegistrationRG(sale.requestLog.matriculas.rg);
      const date = new Date(sale.date._seconds * 1000).toLocaleString("pt-BR");
      setRegistrationSaleDate(date);
      setSaleDatailed(sale);
      setSalesReportModal(!salesReportModal);
    } else {
      setRegistrationClassId(" ");
      setRegistrationOfferId(" ");
      setRegistrationCourseIdsString(" ");
      setregistrationCfcId(" ");
      setRegistrationClerkId(" ");
      setRegistrationExpirationOfTheLast(" ");
      setRegistrationExpirationOfTheFirst(" ");
      setRegistrationFullValue(" ");
      setRegistrationInstallmentValue(" ");
      setRegistrationFinantialType(" ");
      setRegistrationStatus(" ");
      setRegistrationInstallments(" ");
      setRegistrationPaymentType(" ");
      setRegistrationContractValue(" ");
      setRegistrationFu(" ");
      setRegistrationAdress(" ");
      setRegistrationDoc(" ");
      setRegistrationBirthDay(" ");
      setRegistrationCity(" ");
      setRegistrationCEP(" ");
      setRegisstrationEmail(" ");
      setRegistrationDistrict(" ");
      setRegistrationPhone(" ");
      setRegistrationRG(" ");
      const date = new Date(sale.date._seconds * 1000).toLocaleString("pt-BR");
      setRegistrationSaleDate(date);
      setSaleDatailed(sale);
      setSalesReportModal(!salesReportModal);
    }
  }
  return (
    <div className="col-xl-12">
      <div className="card height-equal">
        <div className="card-header card-header-border">
          <div className="row">
            <div className="col-sm-6">
              <h5>{LastestSells}</h5>
            </div>
          </div>
        </div>
        <>{dataList}</>
        <SaleDetail
          salesReportModal={salesReportModal}
          saleDatailed={saleDatailed}
          registrationRG={registrationRG}
          registrationSaleDate={registrationSaleDate}
          registrationStatus={registrationStatus}
          registrationInstallments={registrationInstallments}
          registrationPaymentType={registrationPaymentType}
          registrationPhone={registrationPhone}
          registrationEmail={registrationEmail}
          registrationDoc={registrationDoc}
          registrationContractValue={registrationContractValue}
          registrationExpirationOfTheLast={registrationExpirationOfTheLast}
          registrationExpirationOfTheFirst={registrationExpirationOfTheFirst}
          registrationFullValue={registrationFullValue}
          registrationInstallmentValue={registrationInstallmentValue}
          registrationFinantialType={registrationFinantialType}
          registrationBirthDay={registrationBirthDay}
          registrationCEP={registrationCEP}
          registrationFu={registrationFu}
          registrationDistrict={registrationDistrict}
          registrationAdress={registrationAdress}
          setSalesReportModal={setSalesReportModal}
          isAdmin={isAdmin}
        />
        {/* <div className="card-body">
                    <div className="new-users">
                        <div className="media">

                            <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/2.png')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{name}</h6>
                                <p>{"R$"} {value} {course}</p>
                            </div><span className="pull-right">
                                <button className="btn btn-pill btn-outline-light">{Detaills}</button></span>
                        </div>
                        <div className="media">
                            <img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/5.jpg')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{name1}</h6>
                                <p>{"R$"} {value1} {course1}</p>
                            </div><span className="pull-right">
                                <button className="btn btn-pill btn-outline-light">{Detaills}</button></span>
                        </div>
                        <div className="media"><img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/3.jpg')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{name2}</h6>
                                <p>{"R$"} {value2} {course2}</p>
                            </div><span className="pull-right">
                                <button className="btn btn-pill btn-outline-light">{Detaills}</button></span>
                        </div>
                        <div className="media mb-0"><img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/4.jpg')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{name3}</h6>
                                <p>{"R$"} {value3} {course3}</p>
                            </div><span className="pull-right">
                                <button className="btn btn-pill btn-outline-light">{Detaills}</button></span>
                        </div>
                        <div className="media mt-4 mb-0"><img className="rounded-circle image-radius m-r-15" src={require('../../../assets/images/user/4.jpg')} alt="" />
                            <div className="media-body">
                                <h6 className="mb-0 f-w-700">{name4}</h6>
                                <p>{"R$"} {value4} {course4}</p>
                            </div><span className="pull-right">
                                <button className="btn btn-pill btn-outline-light">{Detaills}</button></span>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default LastSells;
