import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { cnpj } from 'cpf-cnpj-validator';
import { toInteger } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Breadcrumb, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { mask, unMask } from 'remask';
import { Bairro, BoletoB, CancelUpdateProfile, CepCheckout, Cidade, Complemento, Create, Estado, Faturamento1, FU, Numero, Pais, PhoneNumber, PlanoNome, Referencia, Rua, TestEndpoint, UpdateProfile } from '../../../constant';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import seven from '../../../assets/images/user/7.jpg';
import convertToBase64 from '../../../services/convertToBase64';

import { StatesPerGroupBR, StatesBR } from '../../../utils/utilTools';

import { requestAuthConfig } from '../../../utils/utilTools';

const CfcCreate = ({ history }) => {
    const [partnerRef, setPartnerRef] = useState('');
    const [invoicementType, setInvoicementType] = useState(0);
    const [invoicement, setInvoicement] = useState(0);
    const [country, setCountry] = useState('');
    const [plano, setPlano] = useState('')
    const [planoCondicional, setPlanoCondicional] = useState('none')
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [plan, setPlan] = useState('');
    const [plans, setPlans] = useState([]);
    const [plansAndIds, setPlansAndIds] = useState({});
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [valueUF, setValueUF] = useState('');

    const [fus, setFus] = useState('');

    const [base64, setBase64] = useState('');

    const addOrRemoveFu = (fu) => {
        const tempFus = [...fus];
        if (tempFus.includes(fu)) {
          tempFus.splice(tempFus.indexOf(fu), 1);
        }
        else {
          tempFus.push(fu);
        }
        setFus(tempFus);
        console.log(tempFus);
      }

    const [photoURL, setPhotoURL] = useState(seven);
    const isTheImageURLValid = (url, result) => {
        if (result === 'success') {
            setPhotoURL(url);
        }
        else {
            setPhotoURL(seven);
        }
    }

    const cepPatterns = ['99999-999']
    const setCepValue = (cepValue) => {
        setCep(maskGeneric(cepValue, cepPatterns))
    }
    const maskGeneric = (maskValue, patterns) => {
        maskValue = unMask(maskValue)
        return mask(maskValue, patterns)
    }

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/plans`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                console.log(res.data);
                setPlans(res.data.plans);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (plans.length > 0) {
            const tempPlansAndIds = {};
            plans.forEach(plan => tempPlansAndIds[plan.name] = plan.planId);
            setPlansAndIds(tempPlansAndIds);
        }
    }, [plans]);

    useEffect(() => {
        if (invoicementType == 2) {
            setPlanoCondicional('block')
        } else setPlanoCondicional('none')
    }, [invoicementType]);

    useEffect(() => {

        if (unMask(cep).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(cep)}/json/`)
                .then(res => {
                    setRua(res.data.logradouro);
                    setBairro(res.data.bairro);
                    setCidade(res.data.localidade);
                    setEstado(res.data.uf);
                    setCountry('Brasil');
                })
                .catch(err => console.log(err))
        }
        else {
            setRua('');
            setCidade('');
            setBairro('');
            setEstado('');
            setCountry('');
        }
    }, [cep]);

    const [cnpjNumber, setCnpjNumber] = useState('');
    const maskCnpjPatterns = ['99.999.999/9999-99'];
    const cnpjHandler = async (cnpjValue) => {
        setCnpjNumber(setGenericMask(cnpjValue, maskCnpjPatterns));
    }
    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }
    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['(99) 9999-9999', '(99) 99999-9999'];
    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    const [email, setEmail] = useState('')
    const [partner, setPartner] = useState('');

    const submitHandler = event => {
        /* event.preventDefault(); */
        console.log(typeof cidade)
        console.log(cidade)
        console.log(country)
        axios.post(`${LOCAL_BACKEND_URL}/partner`, {
            'name': partner,
            'document': `${unMask(cnpjNumber)}`,
            'email': email,
            'phoneNumber': `${unMask(phoneNumber)}`,
            'zipCode': unMask(cep),
            'street': rua,
            'number': toInteger(numero),
            'complement': `${complemento}`,
            'district': `${bairro}`,
            'city': `${cidade}`,
            'fu': estado,
            'country': `${country}`,
            'invoicement': parseInt(invoicement),
            'invoicementType': parseInt(invoicementType),
            'partnerReference': partnerRef,
            'plan': plan === '' ? plan : plansAndIds[plan],
            'password': password,
            'password2': password2,
            'photoURL': base64,
            'fusList': fus,
            'valueUF': valueUF

        }, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                console.log(res);
                console.log(res.data);
                history.push(`${process.env.PUBLIC_URL}/parceiros/estados`);
            })
            .catch(err => {

                console.log(err.response.data)
            })
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();


    async function convert(e) {
        const base64 = await convertToBase64(e)
        setBase64(base64)
    }

    console.log('base64', base64)
    return (
        <div>
            <Breadcrumb parent="Parceiros" title="CFCs" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Cadastrar Parceiro</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                    <img
                                        className="img-70 rounded-circle"
                                        alt=""
                                        src={photoURL}
                                        onError={() => setPhotoURL(seven)}
                                    />
                                    <input
                                        className="form-control w-75"
                                        type="file"
                                        onChange={(e) => convert(e)}
                                    />
                                </div>
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <Label for="partner">Parceiro</Label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="partner"
                                            id="partner"
                                            placeholder="Parceiro"
                                            onChange={e => setPartner(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O nome do Parceiro é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="partner" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cnpj">CNPJ</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="cnpj"
                                            value={cnpjNumber}
                                            placeholder="Número do CNPJ"
                                            onChange={e => cnpjHandler(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O CNPJ é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="cnpj" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="partnerRef">Referência</Label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="partnerRef"
                                            placeholder="ID"
                                            onChange={e => setPartnerRef(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A referência do parceiro é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="partnerRef" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="email"
                                            value={email}
                                            placeholder="parceiro@email.com"
                                            onChange={e => setEmail(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O email é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">Senha</Label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password"
                                            value={password}
                                            placeholder="Digite a senha do parceiro"
                                            onChange={e => setPassword(e.target.value)}
                                            ref={
                                                register({
                                                    required: "A senha é obrigatória.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password2">Digite a senha novamente</Label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password2"
                                            value={password2}
                                            placeholder="Digite a senha novamente"
                                            onChange={e => setPassword2(e.target.value)}
                                            ref={
                                                register({
                                                    required: "Digite a senha novamente.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <label className="form-label">{PhoneNumber}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={(e) => phoneMask(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O telefone é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="phoneNumber" />
                                    </FormGroup>
                                    <div className="row">
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">{CepCheckout}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={cep}
                                                    name="cep"
                                                    onChange={(e) => setCepValue(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CEP é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cep" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Rua}</label>
                                                <input
                                                    value={rua}
                                                    className="form-control"
                                                    type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">{Numero}</label>
                                                <input
                                                    className="form-control"
                                                    name="numero"
                                                    value={numero}
                                                    onChange={(e) => setNumero(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O número é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    }
                                                    type="text" />
                                                <ErrorMessage errors={errors} name="numero" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Complemento}</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="complemento"
                                                    value={complemento}
                                                    onChange={(e) => setComplemento(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O complemento é obrigatório.",
                                                            minLength: 1
                                                        })
                                                    } />
                                                <ErrorMessage errors={errors} name="complemento" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">{Bairro}</label>
                                                <input value={bairro}
                                                    className="form-control"
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Cidade}</label>
                                                <input value={cidade} className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{Estado}</label>
                                                <select className="form-control" value={estado}>
                                                    <option value={null}>Selecione o Estado</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                    <option value="DF">Distrito Federal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Pais}</label>
                                                <input
                                                    value={country}
                                                    className="form-control"
                                                    onChange={e => setCountry(e.target.value)}
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <FormGroup check inlist onChange={(e) => addOrRemoveFu(e.target.value)}>
                                                <h4 className="row" for="url">Estados</h4>
                                                <div className="row">
                                                    <div className="col-3">
                                                        {
                                                            StatesPerGroupBR.UF[0].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input type="checkbox" value={ stateGroup.sigla } />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-3">
                                                    {
                                                            StatesPerGroupBR.UF[1].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input type="checkbox" value={ stateGroup.sigla } />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div className="col-3">
                                                    {
                                                            StatesPerGroupBR.UF[2].map(stateGroup => {
                                                                return (
                                                                    <li>
                                                                    <Input type="checkbox" value={ stateGroup.sigla } />
                                                                    <Label for={ stateGroup.sigla }>{ stateGroup.nome }</Label>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {/* </Input> */}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{'Estado de pagamento'}</label>
                                                <select
                                                    onChange={e => setValueUF(e.target.value)}
                                                    className="form-control"
                                                    value={valueUF}

                                                >
                                                    <option value={null}>Selecione o estado de pagamento...</option>
                                                    <option value="VN">Valor nacional</option>
                                                    {
                                                        StatesBR.UF.map(stateObject => {
                                                            return (
                                                                <option value={stateObject.sigla}>{stateObject.nome}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">{Faturamento1}</label>
                                                <select
                                                    onChange={e => setInvoicement(e.target.value)}
                                                    className="form-control"
                                                    value={invoicement}

                                                >
                                                    <option value={null}>Selecione o período</option>
                                                    <option value={0}>Semanal</option>
                                                    <option value={1}>Quinzenal</option>
                                                    <option value={2}>Mensal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Tipo de faturamento</label>
                                                <select
                                                    onChange={e => setInvoicementType(e.target.value)}
                                                    className="form-control"
                                                    type="text"
                                                    value={invoicementType}>
                                                    <option value={null}>Selecione o Tipo</option>
                                                    <option value={0}>Avulsa</option>
                                                    <option value={1}>Escalonada</option>
                                                    <option value={2}>Assinatura</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="col-md-4" style={{ display: planoCondicional }} >
                                            <div className="form-group">
                                                <label className="form-label">{PlanoNome}</label>
                                                <select

                                                    value={plan}
                                                    className="form-control"
                                                    onChange={e => setPlan(e.target.value)}
                                                >
                                                    <option select="">Selecione um plano...</option>
                                                    {
                                                        plans.map(plan => {
                                                            return <option>{ plan.name }</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100"></div>


                                    </div>

                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/parceiros/estados`)}>{CancelUpdateProfile}</button>
                                        <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CfcCreate;

function testImage(url, callback, timeout) {
    timeout = timeout || 5000;
    var timedOut = false, timer;
    var img = new Image();
    img.onerror = img.onabort = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "error");
        }
    };
    img.onload = function () {
        if (!timedOut) {
            clearTimeout(timer);
            callback(url, "success");
        }
    };
    img.src = url;
    timer = setTimeout(function () {
        timedOut = true;
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        callback(url, "timeout");
    }, timeout);
}