import React, { useState, useEffect } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { termosData } from '../../../data/termosData';
import { Delete } from 'react-feather';

const TermosContrato = (props) => {

  const [termos, setTermo] = useState([]);
  const [termosData, setTermosData] = useState({});
  
  const createTermo = () => {
    setTermo([...termos, {
      id: termos.length,
      value: <Termo position={`${termos.length}`} removeHandler={deleteTermo} addToFormState={handleTermoChange} />
    }]);
  }
  
  const handleTermoChange = (term, termObj) => {
    const tempTermosData = {...termosData};
    tempTermosData[term] = termObj;
    setTermosData(tempTermosData);
  }

  useEffect(() => {
    props.handleChange('termos', termosData);
  }, [termosData]);

  const deleteTermo = (key) => {
    setTermo(termos.slice(0, termos.length));
    props.handleDelete(key);
  }
  console.log(termos)
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Termos</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <Table>
                  <tr style={ termoStyle }>
                    <th style={{ width: '80%' }}>Termo</th>
                    <th style={{ width: '50%' }}>Obrigatório</th>
                    <th style={{ width: '50%' }}>Excluir</th>
                  </tr>
                  { termos.map(
                    termo => termo.value
                  ) }
                </Table>
                <Button onClick={ createTermo }>Adicionar termo</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

const Termo = (props) => {
  // `termo-field-${props.position}`

  const [termoData, setTermoData] = useState({});
  const handleStateChange = (key, val) => {
    const tempState = {...termoData};
    tempState[key] = val;
    setTermoData(tempState);
  }

  useEffect(() => {
    props.addToFormState(`termo-${props.position}`, termoData);
  }, [termoData]);

  return (
    <tr style={ termoStyle }>
      <td style={{ width: '80%' }}>
        <Input
          type="select"
          name="termo"
          id={`termo-${props.position}`}
          onChange={ e => {
            handleStateChange('termo', e.target.value)}}
        >
          {
            termosData.map(obj => <option>{ obj.title }</option>)
          }
        </Input>
      </td>
      <td style={{ width: '50%' }}>
        <Input
          type="select"
          name="obrigatorio"
          id={`obrigatorio-${props.position}`}
          onChange={ e => handleStateChange('obrigatorio', e.target.value) }
        >
          <option>Sim</option>
          <option>Não</option>
        </Input>
      </td>
      <td style={{ width: '50%' }}>
        <Button onClick={ () => {
          props.removeHandler(`termo-field-${props.position}`);
        }}>
          <Delete />
        </Button>
      </td>
    </tr>
  );
}

const termoStyle = {
  display: 'flex',
  width: '100%'
}

export default TermosContrato;