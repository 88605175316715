import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Create, TestEndpoint } from '../../constant';
import { allCoursesData } from '../../data/allCoursesData';

const PassoCurso = () => {

  const [curso, setCurso] = useState('');

  const submitHandler = event => {
    event.preventDefault();
    axios.post(TestEndpoint, {
      'curso': curso
    } )
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
      .catch(err => console.log(err));
  }

  return (
    <div>
      <Breadcrumb parent="Passos de cursos" title="Passos de cursos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Dados</h5>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <Form>
                  <FormGroup>
                    <Label for="curso">Cursos</Label>
                    <Input
                      type="select"
                      name="curso"
                      id="curso"
                      onChange={e => setCurso(e.target.value)}
                    >
                      {
                        [{'title': 'Escolha uma opção'}, ...allCoursesData].map(obj => <option>{ obj.title }</option>)
                      }
                    </Input>
                  </FormGroup>
                  <Button color="primary" onClick={submitHandler}>{Create}</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default PassoCurso;