import React, { useState, useEffect } from 'react';
import { EditSale, Edit, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, NewPassword, AccessLevel, Salvar, Category } from "../../constant";
import Breadcrumb from "../common/breadcrumb";
import { useForm } from 'react-hook-form';
import { Reference, Image, CustomTitle, Status, EditCourse } from "../../constant";
import seven from '../../assets/images/user/7.jpg';
import { ErrorMessage } from '@hookform/error-message';

import { useLocation } from 'react-router';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { FormGroup, Input, Label } from 'reactstrap';
import convertToBase64 from '../../services/convertToBase64';

import { requestAuthConfig } from '../../utils/utilTools';
import { toast } from 'react-toastify';


const EditCourses = ({ history }) => {

    const [photoURL, setPhotoURL] = useState('');
    const [withImage, setWithImage] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [slug, setSlug] = useState('');
    const [requirements, setRequirements] = useState('');

    const [description, setDescription] = useState('')
    const location = useLocation();
    const [title, setTitle] = useState('');
    const [reference, setReference] = useState('');
    const [status, setStatus] = useState('');
    const [image, setImage] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesAndIds, setCategoriesAndIds] = useState({});
    const [order, setOrder] = useState('');

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/course/${location.courseId}`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                console.log(res)
                setTitle(res.data.course.title);
                setReference(res.data.course.reference);
                setPhotoURL(res.data.course.imageURL);
                setCategoryId(res.data.course.categoryId);
                setOrder(res.data.course.order);
                setDescription(res.data.course.description);
                setSlug(res.data.course.slug);
                setImagePath(res.data.course.imagePath);
                setImageURL(res.data.course.imageURL);
                setCategory(res.data.course.category);
                setRequirements(res.data.course.requirements);
            })
            .catch(err => console.log(err));

        axios.get(`${LOCAL_BACKEND_URL}/allCategories`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setCategories(res.data.categories);
            })
            .catch(err => console.log(err.message));
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            const tempCategoriesAndIds = {};
            categories.forEach(category => {
                tempCategoriesAndIds[category.name] = category.categoryId;
                if (categoryId === category.categoryId) {
                    setCategory(category.name);
                }
            });
            console.log(tempCategoriesAndIds);
            setCategoriesAndIds(tempCategoriesAndIds);
        }
    }, [categories]);

    const submitHandler = event => {
            const dataWithoutImage = {
                'title': title,
                'reference': parseInt(reference),
                'imagePath': imagePath,
                'imageURL': imageURL,
                'categoryId': categoriesAndIds[category],
                'order': parseInt(order),
                'descriptionHtml': description,
                'description': description,
                'slug': slug,
                'requirements': requirements
            }

            const data = {
                'title': title,
                'reference': parseInt(reference),
                'image': photoURL,
                'categoryId': categoriesAndIds[category],
                'order': parseInt(order),
                'descriptionHtml': description,
                'slug': slug,
                'requirements': requirements
            }
        /* event.preventDefault(); */
        axios.patch(`${LOCAL_BACKEND_URL}/course/${location.courseId}`, withImage ? data : dataWithoutImage, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                toast.success('Curso editado!')
                history.push(`${process.env.PUBLIC_URL}/base/cursos`);
            })
            .catch(err => {
                toast.error('Não foi possível editar')
                console.log(err.response.data)
            });
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    async function convert(e) {
        const base64 = await convertToBase64(e)
        setPhotoURL(base64)
        setWithImage(true)
    }
    return (
        <div>
            <Breadcrumb parent="Cursos" title="Editar Cursos" />
            <div className="container-fluid">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="datatable-react mr-5 ml-5 form-group">
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditCourse}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="col-sm-6 mb-5 d-flex  align-items-center justify-content-around">
                                        <img
                                            className="img-70 rounded-circle"
                                            alt=""
                                            src={photoURL}
                                        />
                                        <input
                                            className="form-control w-75"
                                            type="file"
                                            onChange={(e) => convert(e)}
                                        />
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormGroup>
                                            <Label for="order">Ordem</Label>
                                            <input
                                                className="form-control"
                                                type="string"
                                                name="order"
                                                id="order"
                                                value={order}
                                                onChange={e => setOrder(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "A ordem do curso é obrigatória.",
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="order" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{Reference}</label>
                                            <input className="form-control"
                                                type="string"
                                                value={reference}
                                                name="reference"
                                                onChange={e => setReference(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "A referência do curso é obrigatória.",
                                                        minLength: 1
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="reference" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{Category}</label>
                                            <select
                                                className="form-control"
                                                value={category}
                                                name="category"
                                                onChange={e => setCategory(e.target.value)}
                                            >
                                                <option select="">Selecione uma categoria...</option>
                                                {
                                                    categories.map(category => {
                                                        return <option>{ category.name }</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup>
                                            <Label for="title">Título</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={title}
                                                onChange={e => setTitle(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O nome do curso é obrigatória.",
                                                        minLength: 2
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="title" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Label for="image">Descrição do Curso</Label>
                                            <textarea
                                                className="form-control"
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "A descrição do curso é obrigatória.",
                                                        minLength: 5
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="Description" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Label for="image">Slug do Curso</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="slug"
                                                id="slug"
                                                value={slug}
                                                onChange={e => setSlug(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "O slug do curso é obrigatória.",
                                                        minLength: 5
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="slug" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Label for="image">Requisitos do Curso</Label>
                                            <textarea
                                                className="form-control"
                                                value={requirements}
                                                onChange={e => setRequirements(e.target.value)}
                                                ref={
                                                    register({
                                                        required: "Os requisitos do curso é obrigatório.",
                                                        minLength: 5
                                                    })
                                                }
                                            />
                                            <ErrorMessage errors={errors} name="Requirements" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/cursos`)}>
                                        {CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" onClick={handleSubmit} type="submit">{Salvar}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCourses;