import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import man from '../../assets/images/dashboard/user.png';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { requestAuthConfig } from '../../utils/utilTools';

export default function ButtonEmailDelete(props) {
    const [modal, setModal] = useState();

    const toggle = () => {
        setModal(!modal)
    }

    const [refresh, setRefresh] = useState();

    const [emailData, setEmailData] = useState({});

    useEffect(() => {
        setEmailData(props.getEmailFunction(props.email, props.emailId));
    }, [props.email]);

    const deleteEmailHandler = () => {
        axios.delete(`${LOCAL_BACKEND_URL}/email/${emailData.emailModelId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                console.log(res);
                toggle();
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            });

    }

    return (
        <>
            <button onClick={toggle} className="btn btn-sm btn-danger">
                <i className="icon icon-trash"></i>
            </button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmar ação</ModalHeader>
                <ModalBody>
                    <h5>Você tem certeza que deseja remover a mensagem abaixo?</h5>
                    <div className="mt-4 mb-3 mw-200 d-flex justify-content-start align-items-center">

                        <h5>{emailData.name}</h5>
                    </div>
                    <ul>
                        <li className="h6">Mensagem: <span>{emailData.body}</span></li>
                        <li className="h6">Tipo: <span>{emailData.type}</span></li>

                    </ul>
                    <p className="mt-4">
                        <span style={{ color: "red", fontWeight: "bold", }}>Atenção</span> essa ação não poderá ser revertida.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Cancelar</Button>
                    <Button color="danger" onClick={deleteEmailHandler}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}