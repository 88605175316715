import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { User, Edit } from 'react-feather';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios'
import { MyProfile, Bio, Designer, EditProfile, AboutMe, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, NewPassword } from '../../constant'

import { requestAuthConfig } from '../../utils/utilTools';
import { ErrorMessage } from '@hookform/error-message';
import { register } from '../../serviceWorker';


const ProfileEdit = ({ history }) => {

    const [data, setData] = useState([]);
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [photoURL, setPhotoURL] = useState('');
    const [role, setRole] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/user/${localStorage.getItem('userId')}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setData(res.data);
                setPhotoURL(localStorage.getItem('profileURL'));
                setName(localStorage.getItem('userName'));
                setEmail(res.data.user.email);
                setPhoneNumber(res.data.user.phoneNumber);
                setBio(res.data.user.bio);
                setRole(res.data.user.role);
            }).catch((err) => {
                console.log(err);
            });
    }, []);

    const updateUserHandler = () => {
        // e.preventDefault(); 

        const data = {
            "role": role,
            "email": email,
            "password": newPassword === '' ? undefined : newPassword,
            "password2": newPassword === '' ? undefined : confirmNewPassword,
            "phoneNumber": phoneNumber,
            "displayName": name,
            "photoURL": photoURL,
            "bio": bio
        };

        const config = {
            method: 'patch',
            url: `${LOCAL_BACKEND_URL}/user/${localStorage.getItem('userId')}`,
            ...requestAuthConfig,
            data: data
        };

        axios(config)
            .then(res => {
                // history.push(`${process.env.PUBLIC_URL}/base/lista`);
                history.push(`${process.env.PUBLIC_URL}/dashboard/default`)
                console.log(res)
            })
            .catch(err => {
                console.log(err.message);
            });
    }

    const { handleSubmit, register, errors } = useForm();
    const onSubmit = event => updateUserHandler();

    return (
        <Fragment>
            <Breadcrumb parent="Perfil" title="Editar Perfil" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><User /> {MyProfile}</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="row mb-4">
                                            <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={photoURL} /></div>
                                            <div className="col">
                                                <h3 className="mb-1">{name}</h3>
                                                <p className="mb-5">{role}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{EmailAddress}</label>
                                            <input className="form-control" value={email} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{PhoneNumber}</label>
                                            <input className="form-control" type="phone" value={phoneNumber} disabled />
                                        </div>
                                        <div className="form-group mb-5">
                                            <h6 className="form-label">{Bio}</h6>
                                            <textarea className="form-control" style={{ resize: 'none' }} rows="5" value={bio} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><Edit /> {EditProfile}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{Name}*</label>
                                                <input
                                                    className="form-control"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Nome"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O campo nome é obrigatório.",
                                                            minLength: 2
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="email"
                                                    placeholder="fulano@mail.com"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O campo nome é obrigatório.",
                                                            minLength: 4
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="email" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{NewPassword}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    value={newPassword}
                                                    onChange={e => setNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{RetypePassword}</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    value={confirmNewPassword}
                                                    onChange={e => setConfirmNewPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">{PhoneNumber}*</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O campo nome é obrigatório.",
                                                            minLength: 5
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="phoneNumber" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-0">
                                                <label className="form-label">{AboutMe}</label>
                                                <textarea
                                                    style={{ resize: 'none' }}
                                                    className="form-control"
                                                    name="bio"
                                                    rows="5"
                                                    value={bio}
                                                    onChange={(e) => setBio(e.target.value)}>
                                                    ref={
                                                        register({
                                                            required: "O campo nome é obrigatório.",
                                                            minLength: 3
                                                        })
                                                    }
                                                </textarea>
                                                <ErrorMessage errors={errors} name="bio" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/dashboard/default`)}>{CancelUpdateProfile}</button>
                                    <button className="btn btn-primary ml-3" type="submit" onClick={handleSubmit} >{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProfileEdit;