function downloadContent(body) {
    let telaImpressao
    telaImpressao = window.open('about:blank');
    telaImpressao.document.body.innerHTML = 
          '<html><head><title></title></head><body>' + 
          body + "</body>";
    //telaImpressao.document.write(body);
    telaImpressao.window.print();
    telaImpressao.window.close();

}

export default downloadContent;