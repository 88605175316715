import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Users, New } from '../../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import { allCoursesData } from '../../../data/allCoursesData';
import ButtonDeleteUser from '../../icetran/ButtonDeleteUser';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonDeleteCourses from '../../icetran/ButtonDeleteCourses';
import { db } from '../../../data/config';
import { selectImage } from './courseImages';

import { requestAuthConfig } from '../../../utils/utilTools';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';

/*import TransporteDeEscolares from './courseImages/Transporte-Escolar_auto_x2.webp';
import ProdutosPerigosos from './courseImages/Transporte-de-Produtos-Perigosos_auto_x2.jpg';
import VeiculosDeEmergencia from './courseImages/auto_x2.jpg';
import TransporteDePassageiros from './courseImages/coletivo-passageiros.webp';*/





const AllCourses = ({ history }) => {
  const [responseData, setResponseData] = useState([]);
  const [data, setData] = useState();
  const [courseAndId, setCourseAndId] = useState([]);

  const [refresh, setRefresh] = useState();

  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfCourses, setTotalNumberOfCourses] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  /*function selectImage(imgName) {
    switch(imgName) {
        case 'Transportes De Escolares': 
        console.log('1')
            return TransporteDeEscolares;
        case 'Transporte De Produtos Perigosos - MOPP':
          console.log('2')
            return ProdutosPerigosos;
        case 'Condutores De Veículos De Emergência':
          console.log('3')
            return VeiculosDeEmergencia;
        case 'Transportes Coletivo De Passageiros':
          console.log('4')
          return TransporteDePassageiros;
        default: 
          console.log(imgName)
          return 'Default'

    }
}*/

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/courses?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((res) => {
        setResponseData([...res.data.courses]);
        setNumberOfPages(res.data.info.numPages);
      })
      .catch((err) => { console.log(err.response.data) });

    axios.get(`${LOCAL_BACKEND_URL}/everySingleCourse`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfCourses(res.data.courses.length);
      })
      .catch(err => console.log(err.message));

  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {
      let carrierArray = [];
      let courseAndIdCarrier = {};
      responseData.forEach(course => {
        carrierArray.push(course)
        courseAndIdCarrier[course.title] = course.courseId;
      })
      setData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);
      setCourseAndId({ ...courseAndIdCarrier });

      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/courses?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.courses);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }


  const columns = [

    {
      name: 'Ordem',
      selector: 'order',
      sortable: true,
      center: true,
    },
    {
      name: 'Referência',
      selector: 'reference',
      sortable: true,
      center: true,
    },
    {
      name: 'Imagem',
      selector: 'image',
      cell: (row) => {
        return <img style={{ marginLeft: "4px", borderRadius: "10%", width: "100px", height: "70px", marginRight: "10px" }} alt={`${row.image}`} src={row.imageURL}
        />
      },
      sortable: false,
      center: true
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      center: true,
      grow: 3,
      cell: row => <div style={{ overflow: 'inherit' }}>{row.title}</div>,
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "50%" }}>
        <li className="list-group-item-action"><button
          style={{ marginRight: 4 }}
          onClick={() => history.push({
            pathname: `${process.env.PUBLIC_URL}/edit/cursos`,
            courseId: courseAndId[row.title],
            courseInfo: getCourseData(responseData, courseAndId[row.title])

          }
          )} className="btn btn-sm btn-warning"><i className="icon icon-pencil"></i></button></li>
        <li className="list-group-item-action" style={{ marginLeft: '1vw' }}>
          <ButtonDeleteCourses
            refreshPage={setRefresh}
            getCourseFunction={getCourseData}
            course={responseData}
            courseId={courseAndId[row.title]}
          /></li>
      </div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  useEffect(() => {
    const fetchCourseData = async () => {
      const data = await db.collection("course").get()
    };
  }, [])
  // }, []);

  
  function searchCourses(rows) {
    return rows && rows.length ? rows.filter(row =>
      row.title.toLowerCase().indexOf(searchTerm) > -1 ||
      row.order.toString().indexOf(searchTerm) > -1 ||
      row.reference.toString().indexOf(searchTerm) > -1) : rows
  }

  return (
    <div>
      <Breadcrumb parent="Produtos" title="Todos os Produtos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Todos os Produtos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/courses/criar-curso`)} type="button">{New}</button>
                </div>
              </div>
              <div className="ml-5 mt-3 mb-3 col-sm-4">
                <div className="form-group row">
                  <div className="search_wrap search_wrap_3">
                    <div className="search_box">
                      <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchCourses(data)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfCourses}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                            <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                            />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                            <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                            <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                            <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                            />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCourses;

const getCourseData = (course, idToBeFound) => {
  let returnValue = 'Course not found';
  course.forEach(course => {
    if (course.courseId === idToBeFound) {
      returnValue = { ...course };
    }
  });
  return returnValue;
}