import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../common/breadcrumb";
import { LOCAL_BACKEND_URL } from "../../data/urls";
import { BsFilterCircle } from "react-icons/bs";
import { MaxNumberOfItemsPerTable } from "../../constant/designControl";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import fus from '../../utils/fus';

export default function WaitListPanel() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [list, setList] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [filters, setFilters] = useState({
    fu: "",
    course: Number(0),
  });
  const [listFiltered, setListFiltered] = useState([]);

  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfPerson, setTotalNumberOfPersons] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getList();
    getCourses();
    setCurrentPaginationLength(list.length)

    if(list.length > 0 && numberOfPages > 0) {
        const tempCountArray = [];
        for (let i = 1; i <= numberOfPages; i++) {
          tempCountArray.push(i);
        }
        setPaginationCountArray(tempCountArray);
        setTotalNumberOfPersons(list.length)
    }
  }, [token, numberOfPages]);

  const filtereds = list.filter(
    (item) => (item.fu === filters.fu && (Number(filters.course) === item.courseReference || Number(filters.course) === 0)) || (item.courseReference === Number(filters.course) && (filters.fu === item.fu || filters.fu === ""))
  );
  useEffect(() => {
    setListFiltered(filtereds);
  }, [filters]);

  async function getCourses() {
    await axios
      .get(`${LOCAL_BACKEND_URL}/courses`, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((res) => setAllCourses(res.data.courses))
      .catch((err) => console.log(err.response.data));
  }
  async function getList() {
    await axios
      .get(
        `${LOCAL_BACKEND_URL}/waitlistpersons?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
          setList([...res.data.persons])
          setNumberOfPages(res.data.info.numPages)
        })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios
      .get(
        `${LOCAL_BACKEND_URL}/waitlistpersons?limit=${4}&page=${dataIndex}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setList(res.data.persons);
        console.log('res.data.info.numPages', res.data.info.numPages)
        setNumberOfPages(res.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const columns = [
    {
      name: "Nome",
      selector: <div />,
      cell: (row) => <div className="ml-3">{row.name}</div>,
    },
    {
      name: "Telefone",
      selector: <div />,
      cell: (row) => <div className="ml-2">{row.phoneNumber}</div>,
    },
    {
      name: "Email",
      selector: <div />,
      cell: (row) => <div className="ml-2" style={{}}>{row.email}</div>,
    },
    {
      name: "Estado",
      selector: <div />,
      cell: (row) => <div className="ml-2" style={{ marginLeft: "20px" }}>{row.fu}</div>,
    },
    {
      name: "Curso",
      selector: <div />,
      cell: (row) => {
        const course = allCourses.filter(course => course.reference === row.courseReference)
        return <div style={{ marginLeft: "10px" }}>{course[0] ? course[0].title : 'Curso não encontrado'}</div>
      },
    }
  ];

  return (
    <>
      <Breadcrumb parent="Clientes" title="Lista de Espera" />
      <div className="bg-green">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header  d-flex flex-row justify-content-between">
                  <div className="row justify-content-between ml-3 mr-5">
                    <h5>Alunos - Lista de Espera</h5>
                    {/* <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/financeiro/fatura`)} type="button">{New}</button> */}
                  </div>
                  <div className="mr-5 d-flex flex-row justify-content-between">
                    <div
                      className="mr-3 mt-2 d-flex flex-row justify-content-center align-items-center"
                      style={{
                        opacity: `${modalIsOpen ? 1 : 0}`,
                        transition: "all 0.1s ease-out",
                      }}
                    >
                      <div className="h4">
                        <select
                          className="border p-1"
                          onChange={(e) =>
                            setFilters({
                              fu: filters.fu,
                              course: Number(e.target.value),
                            })
                          }
                        >
                          <option
                            selected
                            style={{ color: "green" }}
                            value={""}
                          >
                            Cursos
                          </option>
                          {allCourses.map((course) => (
                            <option
                              key={course.reference}
                              value={course.reference}
                            >
                              {course.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="h4 ml-4">
                        <select
                          className="border p-1"
                          onChange={(e) =>
                            setFilters({
                              course: Number(filters.course),
                              fu: e.target.value,
                            })
                          }
                        >
                          <option selected value={""}>
                            Estado
                          </option>
                          {/* Map de estados */}
                          {fus.map((fu) => (
                            <option key={fu.id} value={fu.initials}>
                              {fu.initials}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <BsFilterCircle
                        style={{ color: `black` }}
                        size={35}
                        cursor={"pointer"}
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />
                    </div>
                  </div>
                </div>
                <div className="datatable-react mr-5 ml-5 mt-5 mb-5">
                  {listFiltered.length === 0 ? (
                    <DataTable
                      columns={columns}
                      data={list}
                      striped={true}
                      center={true}
                      noDataComponent="Não possui nenhum dado."
                      // contextActions={contextActions}
                    />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={listFiltered}
                      striped={true}
                      center={true}
                      noDataComponent="Não possui nenhum dado.2"
                      // contextActions={contextActions}
                    />
                  )}
                </div>
                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfPerson}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                        {
                          currentPagination === 0 ?
                            <PaginationItem disabled>
                              <PaginationLink first href="#javascript" />
                            </PaginationItem>
                            :
                            <PaginationItem>
                              <PaginationLink
                                first
                                onClick={() => changeTableData((currentPagination + 1) - 1)}
                              />
                            </PaginationItem>
                        }
                        {
                          paginationCountArray.map(item =>
                            <PaginationItem>
                              <PaginationLink onClick={() => changeTableData(item)}>
                                {item}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                        {
                          currentPagination === numberOfPages - 1 ?
                            <PaginationItem disabled>
                              <PaginationLink last href="#javascript" />
                            </PaginationItem>
                            :
                            <PaginationItem>
                              <PaginationLink
                                last
                                onClick={() => changeTableData((currentPagination + 1) + 1)}
                              />
                            </PaginationItem>
                        }
                      </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
