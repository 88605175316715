import React, { useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { User } from 'react-feather';
import { CreateClients, CPF, Identification, UpdateProfile, EmailAddress, PhoneNumber, CancelUpdateProfile, Name, RetypePassword, Password, AccessLevel, OracleId, Address, PartnerQuestion, StateCountry } from '../../constant'
import { CreateUser, SellCreate, Course, Client, ZipCode, Street, Number, Complement, District, City, FU, Country } from '../../constant'
import { cpf } from 'cpf-cnpj-validator';
import { TestEndpoint } from '../../constant';
import axios from 'axios';
import { ClientCPFNotFound, ClientCPFFound } from '../../constant';
import { mask, unMask } from 'remask';
import { LOCAL_BACKEND_URL } from '../../data/urls';

// Imports for payment amount input mask
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';
import { indexOf } from 'lodash';
import { toast } from 'react-toastify';

function AdminSellCreate({ history }) {

    const [clients, setClients] = useState([]);
    const [clientWarningVisibility, setClientWarningVisibility] = useState('none');
    const [clientFormVisibility, setClientFormVisibility] = useState('none');
    const [clientWarningMessage, setClientWarningMessage] = useState('');

    // State variables to retrieve and organize course data
    const [course, setCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [courseTitles, setCourseTitles] = useState([]);
    const [gender, setGender] = useState('');

    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState('');
    const [responseCourses, setResponseCourses] = useState([]);
    const [courseReference, setCourseReference] = useState('');
    const [clientCpf, setClientCpf] = useState('');
    const [coursesTitlesAndReferences, setCoursesTitlesAndReferences] = useState('');
    const [partnersIdsAndReferences, setPartnersIdsAndReferences] = useState({});
    // Additional state variables
    const [fu, setFu] = useState('');
    const [paymentMethod, setPaymentMethod] = useState(0);
    const [saleAmount, setSaleAmount] = useState('');

    const [noPartnersInFU, setNoPartnersInFU] = useState(true);
    const [noCourseFoundInPartner, setNoCourseFoundInPartner] = useState(true);

    // Mask for sale amount
    const saleMask = createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false
    });
    console.log('courses', courses)
    const maskSaleAmountPattern = ['999.999.999.999.999'];
    // Handles sale amount change; applies mask automatically
    const saleAmountHandler = async (saleAmountValue) => {
        setSaleAmount(setGenericMask(saleAmountValue, maskSaleAmountPattern));
    }

    useEffect(() => {
        if (partner.length > 0 && partners.length > 0) {
            setCourses([]);
            let partnerId;
            partners.forEach(doc => {
                if(doc.name == partner){
                    partnerId = doc.partnerId;
                    return;
                };
            });
            
            axios.get(`${LOCAL_BACKEND_URL}/allcourses?partnerId=${partnerId}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    setCourses([...res.data.courses])
                    setNoCourseFoundInPartner(false);
                })
                .catch(err =>{
                    console.log(err.response.data);
                    setNoCourseFoundInPartner(true);
                })
            }
    }, [partner, partners]);

    useEffect(() => {
        if (fu.length > 0) {
            setPartners([]);
            axios.get(`${LOCAL_BACKEND_URL}/allpartners/${fu}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    setPartners([...res.data.partners]);
                    setNoPartnersInFU(false);
                })
                .catch(err => {
                    console.log(err.message);
                    setNoPartnersInFU(true);
                });
        }
    }, [fu]);

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/allClients`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
    })
        .then(res => {
            setClients([...res.data.clients]);
        })
        .catch(err => console.log(err.message), setClients([]) );

    }, []);

    useEffect(() => {
        if (courses.length > 0) {
            const tempTitlesAndReferences = {};
            const tempCourseTitles = [];
            courses.forEach(course => {
                tempTitlesAndReferences[course.title] = course.reference;
                tempCourseTitles.push(course.title);
            });
            console.log('tempTitlesAndReferences', tempTitlesAndReferences)
            setCoursesTitlesAndReferences(tempTitlesAndReferences);
            setCourseTitles(tempCourseTitles);
        }else{
            setCourseTitles([]);
        };
    }, [courses]);

    useEffect(() => {
        if (partners.length > 0) {
            const tempPartnersIdsAndReferences = {};
            partners.forEach(partner => {
                tempPartnersIdsAndReferences[partner.name] = {
                    'id': partner.partnerId,
                    'reference': partner.partnerReference
                }
            });
            console.log(tempPartnersIdsAndReferences);
            setPartnersIdsAndReferences(tempPartnersIdsAndReferences);
        };
    }, [partners]);

    const [cpfNumber, setCpfNumber] = useState('');
    const maskCpfPatterns = ['999.999.999-99'];

    const cpfHandler = async (cpfValue) => {
        setCpfNumber(setGenericMask(cpfValue, maskCpfPatterns));
    }

    useEffect(() => {
        if (cpfNumber.length >= 14 && clients.length > 0) {

            // axios.get(`${TestEndpoint}/clients`)
            //     .then(res => {
            //         setClients([...res.data.clients]);
            //     })
            //     .catch(err => console.log(err.message));

            axios.post(`${LOCAL_BACKEND_URL}/cpfverify`, {
                "clientCpf": unformatCpf(cpfNumber)
            }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
                .then((data) => {
                    console.log(data);
                    setClientFormVisibility('');
                    setClientWarningVisibility('block');
                    setClientWarningMessage('');
                })
                .catch(() => {
                    setClientFormVisibility('block');
                    setClientWarningVisibility('block');
                    setClientWarningMessage(ClientCPFNotFound);
                })
        }
        else {
            setClientFormVisibility('none');
            setClientWarningVisibility('none');
            setClientWarningMessage('');
        }
    }, [cpfNumber]);

    const [cepNumber, setCepNumber] = useState('');
    const maskCepPattern = ['99999-999'];

    const cepMask = (cepValue) => {
        setCepNumber(setGenericMask(cepValue, maskCepPattern));
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [idCard, setIdCard] = useState('');
    const [cnh, setCnh] = useState('');
    const [categoriaCNH, setCategoriaCNH] = useState('');
    const [renach, setRenach] = useState('')
    const [bornDate, setBornDate] = useState('')
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() => {
        if (unMask(cepNumber).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(cepNumber)}/json/`)
                .then(res => {
                    setStreet(res.data.logradouro);
                    setNeighborhood(res.data.bairro);
                    setCity(res.data.localidade);
                    setState(res.data.uf);
                    setCountry('Brasil');
                })
                .catch(err => console.log(err));
        }
        else {
            setStreet('');
            setNeighborhood('');
            setCity('');
            setState(null);
        }
    }, [cepNumber]);



    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['(99) 9999-9999', '(99) 99999-9999']

    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }

    const submitHandler = (e) => {
        console.log(partnersIdsAndReferences);
        /* e.preventDefault(); */
        /* let data;
        if (isPartner == true) {
            data = {
                "clientCpf": unMask(cpfNumber),
                "courseReference": parseInt(coursesTitlesAndReferences[course]),
                "value": parseSaleAmount(saleAmount),
                "fu": fu,
                "paymentMethod": parseInt(paymentMethod),
                "partner": isPartner,
                "partnerId": partnersIdsAndReferences[partner].id,
                "partnerReference": partnersIdsAndReferences[partner].reference,
                "integration": parseInt(integration),
                "zipCode": cepNumber.split('-').join(''),
                "street": street,
                "number": parseInt(number),
                "complement": complement,
                "district": state,
                "city": city,
                "country": "Brasil",
                "name": name,
                "email": email,
                "phoneNumber": `+55${unMask(phoneNumber)}`,
                "createdAt": Date(createdAt),
                "bornDate": Date(bornDate),
                "idCard": idCard,
                "bankSLipUrl": 1,
                "gender": gender
            };
        } else {
            data = {
                "clientCpf": unMask(cpfNumber),
                "courseReference": parseInt(coursesTitlesAndReferences[course]),
                "value": parseSaleAmount(saleAmount),
                "fu": fu,
                "paymentMethod": parseInt(paymentMethod),
                "partner": false,
                "integration": parseInt(integration),
                "zipCode": cepNumber.split('-').join(''),
                "street": street,
                "number": parseInt(number),
                "complement": complement,
                "district": state,
                "city": city,
                "country": "Brasil",
                "name": name,
                "email": email,
                "phoneNumber": `+55${unMask(phoneNumber)}`,
                "createdAt": Date(createdAt),
                "bornDate": Date(bornDate),
                "idCard": idCard,
                "bankSLipUrl": 1,
                "gender": gender
            };
            console.log(isPartner)
        }; */
        
        const data = {
            "clientCpf": unMask(cpfNumber),
            "courseReference": parseInt(coursesTitlesAndReferences[course]),
            "value": parseFloat(parseSaleAmount(saleAmount)).toFixed(2),
            "fu": fu,
            "paymentMethod": parseInt(paymentMethod),
            "partnerId": partnersIdsAndReferences[partner].id,
            "partnerReference": partnersIdsAndReferences[partner].reference,
            "zipCode": cepNumber.split('-').join(''),
            "street": street,
            "number": parseInt(number),
            "complement": complement,
            "district": neighborhood,
            "city": city,
            "country": "Brasil",
            "name": name,
            "email": email,
            "phoneNumber": `+55${unMask(phoneNumber)}`,
            "createdAt": new Date(),
            "bornDate": bornDate,
            "idCard": idCard,
            "cnh": cnh,// TODO pegar o nome da variavel aqui
            "categoria": categoriaCNH,
            "renach": renach,
            "bankSLipUrl": 1,
            "gender": gender
        };

        axios.post(`${LOCAL_BACKEND_URL}/sale`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
              }
        })
            .then(res => {
                console.log(res);
                history.push(`${process.env.PUBLIC_URL}/base/vendas`);

            })
            .catch(err => console.log(err.response.data));
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <>
            <Breadcrumb parent="Vendas" title="Nova Venda" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><User /> {SellCreate}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5><strong>{Client}</strong></h5>
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-12">
                                            <div className="form-group" style={{ width: '50%' }}>
                                                <label className="form-label">{CPF}*</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="cpf"
                                                    value={cpfNumber}
                                                    minlength="14"
                                                    maxlength="14"
                                                    onChange={(e) => cpfHandler(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CPF é obrigatório.",
                                                            minLength: 4
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cpf" />
                                            </div>
                                        </div>
                                        <div className="card-body col-md-12" style={{ display: clientWarningVisibility }}>
                                            <div className="row">
                                                {clientWarningMessage}
                                            </div>
                                        </div>
                                        <div style={{display: clientFormVisibility}}>
                                            <PrivateClientCreateForm
                                                bornDate={bornDate}
                                                setBornDate={setBornDate}
                                                parentName={name}
                                                setParentName={setName}
                                                parentEmail={email}
                                                setParentEmail={setEmail}
                                                cpf={cpfNumber}
                                                cpfDisabled={true}
                                                parentCepMask={cepMask}
                                                parentCepNumber={cepNumber}
                                                parentStreet={street}
                                                setParentStreet={setStreet}
                                                parentNeighborhood={neighborhood}
                                                setParentNeighborhood={setNeighborhood}
                                                parentNumber={number}
                                                setParentNumber={setNumber}
                                                parentComplement={complement}
                                                setParentComplement={setComplement}
                                                parentCity={city}
                                                setParentCity={setCity}
                                                parentState={state}
                                                setParentState={setState}
                                                parentPhoneMask={phoneMask}
                                                parentPhone={phoneNumber}
                                                parentCountry={country}
                                                setParentCountry={setCountry}
                                                setParentIdCard={setIdCard}
                                                parentIdCard={idCard}
                                                parentCnh={cnh}
                                                setParentCnh={setCnh}
                                                parentCategoriaCNH={categoriaCNH}
                                                setParentCategoriaCNH={setCategoriaCNH}
                                                parentRenach={renach}
                                                setParentRenach={setRenach}
                                                setParentGender={setGender}
                                                parentGender={gender}
                                            />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Estado</label>
                                                <select
                                                    className="form-control"
                                                    value={fu}
                                                    onChange={(e) => setFu(e.target.value)}
                                                >
                                                    <option value={null}>Selecione o Estado</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                    <option value="DF">Distrito Federal</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Selecione um parceiro*</label>
                                                <select
                                                    className="form-control"
                                                    value={partner}
                                                    onChange={e => {
                                                        console.log(e.target.value)
                                                        setPartner(e.target.value)
                                                    }}
                                                    disabled={noPartnersInFU}
                                                >
                                                    <option select={null}>{"Escolha um parceiro..."}</option>
                                                    {
                                                        partners.map(partner => {
                                                            return <option>{partner.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Meio de pagamento</label>
                                                <select
                                                    className="form-control"
                                                    value={paymentMethod}
                                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                                >
                                                    <option value={null}>Selecione o meio de pagamento</option>
                                                    <option value={0}>Nenhum</option>
                                                    <option value={1}>Boleto</option>
                                                    <option value={2}>Cartão</option>
                                                    <option value={3}>Pix</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Course}*</label>
                                                <select
                                                    className="form-control"
                                                    value={course}
                                                    onChange={e => setCourse(e.target.value)}
                                                    disabled={noCourseFoundInPartner}
                                                >
                                                    <option select={false}>{"Escolha um curso..."}</option>
                                                    {
                                                        courseTitles.map(courseTitle => {
                                                            return <option>{courseTitle}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/vendas`)}>{CancelUpdateProfile}</button>
                                    <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function PartnerSellCreate({ history }) {

    const [clients, setClients] = useState([]);
    const [clientWarningVisibility, setClientWarningVisibility] = useState('none');
    const [clientFormVisibility, setClientFormVisibility] = useState('none');
    const [clientWarningMessage, setClientWarningMessage] = useState('');

    // State variables to retrieve and organize course data
    const [course, setCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [courseTitles, setCourseTitles] = useState([]);
    const [gender, setGender] = useState('');

    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState('');
    const [partnerReference, setPartnerReference] = useState('');
    const [responseCourses, setResponseCourses] = useState([]);
    const [courseReference, setCourseReference] = useState('');
    const [clientCpf, setClientCpf] = useState('');
    const [coursesTitlesAndReferences, setCoursesTitlesAndReferences] = useState('');
    const [partnersIdsAndReferences, setPartnersIdsAndReferences] = useState({});
    // Additional state variables
    const [fu, setFu] = useState('');
    const [paymentMethod, setPaymentMethod] = useState(0);
    const [saleAmount, setSaleAmount] = useState('');

    // Mask for sale amount
    const saleMask = createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 13,
        allowNegative: false,
        allowLeadingZeroes: false
    });


    const maskSaleAmountPattern = ['999.999.999.999.999'];
    // Handles sale amount change; applies mask automatically
    const saleAmountHandler = async (saleAmountValue) => {
        setSaleAmount(setGenericMask(saleAmountValue, maskSaleAmountPattern));
    }

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/allCourses`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
        .then((res) => {
                console.log('resposta', res)
                setCourses([...res.data.courses])
            })
            .catch(err => console.log('Deu erro ao tentar buscar cursos',err.response));

        axios.get(`${LOCAL_BACKEND_URL}/everySinglePartner`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
                    .then((res) => {
                setPartners([...res.data.partners]);
            })
            .catch(err => console.log(err.message));

        axios.get(`${LOCAL_BACKEND_URL}/allClients`, {
            headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setClients([...res.data.clients]);
            })
            .catch(err => console.log(err.message), setClients([]));

        axios.get(`${LOCAL_BACKEND_URL}/partner/${localStorage.getItem('userId')}`)
            .then(res => {
                setPartnerReference(res.data.Partner.partnerReference);
            })
            .catch(err => console.log(err.message));

    }, []);

    useEffect(() => {
        console.log(' to show courses', courses)
        if (courses.length > 0) {
            const tempTitlesAndReferences = {};
            const tempCourseTitles = [];
            courses.forEach(course => {
                tempTitlesAndReferences[course.title] = course.reference;
                tempCourseTitles.push(course.title);
            });
            console.log(tempTitlesAndReferences)
            setCoursesTitlesAndReferences(tempTitlesAndReferences);
            setCourseTitles(tempCourseTitles);
        }
    }, [courses]);

    useEffect(() => {
        if (partners.length > 0) {
            const tempPartnersIdsAndReferences = {};
            partners.forEach(partner => {
                tempPartnersIdsAndReferences[partner.name] = {
                    'id': partner.partnerId,
                    'reference': partner.partnerReference
                }
            });
            console.log(tempPartnersIdsAndReferences);
            setPartnersIdsAndReferences(tempPartnersIdsAndReferences);
        }
    }, [partners]);

    const [cpfNumber, setCpfNumber] = useState('');
    const maskCpfPatterns = ['999.999.999-99'];

    const cpfHandler = async (cpfValue) => {
        setCpfNumber(setGenericMask(cpfValue, maskCpfPatterns));
    }

    useEffect(() => {
        if (cpfNumber.length >= 14) {
            console.log('here');

            axios.post(`${LOCAL_BACKEND_URL}/cpfverify`, {
                "clientCpf": unformatCpf(cpfNumber)
            }, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            })
                .then(() => {
                    setClientFormVisibility('none');
                    setClientWarningVisibility('block');
                    setClientWarningMessage(ClientCPFFound);
                })
                .catch(() => {
                    setClientFormVisibility('block');
                    setClientWarningVisibility('block');
                    setClientWarningMessage(ClientCPFNotFound);
                })
        }
        else {
            setClientFormVisibility('none');
            setClientWarningVisibility('none');
            setClientWarningMessage('');
        }
    }, [cpfNumber]);



    const [cepNumber, setCepNumber] = useState('');
    const maskCepPattern = ['99999-999'];

    const cepMask = (cepValue) => {
        setCepNumber(setGenericMask(cepValue, maskCepPattern));
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [idCard, setIdCard] = useState('');
    const [cnh, setCnh] = useState('');
    const [categoriaCNH, setCategoriaCNH] = useState('');
    const [renach, setRenach] = useState('');
    const [bornDate, setBornDate] = useState('')
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [complement, setComplement] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() => {
        if (unMask(cepNumber).length >= 8) {
            axios.get(`https://viacep.com.br/ws/${unMask(cepNumber)}/json/`)
                .then(res => {
                    setStreet(res.data.logradouro);
                    setNeighborhood(res.data.bairro);
                    setCity(res.data.localidade);
                    setState(res.data.uf);
                    setCountry('Brasil');
                })
                .catch(err => console.log(err));
        }
        else {
            setStreet('');
            setNeighborhood('');
            setCity('');
            setState(null);
        }
    }, [cepNumber]);



    const [phoneNumber, setPhoneNumber] = useState('');
    const phonePattern = ['(99) 9999-9999', '(99) 99999-9999']

    const phoneMask = (phoneValue) => {
        setPhoneNumber(setGenericMask(phoneValue, phonePattern));
    }

    const setGenericMask = (masked, maskPattern) => {
        // return cpf.format(cpfValue);
        masked = unMask(masked);
        return mask(masked, maskPattern);
    }

    const submitHandler = (e) => {
        console.log(partnersIdsAndReferences);

        const data = {
            "clientCpf": unMask(cpfNumber),
            "courseReference": parseInt(coursesTitlesAndReferences[course]),
            "value": parseFloat(parseSaleAmount(saleAmount)).toFixed(2),
            "fu": fu,
            "partnerId": localStorage.getItem('userId'),
            "partnerReference": partnerReference,
            "zipCode": cepNumber.split('-').join(''),
            "street": street,
            "number": parseInt(number),
            "complement": complement,
            "district": neighborhood,
            "city": city,
            "country": "Brasil",
            "name": name,
            "email": email,
            "phoneNumber": `+55${unMask(phoneNumber)}`,
            "createdAt": Date(createdAt),
            "bornDate": Date(bornDate),
            "idCard": idCard,
            "cnh": cnh,
            "categoria": categoriaCNH, // TODO pegar o nome da variavel
            "renach": renach,
            "bankSLipUrl": 1,
            "gender": gender

        };
        console.log(data);

        axios.post(`${LOCAL_BACKEND_URL}/sale`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
              }
        })
            .then(res => {
                console.log(res);
                toast.success('Venda criada!')
                history.push(`${process.env.PUBLIC_URL}/base/vendas`);

            })
            .catch(err =>{
                toast.error('Não foi possível criar essa venda.')
                console.log(err.response.data)
            });
    }

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();
    console.log('course titles', courseTitles)
    return (
        <>
            <Breadcrumb parent="Vendas" title="Nova Venda" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="card" onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><User /> {SellCreate}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5><strong>{Client}</strong></h5>
                                        </div>
                                        {/* <!-- Force next columns to break to new line --> */}
                                        <div className="w-100"></div>
                                        <div className="col-md-12">
                                            <div className="form-group" style={{ width: '50%' }}>
                                                <label className="form-label">{CPF}*</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="cpf"
                                                    value={cpfNumber}
                                                    minlength="14"
                                                    maxlength="14"
                                                    onChange={(e) => cpfHandler(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: "O CPF é obrigatório.",
                                                            minLength: 4
                                                        })
                                                    }
                                                />
                                                <ErrorMessage errors={errors} name="cpf" />
                                            </div>
                                        </div>
                                        <div className="card-body col-md-12" style={{ display: clientWarningVisibility }}>
                                            <div className="row">
                                                {clientWarningMessage}
                                            </div>
                                        </div>
                                        <div>
                                            <PrivateClientCreateForm
                                                parentName={name}
                                                setParentName={setName}
                                                parentEmail={email}
                                                setParentEmail={setEmail}
                                                cpf={cpfNumber}
                                                cpfDisabled={true}
                                                parentCepMask={cepMask}
                                                parentCepNumber={cepNumber}
                                                parentStreet={street}
                                                setParentStreet={setStreet}
                                                parentNeighborhood={neighborhood}
                                                setParentNeighborhood={setNeighborhood}
                                                parentNumber={number}
                                                setParentNumber={setNumber}
                                                parentComplement={complement}
                                                setParentComplement={setComplement}
                                                parentCity={city}
                                                setParentCity={setCity}
                                                parentState={state}
                                                setParentState={setState}
                                                parentPhoneMask={phoneMask}
                                                parentPhone={phoneNumber}
                                                parentCountry={country}
                                                setParentCountry={setCountry}
                                                setParentIdCard={setIdCard}
                                                parentIdCard={idCard}
                                                parentCnh={cnh}
                                                setParentCnh={setCnh}
                                                parentCategoriaCNH={categoriaCNH}
                                                setParentCategoriaCNH={setCategoriaCNH}
                                                parentRenach={renach}
                                                setParentRenach={setRenach}
                                                setParentGender={setGender}
                                                parentGender={gender}
                                            />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Estado</label>
                                                <select
                                                    className="form-control"
                                                    value={fu}
                                                    onChange={(e) => setFu(e.target.value)}
                                                >
                                                    <option value={null}>Selecione o Estado</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                    <option value="DF">Distrito Federal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Course}*</label>
                                                <select
                                                    className="form-control"
                                                    value={course}
                                                    onChange={e => setCourse(e.target.value)}
                                                >
                                                    <option select={false}>{"Escolha um curso..."}</option>
                                                    {
                                                        courseTitles.map(courseTitle => {
                                                            return <option>{courseTitle}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*  <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Data de criação</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={createdAt}
                                                    onChange={e => setCreatedAt(e.target.value)}
                                                />
                                            </div>
                                        </div> */}

                                        {/* <!-- Force next columns to break to new line --> */}
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/vendas`)}>{CancelUpdateProfile}</button>
                                    <button type="submit" className="btn btn-primary ml-3" onClick={handleSubmit}>{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const cpfIsRegistered = (formattedCpf, clients) => {
    const unformattedCpf = unformatCpf(formattedCpf);
    for (let i = 0; i < clients.length; i++) {
        if (clients[i].clientCpf === unformattedCpf) {
            return true;
        }
    }
    return false;
}

const unformatCpf = (formattedCpf) => {
    return formattedCpf
        .split('.')
        .join('')
        .split('-')
        .join('');
}

const PrivateClientCreateForm = (props) => {
    return (
        <div className="card-body">
            <div className="row">
                {/* <!-- Force next columns to break to new line --> */}
                <div className="w-100"></div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">{Name}*</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.parentName}
                            onChange={e => props.setParentName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">Data de Nascimento</label>
                        <input
                            className="form-control"
                            type="date"
                            value={props.bornDate}
                            onChange={e => props.setBornDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">{EmailAddress}*</label>
                        <input
                            className="form-control"
                            type="email"
                            value={props.parentEmail}
                            onChange={e => props.setParentEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">{CPF}</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.cpf}
                            minlength="14"
                            maxlength="14"
                            disabled={props.cpfDisabled}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">Número do RG</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.parentIdCard}
                            onChange={e => props.setParentIdCard(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">Gênero</label>
                        <select
                            className="form-control"
                            value={props.Parentgender}
                            onChange={(e) => props.setParentGender(e.target.value)}
                        >
                            <option value={null}>Selecione o Gênero</option>
                            <option value='m'>Masculino</option>
                            <option value='f'>Feminino</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">{PhoneNumber}</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.parentPhone}
                            onChange={(e) => props.parentPhoneMask(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">CNH</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.parentCnh}
                            onChange={e => props.setParentCnh(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">Categoria CNH*</label>
                        <select
                            className="form-control"
                            value={props.parentCategoriaCNH}
                            onChange={(e) => props.setParentCategoriaCNH(e.target.value)}
                        >
                            <option value={null}>Selecione a categoria</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="AB">AB</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-label">RENACH</label>
                        <input
                            className="form-control"
                            type="text"
                            value={props.parentRenach}
                            onChange={e => props.setParentRenach(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 mt-3 mb-3">
                            <h5><strong>{Address}</strong></h5>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{ZipCode}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentCepNumber}
                                    onChange={(e) => props.parentCepMask(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{Street}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentStreet}
                                    onChange={e => props.setParentNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{Number}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentNumber}
                                    onChange={e => props.setParentNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{Complement}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentComplement}
                                    onChange={e => props.setParentComplement(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{District}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentNeighborhood}
                                    onChange={e => props.setParentNeighborhood(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{City}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentCity}
                                    onChange={e => props.setParentCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{FU}</label>
                                <select
                                    className="form-control"
                                    value={props.parentState}
                                    onChange={e => props.parentSetState(e.target.value)}
                                >
                                    <option value="NULL"> </option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                    <option value="EX">Estrangeiro</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{Country}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={props.parentCountry}
                                    onChange={e => props.setParentCountry(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const NewSellCreate = localStorage.getItem('isAdmin') === 'true' ? AdminSellCreate : PartnerSellCreate;

export default NewSellCreate;

const parseSaleAmount = (amount) => {
    return amount
        .replace(/^\D+/g, '')
        .split('.')
        .join('');
}