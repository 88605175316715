import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { LOCAL_BACKEND_URL } from "../../data/urls";
export default function CompCarousel() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getCoupons();
  }, []);

  async function getCoupons() {
    axios
      .get(`${LOCAL_BACKEND_URL}/banners`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
            console.log('res', res.data.banners)
            setBanners([...res.data.banners])
        })
      .catch((err) => console.log(err.response.data));
  }

  return (
    <Carousel 
        autoPlay
        interval={5000} 
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        stopOnHover={false}
        infiniteLoop={true}
        className="p-2"
        style={{ maxHeight: '230px'}}
    >
      {
        banners.length > 0 ? (
            banners.map((banner) => (
              <div className="position-relative">
                <div className="d-flex justify-content-center align-items-center text-center">
                  {banners.length > 0 && (
                    <a href={banner.url} alt={banner.name} className="w-100">
                      <img
                        src={banner.imageURL}
                        alt="Teste"
                        style={{ maxHeight: "300px" }}
                        className="w-100"
                      ></img>
                    </a>
                  )}
                  {/*
                  <div className='position-absolute position-relative z-10' style={{bottom: 60}}>
                      <h2>Titulo</h2>
                      <p>Descrição</p>
                  </div>
                  */}
                </div>
              </div>
            ))
        ) : null
      }
    </Carousel>
  );
}
