import React from 'react';

const CostumerOwing = ({history}) => {
    return (
        <div
            className='container-fluid text-center bg-white w-75'
            style={{
                marginTop: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div
                className='row h-100'
                style={{
                    height: '100%'
                }}
            >
                <div 
                    className='col-md p-3'
                    style={{
                        height: '100%'
                    }}
                >
                    <p className='h1'>
                        Ei CFC, existem faturas vencidas em aberto!
                    </p>
                    <p className='mt-3 h4'>
                        Clique aqui para acessar suas faturas.
                    </p>
                    <div className='btn mt-2 btn-info btn-lg' onClick={() => history.push('/loja/financeiro/faturas')}>
                        MINHAS FATURAS
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CostumerOwing;