import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Users, New, } from '../../../constant';
import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component';
import man from '../../../assets/images/dashboard/user.png';
import '../../../components/base/lists/filter.css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ButtonDeleteCredit from '../../icetran/ButtonDeleteCredit';
import { db } from "../../../data/config";
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { mask, unMask } from 'remask';

import { requestAuthConfig } from '../../../utils/utilTools';

const CreditPackages = ({ history }) => {

  const [responseData, setResponseData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [creditsAndIds, setCreditsAndIds] = useState([]);
  
  // state variables used to implement pagination
  const [paginationCountArray, setPaginationCountArray] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
  const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');

  
  const [name, setName]                       = useState('');
  const [credits, setCredits]                 = useState(0);
  const [value, setValue]                     = useState(0);
  const [disabled, setDisabled]               = useState(false);
  const [creditPackageId, setCreditPackageId] = useState('');


  // ALTERAR: NÃO FUNCIONA!!!
  const [refresh, setRefresh] = useState();

  useEffect(() => console.log(localStorage));

  useEffect(() => console.log(localStorage.getItem('token')));

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/credits?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.credits);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${LOCAL_BACKEND_URL}/allcredits`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        setTotalNumberOfUsers(res.data.credits.length);
      })
      .catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (responseData.length > 0 && numberOfPages > 0) {

      // Populates the table for the first time
      let carrierArray = [];
      let tempCreditsAndIds = {};
      responseData.forEach(credit => {
        carrierArray.push(credit);
      });
      setTableData(carrierArray);
      setCurrentPaginationLength(carrierArray.length);
      const tempCountArray = [];
      for (let i = 1; i <= numberOfPages; i++) {
        tempCountArray.push(i);
      }
      setPaginationCountArray(tempCountArray);
    }
  }, [responseData, numberOfPages]);

  const changeTableData = (dataIndex) => {
    setCurrentPagination(parseInt(dataIndex) - 1);
    axios.get(`${LOCAL_BACKEND_URL}/credits?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then((response) => {
        setResponseData(response.data.credits);
        setNumberOfPages(response.data.info.numPages);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'space-between', alignItems: "center", }}>{row.name}</div>,
      center: true,
    },
    {
      name: 'Créditos',
      selector: 'credits',
      sortable: true,
      cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center", }}>{row.credits}</div>,
      center: true,
    },
    {
      name: 'Valor',
      selector: 'value',
      sortable: true,
      center: true,
      cell: row => <div>{row.value}</div>
    },
    {
      name: 'Ativado',
      selector: 'disabled',
      sortable: true,
      center: true,
      cell: row => <div>{row.disabled ? 'Não' : 'Sim'}</div>
    },
    {
      name: 'Ações',
      selector: 'edit',
      sortable: false,
      cell: row => <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "50%" }}><li className="list-group-item-action">
        <button
          style={{ marginRight: 8 }}
          onClick={() => history.push({
            pathname: `${process.env.PUBLIC_URL}/edit/credit`,
            state: {
              creditId: row.creditsPackageId,
              creditInfo: row
            }
          })}
          className="btn btn-sm btn-warning">
          <i className="icon icon-pencil"></i>
        </button></li>
        <li className="list-group-item-action">
          <ButtonDeleteCredit
            credit={row}
          />
        </li></div>,
      center: true,
    },
  ];

  // const contextActions = useMemo(() => {
  function searchCredit(rows) {
    return rows.filter(row =>
      row.name.toLowerCase().indexOf(searchTerm) > -1)
  }
  // }, []);

  return (
    <div>
      <Breadcrumb parent="Pacotes de créditos" title="Pacotes de créditos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between ml-3 mr-5">
                  <h5>Pacotes de créditos</h5>
                  <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/creditos/criar`)} type="button">{New}</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="ml-5 mt-3 mb-3 col-sm-4">
                  <div className="form-group row">
                    <div className="search_wrap search_wrap_3">
                      <div className="search_box">
                        <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datatable-react mr-5 ml-5 form-group">
                <DataTable
                  columns={columns}
                  data={searchCredit(tableData)}
                  striped={true}
                  center={true}
                  noDataComponent="Não possui nenhum dado."
                // contextActions={contextActions}
                />


                <div className="container d-flex justify-content-between mw-100 align-items-center">
                  <div className="mt-5">
                    {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfUsers}` : ''}
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                      {
                        currentPagination === 0 ?
                          <PaginationItem disabled>
                              <PaginationLink first href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              first
                              onClick={() => changeTableData((currentPagination + 1) - 1)}
                              />
                          </PaginationItem>
                      }
                      {
                        paginationCountArray.map(item =>
                          <PaginationItem>
                              <PaginationLink onClick={() => changeTableData(item)}>
                              {item}
                              </PaginationLink>
                          </PaginationItem>
                        )
                      }
                      {
                        currentPagination === numberOfPages - 1 ?
                          <PaginationItem disabled>
                              <PaginationLink last href="#javascript" />
                          </PaginationItem>
                          :
                          <PaginationItem>
                              <PaginationLink
                              last
                              onClick={() => changeTableData((currentPagination + 1) + 1)}
                              />
                          </PaginationItem>
                      }
                    </Pagination>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditPackages;
