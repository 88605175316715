import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import man from '../../assets/images/dashboard/user.png';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { requestAuthConfig } from '../../utils/utilTools';

export default function ButtonDeleteSell(props) {
    const [modal, setModal] = useState();

    const toggle = () => {
        setModal(!modal)
    }

    const [saleData, setSaleData] = useState({});

    useEffect(() => {
        setSaleData(props.sale);
    }, [props.sale]);

    const deleteUserHandler = () => {
        axios.delete(`${LOCAL_BACKEND_URL}/sale/${saleData.saleId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                console.log(res);
                toggle();
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            });

    }

    return (
        <>
            <button onClick={toggle} className="btn btn-sm btn-danger">
                <i className="icon icon-trash"></i>
            </button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirmar ação</ModalHeader>
                <ModalBody>
                    <h5>Você tem certeza que deseja remover a venda abaixo?</h5>
                    <div className="mt-4 mb-3 mw-200 d-flex justify-content-start align-items-center">
                        <h4 className="img-70 rounded-circle mr-3" alt="man" src={saleData.clientName} />
                        <h5>{saleData.courseTitle}</h5>
                    </div>
                    <ul>
                        <li className="h6">Estado: <span>{saleData.fu}</span></li>
                        <li className="mt-1 h6">Valor: <span>{saleData.value}</span></li>
                    </ul>
                    <p className="mt-4">
                        <span style={{ color: "red", fontWeight: "bold", }}>Atenção</span> essa ação não poderá ser revertida.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Cancelar</Button>
                    <Button color="danger" onClick={deleteUserHandler}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}