import React, { useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Sells, New, PendentSale, CompleteSale, CompleteAllSale, Warning, Border, CancelSales, DenySales, Faturamento } from '../../../constant'
import '../../../components/base/lists/filter.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { db } from "../../../data/config";
import { tableDataVendas } from '../../../data/dummyTableDataVendas';
import { useState } from 'react';
import 'chartjs-plugin-datalabels';
import { DollarSign, Tag, ShoppingBag } from 'react-feather';
import CountUp from 'react-countup';
import ChartistGraph from 'react-chartist';
import { chartsmall } from '../../../data/default';
import configDB from '../../../data/customizer/config';
import axios from 'axios';
import { LOCAL_BACKEND_URL } from '../../../data/urls';
import { GrMoney } from 'react-icons/gr';
import { BsFillBagCheckFill } from 'react-icons/bs';
import ButtonDeleteSell from '../../icetran/ButtonDeleteSell';

import { formatReais } from '../../../utils/utilTools';
import ButtonDeleteInvoice from '../../icetran/ButtonDeleteInvoice';
import ButtonSeeSell from '../../icetran/ButtonSeeSell';
import ButtonSuccessInvoice from '../../icetran/ButtonSuccesfull';
import ButtonShowInvoice from '../../icetran/ButtonShowInvoice';

import { requestAuthConfig } from '../../../utils/utilTools';

import { MaxNumberOfItemsPerTable } from '../../../constant/designControl';


const primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

const Faturas = ({ history }) => {

    const [searchTerm, setSearchTerm] = useState('');

    const [responseData, setResponseData] = useState([]);
    const [refresh, setRefresh] = useState();
    const [data, setData] = useState([]);
    const [saleClient, setSaleClient] = useState('');
    const [invoiceAndId, setInvoiceAndId] = useState({});

    // state variables used to implement pagination
    const [paginationCountArray, setPaginationCountArray] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPagination, setCurrentPagination] = useState(0);
    const [totalNumberOfInvoices, setTotalNumberOfInvoices] = useState(0);
    const [currentPaginationLength, setCurrentPaginationLength] = useState(0);

    const [salesAndId, setSalesAndId] = useState({});
    const [dataDel, setDataDel] = useState([]);

    const [salesData, setSalesData] = useState([]);
    const [monthValuePendent, setMonthValuePendent] = useState('');
    const [monthValueOk, setMonthValueOk] = useState('')
    const [monthValueDeny, setMonthValueDeny] = useState('')

    const [invoiceData, setInvoiceData] = useState([]);

    const [clients, setClients] = useState([]);
    const [clientsAndIds, setClientsAndIds] = useState({});

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    console.log('data', data)
    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/invoices?limit=${MaxNumberOfItemsPerTable}&page=${currentPagination + 1}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                try {
                    setResponseData(response.data.invoices);
                    setNumberOfPages(response.data.info.numPages);
                }
                catch (err) {
                    console.log(err);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });

        axios.get(`${LOCAL_BACKEND_URL}/allInvoices`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setTotalNumberOfInvoices(res.data.invoices.length);
            })
            .catch(err => console.log(err.message));

    }, []);

    useEffect(() => {
        if (responseData.length > 0 && numberOfPages > 0) {
            let carrierArray = [];
            const tempInvoicesAndIds = {};
            responseData.forEach(invoice => {
                carrierArray.push(invoice);
                carrierArray[carrierArray.length - 1].expirationDate = new Date(invoice.expirationDate._seconds * 1000).toLocaleDateString('pt-BR');
                carrierArray[carrierArray.length - 1].createdAt = new Date(invoice.createdAt._seconds * 1000).toLocaleDateString('pt-BR');
                tempInvoicesAndIds[invoice.invoiceId] = invoice;
            });
            setData(carrierArray);
            setCurrentPaginationLength(carrierArray.length);
            setInvoiceAndId(tempInvoicesAndIds);

            // Creates an array containing the number of pages the pagination should have
            const tempCountArray = [];
            for (let i = 1; i <= numberOfPages; i++) {
                tempCountArray.push(i);
            }
            setPaginationCountArray(tempCountArray);

        }
    }, [responseData, numberOfPages]);

    useEffect(() => {
        if (salesData.length > 0) {
            console.log(responseData);
            let carrierArray = [];
            let invoicesAndIdCarrier = {};
            responseData.forEach(invoice => {
                carrierArray.push(invoice)
                invoicesAndIdCarrier[invoice.clientName] = invoice.invoiceId;
            })
            setDataDel(carrierArray)
            // setInvoiceAndId({ ...invoicesAndIdCarrier });
        }
    }, [salesData]);

    const changeTableData = (dataIndex) => {
        setCurrentPagination(parseInt(dataIndex) - 1);
        axios.get(`${LOCAL_BACKEND_URL}/invoices?limit=${MaxNumberOfItemsPerTable}&page=${dataIndex}`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then((response) => {
            setResponseData(response.data.invoices);
            setNumberOfPages(response.data.info.numPages);
          })
          .catch((error) => {
            console.log(error.message);
          });
      }

    useEffect(() => {
        if (responseData > 0)
            console.log(responseData.bankllipData.barcode.slice(1, -1))
    }, []);

    const columns = [
        {
            name: 'Data',
            selector: 'createdAt',
            sortable: true,
            center: true,
            cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.createdAt}</div>,
        },
        /*{
            name: 'Data de vencimento',
            selector: 'expirationDate',
            sortable: true,
            center: true,
            cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.expirationDate}</div>,
        },*/
        {
            name: 'Parceiro',
            selector: 'partnerName',
            sortable: true,
            center: true,
            cell: row => <div style={{ overflow: 'inherit', textAlign: 'center' }}>{row.partnerName}</div>,
        },

        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            center: true,
            cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>{
                (() => {
                    if (row.status === 0) {
                        return <div className="btn btn-warning pl-3 pr-3 pt-1 pb-1" role="alert" >Pendente</div>
                    } else if (row.status === 1) {
                        return <div className="btn btn-success pl-3 pr-3 pt-1 pb-1" role="alert">Pago</div>
                    } else if (row.status === 2) {
                        return <div className="btn btn-danger pl-3 pr-3 pt-1 pb-1" role="alert" >Cancelada</div>
                    } else if (row.status === 3) {
                        return <div className="btn btn-warning pl-3 pr-3 pt-1 pb-1" role="alert" >Reembolsada</div>
                    }
                })()
            }</div>
        },
        /*{
            name: 'Concluir/Cancelar',
            selector: <div>teste</div>,
            sortable: true,
            center: true,
            cell: row => <div style={{ minWidth: "160px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>{
                (() => {
                    if (row.status === '2') {
                        return <button className="btn btn-danger" onClick={() => history.push(
                            {
                                pathname: `${process.env.PUBLIC_URL}/edit/fatura`,
                                invoiceId: row.invoiceId,
                                invoiceInfo: { ...invoiceAndId[row.invoiceId] }
                            }
                        )} className="btn btn-success">Concluída</button>
                    } else if (row.status !== '2') {
                        return <button className="btn btn-danger" onClick={() => history.push(
                            {
                                pathname: `${process.env.PUBLIC_URL}/edit/fatura`,
                                invoiceId: row.invoiceId,
                                invoiceInfo: { ...invoiceAndId[row.invoiceId] }
                            }
                        )} className="btn btn-danger">Cancelar</button>
                    } else if (row.status === 3) {
                        return <div className="btn btn-warning" role="alert" >Fim</div>
                    }
                })()
            }</div>,
        },*/
        {
            name: 'Ver Vendas',
            selector: 'see',
            sortable: false,
            center: true,
            cell: row => <button className="btn btn-info" style={{ overflow: 'inherit', textAlign: 'center' }} onClick={() => history.push(
                {
                    pathname: `${process.env.PUBLIC_URL}/fatura/vendas`,
                    invoiceId: row.invoiceId,
                    invoiceInfo: { ...invoiceAndId[row.sales] },
                    invoice: row
                }
            )}>Ver</button>
        },
        {
            name: 'Gerar Boleto',
            sortable: false,
            center: true,
            cell: row => <button
                            className={ row.bankSlipUrl === 'Already empty' ? 'btn btn-danger disabled' : 'btn btn-danger' }
                            style={{ overflow: 'inherit', textAlign: 'center', pointerEvents: row.bankSlipUrl === 'Already empty' ?  'none' : '' }}
                            disabled={row.bankSlipUrl === 'Already empty'}
                            onClick={(() => {
                                return window.open(row.bankSlipUrl, '_blank') 
                            })}
                        >Gerar Boleto </button>
        },
    ];

    // const contextActions = useMemo(() => {
    function searchInvoices(rows) {
        return rows.filter(row =>
            row.partnerName.toLowerCase().indexOf(searchTerm) > -1)
    }
    // }, []);

    useEffect(() => {
        const fetchSaleData = async () => {
            const saleData = await db.collection("sale").get()
        };
    }, [])

    return (
        <div>
            <Breadcrumb parent="Financeiro" title="Faturas" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>{Faturamento}</h5>
                                    {/* <button className="btn btn-primary pr-4 pl-4" onClick={() => history.push(`${process.env.PUBLIC_URL}/financeiro/fatura`)} type="button">{New}</button> */}
                                </div>
                            </div>

                            <div className="ml-5 mt-3 mb-3 col-sm-4">
                                <div className="form-group row">
                                    <div className="search_wrap search_wrap_3">
                                        <div className="search_box">
                                            <input type="text" className="form-control" value={searchTerm} placeholder="Digite o que está buscando" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <DataTable
                                    columns={columns}
                                    // data={data}
                                    data={searchInvoices(data)}
                                    striped={true}
                                    center={true}
                                    noDataComponent="Não possui nenhum dado."
                                // contextActions={contextActions}
                                />
                                <div className="container d-flex justify-content-between mw-100 align-items-center">
                                    <div className="mt-5">
                                        {currentPaginationLength > 0 ? `${10 * currentPagination + 1}-${10 * currentPagination + currentPaginationLength} de ${totalNumberOfInvoices}` : ''}
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <Pagination aria-label="Page navigation" className="mt-2 mr-1 pagination justify-content-end pagination-primary">
                                            {
                                                currentPagination === 0 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink first href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    first
                                                    onClick={() => changeTableData((currentPagination + 1) - 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                            {
                                                paginationCountArray.map(item =>
                                                <PaginationItem>
                                                    <PaginationLink onClick={() => changeTableData(item)}>
                                                    {item}
                                                    </PaginationLink>
                                                </PaginationItem>
                                                )
                                            }
                                            {
                                                currentPagination === numberOfPages - 1 ?
                                                <PaginationItem disabled>
                                                    <PaginationLink last href="#javascript" />
                                                </PaginationItem>
                                                :
                                                <PaginationItem>
                                                    <PaginationLink
                                                    last
                                                    onClick={() => changeTableData((currentPagination + 1) + 1)}
                                                    />
                                                </PaginationItem>
                                            }
                                        </Pagination>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Faturas;


const getInvoiceData = (invoice, idToBeFound) => {
    let returnValue = 'Invoice not found';
    invoice.forEach(invoice => {
        if (invoice.invoiceId === idToBeFound) {
            returnValue = { ...invoice };
        }
    });
    return returnValue;
}