import React, { useState } from 'react';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import axios from 'axios';

import {
    useGoogleReCaptcha,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecoveryPasswordSolicitation = ({ history }) => {
    const [email, setEmail] = useState('');
   
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submitHandler = event => {
        try {
            const newToken = executeRecaptcha();
            newToken.then(res => {
                axios.post(`${LOCAL_BACKEND_URL}/recoverypassword`, { 
                    email,
                    token: res 
                })
                .then(res => {
                    setTimeout(() => {
                        toast.success('Solicitação realizada com sucesso.');
                    }, 200);
                    // history.push(`${process.env.PUBLIC_URL}/alterarsenha`);
                })
                .catch(err => {
                    toast.error(err.response.data.error);
                });
            });
        }
        catch (err) {
            setTimeout(() => {
                toast.error("Oppss... Parece que você é um robô.");
            }, 200);
        }
    };

    return (
        <div>
            <GoogleReCaptcha onVerify={t => console.log({ t })} />
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box" style={{ width: '60%' }}>
                                    <div className="text-center"><img src={''} alt="" /></div>
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="text-center mb-5">
                                                <h4>Recuperação de senha</h4>
                                            </div>
                                            <form className="card">
                                                <div className="form-group">
                                                    <label className="form-label pt-0">{"Digite seu email: "}</label>
                                                    <input 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    required="" />
                                                </div>
                                                
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => submitHandler()}>{"Solicitar"}</button>
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/login`)}>{"Voltar ao login"}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
};
export default RecoveryPasswordSolicitation;