import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import axios from 'axios';
import { CancelUpdateProfile, Create, Option, Salvar, TestEndpoint } from '../../constant';
import { useLocation } from 'react-router';
import { LOCAL_BACKEND_URL } from '../../data/urls';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { requestAuthConfig } from '../../utils/utilTools';

const EditTeam = ({ history }) => {

    const location = useLocation();

    const [name, setName] = useState('');

    const [teamModelId, setTeamModelId] = useState('');

    useEffect(() => {
        axios.get(`${LOCAL_BACKEND_URL}/team/${location.state.teamId}`, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                setName(res.data.team.name);

            })
            .catch(err => console.log(err));
    }, []);

    const submitHandler = event => {
        /*  event.preventDefault(); */

        axios.patch(`${LOCAL_BACKEND_URL}/team/${location.state.teamId}`, {
            'name': name,




        }, {
    headers: {
      'Authorization': `bearer ${localStorage.getItem('token')}`
    }
  })
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/base/times`);
            })
            .catch(err => {
                console.log(teamModelId)
                console.log(err.response.data);
            })
    };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = event => submitHandler();

    return (
        <div>
            <Breadcrumb parent="Relacionamentos" title="SMS" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between ml-3 mr-5">
                                    <h5>Editar Time</h5>
                                </div>
                            </div>
                            <div className="datatable-react mr-5 ml-5 form-group">
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <Label for="name">Nome</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nome da Mensagem"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            ref={
                                                register({
                                                    required: "O campo nome é obrigatório.",
                                                    minLength: 1
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="name" />
                                    </FormGroup>

                                    <div className="card-footer text-right">
                                        <button className="btn btn-danger" onClick={() => history.push(`${process.env.PUBLIC_URL}/base/times`)}>{CancelUpdateProfile}</button>
                                        <button className="btn btn-primary ml-3" onClick={handleSubmit} type="submit">{Salvar}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default EditTeam;