import React, { useState, useEffect } from "react";
import {
  Bairro,
  CepCheckout,
  Cidade,
  Complemento,
  Estado,
  Numero,
  Rua,
} from "../../constant";
import { mask, unMask } from "remask";
import axios from 'axios';
import 'react-credit-cards/es/styles-compiled.css';

export default function PaymentForm({
  children,
  setCardExpiry,
  setCardNumber,
  setCardName,
  setCardFocused,
  setCardCvc,
  setCep,
  setEstado,
  setCidade,
  setBairro,
  setRua,
  setComplemento,
  setNumero,
  cidade,
  rua,
  bairro,
  cep,
  estado,
  cardExpire,
  installments,
  total,
  setInstallmentSelected
}) {
  const cepPatterns = ["99999-999"];
  const cardExpiringDataPatterns = ["99/99"]
  const [expire, setExpire] = useState('');
  
  const setCepValue = (cepValue) => {
    setCep(maskGeneric(cepValue, cepPatterns));
  };

  const setExpireValue = (expireValue) => {
    setExpire(maskGeneric(expireValue,cardExpiringDataPatterns))
  }
  
  const maskGeneric = (maskValue, patterns) => {
    maskValue = unMask(maskValue);
    return mask(maskValue, patterns);
  };
  
  useEffect(() => {
    if (unMask(cep).length >= 8) {
      axios
        .get(`https://viacep.com.br/ws/${unMask(cep)}/json/`)
        .then((res) => {
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setCidade(res.data.localidade);
          setEstado(res.data.uf);
        })
        .catch((err) => console.log(err));
    } else {
      setRua("");
      setCidade("");
      setBairro("");
      setEstado("");
    }
  }, [cep]);
  const installmentsNumbers = [];

  for(let i = 0; i < installments; i++) {
    const installmentsValues = {
      installment: i,
      value: i === 0 ? Number(total).toFixed(2) : Number(total / i).toFixed(2)
    }
    installmentsNumbers.push(installmentsValues)                        
  }
  return (
    <>
      <div id="PaymentForm" >
        <div className="row">
          {children}
          <div className="col-md-6">
            <form className="cardForm">
              <div className='row'>
                <div className="col-md-12" >
                  <input
                    className="form-control"
                    type="tel"
                    name="number"
                    placeholder="Número do Cartão"
                    onChange={(e) => setCardNumber(e.target.value)}
                    onFocus={(e) => setCardFocused(e.target.name)}
                  />
                </div>
                <div className="col-md-12">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Nome Impresso no Cartão*"
                    onChange={(e) => setCardName(e.target.value)}
                    onFocus={(e) => setCardFocused(e.target.name)}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mt-1 ml-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="expiry"
                        value={expire}
                        placeholder="VALIDADE*"
                        /*onChange={(e) => {
                          setCardExpire(e.target.value)
                        }}*/
                        onChange={(e) => {
                          setExpire(maskGeneric(e.target.value, cardExpiringDataPatterns))
                          setCardExpiry(e.target.value)
                          console.log(expire)
                        }}
                        onFocus={(e) => setCardFocused(e.target.name)}
                        maxLength="5"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 mt-1">
                    <input
                      className="form-control"
                      type="number"
                      name="cvc"
                      placeholder="CVV*"
                      onChange={(e) => setCardCvc(e.target.value)}
                      onFocus={(e) => setCardFocused(e.target.name)}
                    />
                  </div>
                  <div className="col-md-6 ml-3">
                    <label for="parcelas"><div /></label>
                    <select id="parcelas" name="parcelas" className="form-control" onChange={(e) => setInstallmentSelected(e.target.value)}>
                      {
                        installmentsNumbers.map(installment => {
                          return <option key={installment.installment} value={installment.installment + 1}>{installment.installment + 1}x   R$ {installment.value}</option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>

            </form>
          </div>

        </div >
      </div >
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-12">
              <form className="card">
                <div className="card-body">
                  <div className="row">
                    {/* <!-- Force next columns to break to new line --> */}
                    <div className="w-100"></div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">{CepCheckout}</label>
                        <input
                          className="form-control"
                          type="text"
                          value={cep}
                          onChange={(e) => setCepValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{Rua}</label>
                        <input
                          value={rua}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label className="form-label">{Numero}</label>
                        <input className="form-control" type="text" onChange={(e) => setNumero(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{Complemento}</label>
                        <input className="form-control" type="text" onChange={(e) => setComplemento(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">{Bairro}</label>
                        <input
                          value={bairro}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{Cidade}</label>
                        <input
                          value={cidade}
                          className="form-control"
                          type="text"
                          onChange={(e) => setCidade(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label className="form-label">{Estado}</label>
                        <select className="form-control" value={estado}>
                          <option value={null}>Selecione o Estado</option>
                          <option value="SP">São Paulo</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="PR">Paraná</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RR">Roraima</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="DF">Distrito Federal</option>
                        </select>
                      </div>
                    </div>
                    {/* <!-- Force next columns to break to new line --> */}
                    <div className="w-100"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

